import React from 'react';
import { LineComponent, ResumeComponentLayout } from './resumeComponentLayout';
import { TbDownload } from 'react-icons/tb';
import useDisclosure from '../../hooks/useDisclosure';
import ModalComponent from '../Utils/Modal/Index';

function PackageDetails(props) {

    const { isOpen, onClose, onOpen } = useDisclosure();

    const showModal = (value) => {
        onOpen(true);
    };

    return (
        <>
            <ResumeComponentLayout medium={false} title={"détails colis"} onClick={() => console.log("sdfdsf")} >

                <div>

                    <LineComponent value={'Deux roues'} label={"Description"} />

                    <LineComponent value={'3 Heures'} label={"Valeur commerciale"} />


                    <div className='flex items-center justify-between'>
                        <span className='block font-bold text-slate-400 '>
                            Pièce(s) jointe(s)
                        </span>
                        <span className='block font-bold '>
                            <TbDownload className='py-0 my-0 text-2xl cursor-pointer ' />
                        </span>
                    </div>

                </div>
            </ResumeComponentLayout>

            <ModalComponent isOpen={isOpen} onClose={onClose} size="2xl" title={""}>

            </ModalComponent>
        </>
    );
}

export default PackageDetails;