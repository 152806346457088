import { isArray } from "lodash";
import moment from "./moment";

export const formatDate = (dateString, format) => {
  if (dateString.split(":").length > 2)
    dateString = dateString.replace(" 00:00:00", "");
  const dateObject = new Date(dateString);

  return dateObject.toLocaleString("fr-FR", format);
};

export const formatDefaultDate = (date, format = "DD/MM/YYYY") => {
  return moment(new Date(date)).format(format);
};

export const getUniqueItemsByKey = (array, key) => {
  const uniqueIds = new Set();
  return array.filter((item) => {
    if (!uniqueIds.has(item[key])) {
      uniqueIds.add(item.id);
      return true;
    }
    return false;
  });
};

export const getTimeFromDate = (dateParam) => {
  let date;
  if (dateParam) {
    date = new Date(dateParam);
  } else {
    date = new Date();
  }

  let hours = date.getHours().toString().padStart(2, "0");
  let minutes = date.getMinutes().toString().padStart(2, "0");

  return `${hours}:${minutes}`;
};

export const getDefaultDate = (dateParam) => {
  let date;
  if (dateParam) {
    date = new Date(dateParam);
  } else {
    date = new Date();
  }

  return date.toISOString().split("T")[0];
};

export const formatInputDate = (date) => {
  const d = date ? new Date(date) : new Date();

  const formattedDate = d.toISOString()
  // .split("T")[0];

  return formattedDate;
};

export const formatShippingData = (data) => {
  const p = data.packages[0];
  const c = data.courses[0];
  const formattedData = {
    shipping_id: data.id,
    shipping: {
      pickup_date: moment(data.pickup_date).format("YYYY-MM-DD"), //formatInputDate(data.pickup_date),
      delivery_date: moment(data.delivery_date).format("YYYY-MM-DD"),//formatInputDate(data.delivery_date),
      pickup_time: data.pickup_time,
      delivery_time: data.delivery_time,
      code_donneur: data.code_donneur || "",
      code_receveur: data.code_receveur || "",
      customer_ref: data.customer_ref || "",
      pickup_instruction: data.pickup_instruction || "",
      delivery_instruction: data.delivery_instruction || "",
      comment: data.comment || "",
      tracking_mail: data.tracking_mail || "",
      transport_type: data.transport_type || "",
      devises: data.devises || "",
      type_de_roue: data.type_de_roue || "",
      type_expedition: data.type_expedition || "",
    },
    package: p
      ? {
        width: p.width || "",
        length: p["length"] || "",
        height: p.height || "",
        weight: p.weight || "",
        weight_volume: p.weight_volume || "",
        description: p.description || "",
        value_in_douane: p.value_in_douane || "",
        statut: p.statut || "",
        montant_devi: p.montant_devi || "",
        nombre_devi: p.nombre_devi || "",
        incoterm: p.incoterm,
        additional_insurance: p.additional_insurance,
        additional_insuranec_amount: p.additional_insuranec_amount,
        devises: data.devises || ""
      }
      : undefined,
    course: c
      ? {
        package_description: c.package_description || "",
        additional_insurance_amount: c.additional_insurance_amount || "",
        valeur_douane: c.valeur_douane || "",
        porteur: c.porteur || "",
      }
      : undefined,
    detail_info_supplementaire: data.detail_info_supplementaire || {
      service_cdc: "",
      mode_de_transport: "",
      type_de_douane: "",
      aeroport_depart: "",
      type_de_numero: "",
      numero_commande: "",
      droits_douane_taxe: "",
      mawb: "",
      numero_facture: "",
      type_operation: "",
      type_vehicule: "",
      aeroport_arrive: "",
      numero_bon_vert: "",
      service: "",
      co2_emis: "",
      irc: "",
      numero_compte: "",
      supplements: "",
      frais_dedouanement: "",
      articles_exotiques: "",
    },

    information_importante: data.information_importante,
  };

  return formattedData;
};

export const formatNumberToFrench = (number, decimalPlaces = 2) => {
  if (typeof number !== "number" || isNaN(number)) {
    //throw new Error('Invalid number');
    return "";
  }

  if (typeof decimalPlaces !== "number" || decimalPlaces < 0) {
    throw new Error("Invalid decimal places");
  }

  const formattedNumber = number.toFixed(decimalPlaces);
  return parseFloat(formattedNumber).toLocaleString("fr-FR", {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  });
};

// Example usage:
const originalNumber = 18;
const formattedFrenchNumber = formatNumberToFrench(originalNumber, 2);

export const formatInterface = (type) => {
  let prefix = "";
  switch (type) {
    case "paris":
      prefix = "EX";
      break;
    case "medical":
      prefix = "EM";
      break;
    case "service":
      prefix = "ES";
      break;
    default:
      prefix = "";
      break;
  }

  return prefix;
};

export const jsonToFormData = (jsonData) => {
  const formData = new FormData();

  for (const key in jsonData) {
    if (jsonData.hasOwnProperty(key)) {
      const value = jsonData[key];

      if (Array.isArray(value)) {
        // If the value is an array, append each item with the same key
        for (let i = 0; i < value.length; i++) {
          formData.append(key, value[i]);
        }
      } else {
        // For other types, simply append the key-value pair
        formData.append(key, value);
      }
    }
  }

  return formData;
};

export const getFileUrl = (url) =>
  url ? `${process.env.REACT_APP_BACKEND_HOST}/public/${url}` : "";

export const extractRoles = (roles, key) => {
  const rolesArray = JSON.parse(roles);
  //console.log({ rolesArray })
  if (isArray(rolesArray)) {
    const parts = rolesArray?.map((r) => r.split("_")[key]);
    return parts.filter((p) => p?.length > 0);
  } else {
    return []
  }
};

export const calculInvoicePrice = (price, qty, facturationCode) => {
  let montantFuel =
    (price * qty * parseInt(facturationCode.split("|||")[1])) / 100;
  let montantPq = price * qty;
  let Tva = parseInt(facturationCode.split("|||")[2]) === 1 ? 0.2 : 0;
  let total_final = (montantPq + montantFuel) * Tva + montantPq + montantFuel;

  return total_final;
};

export const toFixed2 = (value) => {
  if (isNaN(value)) {
    return "-";
  } else if (value == 0) {
    return "0.00"
  } else if (parseFloat(value)) {
    return parseFloat(value).toFixed(2);
  } else {
    return value
  }
};

export const roundToTenth = (number) => {
  // Round to the closest 10th
  const roundedNumber = Math.round(number * 10) / 10;

  // Format to two decimal places
  const formattedNumber = Number(roundedNumber.toFixed(2));

  return formattedNumber;
}

export const getWeightVolume = (_package) => {
  if (_package) {
    const l = Number(_package["length"])
    const w = Number(_package.width)
    const h = Number(_package.height)

    //console.log({ l, h, w })

    if (!isNaN(l) && !isNaN(h) && !isNaN(w)) {
      return (l * w * h) / 5000
    }
  } else {
    return ""
  }
}

export const calculateWeightVolume = (_package) => {
  return _package ? (getWeightVolume(_package) ? toFixed2(roundToTenth(getWeightVolume(_package))) : 0) : 0
}

export const getShippingTotal = (selectedRows, facturationLines) => {
  let allTotalHT = 0;
  let allTotalFinal = 0;
  let allTotalTaxable = 0;
  let allTotalNonTaxable = 0;
  let allTotal = 0;
  let allTotalTva = 0;
  let allTotalFuel = 0;
  let allWeight = 0;
  let allWeightVolume = 0;
  let allQty = 0;
  let allFuelTaxable = 0;
  let allFuelNonTaxable = 0;
  let totalShipping = selectedRows.length;
  let allPrix = 0;
  let allPrixTaxable = 0;
  let allPrixNonTaxable = 0;
  let allTotalFinalTaxable = 0;
  let allTotalFinalNonTaxable = 0;

  const transportHash = new Map();

  selectedRows.forEach((shipping) => {
    const shippingLines = facturationLines.filter(
      (f) => f.shipping.id === shipping.id
    );
    const weight = shippingLines[0]?.shipping.packages[0]?.weight || 0;
    const weightVolume =
      shippingLines[0]?.shipping.packages[0]?.weight_volume || 0;
    const qty = shippingLines.reduce((acc, line) => acc + line.qte, 0);
    allWeight += weight;
    allWeightVolume += weightVolume;
    allQty += qty;
    //console.log({ allQty, allWeight })
    const totalHT = shippingLines.reduce((acc, line) => acc + line.total, 0);

    //Prix
    const totalPrix = shippingLines.reduce((acc, line) => acc + line.prix * line.qte, 0);
    const totalPrixTaxable = shippingLines
      .filter((s) => s.tva !== 0)
      .reduce((acc, line) => acc + line.prix * line.qte, 0);

    const totalPrixNonTaxable = shippingLines
      .filter((s) => s.tva === 0)
      .reduce((acc, line) => acc + line.prix * line.qte, 0);

    const totalFinal = shippingLines.reduce(
      (acc, line) => acc + line.total_final,
      0
    );

    const totalFinalTaxable = shippingLines
      .filter((s) => s.tva !== 0)
      .reduce((acc, line) => acc + line.total_final, 0);

    const totalFinalNonTaxable = shippingLines
      .filter((s) => s.tva === 0)
      .reduce((acc, line) => acc + line.total_final, 0);

    const totalTaxable = shippingLines
      .filter((s) => s.tva !== 0)
      .reduce((acc, line) => acc + line.total, 0);

    const totalNonTaxable = shippingLines
      .filter((s) => s.tva === 0)
      .reduce((acc, line) => acc + line.total, 0);

    const totalTva = shippingLines.reduce((acc, line) => acc + line.tva, 0);
    const totalFuel = shippingLines.reduce((acc, line) => acc + line.fuel, 0);

    const fuelTaxable = shippingLines
      .filter((s) => s.tva !== 0)
      .reduce((acc, line) => acc + line.fuel, 0);
    const fuelNonTaxable = shippingLines
      .filter((s) => s.tva === 0)
      .reduce((acc, line) => acc + line.fuel, 0);

    //transport type détails
    const transportType = shipping.transport_type;

    if (transportType) {
      //console.log({ transportType })
      const transportTypeLines = shippingLines.filter(line => line?.shipping.transport_type === transportType);

      const isTaxableAndNonTaxable = transportTypeLines.find(l => l.tva !== 0) && transportTypeLines.find(l => l.tva === 0)

      const _tva = transportTypeLines
        .filter((s) => s.tva !== 0)
        .reduce((acc, line) => acc + line.tva, 0)
      //new type
      if (!transportHash.has(transportType)) {
        transportHash.set(transportType, {
          totalHt: (totalTaxable + totalNonTaxable),
          tva: totalTva,
          totalTTC: totalFinal,
          _tva,
          isTaxableAndNonTaxable
        });
      } else {
        //exists
        const value = transportHash.get(transportType);

        if (value) {
          transportHash.set(transportType, {
            totalHt: value.totalHt + (totalTaxable + totalNonTaxable),
            tva: value.tva + totalTva,
            totalTTC: value.totalTTC + totalFinal,
            _tva,
            isTaxableAndNonTaxable
          });
        }
      }
    }

    allTotalHT += totalHT;
    allPrixTaxable += totalPrixTaxable;
    allPrixNonTaxable += totalPrixNonTaxable
    allPrix += totalPrix;
    allTotalFinal += totalFinal;
    allTotalTaxable += totalTaxable;
    allTotalNonTaxable += totalNonTaxable;
    allTotalTva += totalTva;
    allTotalFuel += totalFuel;
    allFuelTaxable += fuelTaxable;
    allFuelNonTaxable += fuelNonTaxable;
    allTotalFinalTaxable += totalFinalTaxable;
    allTotalFinalNonTaxable += totalFinalNonTaxable
  });

  /*   const x = 441 * 0.2
    console.log(x, toFixed2(x)) */

  return {
    allTotalHT,
    allTotalFinal,
    allTotalFinalTaxable,
    allTotalFinalNonTaxable,
    allTotalTaxable,
    allTotalNonTaxable,
    allTotal: allTotalTaxable + allTotalNonTaxable,
    allTotalFuel,
    allTotalTva,
    allWeight,
    allWeightVolume,
    allQty,
    totalShipping,
    allTotalWithFuel: allTotalHT + allTotalFuel,
    allTotalTaxableWithFuel: allTotalTaxable + allFuelTaxable,
    allTotalNonTaxableWithFuel: allTotalNonTaxable + allFuelNonTaxable,
    allFuelTaxable,
    allFuelNonTaxable,
    transportHash,
    allPrix,
    allPrixTaxable,
    allPrixNonTaxable,
  };
};

export const getFactureRef = (facture) => {
  const _year = facture.echeance?.split("-")?.[0];

  const year = _year ? `${_year[2]}${_year[3]}` : "24"

  //const filename = facture.doc_facture?.split("F-")?.[1]
  //sconsole.log({ filename })
  //const _name = filename?.split(".")?.[0]

  const r = formatFactureRef(`${year}-${facture.ref}`, facture.type, facture.statut)
  //const ref = _name ? `F-24-${_name}` : `F-24-${facture.ref}`
  return r
}

export const getShippingLinesTotals = (shippingLines) => {
  const weight = shippingLines[0]?.shipping.packages[0]?.weight || "-";
  const weight_volume =
    shippingLines[0]?.shipping.packages[0]?.weight_volume || "-";

  const allQty = shippingLines.reduce((acc, product) => acc + product.qte, 0);
  const totalTva = shippingLines.reduce((acc, product) => acc + product.tva, 0);
  const totalFuel = shippingLines.reduce(
    (acc, product) => acc + product.fuel,
    0
  );

  const totalHT = shippingLines.reduce(
    (acc, product) => acc + product.total,
    0
  );

  const totalTaxable = shippingLines
    .filter((s) => s.tva !== 0)
    .reduce((acc, product) => acc + product.total, 0);

  const totalNonTaxable = shippingLines
    .filter((s) => s.tva === 0)
    .reduce((acc, product) => acc + product.total, 0);

  return {
    weight,
    weight_volume,
    allQty,
    totalTva: totalTva,
    totalFuel: totalFuel,
    totalHT: totalHT,
    totalTaxable: totalTaxable,
    totalNonTaxable: totalNonTaxable,
    totalWithFuel: totalHT + totalFuel,
  };
};

export const getShippingsWithLines = (selectedRows, facturationLines) => {
  const roundToTenth = (number) => {
    // Round to the closest 10th
    const roundedNumber = Math.round(number * 10) / 10;

    // Format to two decimal places
    const formattedNumber = Number(roundedNumber.toFixed(2));

    return formattedNumber;
  }

  const sortedShipings = selectedRows.sort((a, b) => a.ref - b.ref);
  return sortedShipings.map((sh) => {

    const shipping = {
      ...sh,
      facturationLines: facturationLines.filter(line => line.shipping?.id === sh.id)
    }
    //const { facturationLines } = useFacturations();
    const shippingLines = shipping.facturationLines//.filter(f => f.shipping.id === data.id)
    //console.log({ shippingLines })
    const weight = shippingLines[0]?.shipping.packages[0]?.weight || "-"
    const shippingPackage = shippingLines[0]?.shipping.packages[0];
    const weight_volume = shippingPackage ? toFixed2(roundToTenth(getWeightVolume(shippingPackage))) : "-"
    //console.log({ data })
    //console.log({ shippingPackage })

    const allQty = shippingLines.reduce((acc, product) => acc + product.qte, 0);
    const totalTva = shippingLines.reduce((acc, product) => acc + product.tva, 0);
    const totalFuel = shippingLines.reduce((acc, product) => acc + product.fuel, 0);

    //const totalPrix = shippingLines.reduce((acc, product) => acc + product.prix, 0);
    const totalPrixTaxable = shippingLines.filter(s => s.tva !== 0).reduce((acc, product) => acc + product.prix * product.qte, 0);
    const totalPrixNonTaxable = shippingLines.filter(s => s.tva === 0).reduce((acc, product) => acc + product.prix * product.qte, 0);

    const totalHT = shippingLines.reduce((acc, product) => acc + product.total, 0);
    //const totalTaxable = shippingLines.filter(s => s.tva !== 0).reduce((acc, product) => acc + product.total, 0);
    //const totalNonTaxable = shippingLines.filter(s => s.tva === 0).reduce((acc, product) => acc + product.total, 0);

    const isParis = shipping.type === "paris";

    return {
      isParis,
      shippingLines,
      weight,
      weight_volume,
      allQty,
      totalTva,
      totalHT,
      totalPrixTaxable,
      totalPrixNonTaxable,
      totalFuel
    }
  })
}

export const getStartAndEndDateOfCurrentMonth = () => {
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth();

  // Get the first day of the month
  const startDate = new Date(currentYear, currentMonth, 1, 12);

  // Get the last day of the month
  const endDate = new Date(currentYear, currentMonth + 1, 1);

  // Formatting the dates to YYYY-MM-DD format
  const formattedStartDate = startDate.toISOString().split("T")[0];
  const formattedEndDate = endDate.toISOString().split("T")[0];
  const todayDate = today.toISOString().split("T")[0];
  return {
    startDate: formattedStartDate,
    endDate: formattedEndDate,
    todayDate
  };
};

export const blobToFile = (blob, fileName, mimeType) => {
  // Create a new Blob with specified type (or default to 'application/octet-stream')
  const type = mimeType || "application/octet-stream";
  const newBlob = new Blob([blob], { type });

  // Construct a File object from the Blob
  const file = new File([newBlob], fileName);

  return file;
};

export const formatFactureRef = (ref, _interface, type) => {
  let suffixe = "EX";
  const prefix = type === "avoir" ? "A" : "F"
  switch (_interface) {
    case "paris":
      suffixe = "EX";
      break;

    case "medical":
      suffixe = "EM";
      break;
    case "service":
      suffixe = "ES";
      break;

    default:
      break;
  }


  //const value = `FA-${moment(new Date()).format("YY")}-${ref}-${suffixe}` //`${suffixe}${ref}`;
  const value = `${prefix}-${ref}-${suffixe}`
  return value
};

export const formatShippingRef = (ref, type) => {
  let prefix = "EP-";
  switch (type) {
    case "paris":
      prefix = "EP-";
      break;

    case "medical":
      prefix = "EM-";
      break;
    case "service":
      prefix = "ES-";
      break;

    default:
      break;
  }

  return `${prefix}${ref}`;
};

export const formatShippingRef2 = (ref, type) => {
  let prefix = "EX";
  switch (type) {
    case "paris":
      prefix = "EX";
      break;

    case "medical":
      prefix = "EM";
      break;
    case "service":
      prefix = "ES";
      break;

    default:
      break;
  }

  return `${prefix}${ref}`;
};

export const getTableBGColor = (type) => {
  let value = "#efe6dc";
  switch (type) {
    case "paris":
      value = "#efe6dc";
      break;

    case "medical":
      value = "#b1d4e7";
      break;
    case "service":
      value = "#9ff9b0";
      break;

    default:
      break;
  }

  return value;
}


export const getAllShippings = () =>
  localStorage.getItem("allShippings") === "true";

export const isValidUrl = (url) => {
  try {
    new URL(url);
    return true;
  } catch (error) {
    return false;
  }
};

export const getTimeDifference = (endDate, startDate) => {
  const differenceInMilliseconds = new Date(endDate) - new Date(startDate);
  const differenceInSeconds = Math.floor(differenceInMilliseconds / 1000);
  return getTimeParts(differenceInSeconds);
};

export const getTimeParts = (time) => {
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = time % 60;

  return { hours, minutes, seconds, time: `${hours}:${minutes}:${seconds}` };
};

export const getNumberOfDays = (date1, date2) => {
  const oneDayInMilliseconds = 24 * 60 * 60 * 1000; // Number of milliseconds in a day

  // Create Date objects for the input dates
  const startDate = new Date(date1);
  const endDate = new Date(date2);

  // Calculate the time difference in milliseconds
  const timeDifference = endDate.getTime() - startDate.getTime();

  // Convert the time difference to days
  const numberOfDays = Math.floor(timeDifference / oneDayInMilliseconds);

  return numberOfDays;
};

export const convertFrenchDateToEnglish = (date) => {
  const [d, m, y] = date.split("-");
  return `${m}/${d}/${y}`;
};

export const combineDateAndTime = (date, time) => {
  // Split the date and time strings
  const dateParts = date?.split("-");
  const [year, month] = dateParts.map(Number);
  const [hours, minutes] = time.split(":").map(Number);

  const day = dateParts[2].slice(0, 2);

  //console.log({ date, time, year, month, day, hours, minutes })

  // Create a new Date object with the extracted values
  const combinedDate = new Date(year, month - 1, day, hours, minutes);

  return combinedDate;
};


export const getWebSite = (type) => {
  let value = "https://www.exnetparis.com";
  switch (type) {
    case "paris":
      value = "https://www.exnetparis.com";
      break;

    case "medical":
      value = "https://www.exnetmedicals.com";
      break;
    case "service":
      value = "https://www.exnetservices.com";
      break;

    default:
      break;
  }


  return value
};


