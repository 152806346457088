import React, { useEffect, useState } from "react";
import Select from "react-select";

const CivilitySelector = ({ showLabel = true, value, onChange }) => {
  const [types, setTypes] = useState([
    { value: "M", label: "Monsieur" },
    { value: "Mme", label: "Madame" },
  ]);

  const values = types.find((t) => t.value === value);

  return (
    <div>
      {showLabel && (
        <label
          for="types"
          className="block mt-1 mb-4 text-sm font-medium text-gray-900 uppercase form-label"
        >
          CHOIX DE CIVILITé
        </label>
      )}
      <Select
        options={types}
        value={values}
        onChange={(v) => onChange(v.value)}
        className={"text-base w-100"}
        placeholder={' CHOIX DE CIVILITE'}
      />
    </div>
  );
};

export default CivilitySelector;
