import React, { useCallback, useEffect, useState } from "react";

import DotLoader from "../../components/dotsLoader/DotLoader.jsx";
import { Link, useParams } from "react-router-dom";
import { Player } from "@lottiefiles/react-lottie-player";
import clsx from "clsx";
import {
  exnetSpaceColor,
  urlSplit,
} from "../../utils/Functions/otherFunctions.js";
import useAuth from "../../hooks/useAuth.jsx";
import useAuthorization from "../../hooks/useAuthorization.jsx";
import { ADMIN_MENUS } from "../../components/Utils/Selectors/MenuSelector.jsx";
import { userService } from "../../services/user/UserService.js";
import { SwitchComponent } from "../../components/Utils/Index.jsx";
import { toast } from "react-toastify";

function SidebarLayout({ menuData, isOpened }) {
  const { data, configuration } = menuData;
  const [menu, setMenu] = useState([]);
  const [showExtra, setShowExtra] = useState(false);
  const [activeLink, setactiveLink] = useState("");
  const { exnetSpace, role } = urlSplit();
  const [isLoading, setisLoading] = useState(false)
  const [contentData, setContentData] = useState({});

  useEffect(() => {
    setMenu(data);
    setShowExtra(configuration.showExtra);

    const fecthInformaton = async () => {
      try {
        userService.LastInformation().then(
          (response) => {

            setContentData({ ...response.data.data });
          },
          (error) => {

            console.log(error);
          }
        );
      } catch (error) {

        console.log("error", error);
      }
    };

    fecthInformaton();
  }, [data, configuration]);

  const MaFonction = (event, id) => {
    event.preventDefault();

    let tabl = document.querySelectorAll(".submenu");

    tabl.forEach((element) => {
      if (element.getAttribute("id") !== id) {
        let identifiant = element.getAttribute("id");
        let a = element.querySelector(`#${identifiant}  a`);
        a.nextElementSibling.style.display = "none";
        a.classList.remove("subdrop");
        a.classList.remove("subdrop");
      }
    });

    let element = document.getElementById(id);
    let a = element.querySelector(`#${id}  a`);

    if (!a.classList.contains("subdrop")) {
      let subMenus = element.querySelectorAll("ul");
      let subMenuItems = element.querySelectorAll("a");

      subMenus.forEach(function (subMenu) {
        subMenu.style.display = "none";
      });

      subMenuItems.forEach(function (subMenuItem) {
        subMenuItem.classList.remove("subdrop");
      });

      a.nextElementSibling.style.display = "block";
      a.classList.add("subdrop");
    } else {
      a.nextElementSibling.style.display = "none";
      a.classList.remove("subdrop");
    }

    let activeParentLink = document.querySelector(
      "#sidebar-menu ul li.submenu a.active"
    );
    if (activeParentLink) {
      let parentLi = activeParentLink.closest("li:last-of-type");

      // let parentLink = parentLi.querySelector("a:first-of-type");
      // parentLink.classList.add("active");
      // parentLink.click();
    }

    let activeSubmenuLinks = document.querySelectorAll(
      "#sidebar-menu ul li.submenu .submenu a.active"
    );
    activeSubmenuLinks.forEach(function (link) {
      let parentUl = link.closest("ul");
      parentUl.style.display = "block";
    });
  };

  const openSidebarMenu = () => {
    let activeLink = document.querySelector("li>a.active");

    if (activeLink) {
      let submenu = document.querySelectorAll(".submenu");

      submenu.forEach((tagHtml) => {
        tagHtml.classList.remove("active-bg");
      });

      let parent = activeLink.parentElement.parentElement.parentElement;
      let parentId = parent.getAttribute("id");

      let a = parent.querySelector(`#${parentId}  a`);
      let subMenus = parent.querySelectorAll("ul");
      let subMenuItems = parent.querySelectorAll("a");

      subMenus.forEach(function (subMenu) {
        subMenu.style.display = "none";
      });

      subMenuItems.forEach(function (subMenuItem) {
        subMenuItem.classList.remove("subdrop");
      });

      if (a && a.nextElementSibling) {
        a.nextElementSibling.style.display = "block";
        a.classList.add("subdrop");
        parent.classList.add("active-bg");
      }
    }
  };

  setTimeout(() => {
    openSidebarMenu();
  }, 1);

  const { user, setUser } = useAuth();

  const { isAdminMenuAllowed, isOpsMenuAllowed } = useAuthorization();

  const isMenuItemsVisible = useCallback(
    (label) => {
      const isAdminMenu = ADMIN_MENUS.find(
        (m) => m.label.toLowerCase() === label.toLowerCase()
      );
      //console.log({ label, isAdminMenu })
      if (isAdminMenu) {
        return isAdminMenuAllowed[isAdminMenu.value];
      } else {
        return true;
      }
    },
    [isAdminMenuAllowed]
  );

  const { imports, exports } = isOpsMenuAllowed;

  const isOpsMenuItemsVisible = useCallback(
    (label) => {
      const isImports = label.toLowerCase().includes("imports");
      const isExports = label.toLowerCase().includes("exports");

      //console.log({ label, isAdminMenu })
      if (isImports) {
        return imports;
      } else if (isExports) {
        return exports;
      } else {
        return true;
      }
    },
    [imports, exports]
  );

  const updateUserInfo = async (value) => {
    try {
      setisLoading(true)
      const toastsId = toast.loading("Modification en cours ...", {
        position: "bottom-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      userService.updateUser(
        {
          tracking_mail: value
        }
      ).then(
        (response) => {
          console.log(response)
          toast.update(toastsId, {
            render: "Modifcation effecutée",
            type: "success",
            isLoading: false,
            autoClose: 1000,
            position: "bottom-center",
          });
          setisLoading(false)
          const payload = { ...user, ...response.data.data }
          setUser(payload)
        },
        (error) => {
          console.log(error)
          toast.update(toastsId, {
            render: "Modification non effecuté",
            type: "error",
            isLoading: false,
            autoClose: 1000,
            position: "bottom-center",
          });

          setisLoading(false)
        }
      )
    } catch (error) {
      console.log(error)
      toast.error("Une erreur lors de la soumission", {
        position: "bottom-center",
        theme: "light",
        autoClose: 1000,
      });
      setisLoading(false)
    }
  }

  /*   
    const isOpsMenuItemsVisible = useCallback(
      (label) => {
        const isAdminMenu = ADMIN_MENUS.find(m => m.label.toLowerCase() === label.toLowerCase());
        //console.log({ label, isAdminMenu })
        if (isAdminMenu) {
          return isAdminMenuAllowed[isAdminMenu.value]
        } else {
          return true
        }
      },
      [isAdminMenuAllowed],
    ) */

  return (
    <div className="fixed h-full pb-10 mt-10 overflow-y-scroll" id="sidebar">
      <div className="sidebar-inner slimscroll">
        {menu.length === 0 ? (
          <div className="justify-center flex-1 text-center row d-flex align-center">
            <DotLoader />
          </div>
        ) : (
          <div id="sidebar-menu" className="pb-10 sidebar-menu ">
            <ul>
              {menu.map((menuItem, index) => (
                <React.Fragment key={index}>
                  {menuItem.liens ? (
                    <li
                      className="submenu"
                      onClick={(event) =>
                        MaFonction(event, "niveauId_" + index)
                      }
                      id={"niveauId_" + index}
                    >
                      <a href="#" className="hover:text-gray-600">
                        {/* <i className={menuItem.icon}></i>{" "} */}
                        {menuItem.icon}
                        {isOpened && (
                          <span className="hover:text-gray-600">
                            {" "}
                            {menuItem.libelle}
                          </span>
                        )}
                        {isOpened && (
                          <span className="menu-arrow hover:text-gray-600"></span>
                        )}
                      </a>
                      <ul style={{ display: "none" }}>
                        {menuItem.liens.map((subMenuItem, subIndex) => (
                          <li key={subIndex}>
                            <Link
                              to={subMenuItem.lien}
                              className={
                                subMenuItem.lien === window.location.pathname
                                  ? "active"
                                  : window.location.pathname.includes(
                                    subMenuItem.lien
                                  )
                                    ? "active flex items-center hover:text-gray-600"
                                    : " flex items-center hover:text-gray-600"
                              }
                            >
                              {subMenuItem.libelle}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </li>
                  ) : menuItem.icon ? (
                    <>
                      {/* {(menuItem.libelle === "Carnet d'adresses" || menuItem.libelle === "Expéditions archivées") && (
                        <hr className="w-[90%] mx-auto my-1" />
                      )} */}
                      {isMenuItemsVisible(menuItem.libelle) ? (
                        <li
                          className={
                            menuItem.lien === window.location.pathname
                              ? "active"
                              : window.location.pathname.includes(menuItem.lien)
                                ? "activeflex items-center"
                                : "flex items-center"
                          }
                        >
                          <Link
                            to={menuItem.lien}
                            className={"flex items-center"}
                          >
                            {/* <i className={menuItem.icon}></i>{" "} */}
                            {menuItem.icon}
                            {isOpened && (
                              <span className="ml-2"> {menuItem.libelle}</span>
                            )}
                          </Link>
                        </li>
                      ) : null}
                    </>
                  ) : (
                    <>
                      {/* {menuItem.libelle === "Archives" && (
                        <hr className="w-[90%] mx-auto my-1" />
                      )} */}

                      <li
                        style={{
                          marginTop: "12px",
                          fontSize: "15px",
                          //marginLeft: "15px",
                          marginLeft: "10px",
                          backgroundColor: exnetSpaceColor(exnetSpace),
                        }}
                        className={
                          "menu-title shadow-sm rounded-md cursor-pointer pl-10 py-1 text-center text-white"
                        }
                      >
                        {isOpened && <span> {menuItem.libelle}</span>}
                      </li>
                    </>
                  )}
                </React.Fragment>
              ))}
              {role === "client" && parseInt(user.calculatrice) === 1 && (
                <>
                  <li
                    className={
                      "calculatrice" === window.location.pathname
                        ? "active"
                        : window.location.pathname.includes("calculatrice")
                          ? "activeflex items-center"
                          : "flex items-center"
                    }
                  >
                    <Link
                      to={`/client/${exnetSpace}/dash/calculatrice`}
                      className={"flex items-center"}
                    >
                      {/* <i className={menuItem.icon}></i>{" "} */}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15.75 15.75V18m-7.5-6.75h.008v.008H8.25v-.008Zm0 2.25h.008v.008H8.25V13.5Zm0 2.25h.008v.008H8.25v-.008Zm0 2.25h.008v.008H8.25V18Zm2.498-6.75h.007v.008h-.007v-.008Zm0 2.25h.007v.008h-.007V13.5Zm0 2.25h.007v.008h-.007v-.008Zm0 2.25h.007v.008h-.007V18Zm2.504-6.75h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V13.5Zm0 2.25h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V18Zm2.498-6.75h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V13.5ZM8.25 6h7.5v2.25h-7.5V6ZM12 2.25c-1.892 0-3.758.11-5.593.322C5.307 2.7 4.5 3.65 4.5 4.757V19.5a2.25 2.25 0 0 0 2.25 2.25h10.5a2.25 2.25 0 0 0 2.25-2.25V4.757c0-1.108-.806-2.057-1.907-2.185A48.507 48.507 0 0 0 12 2.25Z"
                        />
                      </svg>

                      {isOpened && <span className="ml-2"> Calculatrice</span>}
                    </Link>
                  </li>
                </>
              )}
              {role === "client" && 
              (<li>
                <div className="flex items-center justify-between my-3">
                  <label
                    htmlFor="tracking_mail"
                    className="ml-5 text-base font-semibold text-gray-900 cursor-pointer "
                  >
                    Mail de  Tracking
                  </label>
                  <SwitchComponent
                    id="tracking_mail"
                    label="tracking_mail"
                    checked={user?.tracking_mail === 1}
                    onChange={(checked) => {
                      updateUserInfo(checked ? 1 : 0)
                      // setFormValue("tracking_mail", checked ? 1 : 0);
                    }}
                    checkedColor="#098721"
                  />
                </div>
              </li>)}
            </ul>


            {role === "client" && contentData.active_baniere === 1 && (
              <div
                className={clsx({
                  'bg-[#ba9964]': exnetSpace === 'paris',
                  'bg-[#098721]': exnetSpace === 'service',
                  'bg-[#3A8EBA]': exnetSpace === 'medical',
                  'bg-[#222]': exnetSpace !== 'paris' && exnetSpace !== 'service' && exnetSpace !== 'medical',
                }, "w-[220px] mx-auto py-4 px-2 text-center rounded-xl mt-10 shadow")}
                // track-portfolio
                // style={{ display: showExtra ? "" : "none" }}
                style={{
                  color: "#fff",
                  // backgroundColor: `${exnetSpaceColor(exnetSpace)}`,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignContent: "center",
                  }}
                >
                  {contentData?.with_image === 1 && contentData?.image_url && contentData?.image_url !== "" && (
                    <img
                      src={
                        process.env.REACT_APP_BACKEND_HOST +
                        "/" +
                        contentData?.image_url
                      }
                      alt={contentData?.image_url}
                      className={"h-50 rounded-lg mb-3"}
                      height={100}
                      width={100}
                    />
                  )}
                </div>

                <h4 className={"text-base text-center font-semibold font-normal mb-2"}>
                  {contentData?.libele}
                </h4>
                {/* <p className={"text-sm font-light text-center mb-3"}>
                  {contentData?.contenus?.replace(/\n/g, '<br>')}
                </p> */}
                <p className={"text-sm font-light text-center mb-3"}
                  dangerouslySetInnerHTML={{ __html: contentData?.contenus?.replace(/\n/g, '<br>') }}>
                </p>


                <a
                  href={"mailto:operations@exnetservices.com"}
                  className="px-3 py-1 text-sm font-normal text-center text-white border border-white rounded-lg"
                  style={{
                    backgroundColor: `${exnetSpaceColor(exnetSpace)}`,
                  }}
                >
                  Nous contacter
                </a>
              </div>
            )}

          </div>
        )}
      </div>
    </div>
  );
}

export default SidebarLayout;
