import React, { useEffect, useState } from "react";
import Select from "react-select";
import factureService from "../../../services/facture/factureService";

export default function FactureSelector({
    selectedValue,
    onSelect,
    showLabel = true,
    isDisabled,
    label,
    type,
    ...props
}) {
    const [items, setItems] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState(""); // New state for input text value

    useEffect(() => {
        const fechData = async () => { // Accept search term as a parameter
            setIsLoading(true);
            const { data: restData } = await factureService.getFactureRefs({ interface: type })

            if (restData.success) {
                setItems(
                    restData.data.map((el) => ({ ...el, label: el?.full_ref || el?.ref, value: el?.full_ref || el?.ref }))
                );
                setIsLoading(false);
            }
        };

        fechData(); // Call fetchDrivers with the initial value of the input text (empty string)
    }, [type]); // Add searchTerm to the dependency array

    const value = items.find((c) => c.value === selectedValue);

    const results = items//.filter(item => item.includes(searchTerm))

    return (
        <div>
            {/* {showLabel && (
                <label
                    htmlFor="drivers" // Use htmlFor instead of for for accessibility
                    className="block mb-2 text-sm font-medium text-gray-900  capitalize"
                >
                    {label || profil}
                </label>
            )} */}
            <Select
                options={results}
                value={value}
                onInputChange={(newValue) => setSearchTerm(newValue)} // Handle input text change
                onChange={(v) => {
                   
                    onSelect?.(v.value);
                }}
                isDisabled={isDisabled}
                className="text-base"
                {...props}
            />
        </div>
    );
}
