import { TbAddressBook, TbChartLine, TbLayoutDashboard, TbMotorbike, TbPackage, TbPackageExport, TbPackageImport, TbPackageOff } from "react-icons/tb";
import { returnExpeditionTypeLogo } from "../../../utils/Functions/otherFunctions";
import medicalImport from '../../../assets/img/svg/Medicals-import.svg'
import medicalExport from '../../../assets/img/svg/Medicals-export.svg'
import medicalcourse from '../../../assets/img/svg/Medicals.svg'
import expeditionArchiveLogo from '../../../assets/img/Icones/expedition/medicals.svg'
import dashLogo from '../../../assets/img/Icones/Dashboard/medicals.svg'
import contactLogo from '../../../assets/img/Icones/carnet/medicals.svg'
import archiveLogo from '../../../assets/img/Icones/archive/medicals.svg'
import boiteLogo from '../../../assets/img/Icones/boite/medicals.svg'
import freightLogo from '../../../assets/img/Icones/freight/medicals.svg'


export default {
    data: [
        {
            libelle: "Dashboard",
            // lien: "/client/medical/dash",
            icon: <img alt={'fd'} src={dashLogo} className={`mt-0 w-8 hover:text-black`} />,
            liens: [
                {
                    libelle: "Expéditions en cours",
                    lien: "/client/medical/dash",
                },
                {
                    libelle: "Expéditions livrées",
                    lien: "/client/medical/dash/livre",
                },
            ],
            
        },
        {
            libelle: "Mes commandes",
        },

        {
            libelle: "Exports",
            icon: <img alt={'fd'} src={medicalExport} className={`mt-0 w-8 hover:text-black`} />,
            liens: [
                {
                    libelle: "Nouvel export",
                    lien: "/client/medical/dash/export/new",
                },
                {
                    libelle: "Exports en cours",
                    lien: "/client/medical/dash/export/en-cours",
                },
                {
                    libelle: "Exports livrées",
                    lien: "/client/medical/dash/export/livre",
                },

            ],
        },
        {
            libelle: "Imports",
            icon: <img alt={'fd'} src={medicalImport} className={`mt-0 w-8 hover:text-black`} />,
            liens: [

                {
                    libelle: "Nouvel import",
                    lien: "/client/medical/dash/import/new",
                },
                {
                    libelle: "Imports en cours",
                    lien: "/client/medical/dash/import/en-cours",
                },
                {
                    libelle: "Imports livrées",
                    lien: "/client/medical/dash/import/livre",
                }
            ],
        },
        // {
        //     libelle: "Courses",
        //     icon: <i><TbMotorbike /> </i>,
        //     liens: [
        //         {
        //             libelle: "Nouvelle course",
        //             lien: "/client/medical/dash/courses-a-courses/new",
        //         },
        //         {
        //             libelle: "Courses en cours",
        //             lien: "/client/medical/dash/courses-a-courses/en-cours",
        //         },
        //         {
        //             libelle: "Courses livrées",
        //             lien: "/client/medical/dash/courses-a-courses/livre",
        //         },

        //     ],
        // },

        {
            libelle: "Carnet d'adresses",
            lien: "/client/medical/dash/carnet",
            icon: <img alt={'fd'} src={contactLogo} className={` w-8 hover:text-black`} />,
        },
        {
            libelle: "Expéditions archivées",
            lien: "/client/medical/dash/export/archive",
            icon: <img alt={'fd'} src={archiveLogo} className={` w-8 hover:text-black`} />,
        },

        {
            libelle: "Mes Statistiques",
        },
        {
            libelle: "Courses",
            lien: "/client/medical/dash/statiques/expeditions",
            icon: <img alt={'fd'} src={boiteLogo} className={` w-8 hover:text-black`} />,
        },
        {
            libelle: "Freight",
            lien: "/client/medical/dash/statiques/freight",
            icon: <img alt={'fd'} src={freightLogo} className={` w-8 hover:text-black`} />,
        }
    ],
    configuration: {
        showExtra: false,
        showHelp: false,
    }
};
