import React from 'react';
import { LineComponent, ResumeComponentLayout } from './resumeComponentLayout';

function AccountDetails(props) {
    return (<>
        <ResumeComponentLayout title={'détails du compte'} onClick={() => console.log('sdqsdqsdsqd')}>

            <div>
                <LineComponent value={'HECTOR'} label={"Donneur d'ordre"} />

                <LineComponent value={'DSQDSQD'} label={"Centre de facturation"} />

                <LineComponent value={'12345'} label={"Référence du client"} />

                <LineComponent value={'lorem@gmail.com'} label={"Mail de suivi"} />
            </div>
        </ResumeComponentLayout>
    </>

    );
}

export default AccountDetails;