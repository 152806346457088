import React from "react";
import NotificationDetailLayout from "./notificationDetail.layout.jsx";

function NotificationListLayout({notifications}) {
    return (
        <>
            {(!notifications || notifications.data.liens.length === 0) ? (
                <div className="notification-box">
                    <div className="msg-sidebar notifications msg-noti">
                        <div className="topnav-dropdown-header">
                            <span>Messages</span>
                        </div>
                        <div className="drop-scroll msg-list-scroll" id="msg_list" >
                            <ul className="list-box">
                                <li>
                                    <a href="#">
                                        <div className="list-item">
                                            <div className="list-left">
                                                <span className="avatar"> A </span>
                                            </div>
                                            <div className="list-body">
                                                <span className="message-author"> Aucun message </span>
                                                <span className="message-time">  </span>
                                                <div className="clearfix"></div>
                                                <span className="message-content">Vous n'avez aucun message</span>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="topnav-dropdown-footer">
                            <a href={notifications.link}>See all messages</a>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="notification-box">
                    <div className="msg-sidebar notifications msg-noti">
                        <div className="topnav-dropdown-header">
                            <span>Messages</span>
                        </div>
                        <div className="drop-scroll msg-list-scroll" id="msg_list" >
                            <ul className="list-box">
                                {notifications.data.liens.map((notification, index) => (
                                    <NotificationDetailLayout notification={notification} />
                                ))}
                            </ul>
                        </div>
                        <div className="topnav-dropdown-footer">
                            <a href={notifications.link}>See all messages</a>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default NotificationListLayout;
