import { createBrowserRouter, useParams } from "react-router-dom";
import FirstAuthPage from "./Pages/firstPage/firstAuth.page.jsx";
import Page404 from "./Pages/error/Page404.jsx";
import AuthPage from "./Pages/auth.page.jsx";
import Dash from "./dash.jsx";
import PasswordForgotPage from "./Pages/passwordForgot.page.jsx";
import { customerRouter } from "./routing/customer.router.jsx";
import { opsRouter } from "./routing/ops.router.jsx";
import { adminRouter } from "./routing/admin.router.jsx";
import Page500 from "./Pages/error/Page500.jsx";
import Page501 from "./Pages/error/Page501.jsx";
import ConditionGeneralComponent from "./components/ConditionGeneralComponent/ConditionGeneralComponent.jsx";
import PolitiqueConfidentiakiteComponent from "./components/ConditionGeneralComponent/PolitiqueConfidentiakiteComponent.jsx";
import ResetPassword from "./Pages/resetPassword.jsx";
import PasswordResetSentPage from "./Pages/PasswordResetSentPage.jsx";
import exploitationRouter from "./routing/exploitationRouter.jsx";
import { SideBare } from "./components/Layout/SideBare.jsx";
import ResumePage from "./Pages/resume/index.jsx";
import TrackingSummary from "./Pages/resume/tracking.jsx";


const routing = createBrowserRouter([
  {
    path: "/",
    element: <FirstAuthPage />,
  },
  /*  {
     path: "/exploitation/auth",
     element: <AuthPage roleProps={"exploitation"} />,
   }, */
  {
    path: "/admin/forgot-password",
    element: <PasswordForgotPage />,
  },
  {
    path: "/admin/reset-password",
    element: <ResetPassword />,
  },
  {
    path: "/admin/reset-password",
    element: <ResetPassword />,
  },
  {
    path: "/:milieu/suivis",
    element: <TrackingSummary />,
    children: exploitationRouter,
  },
  {
    path: "/:role",
    element: <FirstAuthPage />,
  },
  {
    path: "/:role/:milieu",
    element: <FirstAuthPage />,
  },
  {
    path: "/:role/:milieu/auth",
    element: <AuthPage />,
  },
  {
    path: "/:role/:milieu/forgot-password",
    element: <PasswordForgotPage />,
  },
  {
    path: "/:role/:milieu/reset-password",
    element: <ResetPassword />,
  },
  {
    path: "/password-reset-sent",
    element: <PasswordResetSentPage />,
  },
  {
    path: "/client/:milieu/dash",
    element: <Dash role={"client"} />,
    children: customerRouter,
  },
  {
    path: "/ops/:milieu/dash",
    element: <Dash role={"ops"} />,
    children: opsRouter,
  },
  {
    path: "/admin",
    element: <AuthPage roleProps={"admin"} />,
  },
  {
    path: "/admin/auth",
    element: <AuthPage roleProps={"admin"} />,
  },
  {
    path: "/admin/dash",
    element: <Dash role={"admin"} />,
    children: adminRouter,
  },
  {
    path: "/cgv/:EspaceExnet",
    element: <ConditionGeneralComponent />,
  },
  {
    path: "/politique",
    element: <PolitiqueConfidentiakiteComponent />,
  },
  {
    path: "/test",
    element: <ResumePage />,
  },
  {
    path: "/error/500",
    element: <Page500 />,
  },
  {
    path: "/error/501",
    element: <Page501 />,
  },
  {
    path: "/not-found",
    element: <Page404 />,
  },
  {
    path: "/*",
    element: <Page404 />,
  },
]);

export default routing;
