import React, { useCallback, useEffect, useRef, useState } from 'react';
import opsService from '../../../services/ops/opsService';
import moment from "moment"
import ExpeditionService from '../../../services/expedition/ExpeditionService';
import { useDispatch, useSelector } from 'react-redux';
import { setExpedition } from '../../../features/slices/expeditionSlice';
import { isArray } from 'lodash';
import { getUniqueItemsByKey } from '../../../utils';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { urlSplit } from '../../../utils/Functions/otherFunctions';

function FichiersTabsComponent({ expedition, formVisible = true }) {
    const expeditionDetails = useSelector(state => state.expedition.expeditions);
    const dispatch = useDispatch();
    const fileRef = useRef();
    const { exnetSpace } = urlSplit();

    const [files, setFiles] = useState([])
    const [filesLists, setFilesLists] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [isUploading, setIsUploading] = useState(false);

    /*     const getFiles = useCallback((shipping_id) => {
            const loadFiles = async () => {
                try {
                    setIsLoading(true)
                    const { data } = await opsService.getFiles({
                        shipping_id
                    });
    
                    if (data.success) {
                        setFilesLists(f => [...f, ...data.data])
                    }
                } catch (error) {
                    console.error(error)
                } finally {
                    setIsLoading(false)
                }
            }
    
            loadFiles()
    
        }, []) */

    /*     useEffect(() => {
            if (expedition) {
                getFiles(expedition.id)
            }
        }, [expedition, isLoading])
     */

    useEffect(() => {
        if (expedition) {
            let docs = []
            if (expedition.doc_accompagnement) {
                docs = [...docs, ...expedition.doc_accompagnement?.map((f, i) => ({
                    name: `Doc d'accompagnement ${i + 1}`,
                    type: "PDF",
                    created_at: expedition.created_at,
                    document: f
                }))]
            }

            if (expedition.doc_borderaux) {
                docs = [...docs, {
                    name: `Bordereaux`,
                    type: "PDF",
                    created_at: expedition.created_at,
                    document: expedition.doc_borderaux
                }]
            }

            if (expedition.documents) {
                docs = [...docs, ...expedition?.documents?.map(d => ({ ...d, name: d.nom }))]
            }

            setFilesLists(docs)
        }
    }, [expedition])

    const onFileChange = (e) => {
        const selectedFiles = e.target.files;
        //console.log({ selectedFiles })
        setFiles(Array.from(selectedFiles))
    }

    const handleSubmit = useCallback(async () => {
        if (expedition) {
            try {
                setIsUploading(true);
                const formData = new FormData();
                formData.append("shipping_id", expedition.id);
                files.forEach(file => {
                    formData.append("files[]", file);
                })

                const { data } = await opsService.addFiles(formData);

                if (data.success) {

                    if (data.data) {
                        const lastAdded = [...data.data].slice(-files.length);

                        //console.log({ lastAdded, l: files.length })
                        setFilesLists(f => [...f, ...lastAdded?.map(d => ({ ...d, name: d.nom }))])

                        const end = lastAdded.length > 1 ? "s" : ""
                        toast.success(`Fichier${end} ajouté${end} avec succès`, {
                            position: "bottom-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                    setFiles([])


                    //getFiles(expedition.id)
                    /* ExpeditionService.getExpeditionDetails({ ref: expeditionDetails.ref }).then(
                        (data) => {
                            const { data: expedition } = data.data;
                            dispatch(setExpedition(expedition));
                        },
                        (error) => {
                            console.log(error);
                        }
                    ); */
                }

            } catch (e) {
                console.error(e)
            } finally {
                setIsUploading(false)
            }
        }
    }, [expedition, files])

    const valid = files.length > 0;

    const onDelete = useCallback(async (file, index) => {
        console.log({ file, index })
        try {
            Swal.fire({
                title: "Êtes-vous sûr de vouloir supprimer ce fichier ?",
                text: "Cette action est irréversible !",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#d33",
                cancelButtonColor: exnetSpace === 'paris'?"#ba9964":(exnetSpace === 'service'?"#098721":(exnetSpace === 'medical'?"#3A8EBA":"#222")),
                confirmButtonText: "Oui, supprimer",
                cancelButtonText: "Annuler",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    //delete result
                    const type = file.document.includes("accompagnement") ? "accompagnement" : /* file.document.includes("bordereau") ? "bordereau" : */ "supplementaire";
                    const file_name = file.document.split("/").pop()
                    const payload = {
                        shipping_id: expedition.id,
                        file_name,
                        type
                    }
                    const { data } = await opsService.deleteFile(payload);
                    if (data.success) {
                        setFilesLists(_files => _files.filter((_, i) => i !== index))
                    }
                } else {
                    /* Swal.fire(
                        "Annuler",
                        "Suppression annulée avec succès",
                        "error",
                        500
                    ); */
                }
            })
        } catch (error) {
            console.error(error)
        }
    }, [expedition])


    const lists = getUniqueItemsByKey(filesLists, "document") /* Array.isArray(expedition.doc_accompagnement) ? [...expedition.doc_accompagnement?.map((f, i) => ({
        name: `Doc d'accompagnement ${i + 1}`,
        type: "PDF",
        created_at: expedition.created_at,
        document: f
    })), ...filesLists.map] : []


     */

    //console.log({ lists })



    return (
        <div className="row">
            {formVisible && <div className="row">
                <div className="mb-2">
                    <label className="block mb-2 text-sm font-semibold text-gray-900 " for="default_size">Ajout de fichiers</label>
                    <div className="flex items-center">
                        <input
                            className="w-full mb-5 text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none "
                            id="default_size"
                            type="file"
                            hidden
                            ref={fileRef}
                            onChange={onFileChange}
                            multiple
                        />
                        <div className="flex items-center px-3 py-2.5 rounded-md border cursor-pointer border-gray-200 bg-white w-full max-w-xl"
                            onClick={() => {
                                fileRef.current?.click()
                            }}>
                            <i class="fa fa-solid fa-file text-lg"></i>
                            <div className="ml-4 text-sm text-gray-700">
                                {valid ? `${files?.map(f => f.name).join(",")}` : " Ajouter un document..."}
                            </div>
                        </div>
                        <button
                            className={` ${valid ? "cursor-pointer" : "cursor-not-allowed"} ml-4 text-white bg-black hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm px-5 py-2.5 text-center`}
                            onClick={handleSubmit}
                            disabled={!valid || isUploading}                            >
                            {isUploading ? "Validation..." : "Valider"}
                        </button>
                    </div>

                </div>

            </div>}

            <div className="row">
                <div className="table-responsive">
                    <table className="table mb-0">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>NOM DU DOCUMENT</th>
                                <th>Ajouté le </th>
                                <th>Type </th>
                                <th>Actions </th>
                            </tr>
                        </thead>
                        <tbody>
                            {Array.isArray(lists) && <>
                                {lists?.map((file, i) => (
                                    <tr key={i} className="text-lg">
                                        <td>{i + 1}</td>
                                        <td>{file.name} </td>
                                        <td>{moment(file.created_at).format("DD-MM-YYYY")}</td>
                                        <td className="uppercase">{file.type}</td>
                                        <td>
                                            <div className="flex items-center">
                                                <div className="mr-4 cursor-pointer">
                                                    <a target='_blank' href={`${process.env.REACT_APP_BACKEND_HOST}/${file.document}`} download className="text-gray-700">
                                                        <span title="Télécharger">
                                                            <i class="fas fa-solid fa-download"></i>
                                                        </span>
                                                    </a>
                                                </div>
                                                {file.name?.toLowerCase().includes("bordereau") ? null : <div
                                                    className={`text-red-500 cursor-pointer text-[14px]`}
                                                    onClick={() => {
                                                        //onDeleteConfirmOpen()
                                                        onDelete(file, i)
                                                    }}
                                                    title="Supprimer"
                                                >
                                                    <i className="fas fa-trash"></i>
                                                </div>}
                                            </div>

                                        </td>
                                    </tr>
                                ))
                                }
                            </>
                            }
                        </tbody>
                    </table>
                </div>
            </div>


        </div>
    );
}

export default FichiersTabsComponent;