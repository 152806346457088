import React, { useState } from "react";
import img1 from "../../assets/img/img1.jpg";
import { Player } from "@lottiefiles/react-lottie-player";
import { exnetSpaceColor, urlSplit } from "../../utils/Functions/otherFunctions";
import { TbUser } from "react-icons/tb";
const ProfilePage = React.memo((props) => {
    let data = JSON.parse(localStorage.getItem("persist:root"));

    const { exnetSpace } = urlSplit()
    return (
        <>
            <div className="content">
                <div className="row">
                    <div className="col-sm-7 col-6">
                        <h4 className="page-title">Mon compte</h4>
                    </div>

                </div>
                <div className="card-box profile-header">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="profile-view">
                                <div className="profile-img-wrap">
                                    <div className="profile-img">
                                        <a href="#">
                                            <TbUser size={100} />
                                        </a>
                                    </div>
                                </div>
                                <div className="profile-basic">
                                    <div className="row">
                                        <div className="col-md-5">
                                            <div className="profile-info-left">
                                                <h3 className="user-name m-t-0 mb-0">
                                                    {data.name.replace(/['"]+/g, '')}
                                                </h3>
                                                <small className="text-base staff-id">
                                                    {data.is_actif.replace(/['"]+/g, '') === "1" ? (
                                                        <span className="badge " style={{ backgroundColor: `${exnetSpaceColor(exnetSpace)}` }}>
                                                            Actif
                                                        </span>
                                                    ) : (
                                                        <span className="badge bg-danger ">
                                                            Inactif
                                                        </span>
                                                    )}
                                                </small>

                                            </div>
                                        </div>
                                        <div className="col-md-7">
                                            <ul className="personal-info text-sm">
                                                <li>
                                                    <span className="title text-sm">
                                                        Téléphone:
                                                    </span>
                                                    <span className="text text-sm">
                                                        {data.telephone1.replace(/['"]+/g, '')}
                                                    </span>
                                                </li>
                                                <li>
                                                    <span className="title text-sm">
                                                        Email:
                                                    </span>
                                                    <span className="text text-sm">
                                                        {data.email.replace(/['"]+/g, '')}
                                                    </span>
                                                </li>
                                                <li>
                                                    <span className="title text-sm">
                                                        Adresse:
                                                    </span>
                                                    <span className="text text-sm">
                                                        {data.address1.replace(/['"]+/g, '') === "null" ? (
                                                            <span className="">
                                                                Aucune adresse
                                                            </span>
                                                        ) : (
                                                            <span className="">
                                                                {data.address1.replace(/['"]+/g, '')}
                                                            </span>
                                                        )}
                                                    </span>
                                                </li>
                                                <li>
                                                    <span className="title text-sm">
                                                        Code postal:
                                                    </span>
                                                    <span className="text text-sm">
                                                        {data.postal_code.replace(/['"]+/g, '') === "null" ? (
                                                            <span className="text-sm">
                                                                Aucun code postal
                                                            </span>
                                                        ) : (
                                                            <span className=" text-sm">
                                                                {data.postal_code.replace(/['"]+/g, '')}
                                                            </span>
                                                        )}
                                                    </span>
                                                </li>
                                                <li>
                                                    <span className="title text-sm">
                                                        Créer le:
                                                    </span>
                                                    <span className="text text-sm">
                                                        {new Date(data.created_at.replace(/['"]+/g, '')).toLocaleDateString()}
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="profile-tabs">
                    <ul className="nav nav-tabs nav-tabs-bottom">
                        <li className="nav-item">
                            <a
                                className="nav-link active"
                                href="#about-cont"
                                data-bs-toggle="tab"
                            >
                                About
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                className="nav-link"
                                href="#bottom-tab2"
                                data-bs-toggle="tab"
                            >
                                Centre de facturation
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                className="nav-link"
                                href="#bottom-tab3"
                                data-bs-toggle="tab"
                            >
                                Contacts
                            </a>
                        </li>
                    </ul>

                </div>
            </div>
        </>
    );
})

export default ProfilePage;
