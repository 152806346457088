import React, { useCallback, useEffect, useState } from "react";
import NexExpeditionReferenceComponent from "../../components/NewExpedition/NexExpeditionReferenceComponent";
import NewExpeditionEnlevementLivraisonDetailComponent from "../../components/NewExpedition/NewExpeditionEnlevementLivraisonDetailComponent";
import NewExpeditionExpeditorComponent from "../../components/NewExpedition/NewExpeditionExpeditorComponent";

import NewExpeditionColisComponent from "../../components/NewExpedition/NewExpeditionColisComponent";
import { expeditionTable } from "../../utils/SpaceData";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Head from "../../components/HeadComponent/Head";
import NewExpeditionColisCoursComponent from "../../components/NewExpedition/NewExpeditionColisCoursComponent";
import { ToastContainer, toast } from "react-toastify";
import { LoadingComponent } from "../../utils/loadingUtils";
import ExpeditionService from "../../services/expedition/ExpeditionService.js";
import Accordion from "../../components/accordionComponent/AccordionComponent";
import {
  actuallDate,
  compareDate,
  compareTwoDate,
  exnetSpaceColor,
  getCurrentTime,
  urlSplit,
} from "../../utils/Functions/otherFunctions";
import useAuth from "../../hooks/useAuth";
import Select from "react-select";
import {
  VerifyStep_3,
  VerifyStep_34,
  checkUECountries,
  exportStep_5,
  verifyProformaData,
  verifyProformaHeader,
  verifyStep_4,
} from "../../utils/Functions/verifyDropdown";
import NewClientShippingRederenceComponent from "../../components/NewExpedition/NewClientShippingRederence";
import useDisclosure from "../../hooks/useDisclosure";
import ModalComponent from "../../components/Utils/Modal/Index";
import PolitiqueConfidentiakiteComponent from "../../components/ConditionGeneralComponent/PolitiqueConfidentiakiteComponent";
import ConditionGeneralComponent from "../../components/ConditionGeneralComponent/ConditionGeneralComponent";
import ProformaComponent from "../../components/NewExpedition/ProformaComponent";
import TypedeRoueSelector from "../../components/Utils/Selectors/TypedeRoueSelector";
import {
  getCountryNameOrCode,
  holydayDate,
} from "../../utils/const/countriesConstant";
import clsx from "clsx";
import BackButton from "../../components/backButton/BackButton.jsx";

const InitialData = {
  civility: "Mme",
  company: "",
  fullname: "",
  address1: "",
  address2: "",
  country: "fr",
  postalCode: "",
  city: "",
  phonecode: "+33",
  phone1: "",
  phone2: "",
  email: "",
  "save-contact": 0,
  "update-contact": 0,
};

const NewShippingPage = React.memo((props) => {
  // Paramètre URL
  const { ExpeditionType } = useParams();
  // Les states
  const [errorInformation, setErrorInformation] = useState({
    field: "",
    message: "",
  });
  const [dupliquer, setDupliquer] = useState(1);
  const [nombreColis, setNombreColis] = useState(1);
  const navigate = useNavigate();
  const [overlay, setOverlay] = useState(false);
  const [espace, setEspace] = useState("");
  const [activeAccordion, setActiveAccordion] = useState(1);
  const [activeAccordionList, setActiveAccordionList] = useState([1]);
  const [delais, setDelais] = useState(null);
  const { isAuthenticated, user } = useAuth();
  const [proforma, setproforma] = useState(false);
  const [proformaHeader, setProformaHeader] = useState({});
  const [proformaList, setProformaList] = useState([{}]);
  const { pathname } = useLocation();


  const {
    role,
    exnetSpace,
    expeditionType,
    expedition: TypeExpedition,
  } = urlSplit();

  // **************** Liste des stats ***********************
  // Pour stocker les données sur l'expedititeur

  const [expeditor, setExpeditor] = useState({ ...InitialData });

  const resetExpeditorData = () => {
    setExpeditor({ ...InitialData });
  };

  const [receiver, setReceiver] = useState(InitialData);

  const [cgvPolitique, setCgvPolitique] = useState({
    condition: false,
    politique: false,
  });

  const updateCgvPolitique = (key, value) => {
    setCgvPolitique({
      ...cgvPolitique,
      [key]: value,
    });
  };

  const resetReceiverData = () => {
    setReceiver(InitialData);
  };

  // Pour stocker les details sur les colis
  const [colis, setColis] = useState([
    {
      length: "",
      width: "",
      height: "",
      weight: 1,
      weight_volume: "",
      description: "",
      incoterm: "exw",
      value_in_douane: "",
      devises: "eur",
      documents: [{}],
      ref: "",
    },
  ]);

  // pour stocker les consignes lors de l'expédition
  const [consignes, setConsignes] = useState({
    pickup_date: actuallDate(),
    delivery_date: actuallDate(),
    pickup_instruction: "",
    delivery_instruction: "",
    pickup_time: getCurrentTime(),
    delivery_time: getCurrentTime(delais),
  });

  // Pour stocker les data de colis pour courses à courses
  const [colisCours, setColisCours] = useState({
    package_description: "",
    additional_insurance_amount: "",
    additional_insurance: "",
    documents: [{}],
    created_at: "",
    valeur_douane: 2,
    porteur: "",
  });

  // Pour stocker les informations supplémentaires

  // Références du client
  const [infosSupp, setInfosSupp] = useState({
    user_id: user.id,
    bill_center_id: {},
    username: role.toLowerCase() === "client" ? user?.name : "",
    nomContact: "",
    customer_ref: "",
    tracking_mail: "",
    types: "",
    delais: "",
    transport_type: "",
    code_receveur: "",
    code_donneur: "",
    selected_user: null,
  });

  const verificationNiveau_3 = () => {
    let espace = pathname.split("/");
    espace = espace[espace.length - 2];
    const { valid, error: erreurNiveau_3, errorData } = (espace === 'import' || espace === 'export') ? VerifyStep_34(
      {
        ...consignes,
        delais: delais?.value,
        type_de_roue: infosSupp?.type_de_roue?.value
      },
      pathname.split("/")[2],
      pathname.split("/")[2],
      expeditor.country,
      receiver.country
    ) : VerifyStep_3(
      {
        ...consignes,
        delais: delais?.value,
        type_de_roue: infosSupp?.type_de_roue?.value
      },
      pathname.split("/")[2],
      pathname.split("/")[2],
      expeditor.country,
      receiver.country
    )


    if (erreurNiveau_3) {
      console.log(errorData)
      setErrorInformation({ ...errorData })
      // toast.error(erreurNiveau_3, {
      //   position: "top-center",
      //   autoClose: 500,
      // });
    } else {
      if (
        compareDate(consignes["pickup_date"]) &&
        compareTwoDate(consignes["delivery_date"], consignes["pickup_date"])
      ) {
        toggleAccordion(5);
      } else {
        toast.error(
          "La date de livraison doit être supérieur ou égal à la date d'enlevement.",
          {
            position: "top-center",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      }
    }
  };

  // ********************************* ETAT GLOBALE FIN *****************************************

  //**********************   Les fonctions  ******************************************

  // Pour changer l'état d'un accordion
  const toggleAccordion = (index) => {
    setActiveAccordion(index);
    let temporaryList = [...activeAccordionList];
    if (!temporaryList.includes(index)) {
      temporaryList.push(index);
    }

    setActiveAccordionList([...temporaryList]);
  };
  // Pour Ajouter un colis
  const handleAjouterColis = () => {
    setColis([
      ...colis,
      {
        length: 0,
        width: 0,
        height: 0,
        weight: 1,
        weight_volume: "",
        description: "",
        incoterm: "exw",
        value_in_douane: "",
        devises: "eur",
        documents: [],
        ref: "",
      },
    ]);
    let nbr = nombreColis + 1;
    setNombreColis(nbr);
  };

  // Pour supprimer un colis spécifique
  const multiply = (index, nombre) => {
    let colisData = [...colis];
    let multiplyColis = { ...colisData[index] };
    for (let i = 0; i < nombre; i++) {
      colisData.splice(index, 0, multiplyColis);
    }
    setColis([...colisData]);
  };

  // Supprimer un  colis
  const deletColis = (index) => {
    let colisData = [...colis];
    colisData.splice(index, 1);
    setColis([...colisData]);
  };

  // Mise à jour d'une donnée d'un colis spécifique
  const UpdateColisData = (index, Attribut, valeur) => {
    let newColis = [...colis];
    newColis[index][Attribut] = valeur;
    setColis(newColis);
  };

  const chargement = () => {
    setOverlay(true);

    toast.loading("Soumission du formulaire", {
      position: "top-right",
      theme: "light",
      toastId: "customId",
    });
  };

  const refresh = (render, type, isLoading = true) =>
    toast.update("customId", {
      render: "All is good",
      type: "success",
      isLoading: false,
      autoClose: 5000,
    });

  if (overlay) {
    setTimeout(() => {
      setTimeout(() => {
        setOverlay(false);
      }, 5000);
    }, 10000);
  }

  const resetAllState = () => {
    setInfosSupp({
      user_id: user.id,
      bill_center_id: "",
      username: role.toLowerCase() === "client" ? user?.name : "",
      nomContact: "",
      customer_ref: "",
      tracking_mail: "",
      types: "",
      delais: "",
      transport_type: "",
      code_receveur: "",
      code_donneur: "",
      selected_user: null,
    });

    setColisCours({
      package_description: "",
      additional_insurance_amount: "",
      additional_insurance: "",
      documents: [{}],
      created_at: "",
      valeur_douane: "",
      porteur: "",
    });

    setConsignes({
      pickup_date: actuallDate(),
      delivery_date: actuallDate(),
      pickup_instruction: "",
      delivery_instruction: "",
      pickup_time: getCurrentTime(),
      delivery_time: getCurrentTime(delais),
    });

    setColis([
      {
        length: "",
        width: "",
        height: "",
        weight: 1,
        weight_volume: "",
        description: "",
        incoterm: "exw",
        value_in_douane: "",
        devises: "eur",
        documents: [{}],
        ref: "",
      },
    ]);

    setCgvPolitique({
      condition: false,
      politique: false,
    });

    setReceiver(InitialData);
    setExpeditor(InitialData);
    setActiveAccordion(1);
    setActiveAccordionList([1]);
  };

  useEffect(() => {
    resetAllState();

    let espace = pathname.split("/");
    espace = espace[espace.length - 2];

    if (role === "client") {
      let data = { ...infosSupp };

      if (parseInt(user["2_roues"]) === 1) {
        data["type_de_roue"] = {
          label: "DEUX ROUES",
          value: 2,
        };
        setInfosSupp({ ...data });
      }

      if (parseInt(user["vt"]) === 1) {
        data["type_de_roue"] = {
          label: "QUATRE ROUES",
          value: 4,
        };
        setInfosSupp({ ...data });
      }


      if (espace === "export" || espace === "courses-a-courses") {
        setExpeditor({ ...user });
      }

      if (espace === "import") {
        setReceiver({ ...user });
      }
    }

    // ********************************
    setEspace(window.location.pathname.split("/")[2]);

    if (
      ExpeditionType === undefined ||
      !expeditionTable.includes(ExpeditionType)
    ) {
      navigate("/not-found");
    }

  }, [window.location.href]);

  const { isOpen, onClose, onOpen } = useDisclosure();

  const handleSoumission = () => {
    const { error: ErrorCourse, errorData: MessageCourse } =
      verifyStep_4(colisCours);
    const { error: ErrorColis, errorData: MessageColis } = exportStep_5(
      colis,
      exnetSpace
    );
    const { error: ErrorProforma, errorData: MessageProforma } =
      verifyProformaData(proformaList);
    const { error: ErrorProformaHeader, errorData: MessageProformaHeader } =
      verifyProformaHeader(proformaHeader);

    try {
      const expeditionTypeLowerCase = ExpeditionType.toLowerCase();
      const roleLowerCase = role.toLowerCase();

      const isOpsRole = roleLowerCase === "ops";

      if (
        ((expeditionTypeLowerCase === "import" ||
          expeditionTypeLowerCase === "export") &&
          !ErrorColis) ||
        (expeditionTypeLowerCase !== "import" &&
          expeditionTypeLowerCase !== "export" &&
          !ErrorCourse)
      ) {
        let authorizeSubmission =
          role.toLowerCase() === "client" &&
            cgvPolitique.condition &&
            cgvPolitique.politique
            ? true
            : role.toLowerCase() === "admin" || role.toLowerCase() === "ops"
              ? true
              : false;
        if (authorizeSubmission) {
          chargement();
          let newExpeditionData = new FormData();
          if (infosSupp.bill_center_id) {
            newExpeditionData.append(
              "shipping-info[billing_center_id]",
              infosSupp.bill_center_id?.value
            );
          }

          if (role.toLowerCase() === "ops" && proforma) {
            newExpeditionData.append(
              "proforma[nature]",
              proformaHeader?.nature
            );
            newExpeditionData.append(
              "proforma[num_proforma]",
              proformaHeader?.num_proforma
            );
            newExpeditionData.append(
              "proforma[date_export]",
              proformaHeader?.date_export
            );

            proformaList.map((item, index) => {
              newExpeditionData.append(
                `proforma[proforma_line][${index}][description]`,
                item.description
              );
              newExpeditionData.append(
                `proforma[proforma_line][${index}][qte]`,
                item.qte
              );
              newExpeditionData.append(
                `proforma[proforma_line][${index}][composition]`,
                item.composition
              );
              newExpeditionData.append(
                `proforma[proforma_line][${index}][hs_code]`,
                item.hs_code
              );
              newExpeditionData.append(
                `proforma[proforma_line][${index}][manifacturer]`,
                item.manifacturer
              );
              newExpeditionData.append(
                `proforma[proforma_line][${index}][prix_unitaire]`,
                item.prix_unitaire
              );
              newExpeditionData.append(
                `proforma[proforma_line][${index}][origin]`,
                item.origin
              );
            });
          }

          // newExpeditionData.append("shipping-info[user_id]", user.id);
          newExpeditionData.append(
            "shipping-info[user_id]",
            role === "client" ? user.id : infosSupp?.selected_user
          );
          newExpeditionData.append(
            "shipping-info[type_de_roue]",
            parseInt(infosSupp?.type_de_roue?.value) || 0
          );
          newExpeditionData.append(
            "shipping-info[customer_ref]",
            infosSupp.customer_ref
          );
          newExpeditionData.append("shipping-info[created_at]", actuallDate());
          newExpeditionData.append(
            "shipping-info[pickup_date]",
            consignes.pickup_date
          );
          newExpeditionData.append(
            "shipping-info[delivery_date]",
            consignes.delivery_date
          );
          newExpeditionData.append(
            "shipping-info[pickup_instruction]",
            consignes.pickup_instruction
          );
          newExpeditionData.append(
            "shipping-info[delivery_instruction]",
            consignes.delivery_instruction
          );
          newExpeditionData.append(
            "shipping-info[tracking_mail]",
            infosSupp.tracking_mail
          );
          newExpeditionData.append(
            "shipping-info[expeditor][civility]",
            expeditor?.civility
          );
          newExpeditionData.append(
            "shipping-info[expeditor][company]",
            expeditor.company && expeditor.company !== ""
              ? expeditor.company
              : expeditor?.firstName + " " + expeditor?.lastName
          );
          newExpeditionData.append(
            "shipping-info[expeditor][fullname]",
            expeditor.firstName + " " + expeditor.lastName
          );
          newExpeditionData.append(
            "shipping-info[expeditor][address1]",
            expeditor.address1
          );
          newExpeditionData.append(
            "shipping-info[expeditor][address2]",
            expeditor.address2
          );
          newExpeditionData.append(
            "shipping-info[expeditor][country]",
            getCountryNameOrCode(expeditor.country)
          );
          newExpeditionData.append(
            "shipping-info[expeditor][postalCode]",
            expeditor.postalCode
          );
          newExpeditionData.append(
            "shipping-info[expeditor][city]",
            expeditor.city
          );
          newExpeditionData.append(
            "shipping-info[expeditor][phonecode]",
            expeditor.phonecode || ""
          );
          newExpeditionData.append(
            "shipping-info[expeditor][phone1]",
            expeditor.phone1
          );
          newExpeditionData.append(
            "shipping-info[expeditor][phone2]",
            expeditor.phone2
          );
          newExpeditionData.append(
            "shipping-info[expeditor][email]",
            expeditor.email
          );
          newExpeditionData.append(
            "shipping-info[expeditor][lastName]",
            expeditor?.lastName
          );

          newExpeditionData.append(
            "shipping-info[expeditor][firstName]",
            expeditor?.firstName
          );

          newExpeditionData.append(
            "shipping-info[expeditor][update-contact]",
            expeditor["update-contact"] || 0
          );
          newExpeditionData.append(
            "shipping-info[expeditor][save-contact]",
            expeditor["save-contact"] || 0
          );

          if (expeditor["update-contact"] === 1) {
            newExpeditionData.append(
              "shipping-info[expeditor][contact_id]",
              expeditor["id"] || 0
            );
          }

          newExpeditionData.append(
            "shipping-info[receiver][update-contact]",
            receiver["update-contact"] || 0
          );
          newExpeditionData.append(
            "shipping-info[receiver][save-contact]",
            receiver["save-contact"] || 0
          );

          if (receiver["update-contact"] === 1) {
            newExpeditionData.append(
              "shipping-info[receiver][contact_id]",
              receiver["id"] || 0
            );
          }

          newExpeditionData.append(
            "shipping-info[receiver][civility]",
            receiver?.civility
          );
          newExpeditionData.append(
            "shipping-info[receiver][lastName]",
            receiver?.lastName
          );

          newExpeditionData.append(
            "shipping-info[receiver][firstName]",
            receiver?.firstName
          );
          newExpeditionData.append(
            "shipping-info[receiver][civility]",
            receiver?.civility
          );
          newExpeditionData.append(
            "shipping-info[receiver][company]",
            receiver.company !== ""
              ? receiver.company
              : receiver?.firstName + " " + receiver?.lastName
          );
          newExpeditionData.append(
            "shipping-info[receiver][fullname]",
            receiver.firstName + " " + receiver.lastName
          );
          newExpeditionData.append(
            "shipping-info[receiver][address1]",
            receiver.address1
          );
          newExpeditionData.append(`shipping-info[devises]`, colis[0]?.devises);
          newExpeditionData.append(
            "shipping-info[receiver][address2]",
            receiver.address2 ? receiver.address2 : ""
          );
          newExpeditionData.append(
            "shipping-info[receiver][country]",
            getCountryNameOrCode(receiver.country)
          );
          newExpeditionData.append(
            "shipping-info[receiver][postalCode]",
            receiver.postalCode ? receiver.postalCode : ""
          );
          newExpeditionData.append(
            "shipping-info[receiver][city]",
            receiver.city ? receiver.city : ""
          );
          newExpeditionData.append(
            "shipping-info[receiver][phonecode]",
            receiver.phonecode || ""
          );
          newExpeditionData.append(
            "shipping-info[receiver][phone1]",
            receiver.phone1 ? receiver.phone1 : ""
          );
          newExpeditionData.append(
            "shipping-info[receiver][phone2]",
            receiver.phone2 ? receiver.phone2 : ""
          );
          newExpeditionData.append(
            "shipping-info[receiver][email]",
            receiver.email ? receiver.email : ""
          );
          newExpeditionData.append(
            "shipping-info[pickup_time]",
            consignes.pickup_time
          );
          newExpeditionData.append(
            "shipping-info[delivery_time]",
            consignes.delivery_time
          );
          newExpeditionData.append("shipping-info[ref]", "");
          newExpeditionData.append(
            "shipping-info[username]",
            infosSupp.username
          );
          newExpeditionData.append(
            "shipping-info[code_donneur]",
            infosSupp.code_donneur
          );
          newExpeditionData.append(
            "shipping-info[code_receveur]",
            infosSupp.code_receveur
          );
          newExpeditionData.append("shipping-info[manifest]", "");
          newExpeditionData.append(
            "shipping-info[isops]",
            role.toLowerCase() === "client" ? 0 : 1
          );
          newExpeditionData.append("data-package[0][shipping_id]", "");
          colis.map((item, index) => {
            newExpeditionData.append(
              `data-package[${index}][length]`,
              item.length
            );
            newExpeditionData.append(
              `data-package[${index}][width]`,
              item.width
            );
            newExpeditionData.append(
              `data-package[${index}][height]`,
              item.height
            );
            newExpeditionData.append(
              `data-package[${index}][weight]`,
              item.weight
            );
            newExpeditionData.append(
              `data-package[${index}][weight_volume]`,
              item.weight_volume
            );
            newExpeditionData.append(
              `data-package[${index}][description]`,
              item.description
            );
            newExpeditionData.append(
              `data-package[${index}][incoterm]`,
              item.incoterm
            );
            newExpeditionData.append(
              `data-package[${index}][value_in_douane]`,
              item.value_in_douane
            );

            if (item.fichiers && item.fichiers.length > 0) {
              for (let i in item.fichiers[0]) {
                newExpeditionData.append(
                  `data-package[${index}][documents][]`,
                  item.fichiers[0][i].file
                );
              }
            }
            // else {
            //   newExpeditionData.append(`data-package[${index}][documents][]`, );
            // }

            newExpeditionData.append(
              `data-package[${index}][created_at]`,
              consignes.pickup_date
            );
            newExpeditionData.append(`data-package[${index}][ref]`, " derss");
          });
          newExpeditionData.append(
            "data-course[package_description]",
            colisCours.package_description
          );

          newExpeditionData.append(
            "data-course[additional_insurance_amount]",
            colisCours.additional_insurance_amount
          );

          newExpeditionData.append(
            "data-course[additional_insurance]",
            colisCours.additional_insurance_amount
          );

          let expFile = [];

          if (colisCours.documents.length > 0) {
            for (let i in colisCours.documents) {
              // expFile.push(colisCours.documents[i].file);
              newExpeditionData.append(
                "data-course[documents][]",
                colisCours.documents[i].file
              );
            }
          }
          // else {
          //   newExpeditionData.append(
          //     "data-course[documents][]",
          //     null
          //   );
          // }

          newExpeditionData.append("data-course[created_at]", actuallDate());

          newExpeditionData.append(
            "data-course[valeur_douane]",
            colisCours.valeur_douane
          );

          newExpeditionData.append("data-course[shipping_id]", "");
          newExpeditionData.append("data-course[porteur]", delais?.value);

          newExpeditionData.append(
            "type-expedition",
            expeditionType ? "expedition" : "courses"
          );

          newExpeditionData.append("interface", exnetSpace);

          ExpeditionService.createExpedition(newExpeditionData).then(
            (res) => {
              let { data, success, message } = res.data;
              let reponse = res.data;
              if (success) {
                toast.update("customId", {
                  render: message,
                  type: "success",
                  isLoading: false,
                  autoClose: 1000,
                });

                setTimeout(() => {
                  if (role.toLowerCase() === "client") {
                    navigate(`/client/${exnetSpace.toLowerCase()}/dash`);
                  } else {
                    navigate(`/ops/${exnetSpace.toLowerCase()}/dash`);
                  }
                }, 500);
              } else {
                toast.update("customId", {
                  render: message,
                  type: "error",
                  isLoading: false,
                  autoClose: 5000,
                });
              }
            },
            (error) => {
              toast.update("customId", {
                render: "Une erreur est survenue",
                type: "error",
                isLoading: false,
                autoClose: 500,
              });
            }
          );
        } else {
          toast.dismiss();

          // Pour les messages d'erreur liés aux chackbox
          if (!cgvPolitique.politique)
            setErrorInformation({
              field: "politique",
              message: "Vous devez accepter la politique",
            });

          if (!cgvPolitique.condition)
            setErrorInformation({
              field: "condition",
              message: "Vous devez accepter les cgv",
            });
        }
      } else {
        // Erreur pour les colis de courses à courses
        if (
          ExpeditionType !== "import" &&
          ExpeditionType !== "export" &&
          ErrorCourse
        ) {
          console.log("import ", MessageCourse);

          setErrorInformation({ ...MessageCourse });
        }

        // Erreur pour les colis de import ou export
        if (
          (ExpeditionType === "import" || ExpeditionType === "export") &&
          ErrorColis
        ) {
          console.log("courses ", MessageColis);

          setErrorInformation({ ...MessageColis });
        }

        // if (ErrorProforma) {
        //   console.log(ErrorProforma)
        //   setErrorInformation({ ...MessageProforma })
        // }

        // if (ErrorProformaHeader) {
        //   console.log(ErrorProformaHeader)
        //   setErrorInformation({ ...MessageProformaHeader })
        // }
      }
    } catch (error) {
      toast.dismiss();
      toast.error("Une erreur lors de la création de l'expedition", {
        position: "top-center",
        autoClose: 500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const [modalData, setmodalData] = useState("");

  const showModal = (value) => {
    setmodalData(value);
    onOpen(true);
  };

  useEffect(() => {
    if (exnetSpace === "medical") {
      const isWeekend = (date) => {
        const day = date.getDay();
        return day === 0 || day === 6; // Retourne true si c'est un dimanche (0) ou un samedi (6)
      };
      let datepickup = new Date(consignes.pickup_date);

      const isHoliday = (date) => {
        let newDate = new Date(date)
          .toLocaleDateString()
          .split("/")
          .slice(0, 2)
          .join("/");
        console.log("la nouvelle date 111 :", newDate);
        let result = holydayDate.indexOf("" + newDate);
        console.log("la nouvelle date 11 :", result);
        // holydayDate
        // TODO: Ajouter votre logique pour vérifier si la date est un jour férié
        // Utilisez une liste de jours fériés spécifique à votre région
        return result >= 0 ? true : false; // Pour l'instant, aucune vérification de jours fériés
      };

      // Vérifier si le pickup_date est un week-end ou un jour férié
      while (isWeekend(datepickup) || isHoliday(datepickup)) {
        if (datepickup.getDay() === 0) {
          datepickup.setDate(datepickup.getDate() + 1);
        } else {
          datepickup.setDate(datepickup.getDate() + 2);
        }
      }

      if (datepickup && !isNaN(datepickup.getTime())) {
        let minDeliveryDate = new Date(datepickup);
        let delivery_time = "";

        const isUEcountry = checkUECountries(
          expeditor.country,
          receiver.country
        );
        // Ajouter 2 jours
        if (isUEcountry) {
          minDeliveryDate.setDate(minDeliveryDate.getDate() + 2);
          delivery_time = "13:00";
        } else {
          minDeliveryDate.setDate(minDeliveryDate.getDate() + 3);
          delivery_time = "18:00";
        }

        // Vérifier si la date résultante est un week-end ou un jour férié
        while (isWeekend(minDeliveryDate) || isHoliday(minDeliveryDate)) {
          minDeliveryDate.setDate(minDeliveryDate.getDate() + 1);
        }

        let years = minDeliveryDate.getFullYear();
        let month = (minDeliveryDate.getMonth() + 1)
          .toString()
          .padStart(2, "0");
        let day = minDeliveryDate.getDate().toString().padStart(2, "0");

        setConsignes({
          ...consignes,
          delivery_date: `${years}-${month}-${day}`,
          delivery_time: delivery_time,
        });
      }
    }
  }, [
    consignes.pickup_date,
    consignes.delivery_time,
    expeditor.country,
    receiver.country,
  ]);






  return (
    <>
      <Head>
        <title>Exnet | Nouvelle Expédition</title>
      </Head>

      <BackButton />

      <LoadingComponent overlay={overlay} />

      <div className={"row px-3"}>
        {/* Pour les référende de l'expédition */}

        <div className="mt-5 row">
          <Accordion
            title={TypeExpedition}
            isOpen={activeAccordion === 1 ? true : false}
            toggleAccordion={toggleAccordion}
            activeAccordionList={activeAccordionList}
            index={1}
          >
            <div className="row">
              {role.toLowerCase() === "client" ? (
                <NewClientShippingRederenceComponent
                  type={ExpeditionType}
                  setInfosSupp={setInfosSupp}
                  infosSupp={infosSupp}
                  toggleAccordion={toggleAccordion}
                  ExpeditionType={ExpeditionType}
                  exnetSpace={exnetSpace}
                />
              ) : (
                <NexExpeditionReferenceComponent
                  type={ExpeditionType}
                  setInfosSupp={setInfosSupp}
                  infosSupp={infosSupp}
                  toggleAccordion={toggleAccordion}
                  ExpeditionType={ExpeditionType}
                />
              )}
            </div>
          </Accordion>

          <Accordion
            title={"DéTAILS EXPéDITEUR".toUpperCase()}
            isOpen={activeAccordion === 2 ? true : false}
            toggleAccordion={toggleAccordion}
            activeAccordionList={activeAccordionList}
            index={2}
          >
            {/* Pour l'xpéditeur */}
            <div className="row">
              <NewExpeditionExpeditorComponent
                expeditor={expeditor}
                setExpeditor={setExpeditor}
                toggleAccordion={toggleAccordion}
                resetData={resetExpeditorData}
                contactType={"expeditor"}
                exnetSpace={exnetSpace}
                key={2}
              />
            </div>
          </Accordion>

          <Accordion
            title={"DéTAILS DESTINATAIRE".toUpperCase()}
            isOpen={activeAccordion === 3 ? true : false}
            toggleAccordion={toggleAccordion}
            activeAccordionList={activeAccordionList}
            index={3}
          >
            {/* Pour les receveur */}
            <div className="row">
              <NewExpeditionExpeditorComponent
                expeditor={receiver}
                setExpeditor={setReceiver}
                toggleAccordion={toggleAccordion}
                resetData={resetReceiverData}
                contactType={"receiver"}
                exnetSpace={exnetSpace}
                key={1}
              />
            </div>
          </Accordion>

          <Accordion
            title={"DéTAILS ENLèVEMENT ET LIVRAISON".toUpperCase()}
            isOpen={activeAccordion === 4 ? true : false}
            toggleAccordion={toggleAccordion}
            activeAccordionList={activeAccordionList}
            index={4}
          >
            {/* Pour les détails de l'expédition */}
            <div className="row">
              {ExpeditionType !== "import" && ExpeditionType !== "export" && (
                <>
                  {role.toLowerCase() === "client" && <div className={"col-md-6"}>
                    {" "}
                    <TypedeRoueSelector
                      roue={infosSupp?.type_de_roue?.value}
                      onChange={(v) => {
                        let data = { ...infosSupp };
                        data["type_de_roue"] = v;
                        setInfosSupp({ ...data });
                      }}
                      showLabel={true}

                    //isDisabled={((parseInt(user["2_roues"]) === 1 && parseInt(user["vt"]) === 0) || (parseInt(user["2_roues"]) === 0 && parseInt(user["vt"]) === 1) ) ? true : false}
                    />

                    {errorInformation.field === "type_de_roue" &&
                      <p className="mt-2 text-sm text-red-600" >
                        {errorInformation?.message}
                      </p>
                    }

                  </div>}

                  {role.toLowerCase() !== "client" && <div className={"col-md-6"}>
                    {" "}
                    <TypedeRoueSelector
                      roue={infosSupp?.type_de_roue?.value}
                      onChange={(v) => {
                        let data = { ...infosSupp };
                        data["type_de_roue"] = v;
                        setInfosSupp({ ...data });
                      }}
                      showLabel={true}
                    />

                    {errorInformation.field === "type_de_roue" &&
                      <p className="mt-2 text-sm text-red-600" >
                        {errorInformation?.message}
                      </p>
                    }

                  </div>}

                  <div className={"col-md-6"}>
                    <div className="form-group">
                      <label htmlFor="field6">{"Délais de livraison".toUpperCase()}</label>
                      <Select
                        className="text-base"
                        options={
                          infosSupp?.type_de_roue?.value === 4
                            ? delaisData4
                            : delaisData
                        }
                        value={delais}
                        onChange={setDelais}
                        placeholder={"CHOIX DU DELAI DE LIVRAISON *".toUpperCase()}
                      />
                      {errorInformation.field === "delais" &&
                        <p className="mt-2 text-sm text-red-600" >
                          {errorInformation?.message}
                        </p>
                      }
                      <small className="ml-3">
                        Délais pour Paris intra-muros
                      </small>
                    </div>
                  </div>
                </>
              )}

              <div className="col-md-6">
                <NewExpeditionEnlevementLivraisonDetailComponent
                  kind={"enlevement"}
                  setConsignes={setConsignes}
                  consignes={consignes}
                  toggleAccordion={toggleAccordion}
                  delais={delais}
                  exnetSpace={exnetSpace}
                  key={1}
                />
              </div>

              <div className="col-md-6">
                <NewExpeditionEnlevementLivraisonDetailComponent
                  key={2}
                  kind={"livraison"}
                  setConsignes={setConsignes}
                  consignes={consignes}
                  toggleAccordion={toggleAccordion}
                  delais={delais}
                  exnetSpace={exnetSpace}
                  disabled={exnetSpace === "medical" ? true : false}
                />
              </div>

              <div className="row " style={{ justifyContent: "flex-end" }}>
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group w-100">
                        <button
                          className="btn w-100"
                          onClick={() => toggleAccordion(3)}
                          style={{
                            color: "#fff",
                            backgroundColor: `${exnetSpaceColor(exnetSpace)}`,
                          }}
                        >
                          Précédent
                        </button>
                      </div>
                    </div>

                    <div className="col-6">
                      <div className="form-group w-100">
                        <button
                          id={"next"}
                          className="btn w-100"
                          onClick={verificationNiveau_3}
                          style={{
                            color: "#fff",
                            backgroundColor: `${exnetSpaceColor(exnetSpace)}`,
                          }}
                        >
                          Suivant
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Accordion>

          <Accordion
            title={"DéTAILS COLIS".toUpperCase()}
            isOpen={activeAccordion === 5 ? true : false}
            toggleAccordion={toggleAccordion}
            activeAccordionList={activeAccordionList}
            index={5}
          >
            <>
              {/* Pour les articles */}
              {ExpeditionType === "import" || ExpeditionType === "export" ? (
                <>
                  {colis.map((item, index) => (
                    <div className="row">
                      <NewExpeditionColisComponent
                        index={index}
                        item={item}
                        key={`cle_${index}`}
                        UpdateColisData={UpdateColisData}
                        colis={colis}
                        deletColis={deletColis}
                        multiply={multiply}
                        exnetSpace={exnetSpace}
                        errorMessage={errorInformation}
                      />
                    </div>
                  ))}
                </>
              ) : (
                <>
                  <div className="row">
                    <NewExpeditionColisCoursComponent
                      setColis={setColis}
                      setColisCours={setColisCours}
                      colisCours={colisCours}
                      exnetSpace={exnetSpace}
                      errorMessage={errorInformation}
                    />
                  </div>
                </>
              )}

              {(ExpeditionType === "import" || ExpeditionType === "export") && (
                <>
                  <div className="flex-row-reverse row">
                    <div className="col-md-6">
                      <div className="flex-row-reverse row">
                        <div className="col-md-6">
                          <div className="flex-row-reverse row">
                            <div className="flex-col-reverse col">
                              <div className="form-group pe-3">
                                <button
                                  className="btn w-100"
                                  onClick={handleAjouterColis}
                                  style={{
                                    color: "#fff",
                                    backgroundColor: `${exnetSpaceColor(
                                      exnetSpace
                                    )}`,
                                  }}
                                >
                                  Ajouter Colis
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {role.toLowerCase() === "client" && (
                <>
                  <div className="flex flex-wrap items-center mb-6">
                    <div className="flex items-start mr-12">
                      <div className="flex items-center h-5">
                        <input
                          id="restreint"
                          type="checkbox"
                          checked={cgvPolitique.condition}
                          onChange={(e) => {
                            updateCgvPolitique(
                              "condition",
                              !cgvPolitique.condition
                            );
                          }}
                          className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300"
                        />
                      </div>
                      <label
                        for="restreint"
                        className="ml-2 text-sm font-medium text-gray-900 "
                      >
                        <span
                          className={" pointer"}
                          onClick={(e) => {
                            e.preventDefault();
                            showModal("condition");
                          }}
                        >
                          {" "}
                          En cochant cette case, je reconnais avoir pris
                          connaissance des{" "}
                          <span className={" underline "}>
                            Conditions Générales de Vente et de Transport EXNET
                          </span>{" "}
                          {exnetSpace.toUpperCase()}
                        </span>
                      </label>
                    </div>
                  </div>
                  {errorInformation.field === "condition" && (
                    <p className="mt-2 text-sm text-red-600" id={"full-error"}>
                      {errorInformation.message}
                    </p>
                  )}

                  <div className="flex flex-wrap items-center mb-6">
                    <div className="flex items-start mr-12">
                      <div className="flex items-center h-5">
                        <input
                          id="restreint"
                          type="checkbox"
                          checked={cgvPolitique.politique}
                          onChange={(e) => {
                            updateCgvPolitique(
                              "politique",
                              !cgvPolitique.politique
                            );
                          }}
                          className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300"
                        />
                      </div>
                      <label
                        for="restreint"
                        className="ml-2 text-sm font-medium text-gray-900 pointer "
                        onClick={(e) => {
                          e.preventDefault();
                          showModal("politique");
                        }}
                      >
                        <span>
                          {" "}
                          En cochant cette case, je reconnais avoir pris
                          connaissance du{" "}
                          <span className={"underline"}>
                            traitement des données personnelles
                          </span>{" "}
                          du site, et les accepte sans réserve.
                        </span>
                      </label>
                    </div>
                  </div>

                  {errorInformation.field === "politique" && (
                    <p className="mt-2 text-sm text-red-600" id={"full-error"}>
                      {errorInformation.message}
                    </p>
                  )}
                </>
              )}
            </>
          </Accordion>
        </div>

        {role.toLowerCase() !== "client" && (
          <>
            <div className="flex-row-reverse p-1 mb-5 row">
              {activeAccordion === 5 && (
                <div className="col-6 col-md-2">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn w-50"
                      onClick={() => setproforma(!proforma)}
                      style={{
                        color: "#fff",
                        backgroundColor: `${exnetSpaceColor(exnetSpace)}`,
                      }}
                    >
                      Proforma
                    </button>
                  </div>
                </div>
              )}

              {proforma && (
                <ProformaComponent
                  proformaHeader={proformaHeader}
                  setProformaHeader={setProformaHeader}
                  setProformaList={setProformaList}
                  proformaList={proformaList}
                  errorMessage={errorInformation}
                />
              )}
            </div>
          </>
        )}

        {activeAccordion === 5 && (
          <div className="flex-row-reverse mt-5 row pe-5">
            <div className="col-6 col-md-2">
              <div className="form-group">
                <button
                  disabled={activeAccordion === 5 ? false : true}
                  type="button"
                  className={clsx(
                    { " cursor-not-allowed ": activeAccordion !== 5 },
                    "btn w-100"
                  )}
                  onClick={handleSoumission}
                  // exnetSpace
                  style={{
                    color: "#fff",
                    backgroundColor: `${exnetSpaceColor(exnetSpace)}`,
                  }}
                >
                  Enregistrer
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      <ModalComponent isOpen={isOpen} onClose={onClose} size="2xl" title={""}>
        {modalData === "condition" ? (
          <ConditionGeneralComponent />
        ) : (
          <PolitiqueConfidentiakiteComponent />
        )}
      </ModalComponent>
    </>
  );
});

export default NewShippingPage;

const delaisData = [
  { value: "normal", label: " Normal (3 heures )", time: 180 },
  { value: "spx_1", label: " SPX (45 mins )", time: 45 },
  { value: "spx_2", label: " Sur rendez-vous", time: "rien" },
];

const delaisData4 = [
  { value: "spx_1", label: " SPX (1 heure 30 minutes)", time: 90 },
  { value: "normal", label: " Normal (3 heures )", time: 180 },
  { value: "spx_2", label: " Sur rendez-vous", time: "rien" },
];
