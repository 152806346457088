import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import parisFavicon from './../../assets/img/favicon/parisfavicon.ico'
import medicalFavicon from './../../assets/img/favicon/medicalfavicon.ico'
// import serviceFavicon from './../../assets/img/favicon/servicefavicon.png'



function Head({ espace, children }) {
    const [favicon, setFavicon] = useState(parisFavicon)

    useEffect(() => {
        if (espace === 'medical') {
            setFavicon(medicalFavicon)
        }
        else if (espace === 'service') {
            setFavicon(medicalFavicon)
        }
        else {
            setFavicon(parisFavicon)
        }
    }, [])
    return (
        <Helmet>
            {espace === 'paris' && <link
                rel="stylesheet"
                type="text/css"
                href="/assets/css/styles/parisPaginationStyle.css"
            />}

            {espace === 'medical' && <link
                rel="stylesheet"
                type="text/css"
                href="/assets/css/styles/medicalPaginationStyle.css"
            />}

            {espace === 'service' && <link
                rel="stylesheet"
                type="text/css"
                href="/assets/css/styles/servicePaginationStyle.css"
            />}
            <link rel="icon" href={favicon} type="image/x-icon" />
            {children}
        </Helmet>
    );
}

export default Head;