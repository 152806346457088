import React, { useEffect, useState } from "react";
import { exnetSpaceColor } from "../../../utils/Functions/otherFunctions";
import BillCenterSelector from "../../Utils/Selectors/BillCenterSelector";
import { useLocation } from "react-router-dom";

export default function Filter({
  type,
  filterData,
  setfilterData,
  revokeSearch,
  handleSearch
}) {

  const [closeSearch, setcloseSearch] = useState(false)
  const { pathname } = useLocation()
  const changeValue = (valueKey, value) => {
    let newValue = {};
    for (const property in filterData) {
      if (filterData[property] !== "") {
        newValue[property] = filterData[property];
      }
    }
    newValue[valueKey] = value;

    setfilterData(newValue);
  };

  const handlerEnterClick = (event) => {
    // Vérifier si la touche "Enter" a été pressée (code 13)
    if (event.key === 'Enter') {
      handleSearch()
    }
  };

  useEffect(() => {
    if (closeSearch) {
      revokeSearch()
      setcloseSearch(false)
    }
  }, [closeSearch])


  return (
    <div className="p-3 bg-gray-100 rounded">
      <div className="flex flex-wrap items-center justify-between">
        <div className="w-[230px] mb-6 md:mb-0">
          <label
            className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
            htmlFor="ref"
          >
            Référence
          </label>
          <input
            onKeyDown={handlerEnterClick}
            className="block text-sm w-full h-[40px] px-4 py-2 mb-3 leading-tight text-gray-700 bg-white border border-red-500 rounded appearance-none focus:outline-none focus:bg-white"
            id="ref"
            type="text"
            placeholder=""
            value={filterData.ref ? filterData.ref : ""}
            onChange={(e) => {
              if (parseInt(e.target.value) || e.target.value === "") {
                if (e.target.value === "") {
                  setcloseSearch(true)
                  // revokeSearch()
                  changeValue("ref", e.target.value);
                } else {
                  changeValue("ref", e.target.value);
                }
              }
            }}
          />
        </div>

        <div className="w-[230px] mb-6 md:mb-0">
          <label
            className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
            htmlFor="suivi"
          >
            Suivi
          </label>
          <input
            onKeyDown={handlerEnterClick}
            className="block text-sm w-full h-[40px] px-4 py-2 mb-3 leading-tight text-gray-700 bg-white border border-red-500 rounded appearance-none focus:outline-none focus:bg-white"
            id="suivi"
            type="text"
            placeholder=""
            value={filterData.suivi ? filterData.suivi : ""}
            onChange={(e) => {
              if (e.target.value === "") {
                revokeSearch()
                changeValue("suivi", e.target.value);


              } else {
                changeValue("suivi", e.target.value);
              }
            }}
          />
        </div>
        <div className="w-[230px] mb-6 md:mb-0">
          <label
            className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
            htmlFor="contact"
          >
            Contact
          </label>
          <input
            onKeyDown={handlerEnterClick}
            className="block text-sm h-[40px] w-full  px-4 py-2 mb-3 leading-tight text-gray-700 bg-white border border-red-500 rounded appearance-none focus:outline-none focus:bg-white"
            id="contact"
            type="text"
            placeholder=""
            value={filterData.contact ? filterData.contact : ""}
            onChange={(e) => {
              if (e.target.value === "") {
                revokeSearch()
                changeValue("contact", e.target.value);
              } else {
                changeValue("contact", e.target.value);
              }
            }}

          />
        </div>

        <div className="w-[230px]  md:mb-0 pt-2 mb-4">
          <label
            className="block mb-6 text-xs font-bold tracking-wide text-gray-700 uppercase"
            htmlFor="billing_center"
          >
            Centre de facturation
          </label>
          <BillCenterSelector
            type={type}
            defaultInputValue={filterData.billing_center}
            selectedValue={filterData.billing_center}
            onSelect={(v) => {
              changeValue("billing_center", v.value)
            }}
            isDisabled={false}
            key={pathname}
          />

        </div>

        <div className="w-[230px] mb-6 md:mb-0">
          <label
            className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
            htmlFor="contact"
          >
            Contact
          </label>
          <input
            onKeyDown={handlerEnterClick}
            className="block text-sm h-[40px] w-full  px-4 py-2 mb-3 leading-tight text-gray-700 bg-white border border-red-500 rounded appearance-none focus:outline-none focus:bg-white"
            id="contact"
            type="text"
            placeholder=""
            value={filterData.contact ? filterData.contact : ""}
            onChange={(e) => {
              if (e.target.value === "") {
                revokeSearch()
                changeValue("contact", e.target.value);
              } else {
                changeValue("contact", e.target.value);
              }
            }}

          />
        </div>

        <div className="w-[230px] mb-6 md:mb-0">
          <label
            className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
            htmlFor="DATE D'ENLèvement"
          >
            DATE D'ENLèvement
          </label>
          <input
            onKeyDown={handlerEnterClick}
            className="block text-sm h-[40px] w-full  px-4 py-2 mb-3 leading-tight text-gray-700 bg-white border border-red-500 rounded appearance-none focus:outline-none focus:bg-white"
            id="pickup_date"
            type="date"
            placeholder=""
            value={filterData.pickup_date ? filterData.pickup_date : ""}
            onChange={(e) => {
              if (e.target.value === "") {
                revokeSearch()
                changeValue("pickup_date", e.target.value);
              } else {
                changeValue("pickup_date", e.target.value);
              }
            }}

          />
        </div>

{/* git pu */}
        <div className="w-[230px] mb-6 md:mb-0">
          <label
            className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
            htmlFor="DATE D'ENLèvement"
          >
            DATE De livraison
          </label>
          <input
            onKeyDown={handlerEnterClick}
            className="block text-sm h-[40px] w-full  px-4 py-2 mb-3 leading-tight text-gray-700 bg-white border border-red-500 rounded appearance-none focus:outline-none focus:bg-white"
            id="delivery_date"
            type="date"
            placeholder=""
            value={filterData.delivery_date ? filterData.delivery_date : ""}
            onChange={(e) => {
              if (e.target.value === "") {
                revokeSearch()
                changeValue("delivery_date", e.target.value);
              } else {
                changeValue("delivery_date", e.target.value);
              }
            }}

          />
        </div>

        <div className="w-[230px] mb-6 md:mb-0 flex">
          <button
            onClick={handleSearch}
            type="button"
            className="mx-2 text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm w-full sm:w-auto px-3 py-2.5 text-center"
            // disabled={isSubmitting}
            style={{
              color: "#fff",
              backgroundColor: `${exnetSpaceColor(type)}`,
            }}
          >
            Rechercher
          </button>

          <button
            onClick={revokeSearch}
            type="button"
            className="mx-2 text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm w-full sm:w-auto px-3 py-2.5 text-center"
            // disabled={isSubmitting}
            style={{
              color: "#fff",
              backgroundColor: `${exnetSpaceColor(type)}`,
            }}
          >
            Annuler
          </button>
        </div>
      </div>
    </div>
  );
}
