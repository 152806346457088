import React, { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";


function FacturationCode({
  selectedValue,
  onSelect,
  showLabel = true,
  isDisabled = false,
  ...props
}) {
  const [lines, setLines] = useState([]);

  useEffect(() => {
    // Fetch and set default Lines when the component mounts
    async function fetchAllLines() {
      const perPage = 20;
      let currentPage = 1;
      let allLines = [];

      try {
        while (true) {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/ops/facturation-codes`,
            {
              page: currentPage,
              per_page: perPage,
            }
          );

          const responseData = response.data.data.data;
          if (Array.isArray(responseData)) {
            allLines = [...allLines, ...responseData];

            // If the fetched data length is less than perPage, we've fetched all pages
            if (responseData.length < perPage) {
              break;
            }
          } else {
            break; // Exit the loop if the response is not an array
          }

          currentPage++;
        }

        // Process the aggregated data
        setLines(
          allLines.map((item) => {
            let label = item.label;

            if (item.fuel) {
              label = `${item.label} ${item.description} (${item?.fueldata?.value})`;
            }

            return {
              fuel:item.fueldata?.value,
              tva:item.tva,
              ...item,
              value: item.id + "|||" + item.fueldata?.value + "|||" + item.tva,
              label: label,
            };
          })
        );
      } catch (error) {
        console.error("Error fetching all Lines:", error);
      }
    }

    fetchAllLines();


  }, []);
  const value = lines.find((c) => c?.value === selectedValue) || ""// || lines[0];
  //onSelect(value)
  return (
    <div>
      {showLabel && (
        <label
          for="Line"
          class="block mb-2 text-sm font-medium text-gray-900 "
        >
          Ligne*
        </label>
      )}
      <Select
        placeholder={"Type de ligne".toUpperCase()}
        className={"text-base "}
        options={lines}
        value={value}
        onChange={(v) => {
          onSelect(v);
        }}
        isDisabled={isDisabled}
      />
    </div>
  );
}

export default FacturationCode;
