import React, { useEffect } from 'react';
import BillCenterSelector from '../Utils/Selectors/BillCenterSelector';
import ClientSelector from '../Utils/Selectors/ClientSelector';

function BillingCenterAndClient({ expedition, byOps = false, onChange }) {
    const handleBilling = (data) => {
        onChange(data?.value)
    }

    return (
        <div className="text-base card-box">
            <div className='grid grid-cols-1 gap-6 card-body md:grid-cols-2'>
                <div className={'my-2'}>
                    <BillCenterSelector byOps={byOps} client={expedition?.customer_id} type={expedition?.billing_center?.type} showLabel={true} onSelect={handleBilling} selectedValue={expedition?.billing_center_id} isDisabled={false} />
                </div>

                <div className={'my-2 form-group'}>
                    <label className='uppercase'>
                        Nom de demandeur
                    </label>
                    <input
                        type="text"
                        className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                        id="numero_compte"
                        placeholder="Nom du demandeur"
                        value={expedition?.username}

                    />
                </div>

            </div>
        </div>
    );
}

export default BillingCenterAndClient;