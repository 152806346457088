import React, { useEffect, useState } from "react";
import Select from "react-select";
import BillCenterSelector from "../Utils/Selectors/BillCenterSelector";
import { VerifyStep_1 } from "../../utils/Functions/verifyDropdown";
import { exnetSpaceColor, urlSplit } from "../../utils/Functions/otherFunctions";
import { toast } from "react-toastify";
import useAuth from "../../hooks/useAuth";
import InputComponent from "../InputComponent/InputComponent";


function NewClientShippingRederenceComponent({
  type,
  setInfosSupp,
  infosSupp,
  toggleAccordion,
  ExpeditionType,
  exnetSpace

}) {
  const [errorInformation, setErrorInformation] = useState({ field: '', message: '' })



  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedCentre, setSelectedCentre] = useState(infosSupp?.bill_center_id);
  const [selectedDelais, setSelectedDelais] = useState(null);
  const [selectedRoues, setSelectedRoues] = useState(null);
  const { isAuthenticated, user } = useAuth();

  const { role } = urlSplit();

  const updateData = (element, valeur) => {
    let data = { ...infosSupp };
    data[element] = valeur;
    setInfosSupp({ ...data });
  };

  useEffect(() => {
    let data = { ...infosSupp };
    updateData("user_id", selectedClient?.value);
    setInfosSupp({ ...data });
    if (type === "courses") {
      data["delais"] = selectedDelais;
      setInfosSupp({ ...data });
    }
  }, [selectedDelais]);

  const { valid, error, errorData } = VerifyStep_1(infosSupp);

  const handleNext = () => {
    if (error) {
      console.log(errorData)
      setErrorInformation({ ...errorData })

    } else {
      toggleAccordion(2);
    }
  };

  // useEffect(() => {
  //   updateData('username', user?.name)
  //   setInfosSupp({
  //     user_id: user.id,
  //     bill_center_id: "",
  //     nomContact: "",
  //     customer_ref: "",
  //     tracking_mail: "",
  //     types: "",
  //     delais: "",
  //     transport_type: "",
  //     code_receveur: "",
  //     code_donneur: "",
  //     selected_user: null

  //   })
  // }, [window.location.href])


  return (
    <div className="content">
      <div className="mx-1 row">
        <div className="card-box">
          <div className="row">
            <div className="col-md-6">
              {" "}
              <div className="form-group">
                <BillCenterSelector
                  client={true}
                  user={user?.customer_id}
                  type={"paris"}
                  defaultInputValue={infosSupp?.bill_center_id}
                  selectedValue={selectedCentre}
                  onSelect={(v) => {
                    updateData("bill_center_id", v);
                    setSelectedCentre(v);
                  }}
                  isDisabled={false}
                />
              </div>
            </div>
            <div className="col-md-6">
              {" "}
              <div className="form-group">
               
                <InputComponent
                  value={infosSupp?.customer_ref}
                  onChange={(valeur) => updateData("customer_ref", valeur)}
                  errorMessage={errorInformation.message}
                  placeholder={"Référence du client".toUpperCase()}
                  label='' withLabel={false}
                  isError={errorInformation.field === 'customer_ref' ? true : false}
                />
              </div>
            </div>
            <div className="col-md-6">
              {" "}
              <div className="form-group">
               
                <InputComponent
                  value={infosSupp?.username}
                  onChange={(valeur) => updateData("username", valeur)}
                  errorMessage={errorInformation.message}
                  placeholder={"Nom d'utilisateur*".toUpperCase()}
                  label='' withLabel={false}
                  isError={errorInformation.field === 'username' ? true : false}
                />
              </div>
            </div>
            {(exnetSpace === 'medical') && <div className="col-md-6">
              {" "}
              <div className="form-group">
               
                <InputComponent
                  value={infosSupp?.code_donneur}
                  onChange={(valeur) => updateData("code_donneur", valeur)}
                  errorMessage={errorInformation.message}
                  placeholder={"CODE DONNEUR".toUpperCase()}
                  label='' withLabel={false}
                  isError={errorInformation.field === 'code_donneur' ? true : false}
                />
              </div>
            </div>}

            <div className="col-md-6">
              {" "}
              <div className="form-group">
               
                <InputComponent
                  type="email"
                  value={infosSupp?.tracking_mail}
                  onChange={(valeur) => updateData("tracking_mail", valeur)}
                  errorMessage={errorInformation.message}
                  placeholder={"MAIL DE SUIVI"}
                  label='' withLabel={false}
                  isError={errorInformation.field === 'tracking_mail' ? true : false}
                />
              </div>
            </div>
            {(exnetSpace === 'medical') && <div className="col-md-6">
              {" "}
              <div className="form-group">
               
                <InputComponent
                  value={infosSupp?.code_receveur}
                  onChange={(valeur) => updateData("code_receveur", valeur)}
                  errorMessage={errorInformation.message}
                  placeholder={"CODE Receveur".toUpperCase()}
                  label='' withLabel={false}
                  isError={errorInformation.field === 'code_receveur' ? true : false}
                />
              </div>
            </div>}


            <div className={role !== "client" ? "col-md-6" : "col-md-12 "}>
              <div className="row " style={{ justifyContent: "flex-end" }}>
                <div className="col-3">
                  <div className="form-group w-100">
                    <button
                      id="next"
                      className="btn w-100"
                      onClick={handleNext}
                      style={{ color: '#fff', backgroundColor: `${exnetSpaceColor(exnetSpace)}` }}
                    >
                      Suivant
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewClientShippingRederenceComponent;
