import React, { useEffect, useState } from "react";
import ContactList from "./ContactList";
import contactService from "../../../services/contact/contactService";
import ContactFilter from "./ContactFilter";
import useAuth from "../../../hooks/useAuth";
import useDisclosure from "../../../hooks/useDisclosure";
import ModalComponent from "../../Utils/Modal/Index";
import ContactForm from "./ContactForm";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
    addContact,
    clearContacts,
    removeContact,
    setContacts,
} from "../../../features/slices/contactSlice";
import PaginationComponent from "../../paginationComponent/PaginationComponent";

function ContactIndex() {
    const dispatch = useDispatch();
    const contacts = useSelector((state) => state.contact.contacts);
    const { user } = useAuth();
    const { isOpen, onClose, onOpen } = useDisclosure();

    const [page, setPage] = useState(1);
    const [contactData, setContactData] = useState([]);
    const [filterLetter, setFilterLetter] = useState("*");
    const [isLoading, setIsLoading] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalFormContent, setModalFormContent] = useState(null);
    const [isNew, setIsNew] = useState(true);
    const [perPageItems, setPerPageItems] = useState(25);
    const [totalItems, setTotalItems] = useState(0);
    const [isLetter, setisLetter] = useState(true);
    /* 
        console.log({
            perPageItems,
            totalItems,
            page
        }) */

    // Handle fetching contacts with current filters
    const fetchContacts = async () => {
        setIsLoading(true);
        const options = {
            user_id: user.id,
            by: "user",
            contact_type: "all",
            type: "all",
            page: page + 1, // Adjusting for API which might expect 1-based indexing
            per_page: perPageItems,
            ...(filterLetter !== "*" && {
                filter: isLetter ? { letter: filterLetter } : { other: filterLetter },
            }),
        };

        try {
            const { data } = await contactService.fechClientConatctList(options);
            dispatch(setContacts(data.data.data));
            setTotalItems(data.data.total);
            setIsLoading(false);
            console.log(data.data.data)
        } catch (error) {
            toast.error("An error occurred while fetching contacts.", { position: "top-center" });
            setIsLoading(false);
        }
    };

    // Effect to fetch contacts on filter, page, or perPageItems change
    useEffect(() => {
        fetchContacts();
        // Clearing contacts on filter change to avoid showing stale data
        return () => dispatch(clearContacts());
    }, [page, filterLetter, perPageItems]);

    // Simplified modal handling for adding or editing contacts
    const handleModal = (isNew, contact) => {
        setIsNew(isNew);
        setModalFormContent(contact); // Pass empty object for new contact
        setModalTitle(isNew ? "Créer un contact" : "Modifier un contact");
        onOpen();
    };

    // Pagination handler
    const handlePageClick = ({ selected }) => {
        //console.log({ selected })
        setPage(selected);
    };


    const handleDeleteAction = (id) => {
        const toastId = toast.loading("Suppression en cours...", {
            position: "top-right",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
        try {
            contactService.clientDeleteContact({ id: id }).then((data) => {
                let { success, data: donnee } = data.data;

                if (success) {
                    toast.update(toastId, {
                        render: "Le contact a été supprimé avec succès!",
                        type: "success",
                        isLoading: false,
                        autoClose: 500,
                    });
                    dispatch(removeContact(id));

                    // setData(
                    //     newData.filter(({ id }) => String(id) !== String(id))
                    // );
                } else {
                    toast.update(toastId, {
                        render: "Le contact non supprimé!",
                        type: "error",
                        isLoading: false,
                        autoClose: 500,
                    });
                }
            });
        } catch (error) {
            toast.update(toastId, {
                render: "Le contact non supprimé!",
                type: "error",
                isLoading: false,
                autoClose: 500,
            });
        }
    };

    // Render component
    return (
        <div>
            <ModalComponent isOpen={isOpen} onClose={onClose} size="2xl" title={modalTitle}>
                <ContactForm
                    modalFormContent={modalFormContent}
                    editable={Boolean(modalFormContent)}
                    setIsNew={setIsNew}
                    isNew={!Boolean(modalFormContent)}
                    onClose={() => {
                        //setIsNew(true);
                        onClose();
                    }}
                    setContactData={setContactData}
                    contactList={contactData}
                    setmodalFormContent={setModalFormContent}

                />
            </ModalComponent>

            <div className={"d-flex justify-content-between align-items-center"}>
                <ContactFilter changeTabs={(letter) => setFilterLetter(letter)} />
                <button className="btn btn-primary" onClick={() => handleModal(true)}>
                    <i className="fas fa-plus"></i> Ajouter
                </button>
            </div>

            <ContactList
                isOpen={onOpen}
                contacts={contacts}
                isLoading={isLoading}
                onEdit={(contact) => handleModal(true, contact)}
                handleDeleteAction={handleDeleteAction} // Implement or pass this function similarly to previous logic
                setmodalFormContent={setModalFormContent}
                setmodalTitle={setModalTitle}
                setIsNew={setIsNew}
            />

            <PaginationComponent
                //currentPage={page || 1}
                page={page || 1}
                handlePageClick={handlePageClick}
                totalItems={totalItems}
                perPageItems={perPageItems}
                setnumberPerPage={setPerPageItems}
            />
        </div>
    );
}

export default ContactIndex;
