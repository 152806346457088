import React from 'react'
import Select from 'react-select';

const OptionsSelector = ({ multiple, options, label, value, onChange }) => {

    const val = options.find(option => option.value === value)
    //console.log({ val })
    return (
        <div className='w-full'>
            {label && <label htmlFor="date_echeance" className="block mb-2 text-sm font-semibold text-gray-900 ">{label}</label>}
            <Select
                options={options}
                value={val}
                onChange={onChange}
                isMulti={multiple}
                className='w-100 text-base relative z-90'
            />
        </div>
    )
}

export default OptionsSelector