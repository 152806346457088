import { TbAddressBook, TbChartLine, TbLayoutDashboard, TbMotorbike, TbPackage, TbPackageExport, TbPackageImport, TbPackageOff } from "react-icons/tb";
import { returnExpeditionTypeLogo } from "../../../utils/Functions/otherFunctions";
import { ParisColor } from "../../../utils/const/colorsConstant";
import parisImport from '../../../assets/img/svg/Paris-import.svg'
import parisExport from '../../../assets/img/svg/Paris-export.svg'
import pariscourse from '../../../assets/img/svg/Paris.svg'
import dashLogo from '../../../assets/img/Icones/Dashboard/paris.svg'
import contactLogo from '../../../assets/img/Icones/carnet/paris.svg'
import archiveLogo from '../../../assets/img/Icones/archive/paris.svg'
import boiteLogo from '../../../assets/img/Icones/boite/paris.svg'
import freightLogo from '../../../assets/img/Icones/freight/paris.svg'

export default {
    data: [
        {
            libelle: "Dashboard",
            // lien: "/client/paris/dash",
            icon: <img alt={'fd'} src={dashLogo} className={`mt-0 w-8 hover:text-black`} />,
            liens: [
                {
                    libelle: "Expéditions en cours",
                    lien: "/client/paris/dash",
                    tague:'dashboard-progress'
                },
                {
                    libelle: "Expéditions livrées",
                    lien: "/client/paris/dash/livre",
                    tague:'dashboard-delivered'
                },
            ],
        },
        {
            libelle: "Mes commandes",
        },
        {
            libelle: "Courses",
            icon: <img alt={'fd'} src={pariscourse} className={`mt-0 w-8 hover:text-black`} />,
            liens: [
                {
                    libelle: "Nouvelle course",
                    lien: "/client/paris/dash/courses-a-courses/new",
                    tague:'courses-new'
                },
                {
                    libelle: "Courses en cours",
                    lien: "/client/paris/dash/courses-a-courses/en-cours",
                    tague:'courses-progress'
                },
                {
                    libelle: "Courses livrées",
                    lien: "/client/paris/dash/courses-a-courses/livre",
                    tague:'courses-delivered'
                },

            ],
        },
        {
            libelle: "Exports",
            icon: <img alt={'fd'} src={parisExport} className={`mt-0 w-8 hover:text-black`} />,
            liens: [
                {
                    libelle: "Nouvel export",
                    lien: "/client/paris/dash/export/new",
                    tague:'export-new'
                },
                {
                    libelle: "Exports en cours",
                    lien: "/client/paris/dash/export/en-cours",
                    tague:'export-progress'
                },
                {
                    libelle: "Exports livrées",
                    lien: "/client/paris/dash/export/livre",
                    tague:'export-delivered'
                },

            ],
        },
        {
            libelle: "Imports",
            icon: <img alt={'fd'} src={parisImport} className={`mt-0 w-8 hover:text-black`} />,
            liens: [
                {
                    libelle: "Nouvel import",
                    lien: "/client/paris/dash/import/new",
                    tague:'import-new'
                    
                },
                {
                    libelle: "Imports en cours",
                    lien: "/client/paris/dash/import/en-cours",
                    tague:'import-progress'
                },
                {
                    libelle: "Imports livrées",
                    lien: "/client/paris/dash/import/livre",
                    tague:'import-delivered'
                },

            ],
        },

        {
            libelle: "Carnet d'adresses",
            lien: "/client/paris/dash/carnet",
            icon: <img alt={'fd'} src={contactLogo} className={` w-8 hover:text-black`} />,
            tague:'contact'
        },
        {
            libelle: "Expéditions archivées",
            lien: "/client/paris/dash/export/archive",
            icon: <img alt={'fd'} src={archiveLogo} className={` w-8 hover:text-black`} />,
            tague:'export-archived'
        },
        {
            libelle: "Mes Statistiques",
        },
        {
            libelle: "Courses",
            lien: "/client/paris/dash/statiques/expeditions",
            icon: <img alt={'fd'} src={boiteLogo} className={` w-8 hover:text-black`} />,
            tague:'statistic-expedition'
        },
        {
            libelle: "Freight",
            lien: "/client/paris/dash/statiques/freight",
            icon: <img alt={'fd'} src={freightLogo} className={` w-8 hover:text-black`} />,
            tague:'statistic-freight'
        }
    ],
    configuration: {
        showExtra: false,
        showHelp: false,
    }
};
