import React, { useEffect, useState } from "react";
import Select from "react-select";
import CountriesSelector from "../Utils/Selectors/CountriesSelector";
import { toast } from 'react-toastify';
import { userService } from "../../services/user/UserService";
import { exnetSpaceColor, urlSplit } from "../../utils/Functions/otherFunctions";
import CountryQoteSelector from "../Utils/Selectors/CountryQoteSelector";
import InputComponent from "../InputComponent/InputComponent";
import { calculateValidator } from "../../utils/validators";

const modeOption = [
  {
    value: "export_france_europe",
    label: "EXPÉDITIONS FRANCE / EUROPE",
  },
  {
    value: "export_low_value",
    label: "EXPÉDITIONS LOW VALUE MONDE",
  },
  {
    value: "export_ddp",
    label: "EXPORT DDP ALL-IN",
  },
  // {
  //   value: "import_global",
  //   label: "IMPORT GLOBAL",
  // },
];

function CalculatriceComponent(props) {
  const [calculatriceData, setCalculatriceData] = useState({});
  const [quoteErrorMessage, setquoteErrorMessage] = useState(false)
  const [successMessage, setSuccessMessage] = useState(false)
  const [isLoading, setiLoading] = useState(false)
  const [errorInformation, setErrorInformation] = useState({
    field: "",
    message: "",
  });

  const { exnetSpace } = urlSplit()

  const updateCalculatriceData = (key, value) => {
    setquoteErrorMessage(false)
    setSuccessMessage(false)


    let newValue = value?.value;

    if (key === "mode") {
      if (newValue === "export_low_value") {
        setCalculatriceData({
          ...calculatriceData, commercialValue: 10, [key]: value, start_country: { label: 'France', value: 'Fr' }
        });
      } else {
        setCalculatriceData({
          ...calculatriceData, commercialValue: "", [key]: value, start_country: { label: 'France', value: 'Fr' },
          end_country: ""
        });
      }

    } else {
      setCalculatriceData({ ...calculatriceData, [key]: value, start_country: { label: 'France', value: 'Fr' } });
    }
  };

  const sendRequest = async () => {

    setiLoading(true)
    let data = {
      expedition_mode: calculatriceData?.mode?.value,
      package_value: calculatriceData?.commercialValue,
      package_weight: calculatriceData?.weight,
      package_length: +calculatriceData?.length,
      package_height: calculatriceData?.height,
      package_width: calculatriceData?.width,
      package_destination: parseInt(calculatriceData?.end_country?.value)
    }
    let { error, errorData } =  calculateValidator(data,calculatriceData?.mode?.value!=="export_france_europe")
    console.log(calculateValidator(data))
    if (error) {
      console.log(errorData)
      setErrorInformation({ ...errorData });
      setiLoading(false)
    }
    else {
      setErrorInformation({
        field: "",
        message: "",
      })

      let { data: response } = await userService.sendQuote(data);
      let { errors, success, return_message } = response.data

      if (success) {
        setquoteErrorMessage(false)
        setSuccessMessage(return_message)
      } else {
        setSuccessMessage(false)
        setquoteErrorMessage(errors)
      }
      setiLoading(false)
    }


  }

  const clearAll = () => {
    setCalculatriceData({
      mode: '',
      start_country: { label: 'France', value: 'Fr' },
      commercialValue: '',
      weight: '',
      length: '',
      height: '',
      width: '',
      end_country: {}
    });
  }

  useEffect(() => {
    updateCalculatriceData("start_country", { label: 'France', value: 'Fr' })
    // import_global
  }, []);



  const value2 = (!isNaN(calculatriceData?.width) && !isNaN(calculatriceData?.height) && !isNaN(calculatriceData?.length)) ?
    ((calculatriceData?.width * calculatriceData?.height * calculatriceData?.length) / 5000).toFixed(2) : ""


  return (
    <div className={"card"}>
      {/* <Fieldset legend="Informations personnelles"></Fieldset> */}
      <div className={"card-body"}>
        <form action="">
          <div className={"m-4"}>
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <label
                    className="block mb-2 text-xs font-bold tracking-wide text-black uppercase"
                    htmlFor="ref"
                  >
                    Mode d'expédition
                  </label>
                  <Select
                    options={modeOption}
                    value={calculatriceData?.mode}
                    onChange={(v) => {
                      updateCalculatriceData("mode", v);
                    }}
                    className="w-full bg-white border border-gray-500 rounded-lg outline-none basic-single selection"
                    classNamePrefix="select"
                  />
                  {errorInformation.field === "expedition_mode" &&
                    <p className="mt-2 text-sm text-red-600">
                      {errorInformation.message}
                    </p>
                  }
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <fieldset className="p-6 rounded-lg ">
                  <legend className="mb-4 text-lg font-semibold">
                    Informations de départ
                  </legend>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label
                          className="block mb-2 text-xs font-bold tracking-wide text-black uppercase"
                          htmlFor="ref"
                        >
                          PAYS D'EXPÉDITION
                        </label>
                        <CountriesSelector
                          showLabel={false}
                          onSelect={(v) =>
                            // updateCalculatriceData("start_country", v)
                            console.log(v)
                          }
                          selectedValue={calculatriceData?.start_country?.value}
                          className={"w-full"}
                          isDisabled={true}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label
                          className="block mb-2 text-xs font-bold tracking-wide text-black uppercase"
                          htmlFor="start_postalCode"
                        >
                          CODE POSTAL
                        </label>
                        <InputComponent
                          type={"text"}
                          value={calculatriceData?.start_postalCode}
                          onChange={(valeur) => {
                            updateCalculatriceData(
                              "start_postalCode",
                              valeur
                            );
                          }

                          }
                          errorMessage={errorInformation.message}
                          placeholder={" CODE POSTAL"}
                          label=""
                          withLabel={false}
                          isError={
                            errorInformation.field === "start_postalCode" ? true : false
                          }
                        />


                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
              <div className="col-md-6">
                <fieldset className="p-6 rounded-lg ">
                  <legend className="mb-4 text-lg font-semibold">
                    Informations d'arrivée
                  </legend>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label
                          className="block mb-2 text-xs font-bold tracking-wide text-black uppercase"
                          htmlFor="ref"
                        >
                          PAYS DE DESTINATION
                        </label>
                        <CountryQoteSelector
                          qoteValue={calculatriceData?.mode}
                          showLabel={false}
                          onSelect={(v) =>
                            updateCalculatriceData("end_country", v)
                          }
                          selectedValue={calculatriceData?.end_country}
                          className={"w-full"}
                          isDisabled={calculatriceData?.mode?.value === "import_global"}
                        />
                        {errorInformation.field === "package_destination" &&
                          <p className="mt-2 text-sm text-red-600">
                            {errorInformation.message}
                          </p>
                        }

                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label
                          className="block mb-2 text-xs font-bold tracking-wide text-black uppercase"
                          htmlFor="end_postalCode"
                        >
                          CODE POSTAL
                        </label>
                        <InputComponent
                          key={'start_postalCode'}
                          type={"text"}
                          value={calculatriceData?.start_postalCode}
                          onChange={(valeur) => {
                            updateCalculatriceData(
                              "end_postalCode",
                              valeur
                            );
                          }

                          }
                          errorMessage={errorInformation.message}
                          placeholder={" CODE POSTAL"}
                          label=""
                          withLabel={false}
                          isError={
                            errorInformation.field === "end_postalCode" ? true : false
                          }
                        />

                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>

            <div>
              <fieldset className="p-6 rounded-lg ">
                <legend className="mb-4 text-lg font-semibold">
                  DIMENSIONS ET POIDS DE VOTRE COLIS
                </legend>
                <div className={"row"}>
                  <div className={"col-md-4"}>
                    <div className="form-group">
                      <label
                        className="block mb-2 text-xs font-bold tracking-wide text-black uppercase"
                        htmlFor="length"
                      >
                        LONGUEUR(CM)
                      </label>

                      <InputComponent
                        key={'package_length'}
                        type={"number"}
                        value={calculatriceData.length}
                        onChange={(valeur) => {
                          updateCalculatriceData(
                            "length",
                            valeur
                          );
                        }

                        }
                        errorMessage={errorInformation.message}
                        placeholder={" LONGUEUR"}
                        label=""
                        withLabel={false}
                        isError={
                          errorInformation.field === "package_length" ? true : false
                        }
                      />
                    </div>
                  </div>
                  <div className={"col-md-4"}>
                    <div className="form-group">
                      <label
                        className="block mb-2 text-xs font-bold tracking-wide text-black uppercase"
                        htmlFor="width"
                      >
                        LARGEUR(CM)
                      </label>

                      <InputComponent
                        key={'package_width'}
                        type={"number"}
                        value={calculatriceData?.width}
                        onChange={(valeur) => {
                          updateCalculatriceData(
                            "width",
                            valeur
                          );
                        }

                        }
                        errorMessage={errorInformation.message}
                        placeholder={" LARGEUR"}
                        label=""
                        withLabel={false}
                        isError={
                          errorInformation.field === "package_width" ? true : false
                        }
                      />
                    </div>
                  </div>
                  <div className={"col-md-4"}>
                    <div className="form-group">
                      <label
                        className="block mb-2 text-xs font-bold tracking-wide text-black uppercase"
                        htmlFor="height"
                      >
                        HAUTEUR(CM)
                      </label>

                      <InputComponent
                        key={'package_height'}
                        type={"number"}
                        value={calculatriceData?.height}
                        onChange={(valeur) => {
                          updateCalculatriceData(
                            "height",
                            valeur
                          );
                        }

                        }
                        errorMessage={errorInformation.message}
                        placeholder={" HAUTEUR"}
                        label=""
                        withLabel={false}
                        isError={
                          errorInformation.field === "package_height" ? true : false
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className={"row"}>
                  <div className={"col-md-4"}>
                    <div className="form-group">
                      <label
                        className="block mb-2 text-xs font-bold tracking-wide text-black uppercase"
                        htmlFor="commercialValue"
                      >
                        VALEUR COMMERCIALE (€)
                      </label>

                      <InputComponent
                        key={'commercialValue'}
                        disabled={
                          calculatriceData?.mode?.value === "export_low_value"
                            ? true
                            : false
                        }
                        type={"number"}
                        value={calculatriceData?.commercialValue}
                        onChange={(valeur) => {
                          updateCalculatriceData(
                            "commercialValue",
                            valeur
                          );
                        }

                        }
                        errorMessage={errorInformation.message}
                        placeholder={"VALEUR COMMERCIALE"}
                        label=""
                        withLabel={false}
                        isError={
                          errorInformation.field === "package_value" ? true : false
                        }
                      />
                    </div>
                  </div>
                  <div className={"col-md-4"}>
                    <div className="form-group">
                      <label
                        className="block mb-2 text-xs font-bold tracking-wide text-black uppercase"
                        htmlFor="weight"
                      >
                        POIDS(KG)
                      </label>

                      <InputComponent
                        key={'package_weight'}
                        type={"number"}
                        value={calculatriceData?.weight}
                        onChange={(valeur) => {
                          updateCalculatriceData(
                            "weight",
                            valeur
                          );
                        }

                        }
                        errorMessage={errorInformation.message}
                        placeholder={"POIDS"}
                        label=""
                        withLabel={false}
                        isError={
                          errorInformation.field === "package_weight" ? true : false
                        }
                      />

                    </div>
                  </div>
                  <div className={"col-md-4"}>
                    <div className="form-group">
                      <label
                        className="block mb-2 text-xs font-bold tracking-wide text-black uppercase"
                        htmlFor="weightVolumetric"
                      >
                        Poids volumétrique (kg/cm)
                      </label>

                      <InputComponent
                        key={'poids_volume'}
                        disabled
                        value={value2}

                        onChange={(valeur) => {
                          console.log(valeur)
                        }
                        }
                        errorMessage={errorInformation.message}
                        placeholder={" Poids volumétrique"}
                        label=""
                        withLabel={false}
                        isError={
                          errorInformation.field === "weight" ? true : false
                        }
                      />

                    </div>
                  </div>
                </div>


                <div className={"text-center "}>
                  <button type={'button'} onClick={clearAll} className={"mx-4  w-full md:w-1/4 h-[50px] text-lg    rounded-md text-white "} style={{ backgroundColor: `${exnetSpaceColor(exnetSpace)}` }}>
                    Effacer
                  </button>

                  <button type={'button'} onClick={sendRequest} disabled={isLoading} className={" mx-4 w-full md:w-1/4 h-[50px] text-lg    rounded-md text-white "} style={{ backgroundColor: `${exnetSpaceColor(exnetSpace)}` }}>
                    Valider
                  </button>
                </div>
              </fieldset>

              <div>
                {quoteErrorMessage && <>
                  <h2 className={' text-center text-base font-semibold'}>
                    Le volume de votre envoi dépasse le seuil, nous vous invitons à nous envoyer un mail sur <a href="mailto:commercial@exnetparis.com">commercial@exnetparis.com</a>  afin d’établir un devis
                  </h2>
                </>}

                {successMessage && <>
                  <h2 className={' text-center text-2xl font-bold'}>{successMessage}</h2>
                </>}
              </div>
            </div>
          </div>
        </form>

      </div>
    </div>
  );
}

export default CalculatriceComponent;
