import React from "react";

const tabsHead = [
    "*",
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
];
function ContactFilter({ changeTabs }) {

    return (
        <div>
            <div className="row">
                <div className="col-md-2">
                    <div className="form-group">
                        <input
                            type="text"
                            onChange={(e) => changeTabs(e.target.value,false)}
                            name=""
                            id=""
                            placeholder="Recherche"
                            min={2}
                            className={
                                "w-full  bg-transparent border border-gray-500 rounded-lg outline-none form-control"
                            }
                        />
                    </div>
                </div>
            </div>
            <div className={"my-1"}>
                <ul className="nav nav-tabs nav-tabs-solid nav-tabs-rounded" id="affiche" style={{display:'block !important'}}>
                    {tabsHead.map((item, index) => (
                        <li
                            className="nav-item"
                            key={index}
                            onClick={() => changeTabs(item)}
                        >
                            <a
                                className={`nav-link ${
                                    index === 0 ? "active" : ""
                                }`}
                                href={`#solid-rounded-tab${index}`}
                                data-bs-toggle="tab"
                            >
                                {item}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default ContactFilter;
