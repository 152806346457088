import React, { useCallback, useEffect, useState } from 'react';
import Select from "react-select";
import useDisclosure from '../../../hooks/useDisclosure';
import opsService from '../../../services/ops/opsService';
import { toast } from 'react-toastify';
import { useBasicFormValues } from '../../../hooks/useFormValues';
import { validateEmailData } from '../../../utils/validators';
import { uniqueId } from 'lodash';
import Swal from 'sweetalert2';
import { formatDefaultDate } from '../../../utils/moment';
import ExpeditionService from '../../../services/expedition/ExpeditionService';
import { useDispatch, useSelector } from 'react-redux';
import { setExpedition } from '../../../features/slices/expeditionSlice';
import { urlSplit } from '../../../utils/Functions/otherFunctions';


function EmailTabsComponent({ expedition }) {
    const expeditionDetails = useSelector(state => state.expedition.expeditions);
    const dispatch = useDispatch();
    //console.log({ expedition })
    const initialValue = {
        email: "",
        type: ""
    }
    const { data: emailData, setFormValue } = useBasicFormValues(initialValue);
    const [selectedItem, setSelectedItem] = useState(null)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [emails, setEmails] = useState([]);
    const [search, setSearch] = useState("");
    const [selectedEmails, setSelectedEmails] = useState([]);
    //const { isOpen: isDeleteConfirmOpen, onClose: onDeleteConfirmClose, onOpen: onDeleteConfirmOpen } = useDisclosure()


    const typesOptions = [{ label: "Expéditeur", value: "expeditor" }, { label: "Destinataire", value: "receiver" }]

    const { valid, error: _err } = validateEmailData(emailData)
    const { exnetSpace } = urlSplit();

    useEffect(() => {
        if (expedition) {
            setFormValue("shipping_id", expedition.id)

            let _emails = [];
            if (expedition.expeditor?.email) {
                //console.log({ D: expedition.expeditor })
                _emails = [...expedition.expeditor.email.split(",")?.map(e => ({ id: uniqueId("email--"), email: e, type: "expeditor", label: "Expéditeur", phone: expedition.expeditor.phone1 }))]
            }

            if (expedition.receiver?.email) {
                _emails = [..._emails, ...expedition.receiver.email.split(",")?.map(e => ({ id: uniqueId("email--"), email: e, type: "receiver", label: "Destinataire", phone: expedition.receiver.phone1 }))]
            }

            setEmails(_emails)
        }
    }, [expedition])

    const handleSubmit = useCallback(async () => {
        try {
            if (_err) {
                toast.error(
                    `${_err}`,
                    {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    }
                );
            } else {
                if (selectedItem) {
                    //submitData.comment_id = selectedItem.id
                }
                setIsSubmitting(true);


                const request = selectedItem ? opsService.updateEmail : opsService.addEmail
                const { data } = await request(emailData);
                if (data.success) {
                    //console.log(data.data)
                    ExpeditionService.getExpeditionDetails({ ref: expeditionDetails.ref }).then(
                        (data) => {
                            const { data: expedition } = data.data;
                            dispatch(setExpedition(expedition));
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
                    onSubmit(selectedItem ?
                        {
                            ...selectedItem,
                            ...emailData
                        } : emailData)

                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsSubmitting(false)
        }
    }, [selectedItem, emailData, _err]);



    const onSubmit = (result) => {
        //console.log({ result })
        if (result) {
            let items = [...emails];
            const resultIndex = items.findIndex(i => i.id === result.id);
            //console.log({ resultIndex })
            const isUpdate = resultIndex > -1
            if (isUpdate) {
                items[resultIndex] = result;
            } else {
                items = [result, ...items]
            }

            setEmails(items)
            setSelectedItem(null)

            toast.success(`${isUpdate ? "Email mis à jour" : "Email ajouté"} avec succès!`, {
                position: "bottom-center",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

    }


    const results = emails?.filter(t => {
        const regExp = new RegExp(search, "gi");
        return t.email.match(regExp)
    }
    )

    const onDeleteClick = useCallback(async (e) => {
        setSelectedEmails([e]);
        setTimeout(() => {
            if (expedition && selectedEmails.length > 0) {
                try {

                    Swal.fire({
                        title: "Êtes-vous sûr de vouloir supprimer ?",
                        text: "Cette action est irréversible !",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#d33",
                        cancelButtonColor: exnetSpace === 'paris'?"#ba9964":(exnetSpace === 'service'?"#098721":(exnetSpace === 'medical'?"#3A8EBA":"#222")),
                        confirmButtonText: "Oui, supprimer",
                        cancelButtonText: "Annuler",
                    }).then(async (result) => {
                        if (result.isConfirmed) {
                            await onDelete()
                            ExpeditionService.getExpeditionDetails({ ref: expeditionDetails.ref }).then(
                                (data) => {
                                    const { data: expedition } = data.data;
                                    dispatch(setExpedition(expedition));
                                },
                                (error) => {
                                    console.log(error);
                                }
                            );
                        } else {
                            Swal.fire(
                                "Annuler",
                                "Suppression annulée avec succès",
                                "error",
                                500
                            );
                        }
                    });

                } catch (error) {
                    console.log(error)
                }
            }
        }, 100);
    }, [selectedEmails, expedition?.id])

    const onDelete = useCallback(async () => {
        try {
            const submitData = {
                shipping_id: expedition.id,
                email: selectedEmails[0].email,
                type: selectedEmails[0].type,
            }
            const { data } = await opsService.deleteEmail(submitData);
            if (data.success) {
                setEmails(e => e.filter(e => !selectedEmails.find(se => se.id === e.id)))
                toast.success(
                    `Suppression effectuée avec succès`,
                    {
                        position: "bottom-center",
                        autoClose: 4000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    }
                );
            }
        } catch (error) {

        }
    }, [selectedEmails, expedition?.id])

    //console.log({ emails })

    return (
        <div>

            <div className="p-4 bg-gray-50">
                <div className="flex items-center">
                    <div className="mr-5 w-2/3 max-w-[800px]">
                        <label
                            for="options"
                            className="block mb-2 text-sm font-medium text-gray-900 "
                        >
                            Email(s)*
                        </label>
                        <input
                            className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                            type="type"
                            placeholder="email1@gmail, email2@gmail.com...."
                            value={emailData.email}
                            onChange={(e) => setFormValue("email", e.target.value)}
                        />
                    </div>
                    <div className="w-full max-w-[200px]">
                        <CustomSelector
                            label={"Type*"}
                            value={emailData.type}
                            onChange={v => {
                                console.log(v.value)
                                setFormValue("type", v.value)
                            }}
                            options={typesOptions}
                        />
                    </div>
                </div>
                <button
                    className={` ${valid ? "cursor-pointer" : "cursor-not-allowed"} text-white bg-black hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm px-5 py-2.5 text-center mt-4`}
                    onClick={handleSubmit}
                    disabled={isSubmitting}                            >
                    {isSubmitting ? "Enregistrement..." : selectedItem ? "Modifier" : "Enregistrer"}
                </button>
            </div>

            <div className="p-4 mt-2 bg-gray-50">
                <input
                    className="max-w-lg bg-white border border-gray-500 rounded-lg outline-none form-control "
                    type="type"
                    placeholder="Recherche"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}

                />
            </div>

            <div className="row">
                <div className="mb-5 row">
                    <div className="table-responsive">
                        <table className="table mb-0">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Email</th>
                                    <th>Téléphone</th>
                                    <th>Type</th>
                                    {/* <th>Ajouté le </th> */}
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {results?.map((e, i) => (
                                    <tr key={i}>
                                        <td>{i + 0}</td>
                                        <td>{e.email}</td>
                                        <td>{e.phone}</td>
                                        <td>{e.label}</td>
                                        {/* <td>{formatDefaultDate()}</td> */}
                                        <td>
                                            <div
                                                className="text-red-500 cursor-pointer text-[14px]"
                                                onClick={() => {
                                                    //onDeleteConfirmOpen()
                                                    onDeleteClick(e)
                                                }}
                                                title="Supprimer"
                                            >
                                                <i className="fas fa-trash"></i>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </div>
    );
}

export const CustomSelector = ({ label, options, value, onChange }) => {
    //const [options, setOptions] = useState(options);
    const _value = options.find((c) => c?.value == value);
    return (
        <div>
            {label && (
                <label
                    for="options"
                    className="block mb-2 text-sm font-medium text-gray-900 "
                >
                    {label}
                </label>
            )}
            <Select
                options={options}
                value={_value}
                onChange={(v) => {
                    onChange(v);
                }}
                className="w-100"
            />
        </div>
    );
};

export default EmailTabsComponent;