import React, { useState } from 'react'
import useDisclosure from '../../../hooks/useDisclosure';
//import Form from './Form';
import useAdminLists from '../../../hooks/useAdminLists';
import { TableLoader } from '../../Utils/Loader';
import FormModal from './Form';
import DeleteConfirm from '../../Utils/DeleteConfirm';
import { TbArrowsUpDown } from 'react-icons/tb';
import PaginationComponent from '../../paginationComponent/PaginationComponent';
import useTableFilter from '../../../hooks/useTableFilter';
import TransportTypesService from "../../../services/transportTypes/index";


export default function TransporTypesView() {
    const { isOpen, onClose, onOpen } = useDisclosure();

    const {
        isOpen: isDeleteConfirmOpen,
        onClose: onDeleteConfirmClose,
        onOpen: onDeleteConfirmOpen
    } = useDisclosure();

    const type = "transport-types"

    const { adminData, metadata, setPage, setPerPage, isLoading, onSubmit, onDelete } = useAdminLists({ type });
    const [selectedItem, setSelectedItem] = useState(null)

    const onItemSelected = (item, action) => {
        setSelectedItem(item)
        if (action === "edit") {
            onOpen()
        } else {
            onDeleteConfirmOpen()
        }
    }

    return (
        <div>
            <div className="flex items-center justify-between">
                <h4 className="uppercase page-title">Liste des types de transport</h4>
                <div
                    className="float-right text-white bg-black btn btn-rounded"
                    onClick={onOpen}
                >
                    <i className="fas fa-plus"></i>
                </div>
            </div>

            <div className="py-3">
                {adminData.total ?
                    <PaginationComponent
                        page={metadata.page}
                        handlePageClick={setPage}
                        totalItems={adminData.total}
                        perPageItems={metadata.perPage}
                        setnumberPerPage={setPerPage}
                    /> : null}
            </div>

            <Lists data={adminData.data} onSelect={onItemSelected} isLoading={isLoading} />
            <div className="py-3">
                {adminData.total ?
                    <PaginationComponent
                        page={metadata.page}
                        element='pagination'
                        handlePageClick={setPage}
                        totalItems={adminData.total}
                        perPageItems={metadata.perPage}
                        setnumberPerPage={setPerPage}
                    /> : null}
            </div>

            <FormModal
                isOpen={isOpen}
                onClose={() => {
                    onClose()
                    setSelectedItem(null)
                }}
                onSubmit={onSubmit}
                selectedData={selectedItem}
            />

            {selectedItem &&
                <DeleteConfirm
                    id={selectedItem.id}
                    isOpen={isDeleteConfirmOpen}
                    onClose={onDeleteConfirmClose}
                    onDelete={() => {
                        onDelete(selectedItem)
                        onDeleteConfirmClose()
                    }}
                    deleteRequest={() => TransportTypesService.delete({ id: selectedItem.id })}
                />}
        </div>
    )
}


const Lists = ({ data, onSelect, isLoading }) => {
    const { results, onTableHeaderClick } = useTableFilter({ items: data });

    const HEADERS = [
        { label: 'ID', key: 'id' },
        { label: 'Nom', key: 'label' },
        { label: 'Action', key: null },
    ];
    return (
        <div className="relative overflow-x-auto">
            <div className="bg-white card-box">
                <div className="card-block">
                    {/*                     <h4 className="card-title">Basic Table</h4>*/}
                    <div className="table-responsive">
                        <table className="table mb-0">
                            <thead>
                                <tr className='uppercase'>
                                    {HEADERS.map((h, i) => (
                                        <th key={i} className="text-left cursor-pointer" onClick={() => {
                                            onTableHeaderClick(h.key)
                                        }}>
                                            <div className="flex items-center">
                                                <span className='mr-2'>
                                                    {h.label}
                                                </span>
                                                {h.key && <span>
                                                    <TbArrowsUpDown />
                                                </span>}
                                            </div>

                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {results.length > 0 && (
                                    results.map((item, i) => {
                                        return (
                                            <tr key={i} className="py-3">
                                                <td className={'cursor-pointer'}
                                                    onClick={() => {
                                                        onSelect(item, "edit")
                                                    }}
                                                >{item.id}</td>
                                                <td className={'cursor-pointer'}
                                                    onClick={() => {
                                                        onSelect(item, "edit")
                                                    }}
                                                >{item.label}</td>
                                                <td>
                                                    <div className="flex items-center">
                                                        {/* <div
                                                            className="text-green-600 cursor-pointer text-[14px] mr-5"
                                                            onClick={() => {
                                                                onSelect(item, "edit")
                                                            }} title="Editer"
                                                        >
                                                            <i className="fas fa-edit"></i>
                                                        </div> */}

                                                        <div
                                                            className="text-red-500 cursor-pointer text-[14px]"
                                                            onClick={() => {
                                                                onSelect(item, "delete")
                                                            }}
                                                            title="Supprimer"
                                                        >
                                                            <i className="fas fa-trash"></i>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                )}
                            </tbody>
                        </table>
                        {isLoading ? <TableLoader /> : results.length === 0 ? (
                            <h6 className="my-5 text-lg text-center">Aucune donnée</h6>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    )
}