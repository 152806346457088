import React, { useCallback, useEffect, useMemo } from "react";
import TransportTypeSelector, {
  IncotermSelector,
  TransportTypeSelectorDeux,
} from "../Utils/Selectors/TransportTypeSelector";
import { TbPlus } from "react-icons/tb";
import { calculateWeightVolume, getWeightVolume, roundToTenth, toFixed2 } from "../../utils";
import TexteareaInput from "../Utils/UI/TexteareaInput";
import useDisclosure from "../../hooks/useDisclosure";
import CommentaireComponent from "./CommentaireComponent";
import { convertirTimestampEnDate, updateDate } from "../../utils/Functions/otherFunctions";
import moment from "moment";
import PrincingInput from "../Utils/UI/PrincingInput";

export default function EditPackages({
  expedition,
  onExpeditionDataChange,
  expeditionDetails,
  onAddTrackingModalOpen,
  onInfosSupplementaireChange,
  onDispatchModalOpen,
  setExpeditionData
}) {
  const { package: _package, shipping } = expedition;

  const expeditionPackage = _package || {};

  //console.log({ expeditionPackage })

  const onPackageDataChange = (key, value) => {
    onExpeditionDataChange("package", {
      ...expeditionPackage,
      [key]: value,
    });
  };

  const onShippingDataChange = (key, value) => {
    onExpeditionDataChange("shipping", {
      ...shipping,
      [key]: value,
    });
  };

  const weight_volume = calculateWeightVolume(_package)

  useEffect(() => {
    const value = calculateWeightVolume(_package)
    if (weight_volume !== value) {
      onPackageDataChange("weight_volume", value)
    }
  }, [_package, onPackageDataChange, weight_volume])


  return (
    <>
      <fieldset>
        <legend>Informations sur le colis</legend>
        <div className="row">
          <div className="col-md-2">
            <div className="form-group">
              <label className="form-label ">Longueur *</label>
              <div className="mb-3 input-group">
                <input
                  type="number"
                  className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                  name="field1"
                  value={expeditionPackage.length}
                  onChange={(e) => {
                    onPackageDataChange("length", e.target.value)
                  }
                  }
                />
                <span className="input-group-text">Cm</span>
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <label className="form-label">Largeur *</label>
              <div className="mb-3 input-group">
                <input
                  type="number"
                  className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                  name="field2"
                  value={expeditionPackage.width}
                  onChange={(e) => onPackageDataChange("width", e.target.value)}
                />
                <span className="input-group-text">Cm</span>
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <label className="form-label">Hauteur *</label>
              <div className="mb-3 input-group">
                <input
                  type="number"
                  className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                  name="field1"
                  value={expeditionPackage.height}
                  onChange={(e) =>
                    onPackageDataChange("height", e.target.value)
                  }
                />
                <span className="input-group-text">Cm</span>
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <label className="form-label">Poids *</label>
              <div className="mb-3 input-group">
                <input
                  type="number"
                  className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                  name="field2"
                  value={expeditionPackage.weight}
                  onChange={(e) =>
                    onPackageDataChange("weight", e.target.value)
                  }
                />
                <span className="input-group-text">Kg</span>
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <label className="form-label">Poids volumétrique *</label>
              <div className="mb-3 input-group">
                <input
                  type="number"
                  className="w-full border border-gray-500 rounded-lg outline-none bg-gray-50 form-control"
                  name="field3"
                  value={weight_volume}
                  //onChange={e => onPackageDataChange("weight_volume", e.target.value)}
                  disabled
                />
                <span className="input-group-text">Kg</span>
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <label className="form-label">Valeur commerciale*</label>
              <PrincingInput UpdateColisData={onPackageDataChange} colis={expeditionPackage} index={'none'} />
              {/* <div className="mb-3 input-group">
                <input
                  type="text"
                  className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                  name="field5"
                  value={expeditionPackage.value_in_douane || ""}
                  onChange={(e) =>
                    onPackageDataChange("value_in_douane", e.target.value)
                  }
                />
                <span className="input-group-text">€</span>
              </div> */}
            </div>

          </div>

          <div className="col-md-2">
            <div className="mb-3 form-group">
              <label htmlFor="field6" className="uppercase">Numero de devis</label>
              <input
                type="text"
                className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                name="field6"
                value={expeditionPackage.nombre_devi || ""}
                onChange={(e) =>
                  onPackageDataChange("nombre_devi", e.target.value)
                }
              />
            </div>
          </div>

          <div className="col-md-2">
            <div className="form-group">
              <label htmlFor="volumes" className="mb-1 form-label">
                Volume m<sup>3</sup>
              </label>
              <input
                type="text"
                className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                id="volumes"
                value={expedition?.detail_detail_info_supplementaire?.volumes}
                onChange={(e) => {
                  onInfosSupplementaireChange("volumes", e.target.value);
                }}
              />
            </div>
          </div>

          <div className="col-md-2">
            <div className="mb-3 form-group">
              <label htmlFor="field5" className="form-label">
                Montant de devis
              </label>
              <input
                type="text"
                className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                name="field5"
                value={expeditionPackage.montant_devi || ""}
                onChange={(e) =>
                  onPackageDataChange("montant_devi", e.target.value)
                }
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="mb-3 form-group">
              <label htmlFor="field5" className="form-label">
                Référence interne client
              </label>
              <input
                type="text"
                className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                name="field5"
                value={shipping.customer_ref}
                onChange={(e) =>
                  onShippingDataChange("customer_ref", e.target.value)
                }
              />
            </div>
          </div>

          <div className="col-md-3">
            <TransportTypeSelectorDeux
              value={shipping.transport_type}
              onSelect={(v) => {
                onShippingDataChange("transport_type", v.value);
              }}
            />
          </div>

          {/* <div className="col-md-3">
            <div className="mb-3 form-group">
              <label htmlFor="field5" className="form-label">
                Valeur douane
              </label>
              <input
                type="text"
                className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                name="field5"
                value={expeditionPackage.value_in_douane || ""}
                onChange={(e) =>
                  onPackageDataChange("value_in_douane", e.target.value)
                }
              />
            </div>
          </div> */}
          <div className="col-md-3">
            <div className="mb-3 form-group">
              <label htmlFor="field6" className="uppercase">Dernier Track</label>
              {/* <input
                type="text"
                className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                name="field6"
                value={expeditionPackage.statut || ""}
                onChange={(e) => onPackageDataChange("statut", e.target.value)}
              /> */}
              <div className="relative rounded-md shadow-sm">
                <input
                  type="text"
                  name="tack"
                  id="tack"
                  className="block w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                  placeholder=""
                  aria-describedby="tracking"
                  value={
                    expeditionDetails?.suivis?.length > 0
                      ? expeditionDetails.suivis[
                      expeditionDetails.suivis.length - 1
                      ]["details"]
                      : ""
                  }
                />
                <div className="absolute inset-y-0 right-0 flex items-center pr-3 ">
                  <span
                    className="text-gray-500 sm:text-sm pointer"
                    id="price-currency"
                  >
                    <TbPlus
                      onClick={onAddTrackingModalOpen}
                      className="pointer"
                      type="button"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>



          <div className="col-md-3">
            <div className="form-group ">
              <label htmlFor="date" className="form-label">
                Date d'enlèvement *
              </label>
              <input
                type="date"
                className="w-full bg-white border border-gray-500 rounded-lg outline-none datetimepicker form-control"
                id="date"
                value={shipping.pickup_date}
                // utcOffset('+01:00').
                onChange={(e) =>
                  onShippingDataChange("pickup_date", e.target.value)
                }
              />
            </div>
          </div>

          <div className="col-md-3">
            <div className="form-group">
              <label htmlFor="time" className="form-label">
                Heure d'enlèvement *
              </label>
              <input
                type="time"
                className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                id="time"
                value={shipping?.pickup_time}
                onChange={(e) =>
                  onShippingDataChange("pickup_time", e.target.value)
                }
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group ">
              <label htmlFor="date" className="form-label">
                Date de livraison *
                {/* { updateDate(shipping?.delivery_date)} */}
              </label>
              <input
                type="date"
                className="w-full bg-white border border-gray-500 rounded-lg outline-none datetimepicker form-control"
                id="date"
                value={shipping.delivery_date}
                onChange={(e) =>
                  onShippingDataChange("delivery_date", e.target.value)
                }
              />
            </div>
          </div>

          <div className="col-md-3">
            <div className="form-group">
              <label htmlFor="time" className="form-label">
                Heure de livraison *
              </label>
              <input
                type="time"
                className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                id="time"
                value={shipping?.delivery_time}
                onChange={(e) =>
                  onShippingDataChange("delivery_time", e.target.value)
                }
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group ">
              <label htmlFor="text" className="form-label">
                Instruction d'enlèvement
              </label>
              <TexteareaInput
                rows={1}
                className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                id="text"
                value={shipping?.pickup_instruction}
                onChange={(e) =>
                  onShippingDataChange("pickup_instruction", e.target.value)
                }
              />
            </div>
          </div>


          <div className="col-md-3">
            {" "}
            <div className="mb-3 form-group">
              <label htmlFor="field6" className="uppercase">Instruction de livraison</label>
              <TexteareaInput
                rows={1}
                className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                name="field6"
                value={shipping?.delivery_instruction}
                onChange={(e) =>
                  onShippingDataChange("delivery_instruction", e.target.value)
                }
              />
            </div>
          </div>

          <div className="col-md-3">
            <IncotermSelector
              value={expeditionPackage.incoterm}
              onSelect={(v) => {
                onPackageDataChange("incoterm", v.value);
              }}
            />
          </div>
          <div className="col-md-3">
            <div className="form-group ">
              <label htmlFor="text" className="form-label">
                Description
              </label>
              <TexteareaInput
                rows={1}
                className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                id="text"
                value={expeditionPackage.description || ""}
                onChange={(e) =>
                  onPackageDataChange("description", e.target.value)
                }
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group ">
              <label htmlFor="text" className="form-label">
                Code donneur
              </label>
              <input
                type="text"
                className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                id="text"
                value={shipping?.code_donneur || ""}
                onChange={(e) =>
                  onShippingDataChange("code_donneur", e.target.value)
                }
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group ">
              <label htmlFor="text" className="form-label">
                Code receveur
              </label>
              <input
                type="text"
                className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                id="text"
                value={shipping?.code_receveur || ""}
                onChange={(e) =>
                  onShippingDataChange("code_receveur", e.target.value)
                }
              />
            </div>
          </div>

          <div className="col-md-3">
            <div className="form-group">
              <label htmlFor="myInput1" className="uppercase">Mail tracking</label>
              <input
                type="text"
                className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                id="myInput1"
                value={shipping?.tracking_mail}
                onChange={(e) =>
                  onShippingDataChange("tracking_mail", e.target.value)
                }
              />
            </div>
          </div>

          <div className="col-md-3">
            <div className="mb-3 form-group">
              <label htmlFor="field6" className="uppercase">Information importante </label>

              <div className="relative rounded-md shadow-sm">
                <input
                  type="text"
                  name="tack"
                  id="tack"
                  className="block w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                  placeholder=""
                  aria-describedby="tracking"
                  value={(Array.isArray(expeditionDetails?.information_importante)) ? expeditionDetails?.information_importante[expeditionDetails?.information_importante.length - 1]?.information : ""}
                />
                <div className="absolute inset-y-0 right-0 flex items-center pr-3 ">
                  <span
                    className="text-gray-500 sm:text-sm pointer"
                    id="price-currency"
                  >
                    <TbPlus
                      onClick={onDispatchModalOpen}
                      className="pointer"
                      type="button"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-3">

            <CommentaireComponent withLabel={true} value={expeditionDetails} />
            {/* <label className="form-label">Suppléments</label>
              <TexteareaInput
                rows={1}
                className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                id="text"
                value={shipping?.detail_info_supplementaire?.supplements}
                onChange={(e) => {
                  onInfosSupplementaireChange("supplements", e.target.value);
                }}
              /> */}

          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label className="form-label" >Articles Exotiques</label>
              <TexteareaInput
                rows={1}
                className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                id="text"
                value={expedition?.detail_info_supplementaire?.articles_exotiques}
                onChange={(e) => {
                  onInfosSupplementaireChange(
                    "articles_exotiques",
                    e.target.value
                  );
                }}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label className="form-label">Frais Dédouanement</label>
              <TexteareaInput
                rows={1}
                className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                id="text"
                value={expedition?.detail_info_supplementaire?.frais_dedouanement}
                onChange={(e) => {
                  onInfosSupplementaireChange(
                    "frais_dedouanement",
                    e.target.value
                  );
                }}
              />
            </div>
          </div>

        </div>
      </fieldset>
    </>
  );
}
