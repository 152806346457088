import React, { useState } from "react";
import BlockNote from "../../components/blockNote/BlockNote";
import StateComponent from "../../components/stateComponent/StateComponent";
import UserStateComponent from "../../components/stateComponent/UserStateComponent.jsx";
import AdminListComponent from "../../components/adminListComponent/AdminListComponent";
import { useEffect } from "react";
import adminService from "../../services/admin/adminService";
import TableauComponent from "../../components/tableauComponent/tableau.component";
import { TableAdminEntete } from "../../utils/Functions/TableAdminEntete";
import { TableAdminDataConfig } from "../../utils/Functions/TableAdminDataConfig";
import IndexAdmin from "../../components/Admin/Expeditions/Index.jsx";

function HomeAdminPage(props) {
    // State
    const [StateUserData, setStateUserData] = useState([
        {
            libelle: "CLIENTS",
            nombre: 0,
        },
        {
            libelle: "CENTRES DE FACTURATIONS",
            nombre: 0,
        },
        {
            libelle: "OPS",
            nombre: 0,
        },
        {
            libelle: "ADMINISTRATEUR",
            nombre: 0,
        },
    ]);
    const [isLoading, setisLoading] = useState(true)

    const [StateData, setStateData] = useState([
        {
            libelle: "EXPEDITION EN COURS",
            nombre: 0,
            iconUrl:
                "https://assets7.lottiefiles.com/packages/lf20_etda6qWiuD.json",
            height: 50,
            width: 60,
            bgColor: "holder-blue",
        },
        {
            libelle: "EXPEDITION LIVREES",
            nombre: 0,
            iconUrl:
                "https://assets4.lottiefiles.com/private_files/lf30_nsqfzxxx.json",
            height: 50,
            width: 60,
            bgColor: "holder-light",
        },
        {
            libelle: "EXPEDITION ANNULEES",
            nombre: 0,
            iconUrl:
                "https://assets3.lottiefiles.com/packages/lf20_P7BPtXPF6l.json",
            height: 35,
            width: 40,
            bgColor: "bg-danger",
        },
    ]);

    const [shipping, setShipping] = useState([]);

    // Functions

    const updateStateData = (data, nombre) => {
        let newData = [...StateData];
        if (data === "dataAnnuler") {
            newData[2].nombre = nombre;
        } else if (data === "dataEnCours") {
            newData[0].nombre = nombre;
        } else if (data === "dataLivrer") {
            newData[1].nombre = nombre;
        }
        setStateData(newData);
    };

    const updateStateUserData = (data, nombre) => {
        let newData = [...StateUserData];
        if (data === "nbrClient") {
            newData[0].nombre = nombre;
        } else if (data === "nbrBillings") {
            newData[1].nombre = nombre;
        } else if (data === "nbrOps") {
            newData[2].nombre = nombre;
        } else if (data === "nbrAdmin") {
            newData[3].nombre = nombre;
        }
        setStateUserData(newData);
    };

    // Hook

    useEffect(() => {
        adminService.dashboardData().then(
            (res) => {
                let { data } = res.data;

                updateStateData("dataAnnuler", data?.dataAnnuler);
                updateStateData("dataEnCours", data?.dataEnCours);
                updateStateData("dataLivrer", data?.dataLivrer);
                updateStateUserData("nbrClient", data?.nbrClient);
                updateStateUserData("nbrBillings", data?.nbrBillings);
                updateStateUserData("nbrOps", data?.nbrOps);
                updateStateUserData("nbrAdmin", data?.nbrAdmin);
                if(data?.lastShippings){
                    setShipping([...shipping,...data.lastShippings]);
                }
                setisLoading(false)
                

            },
            (error) => {
                setisLoading(false)
            }
        );
    }, []);




    return (
        <div>
            <div className="wallet-group">
                <div className="row">
                    {StateData.map((item, key) => (
                        <StateComponent data={item} key={key} />
                    ))}
                </div>
            </div>
            <div className="transaction-grp">
                <div className="row">
                    {StateUserData.map((item, key) => (
                        <UserStateComponent data={item} key={key} />
                    ))}
                </div>
            </div>

            <div className="row">
                {/* <TableauComponent
                    colonnes={TableAdminEntete("expeditions")}
                    valeurs={shipping}
                    title={"Liste des 10 dernières expéditions"}
                    withFiltre={false}
                    parametreFiltre={[]}
                    expeditionKind={"all"}
                    espace={"admin"}
                    role="admin"
                    secondTitle=""
                    top={true}
                    children=""
                    dataConfig={TableAdminDataConfig("expeditions")}
                    dataType={"expeditions"}
                /> */}
                <IndexAdmin items={shipping} colonnes={TableAdminEntete("expeditions")} isLoading={isLoading}/>
            </div>
        </div>
    );
}

export default HomeAdminPage;
