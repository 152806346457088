import React, { useCallback, useEffect, useState } from "react";
import "./../../assets/css/bootstrap.min.css";
import "./../../assets/plugins/fontawesome/css/fontawesome.min.css";
import "./../../assets/plugins/fontawesome/css/all.min.css";
import "./../../assets/plugins/datatables/datatables.min.css";
import "./../../assets/css/select2.min.css";
import "./../../assets/css/style.css";
import MessageImg from "./../../assets/img/icon/message-icon.svg";
import logoParis from "./../../assets/img/exnext-logo/paris.png";
import logoMedical from "./../../assets/img/exnext-logo/medical.png";
import logoService from "./../../assets/img/exnext-logo/service.png";
import favParis from "./../../assets/img/favicon/parisfavicon.png";
import favMedical from "./../../assets/img/favicon/medicalfavicon.jpg";
import favService from "./../../assets/img/favicon/medicalfavicon.jpg";
import { Link, useNavigate } from "react-router-dom";
import { Player } from "@lottiefiles/react-lottie-player";
import { getCurrentDate, urlSplit } from "../../utils/Functions/otherFunctions";
import { getAllShippings } from "../../utils";
import { TbUser } from "react-icons/tb";
import AuthService from "../../services/auth/AuthService";

const HeaderLayout = React.memo(({ type, toggleMenu, isOpened }) => {
    const navigate = useNavigate();
    const [userName, setUserName] = useState("John Doe");
    const { role, exnetSpace } = urlSplit()
    let lien = window.location.pathname;
    let logoImg;
    let favImg;
    let checkAdmin = localStorage.getItem("admincheck");

    if (type === "admin" || role === "admin") {
        logoImg = logoParis;
        favImg = favParis;
    } else if (type === "paris") {
        logoImg = logoParis;
        favImg = favParis;
    } else if (type === "medical") {
        logoImg = logoMedical;
        favImg = favMedical;
    } else if (type === "service") {
        logoImg = logoService;
        favImg = favService;
    }

    const getCurrentTime = () => {
        const date = new Date();
        const hours = date.getHours();
        const minutes = date.getMinutes();
        let seconds = date.getSeconds();
        if (seconds < 10) seconds = "0" + seconds;
        return `${hours < 10 ? "0" + hours : hours}:${minutes < 10 ? "0" + minutes : minutes}:${seconds < 10 ? seconds : seconds}`;
    };



    const checkRolesUser = (role = false) => {
        let data = JSON.parse(localStorage.getItem("persist:root"));
        if (userName === "John Doe") {
            let name = data?.name?.replace(/['"]+/g, "");
            if (name?.length > 15) {
                name = name?.substring(0, 15) + "...";
            }
            setUserName(name);
        }
        if (role && data) {
            if (data.roles.includes(role)) {
                return true;
            } else {
                return false;
            }
        }
    };

    const logout = () => {
        localStorage.clear();
        lien.includes("admin")
            ? navigate("/admin/auth")
            : navigate(
                "/" + lien.split("/")[1] + "/" + lien.split("/")[2] + "/auth"
            );
    };

    const goToAdmin = () => {
        localStorage.setItem("client", localStorage.getItem("oldToken"));
        AuthService.setHeader(localStorage.getItem("oldToken"));
        localStorage.setItem("persist:root", localStorage.getItem("persist:rootOld"));

        localStorage.removeItem("oldToken");

        localStorage.removeItem("persist:rootOld");
        localStorage.removeItem("admincheck");

        // navigate("/admin/dash/utilisateurs");
        window.location.href = "/admin/dash/utilisateurs"
    };

    const [currentTime, setCurrentTime] = useState(getCurrentTime);
    const [date, setDate] = useState(getCurrentDate);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(getCurrentTime);
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);


    checkRolesUser();



    const allShippings = getAllShippings() === true

    const toggleAllShippings = useCallback(() => {
        localStorage.setItem("allShippings", allShippings ? false : true)
        //reload page
        window.location.reload()
    }, [allShippings])

    const renderAllShippingBtn = (<button
        style={{
            height: "auto",
            margin: "auto",
            marginLeft: "5px",
            marginRight: "5px",
        }}
        type="button"
        className={`btn btn-outline-all ${allShippings ? "active" : ""}`}
        onClick={toggleAllShippings}
    >
        <span className={"interface-icon"}>
            <i className={"fa fa-sitemap"}></i>
        </span>
        <span className={"interface-text"}>
            Toutes les expéditions
        </span>
    </button>)

    return (
        <>
            <div className="header">
                <div className="header-left pb-2">
                    <a
                        href={role === 'admin' ? `/${role}/dash` : `/${role}/${exnetSpace}/dash`}
                        rel="noopener noreferrer"
                        style={{ display: "none" }}
                        className="fav-image"
                    >
                        <span>
                            {" "}
                            <img
                                src={favImg}
                                style={{
                                    maxWidth: "28px",
                                    margin: "auto",
                                    marginTop: "10px",
                                }}
                                alt="Logo"
                            />
                        </span>
                    </a>
                    <a
                        href={role === 'admin' ? `/${role}/dash` : `/${role}/${exnetSpace}/dash`}
                        rel="noopener noreferrer"
                        className="logo"
                    >
                        <span>
                            {" "}
                            <img
                                src={logoImg}
                                style={{ maxWidth: "115px" }}
                                alt="Logo"
                            />
                        </span>
                    </a>
                </div>
                <div className="left-right-menu">

                    <a id="toggle_btn" className="left-chev" href="#" onClick={toggleMenu}>
                        {isOpened ? <i className="fas fa-chevron-left" /> : <i className="fas fa-chevron-right" />}

                    </a>
                </div>
                <a
                    id="mobile_btn"
                    className="mobile_btn float-left"
                    href="#sidebar"
                >
                    <i className="fas fa-bars"></i>
                </a>
                <ul
                    className={"d-inline-block"}
                    style={{
                        display: "inline-block",
                        height: "100%",
                        marginLeft: "21px",
                    }}
                >
                    <li>
                        <span className={"date-sys"} style={{ fontSize: "large" }}>
                            {date}
                        </span>
                        <br />
                        <span className={"heure-sys"} style={{ fontSize: "small" }}>
                            {currentTime}
                        </span>
                    </li>
                </ul>
                <ul className="nav user-menu user-menu-group float-end d-flex">
                    {role === "admin" || role === "ops" ? (
                        <li className="nav-item">
                            {(checkRolesUser("ROLE_OPS_PARIS") ||
                                checkRolesUser("ROLE_ADMIN")) && (
                                    <button
                                        style={{
                                            height: "auto",
                                            margin: "auto",
                                            marginLeft: "5px",
                                            marginRight: "5px",
                                        }}
                                        type="button"
                                        className={
                                            lien.includes("ops/paris")
                                                ? "btn btn-outline-paris active"
                                                : "btn btn-outline-paris"
                                        }
                                        onClick={() => {
                                            window.location.href = "/ops/paris/dash"
                                            // navigate("/ops/paris/dash")
                                        }}
                                    >
                                        <span className={"interface-icon"}>
                                            <i className={"fa fa-university"}></i>
                                        </span>
                                        <span className={"interface-text"}>Paris</span>
                                    </button>
                                )}
                            {(checkRolesUser("ROLE_OPS_MEDICAL") ||
                                checkRolesUser("ROLE_ADMIN")) && (
                                    <button
                                        style={{
                                            height: "auto",
                                            margin: "auto",
                                            marginLeft: "5px",
                                            marginRight: "5px",
                                        }}
                                        type="button"
                                        className={
                                            lien.includes("ops/medical")
                                                ? "btn btn-outline-medical active"
                                                : "btn btn-outline-medical"
                                        }
                                        onClick={() => {
                                            window.location.href = "/ops/medical/dash"
                                            // navigate("/ops/medical/dash");
                                        }}
                                    >
                                        <span className={"interface-icon"}>
                                            <i className={"fa fa-stethoscope"}></i>
                                        </span>
                                        <span className={"interface-text"}>
                                            Medicals
                                        </span>
                                    </button>
                                )}

                            {(checkRolesUser("ROLE_OPS_SERVICE") ||
                                checkRolesUser("ROLE_ADMIN")) && (
                                    <button
                                        style={{
                                            height: "auto",
                                            margin: "auto",
                                            marginLeft: "5px",
                                            marginRight: "5px",
                                        }}
                                        type="button"
                                        className={
                                            lien.includes("ops/service")
                                                ? "btn btn-outline-service active"
                                                : "btn btn-outline-service"
                                        }
                                        onClick={() => {
                                            window.location.href = "/ops/service/dash"
                                            // navigate("/ops/service/dash");
                                        }}
                                    >
                                        <span className={"interface-icon"}>
                                            <i className={"fa fa-fire"}></i>
                                        </span>
                                        <span className={"interface-text"}>
                                            Services
                                        </span>
                                    </button>
                                )}
                            {checkRolesUser("ROLE_ADMIN") && (
                                <button
                                    style={{
                                        height: "auto",
                                        margin: "auto",
                                        marginLeft: "5px",
                                        marginRight: "5px",
                                    }}
                                    type="button"
                                    className={
                                        lien.includes("admin")
                                            ? "btn btn-outline-admin active"
                                            : "btn btn-outline-admin"
                                    }
                                    onClick={() => {
                                        window.location.href = "/admin/dash"
                                        // navigate("/admin/dash");
                                    }}
                                >
                                    <span className={"interface-icon"}>
                                        <i className={"fa fa-cog"}></i>
                                    </span>
                                    <span className={"interface-text"}>Admin</span>
                                </button>
                            )}
                            {role !== "admin" && <>
                                {renderAllShippingBtn}
                            </>}

                        </li>
                    ) : (
                        role !== "exploitation" ?
                            <>
                                {/* {renderAllShippingBtn} */}
                            </>
                            // <li className="nav-item">
                            //     <button
                            //         style={{
                            //             height: "auto",
                            //             margin: "auto",
                            //             marginLeft: "5px",
                            //             marginRight: "5px",
                            //         }}
                            //         type="button"
                            //         className="btn btn-outline-all"
                            //     >
                            //         <span className={"interface-icon"}>
                            //             <i className={"fa fa-sitemap"}></i>
                            //         </span>
                            //         <span className={"interface-text"}>
                            //             Toutes les expéditions
                            //         </span>
                            //     </button>
                            // </li>
                            : null
                    )}
                    {checkAdmin && (checkAdmin === "true" || checkAdmin === true) && (
                        <li className="nav-item">
                            <button
                                style={{
                                    height: "auto",
                                    margin: "auto",
                                    marginLeft: "5px",
                                    marginRight: "5px",
                                }}
                                type="button"
                                className="btn btn-danger"
                                onClick={() => {
                                    goToAdmin();
                                }}
                            >
                                <span className={"interface-icon"}>
                                    <i className={"fa fa-sitemap"}></i>
                                </span>
                                <span className={"interface-text"}>
                                    Retour à l'admin
                                </span>
                            </button>
                        </li>
                    )}
                    {/* {role !== 'admin' && <li className="nav-item dropdown d-none d-sm-block ">
                        <a
                            href={`/${role}/${exnetSpace}/dash/message`}
                            id="open_msg_box_2"
                            className="hasnotifications nav-link bg-box"
                        >
                            <img src={MessageImg} alt="" />
                        </a>
                    </li>} */}

                    <li className="nav-item dropdown has-arrow user-profile-list">
                        <a
                            href="#"
                            className="dropdown-toggle nav-link user-link"
                            data-bs-toggle="dropdown"
                        >
                            <span className="user-img">
                                <TbUser size={25} className="text-black" />
                                {/* <Player
                                    autoplay
                                    loop
                                    src="https://assets5.lottiefiles.com/packages/lf20_gsiJ2w.json"
                                    style={{ height: "40px", width: "40px" }}
                                ></Player> */}
                                <span className="status online"></span>
                            </span>
                            <div className="user-names">
                                <h5>{userName}</h5>
                                <span>{role.toUpperCase()}</span>
                            </div>
                        </a>
                        <div className="dropdown-menu">
                            <a
                                className="dropdown-item"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    navigate(
                                        lien.split("dash")[0] + "dash/profile"
                                    );
                                }}
                            >
                                Profil
                            </a>

                            <a
                                className="dropdown-item"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    logout();
                                }}
                            >
                                Se déconnecter
                            </a>
                        </div>
                    </li>
                </ul>
                <div className="dropdown mobile-user-menu float-end">
                    <a
                        href="#"
                        className="nav-link dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                    >
                        <i className="fas fa-ellipsis-v"></i>
                    </a>
                    <div className="dropdown-menu dropdown-menu-right">
                        <a
                            className="dropdown-item"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                navigate(lien.split("dash")[0] + "/dash/profile");
                            }}
                        >
                            My Profile
                        </a>

                        <a
                            className="dropdown-item"
                            onClick={() => {
                                logout();
                            }}
                        >
                            Se déconnecter
                        </a>
                    </div>
                </div>
            </div>
        </>

    );
});

export default HeaderLayout;
