import React, { useEffect, useState } from "react";
import { BannierStore } from "./../../../states/bannier";
import { TableLoader } from "../../Utils/Loader";
import ModalComponent from "./../../Utils/Modal/Index";
import ModalComponent2 from "../../modal/ModalComponent";
import { SwitchComponent } from "../../Utils/Index";
import { formatDefaultDate } from "../../../utils/moment";
import adminService from "../../../services/admin/adminService";
import { toast } from "react-toastify";

function ListBaniere({ setAction }) {
  const bannierList = BannierStore((store) => store.bannierList);
  const setBannier = BannierStore((store) => store.setBannier);
  const setBannierList = BannierStore((store) => store.setBannierList);
  const updateBannier = BannierStore((store) => store.updateBannier);
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);

  const onSelect = (item, action) => {
    setBannier(item);
  };

  const handleSubmit = async (id, value) => {
    const toastsId = toast.loading("Modification en cours ...", {
      position: "top-center",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

    try {
      adminService.UpdateInformation({ active_baniere: value, id: id }).then(
        (response) => {
          console.log(response);
          toast.update(toastsId, {
            render: "Modification éffetuée avec succès",
            type: "success",
            isLoading: false,
            autoClose: 1000,
            position: "top-center",
          });
          updateBannier(id, { active_baniere: value });
        },
        (error) => {
          console.log(error);
          toast.update(toastsId, {
            render: "Impossible de faire la modification",
            type: "error",
            isLoading: false,
            autoClose: 1000,
            position: "top-center",
          });
        }
      );
    } catch (error) {
      toast.error("Impossible de faire la modification", {
        position: "top-center",
      });
    }
  };


  useEffect(() => {
    const fecthInformaton = async () => {
      try {
        adminService.fetchInformation().then(
          (response) => {
            let data = response.data.data[0].data;
            // let status = stautsList.find((item, index) => item.value === other?.active_baniere)
            // setContentData({ ...other, active_baniere: status })
            setBannierList(data);
            setIsLoading(false);
          },
          (error) => {
            console.log(error);
            setIsLoading(false);
          }
        );
      } catch (error) {
        setIsLoading(false);
      }
    };

    fecthInformaton();
  }, []);

  return (
    <>
      <div>
        <h3 className="my-3 text-xl font-semibold">Liste des Bannières</h3>
        <div className="relative overflow-x-auto">
          <div className="bg-white card-box">
            <div className="card-block">
              {/*                     <h4 className="card-title">Basic Table</h4>*/}
              <div className="table-responsive">
                <table className="table mb-0">
                  <thead>
                    <tr className="uppercase">
                      <th className="text-left">ID</th>
                      <th className="text-left">Titre</th>
                      <th className="text-left">Contenus</th>
                      <th className="text-left">Photo</th>
                      <th className="text-left">Etat</th>
                      <th className="text-left">Actif</th>
                      <th className="text-left">DATE DE CRÉATION</th>
                      {/* <th className="text-left">Actions</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {bannierList.length > 0 &&
                      bannierList.map((item, i) => {
                        // const formattedType = formatInterface(item.type)
                        return (
                          <tr key={i} className="py-3">
                            <td
                              className={"pointer"}
                              onClick={() => {
                                setAction({ action: "update", id: item?.id });
                              }}
                            >
                              {i + 1}
                            </td>
                            <td
                              className={"pointer"}
                              onClick={() => {
                                setAction({ action: "update", id: item?.id });
                              }}
                            >
                              {item.libele}
                            </td>
                            <td
                              className={"pointer max-w-[300px]"}
                              onClick={() => {
                                setAction({ action: "update", id: item?.id });
                              }}
                            >
                              <div
                                className={`${item.type} text-center rounded-sm`}
                              >
                                {item?.contenus?.slice(0, 86)}....
                              </div>
                            </td>
                            <td
                              className={"pointer"}
                              onClick={() => {
                                setAction({ action: "update", id: item?.id });
                              }}
                            >
                              {item?.with_image===1 && <img
                                src={
                                  process.env.REACT_APP_BACKEND_HOST +
                                  "/" +
                                  item?.image_url
                                }
                                alt={item?.image_url}
                                className={"h-50 rounded-xl"}
                                height={100}
                                width={100}
                              />}

                              {item?.with_image===0 && <>Sans image</>}

                            </td>
                            <td
                              className={"pointer"}
                              onClick={() => {
                                setAction({ action: "update", id: item?.id });
                              }}
                            >
                              {item?.active_baniere
                                ? "Disponible"
                                : "Non disponible"}
                            </td>
                            <td>
                              <SwitchComponent
                                onChange={(checked) => {
                                  handleSubmit(item?.id, checked ? 1 : 0);
                                }}
                                checked={Boolean(item.active_baniere)}
                              />
                            </td>

                            <td
                              className={"pointer"}
                              onClick={() => {
                                setAction({ action: "update", id: item?.id });
                              }}
                            >
                              {formatDefaultDate(item.created_at)}
                            </td>

                            {/* <td>
                                                            <div className="flex items-center">
                                                                <div
                                                                    className="text-green-600 cursor-pointer text-[14px] mr-5"
                                                                    onClick={() => {
                                                                        onSelect(item, "edit")
                                                                    }} title="Editer"
                                                                >
                                                                    <i className="fas fa-edit"></i>
                                                                </div>

                                                                <div
                                                                    className="text-red-500 cursor-pointer text-[14px]"
                                                                    onClick={() => {
                                                                        onSelect(item, "delete")
                                                                    }}
                                                                    title="Supprimer"
                                                                >
                                                                    <i className="fas fa-trash"></i>
                                                                </div>
                                                            </div>
                                                        </td> */}
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                {isLoading ? (
                  <TableLoader />
                ) : bannierList.length === 0 ? (
                  <h6 className="my-5 text-lg text-center">Aucune donnée</h6>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalComponent2 open={open} setOpen={setOpen}>
        ddddddd
      </ModalComponent2>
    </>
  );
}

export default ListBaniere;
