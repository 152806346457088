import React from "react";
import removedLogo from './../../assets/img/icon/call-remove.svg'
import avatarLogo from './../../assets/img/profile/avatar-01.jpg'


function BottomLayout(props) {
    return (
        <>
            <div className="sidebar-overlay" data-reff=""></div>

            <div className="modal fade exchange-currency-bg" id="currency">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <a className="btn-closes" data-bs-dismiss="modal">
                                <i className="feather-x"></i>
                            </a>
                        </div>

                        <div className="modal-body">
                            <div className="crypto-exchange">
                                <div className="card-title">
                                    <h4 className="page-title">
                                        Exchange Cryptocurrency{" "}
                                    </h4>
                                </div>
                                <p className="ms-2 ps-2 pe-4">
                                    Transfer from one wallet to another within
                                    seconds. It's that simple.
                                </p>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="exchange-form">
                                            <form>
                                                <div className="form-row ">
                                                    <div className="form-group col-md-12">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="1.432"
                                                        />
                                                    </div>
                                                    <div className="form-group bg-hover col-md-12">
                                                        <select className="form-control select">
                                                            <option >
                                                                BTC
                                                            </option>
                                                            <option>
                                                                Ethereum
                                                            </option>
                                                            <option>
                                                                Ripple
                                                            </option>
                                                            <option>
                                                                Bitcoin
                                                            </option>
                                                            <option>
                                                                Cardano
                                                            </option>
                                                            <option>
                                                                Litecoin
                                                            </option>
                                                            <option>NEO</option>
                                                            <option>
                                                                Stellar
                                                            </option>
                                                            <option>EOS</option>
                                                            <option>NEM</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary represh-btn mb-4"
                                                        >
                                                            <i
                                                                className="fas fa-exchange-alt"
                                                                aria-hidden="true"
                                                            ></i>
                                                        </button>
                                                    </div>
                                                    <div className="form-group col-md-12 ">
                                                        <input
                                                            type="text"
                                                            className="form-control "
                                                            placeholder="338"
                                                        />
                                                    </div>
                                                    <div className="form-group bg-hover col-md-12">
                                                        <select className="form-control select">
                                                            <option >
                                                                BTC
                                                            </option>
                                                            <option>
                                                                Ethereum
                                                            </option>
                                                            <option>
                                                                Ripple
                                                            </option>
                                                            <option>
                                                                Bitcoin
                                                            </option>
                                                            <option>
                                                                Cardano
                                                            </option>
                                                            <option>
                                                                Litecoin
                                                            </option>
                                                            <option>NEO</option>
                                                            <option>
                                                                Stellar
                                                            </option>
                                                            <option>EOS</option>
                                                            <option>NEM</option>
                                                        </select>
                                                    </div>
                                                    <div className="text-center">
                                                        <button className="btn btn-primary submit-btn mt-2">
                                                            Exchange Now
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade exchange-currency-bg" id="incoming">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="crypto-income-blk text-center">
                                <div className="voice-call-user">
                                    <img
                                        src={avatarLogo}
                                        alt="img"
                                    />
                                    <h3>Bernardo James</h3>
                                    <p>Senior Developer</p>
                                </div>
                                <div className="calling-income">
                                    <h4>Calling...</h4>
                                </div>
                                <div className="voice-menu-income comman-flex">
                                    <a
                                        href="#"
                                        className="btn btn-primary call-remove comman-flex me-2"
                                        data-bs-dismiss="modal"
                                    >
                                        <img
                                            src={removedLogo}
                                            alt="img"
                                        />
                                    </a>
                                    <a
                                        href="#"
                                        className="btn btn-primary call-received comman-flex"
                                    >
                                        <img
                                            src={removedLogo}
                                            alt="img"
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BottomLayout;
