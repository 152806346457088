import React, { useEffect } from "react";
import DetailsColisComponent from "../../components/detailsExpedition/DetailsColisComponent";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";

const ExpeditionDetailsPage = React.memo((props) => {
  const { ExpeditionType, identifiant } = useParams();
  const [espace, setEspace] = useState();
  const navigate = useNavigate();
  const { pathname } = useLocation()

  useEffect(() => {
    if (window.location.pathname.split("/")[1] !== "client") {
      if (
        ExpeditionType === undefined ||
        identifiant === undefined ||
        !parseInt(identifiant)
      ) {
        let lien = window.location.pathname.split("/");
        lien.pop();
        if (lien[0].toLowerCase() !== "client") {
          lien = lien.join("/");
          window.location.href = lien;
        }
      }
    }

    let pathName = window.location.pathname.split("/")[2];
    setEspace(pathName);
  }, []);

  return (
    <>
      <div className="row" style={{ width: "99%", margin: "auto" }}>
        <div className="col-md-12">
          <DetailsColisComponent
            expeditionId={identifiant}
            expeditionSpace={espace}
            key={pathname}
          />
        </div>
      </div>
    </>
  );
});

export default ExpeditionDetailsPage;
