import React, { useCallback, useEffect, useState } from "react";
import { useBasicFormValues } from "../../hooks/useFormValues";
import { useParams } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import opsService from "../../services/ops/opsService";
import { toast } from "react-toastify";
import { validateTrackingData } from "../../utils/validators";
import ModalComponent from "../Utils/Modal/Index";
import { getTimeFromDate } from "../../utils";
import CoursierSelector from "../Utils/Selectors/CoursierSelector";
import AddTrackingComponent from "./AddTrackingComponent";

export default function AddTrackingForm({
  data,
  template,
  expedition,
  onSubmit,
  onClose,
  updatedSelectedData,
}) {
  const selectedValue = { ...data };
  const { milieu: type } = useParams();
  const initialValue = {
    details: "",
    heur_suivi: "",
    date_suivi: "",
    agents: "",
    chaufeur: "",
    visible: 1,
    problem: 0,
    mail: 0,
    sms: 0,
    shipping_id: "",
    isparis: type === "paris" ? 1 : 0,
    ismedical: type === "medical" ? 1 : 0,
    isservice: type === "service" ? 1 : 0,
    isdriver: 0
  };
  const {
    data: trackingData,
    setFormValue,
    setData,
  } = useBasicFormValues(initialValue);
  const [suivis, setSuivis] = useState([]);
  const [status, setStatus] = useState("");

  const [isSubmitting, setIsSubmitting] = useState(false);

  const STATUS = {
    SENDING_MAIL: "SENDING_MAIL",
    SENDING_SMS: "SENDING_SMS",
  };

  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      setFormValue("agents", user.name);
    }
  }, [user]);

  useEffect(() => {
    if (expedition) {
      //console.log({ expedition })
      setFormValue("shipping_id", expedition.id);
    }
  }, [expedition]);

  useEffect(() => {
    if (data) {
      setData((v) => ({ ...v, ...data }));
    }
  }, [data]);

  const onChaufeurSelect = (v) => {
    setFormValue("chaufeur", v);
  };


  const sendMailAndSms = useCallback(
    async (_status) => {
      try {
        setStatus(_status);

        const actionType = _status === STATUS.SENDING_MAIL ? "mail" : "sms";

        const { data } = await opsService.sendMailOrSms({
          type: actionType,
          shipping_id: expedition.id,
          interface: type
        });

        if (data.success) {
          setStatus("");
          toast.success(
            `${actionType === "mail" ? "Mail" : "Sms"} envoyé avec succès`,
            {
              position: "bottom-center",
              autoClose: 4000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        }
      } catch (error) {
        console.error(error);
      }
    },
    [STATUS.SENDING_MAIL, expedition?.id, type]
  );

  const sendingMail = status === STATUS.SENDING_MAIL;
  const sendingSms = status === STATUS.SENDING_SMS;



  const handleSubmit = useCallback(async () => {
    let createdAt = new Date(expedition.created_at).getTime() - (86400000*7);
    let newDate = new Date(trackingData.date_suivi).getTime()


    if (createdAt <= newDate) {

      try {
        const { error: _err } = validateTrackingData(trackingData);

        if (_err) {
          toast.error(`${_err}`, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {

          const submitData = {
            ...trackingData,
            shipping_id: expedition.id,
            template: template ? 1 : 0,
          };
          setIsSubmitting(true);

          const { data } = await opsService.addTracking(submitData);
          if (data.success) {
            onSubmit?.(data.data);

            setSuivis((s) => [data.data, ...s]);

            if (updatedSelectedData) {
              // Pour mettre à jour en passant par le tableau
              let newValue = { ...selectedValue };
              newValue?.suivis?.push(data.data);
              updatedSelectedData({ ...newValue });
            }

            setData({ ...initialValue, agents: user.name, shipping_id: expedition.id })

            toast.success(`Le suivi a été enregistré avec succès`, {
              position: "bottom-center",
              autoClose: 4000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            if (onClose) {
              onClose();
            }
          }
        }
      } catch (error) {
        console.error(error);
        if (error.response?.status === 400) {
          //console.log("ERR", error.response.data)
          toast.error(error.response.data.error, {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error("Une erreur est survenue", {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } finally {
        setIsSubmitting(false);
      }

    } else {
      toast.error('Impossible de mettre cette date de suivis', {
        position: 'top-center'
      })
    }
  }, [trackingData, expedition, template, user]);

  useEffect(() => {
    console.log('trackingData', trackingData)


    const d = new Date();

    const date = d.toISOString().split("T")[0];
    const heure = getTimeFromDate();

    setFormValue("date_suivi", date);
    setFormValue("heur_suivi", heure);


  }, []);

  const verifyDate = (value) => {

    setFormValue("date_suivi", value);

  }

  return (
    <div>
      <div className="row">
        <div className="form-group">
          <textarea
            rows="5"
            cols="5"
            className="textarea w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
            placeholder={"Description"}
            value={trackingData.details}
            onChange={(e) => setFormValue("details", e.target.value)}
          ></textarea>
        </div>
        <div className="col-md-6">
          <div className="relative max-w-sm">
            <input
              type="date"
              className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5      "
              placeholder="Select date"
              value={trackingData.date_suivi}
              onChange={(e) => verifyDate(e.target.value)}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <input
              type="time"
              className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
              placeholder={"Heure"}
              value={trackingData.heur_suivi}
              onChange={(e) => setFormValue("heur_suivi", e.target.value)}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group ">
            <label
              className="block tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="agents"
            >
              Agent*
            </label>
            <input
              className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5      "
              id="agents"
              type="agents"
              placeholder=""
              value={trackingData.agents}
              disabled
            /* onChange={(e) => {
                          setFormValue("agents", e.target.value);
                      }} */
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group ">
            <CoursierSelector
              selectedValue={trackingData.chaufeur}
              onChange={onChaufeurSelect}
            />
          </div>
        </div>
        <div className="col-md-7">
          <div className="form-group">
            <div className="checkbox">
              <label className={"selection"}>
                <input
                  type="checkbox"
                  name="checkbox"
                  value={trackingData.visible}
                  checked={trackingData.visible}
                  onChange={() =>
                    setFormValue("visible", trackingData.visible === 1 ? 0 : 1)
                  }
                />{" "}
                Visible
              </label>
            </div>

            <div className="checkbox">
              <label className={"selection"}>
                <input
                  type="checkbox"
                  name="checkbox"
                  value={trackingData.problem}
                  checked={trackingData.problem}
                  onChange={() =>
                    setFormValue("problem", trackingData.problem === 1 ? 0 : 1)
                  }
                />{" "}
                Case à cocher en cas de problème sur le colis
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="row " style={{ justifyContent: "flex-start" }}>
        {/*  <div className="col-md-6">
                    <div className="row">
                        <div className="col-6">
                            <div className="form-group w-100">
                                <button
                                    className={`text-white bg-black ${isValid ? "cursor-pointer" : "cursor-not-allowed"} hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center`}
                                    onClick={handleSubmit}
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting ? "Enregistement..." : "Enregistrer"}
                                </button>
                                <button
                                    className={`text-white bg-black ${sendingMail ? "cursor-pointer" : "cursor-not-allowed"} hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center mr-6`}
                                    onClick={() => {
                                        sendMailAndSms(STATUS.SENDING_MAIL)
                                    }}
                                    disabled={sendingMail}
                                >
                                    Envoyer mail
                                </button>

                                <button
                                    className={`text-white bg-black ${sendingSms ? "cursor-pointer" : "cursor-not-allowed"} hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center`}
                                    onClick={() => {
                                        sendMailAndSms(STATUS.SENDING_SMS)
                                    }}
                                    disabled={sendingSms}
                                >
                                    Envoyer sms
                                </button>
                            </div>
                        </div>
                    </div>
                </div> */}

        <div className="flex items-center flex-wrap">
          <>
            {expedition.manifest === 0 && (
              <>
                <button
                  className={`text-white bg-black ${sendingMail ? "cursor-pointer" : "cursor-not-allowed"
                    } hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center mr-6 mt-2`}
                  onClick={() => {
                    sendMailAndSms(STATUS.SENDING_MAIL);
                  }}
                  disabled={sendingMail}
                >
                  Envoyer mail
                </button>

                <button
                  className={`text-white bg-black ${sendingSms ? "cursor-pointer" : "cursor-not-allowed"
                    } hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center mr-6 mt-2`}
                  onClick={() => {
                    sendMailAndSms(STATUS.SENDING_SMS);
                  }}
                  disabled={sendingSms}
                >
                  Envoyer sms
                </button>
              </>
            )}

            <button
              className={`text-white bg-black hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center mt-2`}
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              {isSubmitting ? "Enregistement..." : "Enregistrer"}
            </button>
          </>
        </div>
      </div>
    </div>
  );
}

export const AddTrackingModal = ({
  isOpen,
  onClose,
  onSubmit,
  expedition,
  updatedSelectedData = false,
  showComment = false,
  setExpeditionData = false
}) => {
  return (
    <ModalComponent
      isOpen={isOpen}
      onClose={onClose}
      title={"Ajout de tracking"}
    >
      <div className="py-2 px-4">
        {/* <AddTrackingForm
                    expedition={expedition}
                    onSubmit={onSubmit}
                    data={data}
                    template={template}
                /> */}
        <div className="mt-3">
          <AddTrackingComponent
            expedition={expedition}
            showComment={showComment}
            setExpeditionData={setExpeditionData}
            onSubmit={onSubmit} />
          {/* <TrackingFormWithTemplates onAddSubmit={onSubmit} expedition={expedition} onClose={onClose} updatedSelectedData={updatedSelectedData} /> */}
        </div>
      </div>
    </ModalComponent>
  );
};
