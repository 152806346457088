import { TbCirclePlus, TbFileInvoice, TbHistory, TbLayoutDashboard, TbMotorbike, TbPackageExport, TbPackageImport, TbPackageOff, TbUser } from 'react-icons/tb';
import { returnExpeditionTypeLogo } from '../../../utils/Functions/otherFunctions';
import medicalImport from '../../../assets/img/svg/Medicals-import.svg'
import medicalExport from '../../../assets/img/svg/Medicals-export.svg'
import medicalcourse from '../../../assets/img/svg/Medicals.svg'
import archiveLogo from '../../../assets/img/Icones/archive/medicals.svg'
import expeditionArchiveLogo from '../../../assets/img/Icones/expedition/medicals.svg'
import factureLogo from '../../../assets/img/Icones/Facture/medicals.svg'
import historiqueLogo from '../../../assets/img/Icones/historique/medicals.svg'
import plusLogo from '../../../assets/img/Icones/plus/medicals.svg'
import explotationLogo from '../../../assets/img/Icones/exploitation/medicals.svg'
import dashLogo from '../../../assets/img/Icones/Dashboard/medicals.svg'

export default {
    data: [
        {
            libelle: "Dashboard",
            icon: <img alt={'fd'} src={dashLogo} className={` w-8 hover:text-black`} />,
            liens: [
                {
                    libelle: "Expéditions en attente",
                    lien: "/ops/medical/dash/en-attente",
                    key: "shippingPending"
                },
                {
                    libelle: "Expéditions en cours",
                    lien: "/ops/medical/dash/en-cours",
                    key: "shippingActive"
                },
                {
                    libelle: "Expéditions livrées",
                    lien: "/ops/medical/dash/livre",
                    key: "shippingDelivered"
                },
                {
                    libelle: "Expéditions à archiver",
                    lien: "/ops/medical/dash/a-archivee",
                    key: "shippingTobeArchived"
                },
                {
                    libelle: "Expéditions facturées",
                    lien: "/ops/medical/dash/facturee",
                    key: "shippingFactured"
                },
                {
                    libelle: "Expéditions annulées",
                    lien: "/ops/medical/dash/annulee",
                    key: "shippingCancelled"
                },
                {
                    libelle: "Manifest",
                    lien: "/ops/medical/dash/manifest",
                    key: "shippingManifest"
                }
            ],
        },
        {
            libelle: "Les courses",
        },
        {
            libelle: "Liste des courses",
            icon: <img alt={'fd'} src={medicalcourse} className={` w-8 hover:text-black`} />,
            liens: [
                {
                    libelle: "Courses en attente",
                    lien: "/ops/medical/dash/courses-a-courses/en-attente",
                },
                {
                    libelle: "Courses en cours",
                    lien: "/ops/medical/dash/courses-a-courses/en-cours",
                },
                {
                    libelle: "Courses livrées",
                    lien: "/ops/medical/dash/courses-a-courses/livre",
                },
                {
                    libelle: "Courses à archiver",
                    lien: "/ops/medical/dash/courses-a-courses/a-archivee",
                },
                {
                    libelle: "Courses facturées",
                    lien: "/ops/medical/dash/courses-a-courses/facturee",
                },
                {
                    libelle: "Courses annulées",
                    lien: "/ops/medical/dash/courses-a-courses/annulee",
                },
                {
                    libelle: "Manifest",
                    lien: "/ops/medical/dash/manifest",
                },
            ],
        },
        {
            libelle: "Nouvelle course",
            lien: "/ops/medical/dash/courses-a-courses/new",
            icon: <img alt={'fd'} src={plusLogo} className={` w-8 hover:text-black`} />,
        },
        {
            libelle: "Les exports",
        },
        {
            libelle: "Liste des exports",
            icon: <img alt={'fd'} src={medicalExport} className={` w-8 hover:text-black`} />,
            liens: [
                {
                    libelle: "Exports en attente",
                    lien: "/ops/medical/dash/export/en-attente",
                },
                {
                    libelle: "Exports en cours",
                    lien: "/ops/medical/dash/export/en-cours",
                },
                {
                    libelle: "Exports livrées",
                    lien: "/ops/medical/dash/export/livre",
                },
                {
                    libelle: "Exports à archiver",
                    lien: "/ops/medical/dash/export/a-archivee",
                },
                {
                    libelle: "Exports facturées",
                    lien: "/ops/medical/dash/export/facturee",
                },
                {
                    libelle: "Exports annulées",
                    lien: "/ops/medical/dash/export/annulee",
                },
                {
                    libelle: "Manifest",
                    lien: "/ops/medical/dash/manifest",
                },
            ],
        },
        {
            libelle: "Nouvel export",
            lien: "/ops/medical/dash/export/new",
            icon: <img alt={'fd'} src={plusLogo} className={` w-8 hover:text-black`} />,
        },
        {
            libelle: "Les imports",
        },
        {
            libelle: "Liste des imports",
            icon: <img alt={'fd'} src={medicalImport} className={` w-8 hover:text-black`} />,
            liens: [
                {
                    libelle: "Imports en attente",
                    lien: "/ops/medical/dash/import/en-attente",
                },
                {
                    libelle: "Imports en cours",
                    lien: "/ops/medical/dash/import/en-cours",
                },
                {
                    libelle: "Imports livrées",
                    lien: "/ops/medical/dash/import/livre",
                },
                {
                    libelle: "Imports à archiver",
                    lien: "/ops/medical/dash/import/a-archivee",
                },
                {
                    libelle: "Imports facturées",
                    lien: "/ops/medical/dash/import/facturee",
                },
                {
                    libelle: "Imports annulées",
                    lien: "/ops/medical/dash/import/annulee",
                },
                {
                    libelle: "Manifest",
                    lien: "/ops/medical/dash/manifest",
                }
            ],
        },
        {
            libelle: "Nouvel import",
            lien: "/ops/medical/dash/import/new",
            icon: <img alt={'fd'} src={plusLogo} className={` w-8 hover:text-black`} />,
        },
        {
            libelle: "Gestion",
        },
        {
            libelle: "Factures",
            icon: <img alt={'fd'} src={factureLogo} className={` w-8 hover:text-black`} />,
            key: "facture",
            liens: [
                {
                    libelle: "Liste des factures",
                    lien: "/ops/medical/dash/factures/listes",
                },
                {
                    libelle: "Bordereaux archivés",
                    lien: "/ops/medical/dash/factures/bordereaux",
                },
                {
                    libelle: "Extractions",
                    lien: "/ops/medical/dash/factures/extractions",
                },
                {
                    libelle: "Liste globale",
                    lien: "/ops/medical/dash/factures/liste-globale",
                },
            ],
        },
        {
            libelle: "Expéditions archivées",
            lien: "/ops/medical/dash/archivee",
            icon: <img alt={'fd'} src={expeditionArchiveLogo} className={` w-8 hover:text-black`} />,
            key: "archived"
        },
        {
            // chauffeurs
            libelle: "Exploitation / dispatch",
            lien: "/exploitation/medical/auth",
            icon: <img alt={'fd'} src={explotationLogo} className={` w-8 hover:text-black`} />,
        },
        {
            libelle: "Le 24 Heures",
            lien: "/ops/medical/dash/history",
            icon: <img alt={'fd'} src={historiqueLogo} className={` w-8 hover:text-black`} />,
        },
    ],
    configuration: {
        showExtra: false,
        showHelp: false,
    }
};
