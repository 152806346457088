import React, { useEffect, useState } from 'react';
import { TbArrowsUpDown } from 'react-icons/tb';
import { exnetSpaceColor, urlSplit } from '../../utils/Functions/otherFunctions';
import { trierTableau } from '../../utils/Functions/tableFilter';
import moment from 'moment';
import LoaderTableau from '../tableauComponent/LoaderTableau';

const headers = [
    {
        label: 'Expedition',
        filterKey: ''
    },
    {
        label: 'Date',
        filterKey: 'created_at'
    },
    {
        label: 'Action',
        filterKey: 'action'
    },
    {
        label: 'Utilisateur',
        filterKey: 'user.name'
    },

]

function List({ items = [], isLoading }) {
    const { exnetSpace } = urlSplit()
    const [filterBy, setfilterBy] = useState('ref');
    const [filterOrder, setfilterOrder] = useState('desc');



   
    const updateFilterListe2 = (filterKey) => {
        //console.log({ filterKey })
        if (filterKey) {
            setfilterBy(filterKey)
            let newOrderFilter = filterOrder === 'desc' ? 'asc' : 'desc'
            setfilterOrder(newOrderFilter)

        }
    }

    const newTable = trierTableau(items, filterBy, filterOrder)

    return (
        <div>
            <div className="card-box">
                <div className="card-block">
                    <div className="table-responsive">
                        <table className="table mb-0">
                            <thead>
                                <tr>
                                    {headers.map((item, index) => (
                                        <th key={index} onClick={() => updateFilterListe2(item.filterKey, filterOrder === 'desc' ? 'asc' : 'desc')} className="cursor-pointer">
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <span>
                                                    {item.label.toUpperCase()}
                                                </span>

                                                <span>
                                                    <TbArrowsUpDown color={`${exnetSpaceColor(exnetSpace)}`} />
                                                </span>


                                            </div>
                                        </th>
                                    ))}
                                </tr>
                            </thead>

                            <tbody>
                                {isLoading ? (<>
                                    <tr key="">
                                        <td colSpan={headers.length}>
                                            <LoaderTableau />
                                        </td>
                                    </tr>
                                </>
                                ) : (
                                    <>

                                        {items.length > 0 ? (<>
                                            {newTable.map((item, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        {item?.shipping?.ref || '-'}
                                                    </td>
                                                    <td>
                                                        {moment(item.created_at).format("DD-MM-YYYY")}
                                                    </td>
                                                    <td>
                                                        {item.action}
                                                    </td>
                                                    <td>
                                                        {item?.user?.name}
                                                    </td>
                                                </tr>
                                            ))}
                                        </>) : (
                                            <tr key="">
                                                <td colSpan={headers.length} className={'text-center text-uppercase'}>
                                                    <span className={'text-2xl '}>  Vous n'avez pas actuellement de données disponibles</span>

                                                </td>
                                            </tr>
                                        )}
                                    </>
                                )}


                            </tbody>
                        </table>
                    </div>

                </div>
            </div>

        </div>
    );
}

export default List;