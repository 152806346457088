import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import {
  exnetSpaceColor,
  refPrefixer,
} from "../../../utils/Functions/otherFunctions";
import Restaure from "./Restaure";
import LoaderTableau from "../../tableauComponent/LoaderTableau";
import EmptyTableau from "../../tableauComponent/EmptyTableau";
import ReactPaginate from "react-paginate";
import { trierTableau } from "../../../utils/Functions/tableFilter";
import TableRowComponent, { ClientTableRow } from "../../rowComponent/TableRowComponent";
import { TbArrowsUpDown } from "react-icons/tb";

export default function ExpeditionLists({
  type_expedition,
  items,
  setItems,
  type,
  isLoading,
}) {
  const entete =
    type === "medical"
      ? [
        "REF",
        "EXP",
        "REF interne",
        "Code don",
        "adresse",
        "DEST",
        "Code rec",
        "adresse",
        "TRACKING",
        "Action",
      ]
      : ["REF", "EXP", "adresse", "DEST", "adresse", "TRACKING", "Action"];


  const [filterBy, setfilterBy] = useState("ref");
  const [filterOrder, setfilterOrder] = useState("desc");


  const checkCountry = (a, b) => {
    // A expedition
    // Receiver
    if (a?.toUpperCase() === "FR" || a?.toUpperCase() === "FRANCE") {
      return "export";
    } else if (a?.toUpperCase() !== "FR" || a?.toUpperCase() !== "FRANCE") {
      return "import";
    }
  };

  const modifiedText = (text) => {
    return text
      ? text.split(" ").map((word, index) => {
        return index !== 0 && index % 3 === 0
          ? [<br key={index} />, word]
          : word + " ";
      })
      : "-";
  };

  const tableDataFilter = (index) => {
    //console.log('filte ',filterKey(index),filterOrder === "desc" ? "asc" : "desc")
    setfilterBy(filterKey(index));
    setfilterOrder(filterOrder === "desc" ? "asc" : "desc");
  };


  const filterKey = (index) => {
    if (type !== "medical") {
      if (index === 0) {
        return "ref";
      } else if (index === 1) {
        return "expeditor.fullname";
      } else if (index === 2) {
        return "expeditor.address1";
      } else if (index === 3) {
        return "expeditor.country";
      } else if (index === 4) {
        return "receiver.fullname";
      } else if (index === 5) {
        return "receiver.address1";
      } else if (index === 6) {
        return "receiver.country";
      }
    } else {
      if (index === 0) {
        return "ref";
      } else if (index === 1) {
        return "expeditor.fullname";
      } else if (index === 2) {
        return "customer_ref";
      } else if (index === 3) {
        return "code_donneur";
      } else if (index === 4) {
        return "expeditor.address1";
      } else if (index === 5) {
        return "expeditor.country";
      } else if (index === 6) {
        return "receiver.fullname";
      } else if (index === 7) {
        return "code_receveur";
      } else if (index === 8) {
        return "receiver.address1";
      } else if (index === 9) {
        return "receiver.country";
      }
    }
  };


  let filtedArray = useMemo(() => trierTableau(items, filterBy, filterOrder), [filterBy, items, filterOrder])

  /*   useEffect(() => {
      let filtedArray = trierTableau(items, filterBy, filterOrder);
      console.log('filtered list ', filtedArray)
      setItems([...filtedArray])
    }, [filterOrder, filterBy, items]) */

  //console.log('__', filtedArray.map(i => i.ref))

  return (
    <div>
      <div className="card-box">
        <div className="card-block">
          <div className="table-responsive">
            <table className="table mb-0">
              <thead>
                <tr>
                  {entete.map((item, index) => (
                    <th
                      key={index}
                      style={{ whiteSpace: "pre-line" }}
                      onClick={() => tableDataFilter(index)}
                      className="cursor-pointer"
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>{item.toUpperCase()}</span>
                        {index !== entete.length - 1 && (
                          <span>
                            <TbArrowsUpDown color={exnetSpaceColor(type)} />
                          </span>
                        )}
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr key="">
                    <td colSpan={entete.length}>
                      <LoaderTableau />
                    </td>
                  </tr>
                ) : (
                  <>
                    {filtedArray.length === 0 ? (
                      <tr key="">
                        <td className="px-6 py-2 text-lg text-center whitespace-no-wrap" colSpan={entete.length}>

                         {type_expedition==="course"?"Vous n'avez pas de courses en cours":"Vous n'avez pas d'expéditions pour le moment"} 

                        </td>
                      </tr>
                    ) : (
                      <>
                        {filtedArray.map((item, index) => {
                          return <ClientTableRow key={index} item={item} />;
                        })}
                      </>
                    )}
                  </>
                )}
              </tbody>
            </table>
          </div>

        </div>
      </div>
    </div>
  );
}
