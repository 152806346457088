import React from "react";
import { enteteContactData } from "../../../utils/ArrayHeaderData";
import LoaderTableau from "../../tableauComponent/LoaderTableau";
import { getCountryNameOrCode } from "../../../utils/const/countriesConstant";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from 'react-redux';
import { urlSplit } from "../../../utils/Functions/otherFunctions";

function ContactList({
    value,
    isLoading,
    isOpen,
    setmodalTitle,
    setmodalFormContent,
    handleDeleteAction,
    setIsNew
}) {

    const { exnetSpace } = urlSplit();


    const handleDelete = (identifiant) => {
        Swal.fire({
            title: "Êtes-vous sûr de vouloir supprimer ?",
            text: "Cette action est irréversible !",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: exnetSpace === 'paris' ? "#ba9964" : (exnetSpace === 'service' ? "#098721" : (exnetSpace === 'medical' ? "#3A8EBA" : "#222")),
            confirmButtonText: "Oui, supprimer",
            cancelButtonText: "Annuler",
        }).then((result) => {
            if (result.isConfirmed) {
                handleDeleteAction(identifiant);
            } else {
                Swal.fire(
                    "Annuler",
                    "Suppression annulée avec succès",
                    "error",
                    500
                );
            }
        });
    };

    const handleEdite = (identifiant) => {
        const data = contacts.find((element) => {
            return element.id === identifiant;
        });
        //console.log({ data })
        setmodalFormContent(data);
        setmodalTitle("Modifier un contact");
        isOpen(data);
    };

    const dispatch = useDispatch();
    const contacts = useSelector((state) => state.contact.contacts);
    return (
        <>
            <div className="card-box">
                <div className="card-block">
                    <div className="table-responsive">
                        <table className="table mb-0">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    {enteteContactData?.map((item, index) => (
                                        <th
                                            key={index}
                                            // style={{ whiteSpace: "pre-line" }}
                                            className={'w-auto'}
                                        >
                                            {item.toUpperCase()}
                                        </th>
                                    ))}
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {isLoading ? (
                                    <tr key="">
                                        <td colSpan={enteteContactData?.length + 2}>
                                            <LoaderTableau />
                                        </td>
                                    </tr>
                                ) : (
                                    <>
                                        {contacts?.length === 0 ? (
                                            <tr key="">
                                                <td
                                                    colSpan={
                                                        enteteContactData?.length + 2
                                                    }
                                                >
                                                    <div className="text-center">
                                                        <span className="text-base font-500" >Vous n'aviez pas de contact pour le moment</span>
                                                    </div>

                                                </td>
                                            </tr>
                                        ) : (
                                            <>
                                                {contacts?.map((item, index) => (
                                                    <tr key={index}>
                                                        <td className="pointer " onClick={() =>
                                                            handleEdite(
                                                                item.id
                                                            )
                                                        }>{item.id}</td>
                                                        <td className="pointer " onClick={() =>
                                                            handleEdite(
                                                                item.id
                                                            )
                                                        }>{item.fullname || item?.firstName + " " + item?.lastName}</td>
                                                        <td className="pointer " onClick={() =>
                                                            handleEdite(
                                                                item.id
                                                            )
                                                        }>{item.email1}</td>
                                                        <td className="pointer " onClick={() =>
                                                            handleEdite(
                                                                item.id
                                                            )
                                                        }>
                                                            {item.telephone1}
                                                        </td>
                                                        <td className="pointer " onClick={() =>
                                                            handleEdite(
                                                                item.id
                                                            )
                                                        }>{item.address ?? item.address1}</td>
                                                        <td className="pointer " onClick={() =>
                                                            handleEdite(
                                                                item.id
                                                            )
                                                        }>
                                                            {item.postal_code}
                                                        </td>
                                                        <td className="pointer " onClick={() =>
                                                            handleEdite(
                                                                item.id
                                                            )
                                                        }>{item.city}</td>
                                                        <td className="pointer " onClick={() =>
                                                            handleEdite(
                                                                item.id
                                                            )
                                                        }>
                                                            <div
                                                                className={'flex items-center gap-3 flex-wrap'}
                                                            >
                                                                <span>
                                                                    <img
                                                                        src={`https://flagcdn.com/16x12/${getCountryNameOrCode(
                                                                            getCountryNameOrCode(item.country),
                                                                            true
                                                                        )}.png`}
                                                                        srcSet={`https://flagcdn.com/32x24/${getCountryNameOrCode(
                                                                            getCountryNameOrCode(item.country),
                                                                            true
                                                                        )}.png 2x,
                  https://flagcdn.com/48x36/${getCountryNameOrCode(
                                                                            getCountryNameOrCode(item.country),
                                                                            true
                                                                        )}.png 3x`}
                                                                        width="16"
                                                                        height="12"
                                                                        alt={getCountryNameOrCode(
                                                                            getCountryNameOrCode(item.country),
                                                                            true
                                                                        )}
                                                                    />
                                                                </span>
                                                                <span> {getCountryNameOrCode(item.country)}</span>
                                                            </div>

                                                        </td>


                                                        <td>
                                                            <div className="flex items-center">
                                                                <div
                                                                    className="text-green-600 cursor-pointer text-[14px] mr-5"
                                                                    title="Editer"
                                                                    onClick={() => {
                                                                        setIsNew(false)
                                                                        handleEdite(
                                                                            item.id
                                                                        )
                                                                    }

                                                                    }
                                                                >
                                                                    <i className="fas fa-edit"></i>
                                                                </div>

                                                                <div
                                                                    className="text-red-500 cursor-pointer text-[14px]"
                                                                    onClick={() =>
                                                                        handleDelete(
                                                                            item.id
                                                                        )
                                                                    }
                                                                    title="Supprimer"
                                                                >
                                                                    <i className="fas fa-trash"></i>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </>
                                        )}
                                    </>
                                )}
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </>
    );
}

export default ContactList;
