import React, { useCallback, useState } from "react";
import ModalComponent from "../../Utils/Modal/Index";
import opsService from "../../../services/ops/opsService";
import { toast } from "react-toastify";
import { exnetSpaceColor } from "../../../utils/Functions/otherFunctions";
import { formatDefaultDate } from "../../../utils/moment";
import useAuth from "../../../hooks/useAuth";
import Loader from "../../Utils/Loader";

export default function InformationModal({
  type,
  isOpen,
  onClose,
  selectedData,
  onSubmit,
}) {
  const { user } = useAuth();

  const initialData = {
    note: "",
  };

  const [informationData, setInformationData] = useState(initialData);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const setFormValue = (key, value) => {
    //alert(`${key}---${value}`)
    setInformationData((d) => ({
      ...d,
      [key]: value,
    }));
  };

  const handleSubmit = useCallback(
    async (e) => {
      try {
        e?.preventDefault();
        setIsSubmitting(true);
        const submitData = {
          information: informationData.note,
          shipping_id: selectedData.id,
        };

        const { data } = await opsService.addInformations(submitData);
        if (data.success) {
          setInformationData(initialData);
          onSubmit?.({ ...data.data, user });
          toast.success(`Informations ajoutées avec succès!`, {
            position: "bottom-center",
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          onClose();
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsSubmitting(false);
      }
    },
    [user, informationData, selectedData, onSubmit, onClose, initialData]
  );

  return (
    <ModalComponent
      isOpen={isOpen}
      onClose={onClose}
      title={`Informations importantes`}
    >
      <div>
        <form className="w-full px-3 py-4">
          <div className="mb-6">
            <div className="max-w-lg ml-0 mx-auto">
              <label
                className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                htmlFor="note"
              >
                Note
              </label>
              <textarea
                className="h-full min-h-[100px] w-full resize-none rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:resize-none disabled:border-0 disabled:bg-blue-gray-50"
                id="note"
                placeholder="Note"
                value={informationData.note}
                onChange={(e) => {
                  setFormValue("note", e.target.value);
                }}
              ></textarea>
            </div>
          </div>

          <div className="flex justify-center">
            <button
              onClick={handleSubmit}
              type="submit"
              className="text-white flex items-center focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
              disabled={isSubmitting}
              style={{
                color: "#fff",
                backgroundColor: `${exnetSpaceColor(
                  type || selectedData.type
                )}`,
              }}
            >
              <span className="mr-2">Enregistrer</span>
              {isSubmitting && <Loader />}
            </button>
          </div>
        </form>
        {selectedData?.information_importante?.length > 0 && (
          <div className="table-responsive mt-6">
            <table className="table mb-0">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Information importante</th>
                  <th>Ajouté par</th>
                  <th>Ajouté le </th>
                </tr>
              </thead>
              <tbody>
                {selectedData?.information_importante.map((e, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{e.information}</td>
                    <td>{e.user?.name}</td>
                    <td>
                      {formatDefaultDate(e.created_at, "DD-MM-YYYY hh:mm")}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </ModalComponent>
  );
}
