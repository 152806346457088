import React, { useEffect, useState } from "react";
// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { exnetSpaceColor } from "../../utils/Functions/otherFunctions";
import { toFixed2 } from "../../utils";
import IncotermSelector from "../Utils/Selectors/IncotermSelector";
import PrincingInput from "../Utils/UI/PrincingInput";
import { InputComponent2 } from "../InputComponent/InputComponent2";
import clsx from "clsx";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

function NewExpeditionColisComponent({
  index,
  UpdateColisData,
  colis,
  deletColis,
  multiply,
  exnetSpace,
  errorMessage
}) {
  const [files, setFiles] = useState([]);
  const [nombre, setNombre] = useState(0);

  // index Attribut valeur

  useEffect(() => {
    // console.log(files);
    UpdateColisData(index, "fichiers", [files]);
  }, [files]);

  useEffect(() => {
    UpdateColisData(
      index,
      "weight_volume",
      (colis[index].height * colis[index].width * colis[index].length) / 5000
    );
  }, [colis[index].height, colis[index].width, colis[index].length]);



  return (
    <div className="row" key={`row_${index}`}>
      {errorMessage.field}
      <div className="card-box">
        <h4 className="text-center card-title">Colis {index + 1}</h4>
        <hr className="mt-2 mb-6" />

        <div className={"row"}>
          <div className={"col-4"}>
            <div className={`flex gap-5 `}>
              <div className={`flex-2 form-group`}>

                <span className={'text-[14px] text-[#35446f] font-[600]'}>
                  DIMENSIONS
                </span>
                <label className={`form-label grid md:grid-cols-3`}>

                  <span className={`text-left ml-2`}>L </span>
                  <span className={`text-left `}>l </span>
                  <span className={`text-left `}>h</span>
                </label>
                <div className="mb-3 flex items-center w-80">
                  <input
                    type="number"
                    className={clsx({ "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500": errorMessage?.field2 === 'length' && index === errorMessage.field, "ring-gray-300 focus:ring-gray-500 bg-white border border-gray-500": !errorMessage?.field2 === 'length' && index !== errorMessage.field }, "px-2 block w-full rounded-s-md border-0 py-1.5 pr-10  ring-1 ring-inset  focus:ring-2 focus:ring-inset text-base sm:leading-6")}
                    aria-label="Longueure"
                    value={colis[index].length}
                    min={0}
                    onChange={(e) =>
                      UpdateColisData(index, "length", e.target.value)
                    }
                  />
                  <span className="input-group-text">X</span>
                  <input
                    type="number"
                    className={clsx({ "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500": errorMessage?.field2 === 'width' && index === errorMessage.field, "ring-gray-300 focus:ring-gray-500 bg-white border border-gray-500": !errorMessage?.field2 === 'width' && index !== errorMessage.field }, "px-2 block w-full rounded-none border-0 py-1.5 pr-10  ring-1 ring-inset  focus:ring-2 focus:ring-inset text-base sm:leading-6")}
                    aria-label="Largeur"
                    value={colis[index].width}
                    min={0}
                    onChange={(e) =>
                      UpdateColisData(index, "width", e.target.value)
                    }
                  />

                  <span className="input-group-text">X</span>
                  <input
                    type="number"
                    className={clsx({ "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500": errorMessage?.field2 === 'height' && index === errorMessage.field, "ring-gray-300 focus:ring-gray-500 bg-white border border-gray-500": !errorMessage?.field2 === 'height' && index !== errorMessage.field }, "px-2 block w-full rounded-e-md  border-0 py-1.5 pr-10  ring-1 ring-inset  focus:ring-2 focus:ring-inset text-base sm:leading-6")}

                    aria-label="Hauteur"
                    value={colis[index].height}
                    min={0}
                    onChange={(e) =>
                      UpdateColisData(index, "height", e.target.value)
                    }
                  />
                  <span className="input-group-text">cm</span>
                </div>
                {(index === errorMessage.field && (errorMessage?.field2 === 'height' || errorMessage?.field2 === 'width' || errorMessage?.field2 === 'length')) &&
                  <p className="mt-2 text-sm text-red-600" id={"full-error"}>
                    {errorMessage.message}
                  </p>
                }
              </div>
            </div>
          </div>
          <div className={`col-2`}>
            <div className={`form-group`}>
              <label className={`form-label mb-4 mt-1 uppercase `}>
                POIDS VOLUMéTRIQUE
              </label>
              <input
                type="search"
                // className="bg-white border border-gray-500 rounded-lg outline-none form-control"
                className={clsx({ "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500": errorMessage?.field2 === 'weight_volume' && index === errorMessage.field, "ring-gray-300 focus:ring-gray-500 bg-white border border-gray-500": !errorMessage?.field2 === 'weight_volume' && index !== errorMessage.field }, "px-2 block w-full rounded-e-md  border-0 py-1.5 pr-10  ring-1 ring-inset  focus:ring-2 focus:ring-inset text-base sm:leading-6")}
                disabled
                value={parseFloat(toFixed2((colis[index].height *
                  colis[index].width *
                  colis[index].length) /
                  5000))

                }
                min={0}
                onChange={(e) =>
                  UpdateColisData(
                    index,
                    "weight_volume",
                    parseFloat(toFixed2(
                      (colis[index].height *
                        colis[index].width *
                        colis[index].length) /
                      5000
                    )
                    ))

                }
              />
            </div>
            {(index === errorMessage.field && errorMessage?.field2 === 'weight_volume') &&
              <p className="mt-2 text-sm text-red-600" id={"full-error"}>
                {errorMessage.message}
              </p>
            }
          </div>
          <div className={`col-2 form-group `}>
            <label className={`form-label mb-4 mt-1`}>POIDS REEL</label>
            <div className="mb-3 input-group">
              <input
                type="number"
                className="w-full bg-white form-control"
                value={colis[index].weight}
                min={0}
                onChange={(e) =>
                  UpdateColisData(index, "weight", e.target.value)
                }
              />
              <span className="input-group-text">Kg</span>
            </div>
          </div>

          <div className="form-group col-md-2">
            <label className={`form-label mb-4 mt-1`}>VALEUR COMMERCIALE </label>

            <PrincingInput UpdateColisData={UpdateColisData} colis={colis} index={index} errorMessage={errorMessage} />


          </div>
          <div className="form-group col-md-2">
            <IncotermSelector value={colis[index]["incoterm"]} onChange={(v) =>
              UpdateColisData(index, "incoterm", v)
            }
              showLabel={true}
            />

          </div>
        </div>

        <div className={`row  m-t-20 m-b-20 `}>
          <div className={`col-md-4`}>
            <div className="row">
              <div className="form-group ">
                <label className={`form-label`}>DESCRIPTION*</label>
                <textarea
                  rows="5"
                  cols="5"
                  // className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                  className={clsx({ "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500": errorMessage?.field2 === 'description' && index === errorMessage.field, "ring-gray-300 focus:ring-gray-500 bg-white border border-gray-500": !errorMessage?.field2 === 'description' && index !== errorMessage.field }, "px-2 block w-full rounded-e-md  border-0 py-1.5 pr-10  ring-1 ring-inset  focus:ring-2 focus:ring-inset text-base sm:leading-6 h-[10vh]")}
                  value={colis[index]["package_description"]}
                  style={{ resize: "none", }}
                  onChange={(e) => {
                    UpdateColisData(
                      index,
                      "package_description",
                      e.target.value
                    );
                    UpdateColisData(index, "description", e.target.value);
                  }}
                ></textarea>

                {errorMessage?.field2 === 'description' && index === errorMessage.field &&
                  <p className="mt-2 text-sm text-red-600" id={"full-error"}>
                    {errorMessage.message}
                  </p>
                }
              </div>
            </div>
          </div>

          <div className={`col-md-4`}>
            <div className="mt-4 row">
              {/* Pour les fichiers */}

              <FilePond
                files={files}
                allowReorder={true}
                allowMultiple={true}
                onupdatefiles={setFiles}
                labelIdle='Faites glisser et déposez vos fichiers ou <span className="filepond--label-action">parcourir</span>'
              />
            </div>
          </div>

          <div className="col-md-4 ">
            <div className="items-end row h-100">
              <div className="col-4 ">
                <div className="form-group">
                  <input
                    type="number"
                    value={nombre}
                    onChange={(e) => setNombre(e.target.value)}
                    name=""
                    id=""
                    placeholder="X Fois"
                    min={2}
                    className={
                      "w-full  bg-white border border-gray-500 rounded-lg outline-none form-control"
                    }
                  />
                </div>
              </div>

              <div className="col-4">
                <div className="mb-3 form-group">
                  <button
                    className="btn w-110 "
                    onClick={() => multiply(index, nombre)}
                    style={{ color: '#fff', backgroundColor: `${exnetSpaceColor(exnetSpace)}` }}
                  >
                    Dupliquer
                  </button>
                </div>
              </div>

              <div className="col-4">
                <div className="form-group ">
                  <button
                    className="btn w-110 "
                    onClick={() => {
                      deletColis(index);
                    }}
                    style={{ color: '#fff', backgroundColor: `${exnetSpaceColor(exnetSpace)}` }}
                  >
                    Supprimer
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewExpeditionColisComponent;
