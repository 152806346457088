import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { TbArrowsUpDown, TbFileDownload } from "react-icons/tb";
import xlsx from "export-json-as-xlsx"
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { LinkData, exnetSpaceColor, refPrefixer, urlSplit } from "../../../utils/Functions/otherFunctions";
import { ConfirmModal } from "../../Utils/DeleteConfirm";
import factureService from "../../../services/facture/factureService";
import useDisclosure from "../../../hooks/useDisclosure";
import LoaderTableau from "../../tableauComponent/LoaderTableau";
import { trierTableau } from "../../../utils/Functions/tableFilter";



const entete_expedition = [
    {
        label: "REF",
        cle: "ref"
    },
    {
        label: "EXP",
        cle: '.expeditor.fullname'
    },
    {
        label: "REF interne",
        cle: 'pickup_time'
    }, {
        label: "Code \ndonneur",
        cle: 'code_donneur'
    },
    {
        label: "Addresse",
        cle: 'expeditor.address1'
    },
    {
        label: "DEST",
        cle: 'receiver.fullname'
    },
    {
        label: "Code receveur",
        cle: 'code_receveur'
    },
    {
        label: "Addresse",
        cle: '.receiver.address1'

    },
    {
        label: "Centre de facturation",
        cle: 'bill_center_id'
    },
    {
        label: "N° Facture",
        cle: 'suivis_details'
    }
];


const excelHeader = [
    {
        label: "ref",
        cle: ''
    },
    {
        label: "demandeur",
        cle: ''
    },
    {
        label: "Exp",
        cle: '',
    },
    {
        label: "expCity",
        cle: ''
    },
    {
        label: "Dest",
        cle: ''
    },
    {
        label: "DestCity",
        cle: ''
    },
    {
        label: "createdAt",
        cle: ''
    },
    {
        label: "pickup",
        cle: ''
    },
    {
        label: "delivery",
        cle: ''
    },
    {
        label: "refInt",
        cle: ''
    },
    {
        label: "libelle",
        cle: ''
    },
    {
        label: "description",
        cle: ''
    },
    {
        label: "commentaire",
        cle: ''
    },
    {
        label: "PrixU",
        cle: ""
    },
    {
        label: "qte",
        cle: ''
    },
    {
        label: "fuel",
        cle: ""
    },
    {
        label: "Amount",
        cle: ''
    },
    {
        label: "tva",
        cle: 'tva'
    },
    {
        label: "total",
        cle: "total"
    }
]


export default function BordereaulList({
    items,
    setItems,
    isLoading,
}) {

    const [entete, setEntete] = useState([]);
    const { pathname } = useLocation();
    const [selectedItem, setSelectedItem] = useState(null);

    const { isOpen, onOpen, onClose } = useDisclosure()
    const { exnetSpace, role: userRole } = urlSplit();
    const [filterOrder, setfilterOrder] = useState('desc');
    const [filterBy, setfilterBy] = useState('ref');

    useEffect(() => {
        setEntete(entete_expedition);
    }, []);

    const modifiedText = (text) => {
        return text
            ? text.split(" ")?.map((word, index) => {
                return index !== 0 && index % 3 === 0
                    ? [<br key={index} />, word]
                    : " " + word;
            })
            : "-";
    };

    const filteredArray = items.reduce((uniqueArray, currentItem) => {
        // Check if an object with the same value exists in the uniqueArray
        const existingItem = uniqueArray.find(item => item.id === currentItem.id);

        // If not found, add the current item to the uniqueArray
        if (!existingItem) {
            uniqueArray.push(currentItem);
        }

        return uniqueArray;
    }, []);

    // const sortedArray = filteredArray.sort((a, b) => {
    //     return parseInt(b.id) - parseInt(a.id);
    // });

    const [currentPage, setCurrentPage] = useState(0);
    const navigate = useNavigate();
    const offset = currentPage * 25;
    const currentPageData = filteredArray.slice(offset, offset + 25);

    const handlePageClick = (event) => {
        setCurrentPage(event.selected);
    };
    const lien = window.location.pathname.split("/");
    let { espace } = LinkData(lien);

    const downloadPDF = (item) => {
        const pdfUrl = `${process.env.REACT_APP_BACKEND_HOST}/${item.doc_facture}`;
        setSelectedItem(item);

        //const url = URL.createObjectURL(pdfBlob);
        const fileName = pdfUrl.split("factures/")[1]
        const a = document.createElement("a");
        a.href = pdfUrl;
        a.target = "_blank"
        a.download = fileName + ".pdf";
        document.body.appendChild(a);
        a.click();

        // Clean up
        //window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
        setSelectedItem(null)
    }

    const onRedition = (item) => {
        setSelectedItem(item);
        onOpen()
    }

    //console.log({ selectedItem })

    const onReedited = useCallback(() => {
        const data = items?.map(item => selectedItem?.id === item.id ? ({
            ...item,
            active_for_reedited: 1
        }) : item);

        setItems(d => ({
            ...d,
            data
        }));

        setSelectedItem(null)
    }, [selectedItem, items])


    const DownloadExcelCustom = async () => {
        let workbook = new ExcelJS.Workbook();
        let worksheet = workbook.addWorksheet('Ma Feuille');

        // // Définir les en-têtes
        // worksheet.columns = [
        //     { header: 'Colonne 1', key: 'col1', width: 30 },
        //     { header: 'Colonne 2', key: 'col2', width: 30 },
        //     // Autres colonnes...
        // ];

        let newValueTable = []
        entete_expedition.forEach((item, index) => {
            newValueTable.push({ header: item, key: excelHeader[index], width: 30 })
        })

        worksheet.columns = newValueTable;

        worksheet.getRow(1).font = { bold: true };


        // Ajouter des données
        let newData = [];

        items.forEach(item => {
            let newArray = item.shipping_ids
            newArray.forEach(element => {
                let newElement = {
                    ref: `${item.type === "paris" ? "EX" : item.type === "medical" ? "EM" : "ES"}-${element.ref}`,
                    demandeur: item?.billing_center?.billing_center_name,
                    Exp: element?.expeditor?.fullname,
                    expCity: element?.expeditor?.city,
                    Dest: element?.receiver?.fullname,
                    DestCity: element?.receiver?.city,
                    createdAt: moment(element?.created_at).format("DD-MM-YYYY HH:mm"),
                    pickup: moment(element?.pickup_date).format("DD-MM-YYYY"),
                    delivery: moment(element?.delivery_date).format("DD-MM-YYYY"),
                    refInt: element?.customer_ref,
                    libelle: item?.bon_de_commende,
                    description: '',
                    commentaire: returnComment(element),
                    PrixU: item?.montant,
                    qte: item.shipping_ids.length,
                    fuel: item?.fuel,
                    Amount: item?.total,
                    tva: item?.tva,
                    total: parseFloat(parseFloat(item?.fuel) + parseFloat(item?.tva) + parseFloat(item?.montant)).toFixed(3)
                };

                newData.push(newElement)
            })
        });

        const data = [...newData]

        data.forEach(row => {
            worksheet.addRow(row);
        });

        // Appliquer les styles de bordure à toutes les lignes, y compris les données
        worksheet.eachRow(function (row, rowNumber) {
            row.eachCell(function (cell, colNumber) {
                cell.border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' }
                };
            });
        });

        // Écrire dans un tampon et sauvegarder le fichier
        const buffer = await workbook.xlsx.writeBuffer();
        saveAs(new Blob([buffer]), 'monTableauPersonnalise.xlsx');
    };


    const DownloadXls = () => {

        let newData = [];

        items.forEach(item => {
            let newArray = item.shipping_ids
            newArray.forEach(element => {
                let newElement = {
                    ref: `${item.type === "paris" ? "EX" : item.type === "medical" ? "EM" : "ES"}-${element.ref}`,
                    demandeur: item?.billing_center?.billing_center_name,
                    Exp: element?.expeditor?.fullname,
                    expCity: element?.expeditor?.city,
                    Dest: element?.receiver?.fullname,
                    DestCity: element?.receiver?.city,
                    createdAt: moment(element?.created_at).format("DD-MM-YYYY HH:mm"),
                    pickup: moment(element?.pickup_date).format("DD-MM-YYYY"),
                    delivery: moment(element?.delivery_date).format("DD-MM-YYYY"),
                    refInt: element?.customer_ref,
                    libelle: item?.bon_de_commende,
                    description: '',
                    commentaire: returnComment(element),
                    PrixU: item?.montant,
                    qte: item.shipping_ids.length,
                    fuel: item?.fuel,
                    Amount: item?.total,
                    tva: item?.tva,
                    total: parseFloat(parseFloat(item?.fuel) + parseFloat(item?.tva) + parseFloat(item?.montant)).toFixed(3)
                };

                newData.push(newElement)
            })


        });

        let data = [
            {
                sheet: "Adults",
                columns: [
                    { label: 'AWB', value: 'ref' },
                    { label: 'Demandeur', value: 'demandeur' },
                    { label: 'Expediteur', value: 'Exp' },
                    { label: 'Ville d\'expédition', value: 'expCity' },
                    { label: 'Destinataire', value: 'Dest' },
                    { label: 'Ville de destination', value: 'DestCity' },
                    { label: 'Date de demande', value: 'createdAt', format: "DD-MM-YYYY", type: "d" },
                    { label: 'Date enlèvement', value: 'pickup', format: "DD-MM-YYYY", type: "d" },
                    { label: 'Date livraison', value: 'delivery', format: "DD-MM-YYYY", type: "d" },
                    { label: 'Ref interne', value: 'refInt' },
                    { label: 'Libellé', value: 'libelle' },
                    { label: 'Description', value: 'description' },
                    { label: 'Commentaire', value: 'commentaire' },
                    { label: 'Prix unitaire', value: 'PrixU' },
                    { label: 'Quantité', value: 'qte' },
                    { label: 'Gazoil', value: 'fuel' },
                    { label: 'Montant', value: 'Amount' },
                    { label: 'TVA', value: 'tva' },
                    { label: 'Total', value: 'total' },

                ],
                content: newData,
            },

        ]

        let settings = {
            fileName: "MySpreadsheet", // Name of the resulting spreadsheet
            extraLength: 3, // A bigger number means that columns will be wider
            writeMode: "writeFile", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
            writeOptions: {}, // Style options from https://docs.sheetjs.com/docs/api/write-options

            RTL: false, // Display the columns from right-to-left (the default value is false)
        }


        xlsx(data, settings) // Will download the excel file
    }


    const returnComment = (value) => {
        if (value) {
            let newValue = value?.comments;
            if (newValue?.length > 0) {
                let { content } = newValue[newValue?.length - 1]
                return (content)
            } else {
                return ''
            }

        }
    }


    const updateFilterListe2 = (filterKey) => {
        //console.log({ filterKey })
        if (filterKey) {
            setfilterBy(filterKey)
            let newOrderFilter = filterOrder === 'desc' ? 'asc' : 'desc'
            setfilterOrder(newOrderFilter)

        }
    }

    const newTable = trierTableau(filteredArray, filterBy, filterOrder)

    return (
        <div>
            <div className="card-box">
                <div className="card-block">
                    <div className="table-responsive">

                        <table className="table mb-0">
                            <thead>
                                <tr>
                                    {entete_expedition?.map(({ label, cle }, index) => (
                                        <th key={index} onClick={() => updateFilterListe2(cle || "")} className="cursor-pointer">
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <span>
                                                    {label?.toUpperCase()}
                                                </span>
                                                {cle &&
                                                    <span>
                                                        <TbArrowsUpDown color={`${exnetSpaceColor(exnetSpace)}`} />
                                                    </span>

                                                }
                                            </div>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {isLoading ? (
                                    <tr key="">
                                        <td colSpan={entete_expedition.length}>
                                            <LoaderTableau />
                                        </td>
                                    </tr>
                                ) : (
                                    <>
                                        {items.length === 0 ? (
                                            <tr key="">
                                                <td colSpan={entete_expedition.length} className={'text-center text-uppercase'}>
                                                    <span className={'text-2xl '}>  Vous n'avez pas actuellement de données disponibles</span>

                                                </td>
                                            </tr>

                                        ) : (
                                            <>
                                                {newTable?.map((item, index) => (
                                                    <>

                                                        <tr
                                                            style={{ color: exnetSpaceColor(item.type) }}
                                                            className="pointer"
                                                            onClick={() => {
                                                                if (exnetSpace) {
                                                                    let pathnameTable = pathname.split("/");
                                                                    let path =
                                                                        pathnameTable.length > 5
                                                                            ? `/${pathnameTable[1]}/${exnetSpace}/dash/${pathnameTable[4]}/${pathnameTable[5]}/${item.ref}`
                                                                            : pathnameTable.length > 4
                                                                                ? `/${pathnameTable[1]}/${exnetSpace}/dash/${pathnameTable[4]}/${item.ref}`
                                                                                : `/${pathnameTable[1]}/${exnetSpace}/dash/detail/${item.ref}`;

                                                                    navigate(`/ops/${exnetSpace}/dash/detail/${item.ref}`);
                                                                }
                                                            }} >
                                                            <td>
                                                                {refPrefixer(
                                                                    espace
                                                                )}
                                                                {item.ref}{" "}
                                                                {item.type_expedition ===
                                                                    "course" &&
                                                                    (item.transport_type ===
                                                                        "deux_roues" ||
                                                                        item.transport_type ==
                                                                        null ||
                                                                        item.transport_type === "") && (
                                                                        <span className="fa fa-motorcycle"></span>
                                                                    )}
                                                                {item.type_expedition ===
                                                                    "course" &&
                                                                    item.transport_type ===
                                                                    "quatre_roues" && (
                                                                        <span className="fa fa-truck"></span>
                                                                    )}
                                                            </td>

                                                            <td
                                                                style={{
                                                                    maxWidth:
                                                                        "250px",
                                                                }}
                                                            >
                                                                {" "}
                                                                <strong>
                                                                    {modifiedText(
                                                                        item
                                                                            .expeditor
                                                                            ?.company
                                                                    )}
                                                                </strong>
                                                                <br />
                                                                <span>
                                                                    {modifiedText(
                                                                        item
                                                                            .expeditor
                                                                            ?.fullname
                                                                    )}
                                                                </span>
                                                                <br />
                                                                {item
                                                                    .expeditor
                                                                    ?.email &&
                                                                    item.expeditor?.email
                                                                        .split(
                                                                            ","
                                                                        )
                                                                        ?.map(
                                                                            (
                                                                                element,
                                                                                index
                                                                            ) => (
                                                                                <>
                                                                                    {" "}
                                                                                    <span
                                                                                        key={
                                                                                            element
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            element
                                                                                        }
                                                                                    </span>
                                                                                    <br />
                                                                                </>
                                                                            )
                                                                        )}
                                                                <strong className="">
                                                                    {" "}
                                                                    {moment(
                                                                        item.pickup_date
                                                                    ).format(
                                                                        "DD-MM-YYYY"
                                                                    )}
                                                                </strong>
                                                                <br />
                                                                <strong>
                                                                    {" "}
                                                                    {moment(
                                                                        item.pickup_date
                                                                    ).format(
                                                                        "HH:MM"
                                                                    )}
                                                                </strong>
                                                                <br />
                                                            </td>
                                                            <td
                                                                className={
                                                                    "text-center"
                                                                }
                                                                style={{
                                                                    maxWidth:
                                                                        "50px",
                                                                }}
                                                            >
                                                                {item.customer_ref
                                                                    ? item.customer_ref
                                                                    : "-"}
                                                            </td>
                                                            <td
                                                                style={{
                                                                    maxWidth:
                                                                        "100px",
                                                                }}
                                                            >
                                                                {item.code_donneur
                                                                    ? item.code_donneur
                                                                    : "NULL"}
                                                            </td>
                                                            <td>
                                                                <span>
                                                                    {" "}
                                                                    {modifiedText(
                                                                        item
                                                                            .expeditor
                                                                            ?.address1
                                                                    )}
                                                                </span>{" "}
                                                                <br />
                                                                <span>
                                                                    {
                                                                        item
                                                                            .expeditor
                                                                            ?.city
                                                                    }
                                                                </span>
                                                                <br />
                                                                <span>
                                                                    {
                                                                        item
                                                                            .expeditor
                                                                            ?.postalCode
                                                                    }
                                                                </span>
                                                            </td>

                                                            <td>
                                                                {" "}
                                                                <h4>
                                                                    {
                                                                        item
                                                                            .receiver
                                                                            ?.company
                                                                    }
                                                                </h4>
                                                                <span>
                                                                    {modifiedText(
                                                                        item
                                                                            .receiver
                                                                            ?.fullname
                                                                    )}
                                                                </span>
                                                                <br />
                                                                {item
                                                                    .receiver
                                                                    ?.email &&
                                                                    item
                                                                        .receiver
                                                                        ?.email
                                                                    ? item.receiver?.email
                                                                        .split(
                                                                            ","
                                                                        )
                                                                        ?.map(
                                                                            (
                                                                                element,
                                                                                index
                                                                            ) => (
                                                                                <>
                                                                                    {" "}
                                                                                    <span
                                                                                        key={
                                                                                            element
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            element
                                                                                        }
                                                                                    </span>
                                                                                    <br />
                                                                                </>
                                                                            )
                                                                        )
                                                                    : "-"}
                                                                <span>
                                                                    {moment(
                                                                        item.delivery_date
                                                                    ).format(
                                                                        "DD-MM-YYYY"
                                                                    )}{" "}
                                                                </span>
                                                                <br />
                                                            </td>
                                                            <td
                                                                style={{
                                                                    maxWidth:
                                                                        "100px",
                                                                }}
                                                            >
                                                                {item.code_receveur
                                                                    ? item.code_receveur
                                                                    : "NULL"}
                                                            </td>
                                                            <td>
                                                                <span>
                                                                    {" "}
                                                                    {modifiedText(
                                                                        item
                                                                            .receiver
                                                                            ?.address1
                                                                    )}
                                                                </span>{" "}
                                                                <br />
                                                                <span>
                                                                    {
                                                                        item
                                                                            .receiver
                                                                            ?.city
                                                                    }
                                                                </span>
                                                                <br />
                                                                <span>
                                                                    {
                                                                        item
                                                                            .receiver
                                                                            ?.postalCode
                                                                    }
                                                                </span>
                                                            </td>
                                                            <td>
                                                                {item.billing_center?.billing_center_name}
                                                            </td>

                                                            <td>
                                                                {item?.last_facture_ref}
                                                            </td>

                                                        </tr>
                                                    </>

                                                ))}
                                            </>
                                        )}

                                    </>
                                )}

                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
            {selectedItem && <ConfirmModal
                title={`Réédition de facture`}
                description={"Etes-vous sûr de vouloir rééditer cette facture? Cela entraînera la suppression de la facture actuelle"}
                isOpen={isOpen}
                onClose={onClose}
                confirmRequest={() => factureService.reeditFacture({ id: selectedItem.id })}
                onConfirm={onReedited}
                successText={"Facture rééditée avec succès"}
                submitText={"Oui, rééditer"}
                submittingText={"Réédition..."}
            />}
        </div>
    );
}

