import { useParams } from "react-router-dom";
import ExpeditionDetailsPage from "../Pages/dashboard/ExpeditionDetails.page.jsx";
import NewShippingPage from "../Pages/dashboard/newShipping.page.jsx";
import ListContactComponent from "../components/contact/ListContactComponent.jsx";
import ProfilePage from "../Pages/dashboard/profile.page.jsx";
import SettingPage from "../Pages/dashboard/setting.page.jsx";
import ManifestView from "../components/Manifest/Index.jsx";
// import { ExpeditionsView } from "../components/Tableaux/Expeditions/Index.jsx";
import ExpeditionsView2 from "../components/chauffeurComponent/Expeditions/Index.jsx";
import {  FactureExpeditionArchiveePage, FactureGlobalePage, FactureListPage } from "../components/Tableaux/Factures/IndexFacture";
import Tchatcomponent from "../components/tchatComponent/opsTchatcomponent.jsx";
import { OpsAllExpedition, OpsArchivedExpedition, OpsCancelledExpedition, OpsDeliveredExpedition, OpsFacturedExpedition, OpsForArchivedExpedition, OpsInProgressExpedition, OpsPendingExpedition } from "../components/Tableaux/Expeditions/ExpeditionPageListOps";
import { IndexExtraction } from "../components/OPS/Factures/Extractions/IndexExtraction.jsx";
import { IndexListGlobale } from "../components/OPS/Factures/ListGlobale/IndexListGlobale.jsx";
import { IndexBorderauxArchive } from "../components/OPS/BorderauxArcvive/IndexBorderauxArchive.jsx";
import { IndexListSimple } from "../components/OPS/Factures/ListeSimple/IndexListSimple.jsx";
import Index from "../components/historiqueComponent/Index.jsx";



const findExpeditionState = (ExpeditionType) => {
  let expeditionState;
  switch (ExpeditionType) {
    case "livre":
      expeditionState = "livrées";
      break;
    case "a-archivee":
      expeditionState = "à archivées";
      break;
    case "facturee":
      expeditionState = "facturées";
      break;
    case "annulee":
      expeditionState = "annulées";
      break;
    case "en-attente":
      expeditionState = "en attentes";
      break;
    default:
      expeditionState = ExpeditionType;
      break;
  }

  return expeditionState
}

const ReturnRoute = (lien) => {
  let route = lien[lien.length - 1];
  if (route === "a-archivee" || route === "facturee" || route === "annulee") {
    return route + "s";
  } else if (route === "livre" || route === "archive") {
    return route + "es";
  } else if (route === "dash" || route === "") {
    return "en-attente";
  } else {
    return route;
  }
};

const returnInfo = (ExpeditionType, title, subtitle) => {
  const validTypes = ["en-cours", "livre", "a-archivee", "facturee", "annulee", "en-attente"];
  const expType = findExpeditionState(ExpeditionType);

  if (
    validTypes.includes(ExpeditionType)
  ) {
    subtitle = ExpeditionType
      ? subtitle + " " + expType.replaceAll("-", " ")
      : subtitle;
  } else {
    title = ExpeditionType
      ? title + " - " + expType.replaceAll("-", " ")
      : title;
  }

  return { title, subtitle };
}




const ContactList = () => {
  return <ListContactComponent role={"ops"} expeditionKind={"carnet"} />;
};

// const ExpeditionTable = ({ route, title, subtitle }) => {
//   // en-cours livrees a-archivees facturees annulees en-attente
//   // "category": "", // import ou export ou all #default all#
//   //     "type_expedition": "", // expedition ou course

//   let { ExpeditionType, identifiant } = useParams();
//   let expType;
//   switch (ExpeditionType) {
//     case "livre":
//       expType = "livrées";
//       break;
//     case "a-archivee":
//       expType = "à archivées";
//       break;
//     case "facturee":
//       expType = "facturées";
//       break;
//     case "annulee":
//       expType = "annulées";
//       break;
//     case "en-attente":
//       expType = "en attentes";
//       break;
//     default:
//       expType = ExpeditionType;
//       break;
//   }
//   if (
//     [
//       "en-cours",
//       "livre",
//       "a-archivee",
//       "facturee",
//       "annulee",
//       "en-attente",
//     ].includes(ExpeditionType)
//   ) {
//     subtitle = ExpeditionType
//       ? subtitle + " " + expType.replaceAll("-", " ")
//       : subtitle;
//   } else {
//     title = ExpeditionType
//       ? title + " - " + expType.replaceAll("-", " ")
//       : title;
//   }

//   let lien = window.location.pathname.split("/");
//   const returnRoute = () => {
//     let route = lien[lien.length - 1];
//     if (route === "a-archivee" || route === "facturee" || route === "annulee") {
//       return route + "s";
//     } else if (route === "livre" || route === "archive") {
//       return route + "es";
//     } else if (route === "dash" || route === "") {
//       return "en-attente";
//     } else {
//       return route;
//     }
//   };

//   const route2 = returnRoute();
//   return (
//     <ExpeditionsView
//       category={
//         ExpeditionType === "import" || ExpeditionType === "export"
//           ? ExpeditionType
//           : "all"
//       }
//       type_expedition={
//         route === "non-specifier"
//           ? "all"
//           : ExpeditionType === "courses-a-courses"
//             ? "courses"
//             : "expedition"
//       }
//       type={"paris"}
//       route={route === "non-specifier" ? route2 : route}
//       title={title}
//       subtitle={subtitle}
//     />
//   );
// };

const ExpeditionTable2 = () => {
  // en-cours livrees a-archivees facturees annulees en-attente
  // "category": "", // import ou export ou all #default all#
  //     "type_expedition": "", // expedition ou course

  const { ExpeditionType, identifiant } = useParams();

  return (
    <ExpeditionsView2
      category={"all"}
      type_expedition={"expedition"}
      type={"paris"}
      route={"en-cours"}
    />
  );
};


const InProgressOpsExpedition = ({ route, title: titre, subtitle: sousTitre }) => {

  let { ExpeditionType, identifiant } = useParams();
  const { title, subtitle } = returnInfo(ExpeditionType, titre, sousTitre)
  let lien = window.location.pathname.split("/");
  const route2 = ReturnRoute(lien)

  return (
    <OpsInProgressExpedition
      category={
        ExpeditionType === "import" || ExpeditionType === "export"
          ? ExpeditionType
          : "all"
      }
      type_expedition={
        route === "non-specifier"
          ? "all"
          : ExpeditionType === "courses-a-courses"
            ? "courses"
            : "expedition"
      }
      type={"paris"}
      route={route === "non-specifier" ? route2 : route}
      title={title}
      subtitle={subtitle}
    />
  );
};

const ArchivedOpsExpedition = ({ route, title: titre, subtitle: sousTitre }) => {

  let { ExpeditionType, identifiant } = useParams();
  const { title, subtitle } = returnInfo(ExpeditionType, titre, sousTitre)
  let lien = window.location.pathname.split("/");
  const route2 = ReturnRoute(lien)

  return (
    <OpsArchivedExpedition
      category={
        ExpeditionType === "import" || ExpeditionType === "export"
          ? ExpeditionType
          : "all"
      }
      type_expedition={
        route === "non-specifier"
          ? "all"
          : ExpeditionType === "courses-a-courses"
            ? "courses"
            : "expedition"
      }
      type={"paris"}
      route={route === "non-specifier" ? route2 : route}
      title={title}
      subtitle={subtitle}
    />
  );
};

const ForArchivedOpsExpedition = ({ route, title: titre, subtitle: sousTitre }) => {

  let { ExpeditionType, identifiant } = useParams();
  const { title, subtitle } = returnInfo(ExpeditionType, titre, sousTitre)
  let lien = window.location.pathname.split("/");
  const route2 = ReturnRoute(lien)

  return (
    <OpsForArchivedExpedition
      category={
        ExpeditionType === "import" || ExpeditionType === "export"
          ? ExpeditionType
          : "all"
      }
      type_expedition={
        route === "non-specifier"
          ? "all"
          : ExpeditionType === "courses-a-courses"
            ? "courses"
            : "expedition"
      }
      type={"paris"}
      route={route === "non-specifier" ? route2 : route}
      title={title}
      subtitle={subtitle}
    />
  );
};

const DeliveredOpsExpedition = ({ route, title: titre, subtitle: sousTitre }) => {

  let { ExpeditionType, identifiant } = useParams();
  const { title, subtitle } = returnInfo(ExpeditionType, titre, sousTitre)
  let lien = window.location.pathname.split("/");
  const route2 = ReturnRoute(lien)

  return (
    <OpsDeliveredExpedition
      category={
        ExpeditionType === "import" || ExpeditionType === "export"
          ? ExpeditionType
          : "all"
      }
      type_expedition={
        route === "non-specifier"
          ? "all"
          : ExpeditionType === "courses-a-courses"
            ? "courses"
            : "expedition"
      }
      type={"paris"}
      route={route === "non-specifier" ? route2 : route}
      title={title}
      subtitle={subtitle}
    />
  );
};

const PendingOpsExpedition = ({ route, title: titre, subtitle: sousTitre }) => {

  let { ExpeditionType, identifiant } = useParams();
  const { title, subtitle } = returnInfo(ExpeditionType, titre, sousTitre)
  let lien = window.location.pathname.split("/");
  const route2 = ReturnRoute(lien)

  return (
    <OpsPendingExpedition
      category={
        ExpeditionType === "import" || ExpeditionType === "export"
          ? ExpeditionType
          : "all"
      }
      type_expedition={
        route === "non-specifier"
          ? "all"
          : ExpeditionType === "courses-a-courses"
            ? "courses"
            : "expedition"
      }
      type={"paris"}
      route={route === "non-specifier" ? route2 : route}
      title={title}
      subtitle={subtitle}
    />
  );
};

const FacturedOpsExpedition = ({ route, title: titre, subtitle: sousTitre }) => {

  let { ExpeditionType, identifiant } = useParams();
  const { title, subtitle } = returnInfo(ExpeditionType, titre, sousTitre)
  let lien = window.location.pathname.split("/");
  const route2 = ReturnRoute(lien)

  return (
    <OpsFacturedExpedition
      category={
        ExpeditionType === "import" || ExpeditionType === "export"
          ? ExpeditionType
          : "all"
      }
      type_expedition={
        route === "non-specifier"
          ? "all"
          : ExpeditionType === "courses-a-courses"
            ? "courses"
            : "expedition"
      }
      type={"paris"}
      route={route === "non-specifier" ? route2 : route}
      title={title}
      subtitle={subtitle}
    />
  );
};

const AllOpsExpedition = ({ route, title: titre, subtitle: sousTitre }) => {

  let { ExpeditionType, identifiant } = useParams();
  const { title, subtitle } = returnInfo(ExpeditionType, titre, sousTitre)
  let lien = window.location.pathname.split("/");
  const route2 = ReturnRoute(lien)

  return (
    <OpsAllExpedition
      category={
        ExpeditionType === "import" || ExpeditionType === "export"
          ? ExpeditionType
          : "all"
      }
      type_expedition={
        route === "non-specifier"
          ? "all"
          : ExpeditionType === "courses-a-courses"
            ? "courses"
            : "expedition"
      }
      type={"paris"}
      route={route === "non-specifier" ? route2 : route}
      title={title}
      subtitle={subtitle}
    />
  );
};

const CancelledOpsExpedition = ({ route, title: titre, subtitle: sousTitre }) => {

  let { ExpeditionType, identifiant } = useParams();
  const { title, subtitle } = returnInfo(ExpeditionType, titre, sousTitre)
  let lien = window.location.pathname.split("/");
  const route2 = ReturnRoute(lien)

  return (
    <OpsCancelledExpedition
      category={
        ExpeditionType === "import" || ExpeditionType === "export"
          ? ExpeditionType
          : "all"
      }
      type_expedition={
        route === "non-specifier"
          ? "all"
          : ExpeditionType === "courses-a-courses"
            ? "courses"
            : "expedition"
      }
      type={"paris"}
      route={route === "non-specifier" ? route2 : route}
      title={title}
      subtitle={subtitle}
    />
  );
};

export const opsRouter = [
  {
    path: "",
    element: (
      <PendingOpsExpedition
        route={"non-specifier"}
        title={"Dashboard OPS"}
        subtitle={"Liste des expéditions en attentes"}
      />
    ),
  },
  {
    path: "carnet",
    element: <ContactList role={"ops"} expeditionKind={"import"} />,
  },
  {
    path: "archivee",
    element: (
      <ArchivedOpsExpedition
        route={"archivees"}
        title={"Dashboard OPS"}
        subtitle={"Liste des expéditions archivées"}
      />
    ),
  },
  {
    path: "manifest",
    element: <ManifestView role={"ops"} expeditionKind={"manifest"} />,
  },
  {
    path: ":ExpeditionType",
    element: (
      <PendingOpsExpedition
        route={"non-specifier"}
        title={"Dashboard OPS"}
        subtitle={"Liste des expéditions"}
      />
    ),
  },
  {
    path: ":ExpeditionType/a-archivee",
    element: (
      <ForArchivedOpsExpedition
        route={"a-archivees"}
        title={"Dashboard OPS"}
        subtitle={"Liste des expéditions à archivées"}
      />
    ),
  },
  {
    path: ":ExpeditionType/facturee",
    element: (
      <FacturedOpsExpedition
        route={"facturees"}
        title={"Dashboard OPS"}
        subtitle={"Liste des expéditions factuées"}
      />
    ),
  },
  {
    path: ":ExpeditionType/annulee",
    element: (
      <CancelledOpsExpedition
        route={"annulees"}
        title={"Dashboard OPS"}
        subtitle={"Liste des expéditions annulées"}
      />
    ),
  },
  {
    path: ":ExpeditionType/en-cours",
    element: (
      <InProgressOpsExpedition
        route={"en-cours"}
        title={"Dashboard OPS"}
        subtitle={"Liste des expéditions en cours"}
      />
    ),
  },
  {
    path: ":ExpeditionType/livre",
    element: (
      <DeliveredOpsExpedition
        route={"livrees"}
        title={"Dashboard OPS"}
        subtitle={"Liste des expéditions livrées"}
      />
    ),
  },
  {
    path: ":ExpeditionType/archive",
    element: (
      <ArchivedOpsExpedition
        route={"archivees"}
        title={"Dashboard OPS"}
        subtitle={"Liste des expéditions archivées"}
      />
    ),
  },
  {
    path: ":ExpeditionType/en-attente",
    element: (
      <PendingOpsExpedition
        route={"en-attente"}
        title={"Dashboard OPS"}
        subtitle={"Liste des expéditions en attentes"}
      />
    ),
  },
  {
    path: ":ExpeditionType/new",
    element: <NewShippingPage />,
  },
  {
    path: "message",
    element: <Tchatcomponent />,
  },
  {
    path: "message/:identifiant",
    element: <Tchatcomponent />,
  },
  {
    path: ":ExpeditionType/a-archivee/:identifiant",
    element: <ExpeditionDetailsPage />,
  },
  {
    path: ":ExpeditionType/facturee/:identifiant",
    element: <ExpeditionDetailsPage />,
  },
  {
    path: ":ExpeditionType/annulee/:identifiant",
    element: <ExpeditionDetailsPage />,
  },
  {
    path: ":ExpeditionType/en-cours/:identifiant",
    element: <ExpeditionDetailsPage />,
  },
  {
    path: ":ExpeditionType/livre/:identifiant",
    element: <ExpeditionDetailsPage />,
  },
  {
    path: ":ExpeditionType/archive/:identifiant",
    element: <ExpeditionDetailsPage />,
  },
  {
    path: ":ExpeditionType/en-attente/:identifiant",
    element: <ExpeditionDetailsPage />,
  },
  {
    path: ":ExpeditionType/:identifiant",
    element: <ExpeditionDetailsPage />,
  },

  {
    path: "profile",
    element: <ProfilePage />,
  },

  {
    // Nouvelle route pour ChauffeurComponent essaie

    path: "chauffeurs", // Nouvelle route pour ChauffeurComponent
    element: <ExpeditionTable2 />,
  },
  {
    path: "history",
    element: <Index />,
  },
  {
    path: "factures/listes",
    element: <IndexListSimple title={"Facture"} subtitle={"Liste des factures"} type={"facture"} />,
  },
  {
    path: "factures/bordereaux",
    element: <IndexBorderauxArchive title={"Facture"} subtitle={"Liste des brodereaux archivés"} type={"expedition"} />,
  },
  {
    path: "factures/extractions",
    element: <IndexExtraction title={"Facture"} subtitle={"Extraction en excel"} type={"facture"} />,
  },

  {
    path: "factures/liste-globale",
    element: <IndexListGlobale title={"Facture"} subtitle={"Liste globale des factures"} type={"facture"} />,
  },
];
