import React, { useState } from "react";
import ModalComponent from "../../Utils/Modal/Index";
import UsersSelector from "../../Utils/Selectors/UsersSelector";
import ExpeditionService from "../../../services/expedition/ExpeditionService";
import { toast } from "react-toastify";



export default function AttributionFormModal({
    isOpen,
    onClose,
    selectedData,
    type,
    selectedProfile
}) {

    const [expeditionState, setExpeditionState] = useState("enlevement");

    const initialData = {
        description: "",
        mawb: "",
        ex1: "",
        datemanifest: "",
        viavol: "",
        type,
    };

    const [formData, setFormData] = useState({
        email: "",
        telephone: "",
        chauffeur_id: ""
    });

    const [isSubmitting, setIsSubmitting] = useState(false);

    const isValid = selectedData?.id && formData.chauffeur_id

    const setFormValue = (key, value) => {
       
        setFormData((d) => ({
            ...d,
            [key]: value,
        }));
    };

    const handleSubmit = async (e) => {
        try {
            e?.preventDefault();
            setIsSubmitting(true);
            const submitData = formData;
            delete submitData.created_at;
            delete submitData.updated_at;
            
            const { data } = await ExpeditionService.affectShipping({
                chauffeur_id: formData.chauffeur_id,
                shipping_id: selectedData.id
            });
            if (data.success) {
                setFormData(initialData);
                toast.success(
                    `Expédition affectée avec succès`,
                    {
                        position: "bottom-center",
                        autoClose: 4000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    }
                );
                onClose()
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsSubmitting(false);
        }
    };

    const onSelect = (value) => {
        setFormData(d => ({
            ...d,
            ...value,
            chauffeur_id: value?.id || ""
        }));
    };



    return (
        <ModalComponent
            isOpen={isOpen}
            onClose={onClose}
            title={`Attribuer à un ${selectedProfile}`}
        >

            <form className="w-full py-4 px-3">
                <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <UsersSelector
                            profil={selectedProfile}
                            selectedValue={""}
                            onSelect={onSelect}
                        />
                    </div>
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label
                            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            htmlForor="email"
                        >
                            Email
                        </label>
                        <input
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                            id="email"
                            type="email"
                            placeholder=""
                            value={formData.email}
                            onChange={(e) => {
                                setFormValue("email", e.target.value);
                            }}
                        />
                    </div>
                </div>

                <div className="flex items-center flex-wrap -mx-3 mb-6">
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label
                            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            htmlFor="telephone"
                        >
                            Téléphone
                        </label>
                        <input
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                            id="telephone"
                            type="text"
                            placeholder=""
                            value={formData.telephone1}
                            onChange={(e) => {
                                setFormValue("telephone1", e.target.value);
                            }}
                        />
                    </div>
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0 m-y-auto ">
                        <div className="flex items-end mb-1">
                            <input
                                type="checkbox"
                                id="myCheckbox"
                                className="form-checkbox text-indigo-600 h-4 w-4"
                                value={"enlevement"}
                                checked={
                                    expeditionState === "enlevement" ? true : false
                                }
                                onChange={(e) => setExpeditionState("enlevement")}
                            />
                            <label
                                htmlFor="myCheckbox"
                                className="tracking-wide text-gray-700 text-sm ml-2"
                            >
                                Enlèvement
                            </label>
                        </div>
                        <div className="flex items-end">
                            <input
                                type="checkbox"
                                id="myCheckbox2"
                                className="form-checkbox text-indigo-600 h-4 w-4"
                                value={"livraison"}
                                checked={
                                    expeditionState === "livraison" ? true : false
                                }
                                onChange={() => setExpeditionState("livraison")}
                            />
                            <label
                                htmlFor="myCheckbox2"
                                className="tracking-wide text-gray-700 text-sm ml-2"
                            >
                                Livraison
                            </label>
                        </div>
                    </div>
                </div>

                <div className="flex justify-center">
                    <button
                        onClick={handleSubmit}
                        type="submit"
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
                        disabled={!isValid || isSubmitting}
                    >
                        Enregistrer
                    </button>
                </div>
            </form>

        </ModalComponent>
    );
}
