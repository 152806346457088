import React from 'react';
import { formatDefaultDate } from '../../../utils';
import { refPrefixer, urlSplit } from '../../../utils/Functions/otherFunctions';
import { Paragraphe } from '../../Utils/UI/PragrapheComponent';

function ReferenceClientExpeditionComponent({ Expedition }) {
    const { exnetSpace, expeditionName } = urlSplit()

    const returnState = () => {
        if (Expedition) {
            return Expedition.pod > 0 && Expedition.raison ? 'Annuler' : Expedition.pod > 0 && !Expedition ? 'Livrer' : 'En cours';
        }
    }

    const returnDate = () => {
        if (Expedition.pickup_date) {
            return formatDefaultDate(Expedition.pickup_date)

        }

    }

    return (
        <div className="bg-white rounded-md h-100">
            <div className="px-3 ">

                <div className="text-center  flex items-center justify-center text-base font-semibold h-[40px] py-6">
                    <span>{`RéFéRENCE ${Expedition.type_expedition === 'courses' ? "COURSE EXPRESS" : "EXPéDITION"}`.toUpperCase()}</span>

                </div>
                <hr className="mt-0" />

                <div className="mt-6 text-base font-normal uppercase">

                    <Paragraphe label={`Numéro ${Expedition.type_expedition === 'courses' ? "COURSE EXPRESS" : "EXPéDITION"}`} value={`${refPrefixer(exnetSpace)}${Expedition.ref}`} />
                    <Paragraphe label={'Référence client'} value={Expedition.customer_ref} />
                    {exnetSpace === 'medical' && <>
                        <Paragraphe label={'Code donneur'} value={Expedition.code_donneur} />
                        <Paragraphe label={'Code receveur'} value={Expedition?.code_receveur} />
                    </>}
                    <Paragraphe label={'Statut'} value={returnState()} />
                    <Paragraphe label={'Date d\'expédition'} value={returnDate()} />
                    <Paragraphe label={'horaire'} value={Expedition.pickup_time?.split(':').slice(0, 2).join(':')} />



                </div>
            </div>
        </div>
    );
}

export default ReferenceClientExpeditionComponent;