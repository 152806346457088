import React, { useCallback, useEffect, useState } from 'react'
import { formatDefaultDate } from '../../utils/moment'
import { combineDateAndTime } from '../../utils'
import { SwitchComponent } from '../Utils/Index'
import { trierTableauParDateEtHeureSuivi } from '../../utils/Functions/otherFunctions'

export default function SuiviLists({ items,onToggleActive }) {

    const [lists, setLists] = useState([])

    const convertirEnDate = (dateString, heureString, data) => {
        if (data) {
            let date = new Date(dateString).toLocaleDateString()
            let heures = new Date(dateString).toLocaleTimeString()
            const [annee, mois, jour] = date?.split('-');
            const [heure, minute] = heures?.split(':');
            return new Date(annee, mois - 1, jour, heure, minute, 0).getTime();
        } else {
            const [annee, mois, jour] = dateString?.split('-');
            const [heure, minute] = heureString?.split(':');
            return new Date(annee, mois - 1, jour, heure, minute, 0).getTime();
        }
    };

    useEffect(() => {
        if (items) {
            setLists(trierTableauParDateEtHeureSuivi(items))
            console.log(trierTableauParDateEtHeureSuivi(items))
        }
    }, [items])

    

    // const results = items?.sort((a, b) => convertirEnDate(b.date_suivi || b.created_at, b.heur_suivi, b.date_suivi ? true : false) - convertirEnDate(a.date_suivi || a.created_at, a.heur_suivi, a.date_suivi ? true : false)) || [];
    // const results = trierTableauParDateEtHeureSuivi(items)




    return (
        <div className={"row my-5"}>
            <div className="table-responsive">
                <table className="table mb-0">
                    <thead>
                        <tr>
                            <th>Suivi</th>
                            <th>Agent</th>
                            <th>Chauffeur</th>
                            <th>Date</th>
                            <th style={{ minWidth: 90 }}>Visible</th>
                            <th>Identifiant</th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map((item, i) => (
                            <tr key={i}>
                                <td>{item.details}</td>
                                <td>{item.agents}</td>
                                <td>{item.chaufeur}</td>
                                <td>{formatDefaultDate(combineDateAndTime(item.date_suivi, item.heur_suivi), "DD-MM-YYYY HH:mm")}</td>
                                <td>
                                    <SwitchComponent
                                        checked={item.visible === 1}
                                        onChange={(value) => {
                                            onToggleActive(item, value);
                                        }}
                                    />
                                </td>
                                <td>{item.template}</td>
                            </tr>
                        ))}


                    </tbody>
                </table>
            </div>
        </div>
    )
}
