import parisClientMenu from './client/_paris.menu.jsx';
import medicalClientMenu from './client/_medical.menu.jsx';
import serviceClientMenu from './client/_service.menu.jsx';

import parisOpsMenu from './ops/_paris.menu.jsx';
import medicalOpsMenu from './ops/_medical.menu.jsx';
import serviceOpsMenu from './ops/_service.menu.jsx';

import adminMenu from './admin/_admin.menu.jsx';
import { exploitationMedicalMenu, exploitationParisMenu, exploitationServiceMenu } from './exploitation.js';
export default { parisClientMenu, medicalClientMenu, serviceClientMenu, parisOpsMenu, medicalOpsMenu, serviceOpsMenu, adminMenu, exploitationMedicalMenu, exploitationParisMenu, exploitationServiceMenu };
