import { TbAddressBook, TbChartLine, TbLayoutDashboard, TbMotorbike, TbPackage, TbPackageExport, TbPackageImport, TbPackageOff } from "react-icons/tb";
import { returnExpeditionTypeLogo } from "../../../utils/Functions/otherFunctions";
import servicesImport from '../../../assets/img/svg/Services-import.svg'
import servicesExport from '../../../assets/img/svg/Services-export.svg'
import servicescourse from '../../../assets/img/svg/Services.svg'
import dashLogo from '../../../assets/img/Icones/Dashboard/services.svg'
import contactLogo from '../../../assets/img/Icones/carnet/services.svg'
import archiveLogo from '../../../assets/img/Icones/archive/services.svg'
import boiteLogo from '../../../assets/img/Icones/boite/services.svg'
import freightLogo from '../../../assets/img/Icones/freight/services.svg'

export default {
    data: [
        {
            libelle: "Dashboard",
            // lien: "/client/service/dash",
            icon: <img alt={'fd'} src={dashLogo} className={` w-8 hover:text-black`} />,
            liens: [
                {
                    libelle: "Expéditions en cours",
                    lien: "/client/service/dash",
                },
                {
                    libelle: "expéditions livrées",
                    lien: "/client/service/dash/livre",
                },
            ],
        },
        {
            libelle: "Mes expéditions",
        },
        {
            libelle: "Courses",
            icon: <img alt={'fd'} src={servicescourse} className={` w-8 hover:text-black`} />,
            liens: [
                {
                    libelle: "Nouvelle course",
                    lien: "/client/service/dash/courses-a-courses/new",
                },
                {
                    libelle: "Course en cours",
                    lien: "/client/service/dash/courses-a-courses/en-cours",
                },
                {
                    libelle: "Course livrées",
                    lien: "/client/service/dash/courses-a-courses/livre",
                },

            ],
        },


        {
            libelle: "Exports",
            icon: <img alt={'fd'} src={servicesExport} className={` w-8 hover:text-black`} />,
            liens: [
                ,
                {
                    libelle: "Nouvel export",
                    lien: "/client/service/dash/export/new",
                },
                {
                    libelle: "Exports en cours",
                    lien: "/client/service/dash/export/en-cours",
                },
                {
                    libelle: "Exports livrées",
                    lien: "/client/service/dash/export/livre",
                }
            ],
        },
        {
            libelle: "Imports",
            icon: <img alt={'fd'} src={servicesImport} className={` w-8 hover:text-black`} />,
            liens: [
                {
                    libelle: "Nouvel import",
                    lien: "/client/service/dash/import/new",
                },
                {
                    libelle: "Imports en cours",
                    lien: "/client/service/dash/import/en-cours",
                },
                {
                    libelle: "Imports livrées",
                    lien: "/client/service/dash/import/livre",
                },

            ],
        },
        {
            libelle: "Carnet d'adresses",
            lien: "/client/service/dash/carnet",
            icon: <img alt={'fd'} src={contactLogo} className={` w-8 hover:text-black`} />,
        },
        {
            libelle: "Expéditions archivées",
            lien: "/client/service/dash/export/archive",
            icon: <img alt={'fd'} src={archiveLogo} className={` w-8 hover:text-black`} />,
        },
        {
            libelle: "Mes Statistiques",
        },
        {
            libelle: "Courses",
            lien: "/client/service/dash/statiques/expeditions",
            icon: <img alt={'fd'} src={boiteLogo} className={` w-8 hover:text-black`} />,
        },
        {
            libelle: "Freight",
            lien: "/client/service/dash/statiques/freight",
            icon: <img alt={'fd'} src={freightLogo} className={` w-8 hover:text-black`} />,
        }
    ],
    configuration: {
        showExtra: false,
        showHelp: false,
    }
};
