import React from 'react';
import ReactPaginate from "react-paginate";
import { exnetSpaceColor, urlSplit } from '../../utils/Functions/otherFunctions';
import clsx from 'clsx';

function PaginationComponent({ handlePageClick, totalItems, perPageItems, setnumberPerPage, page = 1, showPagination = true }) {


    const { exnetSpace } = urlSplit();

    //const bgColor = `bg-[${exnetSpaceColor(exnetSpace)}]`

    //console.log("PAGE", page, totalItems, perPageItems)


    return (
        <>
            <div>
                <span>Affichage de {(perPageItems * page) - perPageItems} à {(perPageItems * page)} sur {totalItems} éléments</span>
            </div>
            {showPagination && <div className={'flex justify-between items-center my-2'}>
                <div>
                    <div className="flex items-center justify-between ">
                        <div className="relative w-[100px]">
                            <select
                                onChange={(e) => setnumberPerPage(e.target.value)}
                                id="example-select"
                                className="block w-full px-4 py-2 pr-8 text-sm leading-tight bg-white border border-gray-300 rounded shadow appearance-none hover:border-gray-400 focus:outline-none focus:shadow-outline"
                            >
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={75}>75</option>
                                <option value={100}>100</option>
                                <option value={250}>250</option>
                                <option value={1000000}>Tous</option>
                            </select>
                            <div className="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none">
                                <svg
                                    className="w-4 h-4 fill-current"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                >
                                    <path d="M7 7l3-3 3 3m0 6l-3 3-3-3" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <nav>
                        <div
                            className="flex justify-end mb-0 pagination"
                            listClassName="justify-end mb-0"
                        >
                            <ReactPaginate
                                initialPage={0}
                                forcePage={page - 1}
                                breakLabel="..."
                                nextLabel=">"
                                nextClassName="border border-black bg-white rounded-md ml-4"
                                nextLinkClassName="font-calibri text-lg text-black"
                                onPageChange={(v) => {
                                   
                                    handlePageClick(v.selected + 1)
                                }}
                                pageRangeDisplayed={5}
                                previousLabel="<"
                                previousClassName="border border-black bg-white rounded-md mr-4"
                                previousLinkClassName="font-calibri text-lg text-black"
                                renderOnZeroPageCount={null}
                                breakClassName={"break-me"}
                                pageCount={Math.ceil(totalItems / parseInt(perPageItems))}
                                marginPagesDisplayed={2}
                                containerClassName={"pagination items-center"}
                                subContainerClassName={"pages pagination"}
                                activeClassName={
                                    clsx({
                                        'bg-[#ba9964]': exnetSpace === 'paris',
                                        'bg-[#098721]': exnetSpace === 'service',
                                        'bg-[#3A8EBA]': exnetSpace === 'medical',
                                        'bg-[#222]': exnetSpace !== 'paris' && exnetSpace !== 'service' && exnetSpace !== 'medical',
                                    }, `text-white flex items-center justify-center h-7 w-7 rounded-full`)

                                }
                            />
                        </div>
                    </nav>
                </div>


            </div>}
        </>

    );
}

export default PaginationComponent;