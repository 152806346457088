import React, { useState } from 'react';
import { LineComponent, ResumeComponentLayout } from './resumeComponentLayout';
import useDisclosure from '../../hooks/useDisclosure';
import ModalComponent from '../Utils/Modal/Index';
import CivilitySelector from '../Utils/Selectors/CivilitySelector';
import CarnetAddress, { ContactFormInput } from '../contact/CarnetAddress';
import { ExpeditionStore } from '../../states/expeditionStore';
import { ErrorStore } from '../../states/inputError';
import CountriesSelector from '../Utils/Selectors/CountriesSelector';
import Loader from '../Utils/Loader';

function UserDetails({ title, onClick, useType }) {


    const updateReceiverData = ExpeditionStore((state) => state.updateReceiverData)
    const updateSenderData = ExpeditionStore((state) => state.updateSenderData)
    const receiverData = ExpeditionStore((state) => state.receiverData)
    const senderData = ExpeditionStore((state) => state.senderData)

    const errorField = ErrorStore((state) => state.errorField);
    const setMessageError = ErrorStore((state) => state.setMessageError);

    const [isSubmitting, setIsSubmitting] = useState(false);

    const { isOpen, onClose, onOpen } = useDisclosure();

    const handleSubmit = () => {
        console.log("fssqs")
    }

    const onContactSelect = () => {
        console.log("onContactSelect")
    }

    const updateData = (key, value) => {
        let newData = {};
        newData[key] = value;
        if (useType === 'receiver') {
            updateReceiverData(newData)
        } else {
            updateSenderData(newData)
        }

        let errorMessage = {
            field: errorField.field === key ? "" : errorField.field,
            message: errorField.field === key ? "" : errorField.message,
        };

        setMessageError({ ...errorMessage });
    };

    return (
        <>
            <ResumeComponentLayout title={title} onClick={() => onOpen(true)} medium={false}>

                <div>
                    <LineComponent value={'HECTOR'} label={"Contact"} />

                    <LineComponent value={'DSQDSQD'} label={"Entreprise(Facultatif)"} />

                    <LineComponent value={'12345'} label={"Pays"} />

                    <LineComponent value={'lorem@gmail.com'} label={"Adresse"} />

                    <LineComponent value={'lorem@gmail.com'} label={"Ville"} />

                    <LineComponent value={'lorem@gmail.com'} label={"Téléphone"} />

                    <LineComponent value={'lorem@gmail.com'} label={"Email"} />
                </div>
            </ResumeComponentLayout>


            <>
                <ModalComponent
                    isOpen={isOpen}
                    onClose={onClose}
                    title={`Modifier`}
                >
                    {/* {validationError && (
                        <div className="bg-red-100 mt-4 text-red-600 px-4 py-2 rounded-md text-[13px]">{validationError}</div>
                    )} */}
                    <form onSubmit={handleSubmit} className="p-4">
                        <div className="flex flex-wrap items-center justify-between mb-2">
                            <div className="min-w-[150px]">
                                <CivilitySelector
                                    value={useType === 'receiver' ? receiverData?.civility : senderData?.civility}
                                    onSelect={v => {
                                        updateData("civility", v.value)
                                    }}

                                />
                            </div>
                            {/* 
                            {civilities?.map((c, i) => (
                                <div className={`px-5 py-[10px] rounded text-sm border cursor-pointer ${selectedTab === i + 1 ? "bg-gray-100" : "bg-transparent"}`}
                                    onClick={() => {
                                        c.onClick?.()
                                    }}
                                >
                                    {c.label}
                                </div>
                            ))} */}

                        </div>
                        <div className="grid grid-cols-2 gap-4">
                            <ContactFormInput
                                label="Nom de l'entreprise"
                                value={useType === 'receiver' ? receiverData?.company : senderData?.company}
                                onChange={value => {
                                    updateData("company", value)
                                }}
                                onSelect={onContactSelect}
                                attribute={"company"}
                            />
                            <div>
                                <ContactFormInput
                                    label="Nom et Prénom(s)"
                                    value={useType === 'receiver' ? receiverData?.fullname : senderData?.fullname}
                                    onChange={value => {
                                        updateData("fullname", value)
                                    }}
                                    onSelect={onContactSelect}
                                    attribute={"fullname"}
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-2 gap-4">
                            <div className="mt-4">
                                <ContactFormInput
                                    label="Adresse"
                                    value={useType === 'receiver' ? receiverData?.address1 : senderData?.address1}
                                    onChange={value => {
                                        updateData("address1", value)
                                    }}
                                    onSelect={onContactSelect}
                                    attribute={"address1"}
                                />
                            </div>

                            <div className="mt-4">
                                <ContactFormInput
                                    label="Complément d'adresse"
                                    value={useType === 'receiver' ? receiverData?.address2 : senderData?.address2}
                                    onChange={value => {
                                        updateData("address2", value)
                                    }}
                                    onSelect={onContactSelect}
                                    attribute={"address2"}
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-[1fr_100px_1fr] gap-4 mt-4">
                            <div>
                                <CountriesSelector
                                    selectedValue={useType === 'receiver' ? receiverData?.country : senderData?.country}
                                    onSelect={v => updateData("country", v.value)}
                                />
                            </div>
                            <div>
                                <label className="block mb-2 text-sm font-medium text-gray-900 uppercase ">Code Postal</label>
                                <input
                                    type="text"
                                    name="postalCode"
                                    value={useType === 'receiver' ? receiverData?.postalCode : senderData?.postalCode}
                                    onChange={(e) => {
                                        updateData("postalCode", e.target.value)

                                    }}
                                    className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                                    required
                                />
                            </div>
                            <div>
                                <label className="block mb-2 text-sm font-medium text-gray-900 uppercase">Ville</label>
                                <input
                                    type="text"
                                    name="city"
                                    value={useType === 'receiver' ? receiverData?.city : senderData?.city}
                                    onChange={(e) => {
                                        updateData("city", e.target.value)

                                    }}
                                    className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                                    required
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-2 gap-4 mt-4">
                            <div>
                                <label className="block mb-2 text-sm font-medium text-gray-900 uppercase">Téléphone</label>
                                <div className="flex">
                                    <input
                                        type="text"
                                        name="phone1"
                                        value={useType === 'receiver' ? receiverData?.phone1 : senderData?.phone1}
                                        onChange={(e) => {
                                            updateData("phone1", e.target.value)

                                        }}
                                        className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control" required
                                    />
                                </div>
                            </div>
                            <div>
                                <label className="block mb-2 text-sm font-medium text-gray-900 uppercase">Téléphone 2</label>
                                <div className="flex">
                                    <input
                                        type="text"
                                        name="phone2"
                                        value={useType === 'receiver' ? receiverData?.phone2 : senderData?.phone2}
                                        onChange={(e) => {
                                            updateData("phone2", e.target.value)

                                        }}
                                        className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control" />

                                </div>
                            </div>
                        </div>

                        <div className="mt-4">
                            <label className="block mb-2 text-sm font-medium text-gray-900 uppercase">Email</label>
                            <input
                                type="email"
                                name="email"
                                value={useType === 'receiver' ? receiverData?.email : senderData?.email}
                                onChange={(e) => {
                                    updateData("email", e.target.value)

                                }}
                                className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                                required
                            />
                        </div>

                        <div className="flex justify-center text-right ">
                            <button
                                type="submit"
                                className={` ${errorField ? "cursor-pointer" : "cursor-not-allowed"} text-white bg-black hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm px-14 py-2.5 text-center mt-4`}
                                onClick={handleSubmit}
                                disabled={isSubmitting}
                            //title={`${}`}
                            >
                                <span className="mr-3">
                                    Enregistrer
                                </span>
                                {isSubmitting && (
                                    <Loader />
                                )}

                            </button>
                          
                        </div>
                    </form>
                </ModalComponent>
                <CarnetAddress
                    isOpen={isOpen}
                    onClose={() => {
                        onClose()
                    }} onSelect={onContactSelect}
                />
            </>
        </>
    );
}

export default UserDetails;

