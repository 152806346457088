export const Liste = [
    { value: "BENIN", label: "BENIN" },
    { value: "TOGO", label: "TOGO" },
    { value: "NIGER", label: "NIGER" },
    { value: "NIGERIA", label: "NIGERIA" },
];

export const parametreFiltre = [
    { type: "text", libelle: "Référence", Nom_colonne: "Reference" },
    {
        type: "select",
        libelle: "Role",
        Nom_colonne: "Role",
        liste: [
            {
                value: "COMPTABLE",
                label: "COMPTABLE",
            },
            {
                value: "CTO",
                label: "CTO",
            },
            {
                value: "CEO",
                label: "CEO",
            },
        ],
    },
    {
        type: "select",
        libelle: "Civilité",
        Nom_colonne: "civilite",
        liste: [
            {
                value: "Mr",
                label: "Monsieur",
            },
            {
                value: "Mme",
                label: "Madame",
            },
        ],
    },
    {
        type: "date",
        libelle: "Start date",
        Nom_colonne: "Start",
        niveau: "from",
    },
    { type: "date", libelle: "End date", Nom_colonne: "end", niveau: "end" },
];

export const valeurs = [
    {
        reference: "7734",
        enlevement_date: "12/02/2021",
        expediteur: "ATT",
        pays_expediteur: "Togo",
        ville_expediteur: "LOME",
        destinataire: "HCNT",
        pays_destinataire: "Benin",
        ville_destinataire: "contonou",
        date_livraison: "10/02/2022",
        tracking: "lorem  lorem",
        pod: "cdnbscdscn",
    },
    {
        reference: "7643",
        enlevement_date: "12/03/2021",
        expediteur: "TTK",
        pays_expediteur: "BENIN",
        ville_expediteur: "Cotonou",
        destinataire: "PDG DJANGOUN",
        pays__destinataire: "Benin",
        ville__destinataire: "contonou",
        date_livraison: "10/02/2022",
        tracking: "lorem  lorem",
        pod: "cdnbscdscn",
    },
    {
        reference: "Y3",
        enlevement_date: "CEO",
        expediteur: "Bénin",
        pays_expediteur: 23,
        ville_expediteur: "PARAKOU",
        destinataire: "MHD",
        pays_destinataire: "Benin",
        ville_destinataire: "contonou",
        date_livraison: "10/02/2022",
        tracking: "lorem  lorem",
        pod: "cdnbscdscn",
    },
    {
        reference: "7734",
        enlevement_date: "12/02/2021",
        expediteur: "ATT",
        pays_expediteur: "Togo",
        ville_expediteur: "LOME",
        destinataire: "HCNT",
        pays_destinataire: "Benin",
        ville_destinataire: "contonou",
        date_livraison: "10/02/2022",
        tracking: "lorem lorem",
        pod: "cdnbscdscn",
    },
    {
        reference: "2456",
        enlevement_date: "23/05/2021",
        expediteur: "XYZ Company",
        pays_expediteur: "France",
        ville_expediteur: "Paris",
        destinataire: "ABC Corporation",
        pays_destinataire: "Espagne",
        ville_destinataire: "Madrid",
        date_livraison: "15/03/2022",
        tracking: "ipsum ipsum",
        pod: "dbnvsjfvjsd",
    },
    {
        reference: "9876",
        enlevement_date: "10/08/2021",
        expediteur: "Company ABC",
        pays_expediteur: "USA",
        ville_expediteur: "New York",
        destinataire: "Company XYZ",
        pays_destinataire: "Canada",
        ville_destinataire: "Toronto",
        date_livraison: "05/06/2022",
        tracking: "dolor dolor",
        pod: "fbhvsjkfvjns",
    },
    {
        reference: "5432",
        enlevement_date: "15/04/2021",
        expediteur: "Company XYZ",
        pays_expediteur: "Allemagne",
        ville_expediteur: "Berlin",
        destinataire: "Company ABC",
        pays_destinataire: "Italie",
        ville_destinataire: "Rome",
        date_livraison: "20/12/2022",
        tracking: "sit sit",
        pod: "mncbskcvls",
    },
    {
        reference: "1122",
        enlevement_date: "05/03/2021",
        expediteur: "ABC Corporation",
        pays_expediteur: "Japon",
        ville_expediteur: "Tokyo",
        destinataire: "XYZ Company",
        pays_destinataire: "Chine",
        ville_destinataire: "Shanghai",
        date_livraison: "30/09/2022",
        tracking: "amet amet",
        pod: "nbdskvcbsk",
    },
    {
        reference: "8899",
        enlevement_date: "18/06/2021",
        expediteur: "Company XYZ",
        pays_expediteur: "Royaume-Uni",
        ville_expediteur: "Londres",
        destinataire: "Company ABC",
        pays_destinataire: "Australie",
        ville_destinataire: "Sydney",
        date_livraison: "25/07/2022",
        tracking: "consectetur consectetur",
        pod: "svklnfvldkn",
    },
    {
        reference: "3344",
        enlevement_date: "20/07/2021",
        expediteur: "XYZ Company",
        pays_expediteur: "Russie",
        ville_expediteur: "Moscou",
        destinataire: "ABC Corporation",
        pays_destinataire: "Inde",
        ville_destinataire: "New Delhi",
        date_livraison: "01/01/2022",
        tracking: "adipiscing adipiscing",
        pod: "vskjvnskjvns",
    },
    {
        reference: "5569",
        enlevement_date: "30/09/2021",
        expediteur: "ABC Corporation",
        pays_expediteur: "Brésil",
        ville_expediteur: "Brasilia",
        destinataire: "XYZ Company",
        pays_destinataire: "Argentine",
        ville_destinataire: "Buenos Aires",
        date_livraison: "10/10/2022",
        tracking: "elit elit",
        pod: "vskjvnskjvns",
    },
    {
        reference: "5566",
        enlevement_date: "30/09/2021",
        expediteur: "ABC Corporation",
        pays_expediteur: "Brésil",
        ville_expediteur: "Brasilia",
        destinataire: "XYZ Company",
        pays_destinataire: "Argentine",
        ville_destinataire: "Buenos Aires",
        date_livraison: "10/10/2022",
        tracking: "elit elit",
        pod: "vskjvnskjvns",
    },
    {
        reference: "1234",
        enlevement_date: "01/10/2021",
        expediteur: "DEF Corporation",
        pays_expediteur: "France",
        ville_expediteur: "Paris",
        destinataire: "GHI Company",
        pays_destinataire: "Espagne",
        ville_destinataire: "Barcelone",
        date_livraison: "15/10/2022",
        tracking: "Lorem ipsum",
        pod: "xycyzxyz",
    },
    {
        reference: "9876",
        enlevement_date: "12/11/2021",
        expediteur: "MNO Corporation",
        pays_expediteur: "Allemagne",
        ville_expediteur: "Berlin",
        destinataire: "PQR Company",
        pays_destinataire: "Italie",
        ville_destinataire: "Rome",
        date_livraison: "25/11/2022",
        tracking: "dolor sit amet",
        pod: "zabxyzxyz",
    },
    {
        reference: "5432",
        enlevement_date: "20/12/2021",
        expediteur: "STU Corporation",
        pays_expediteur: "Royaume-Uni",
        ville_expediteur: "Londres",
        destinataire: "VWX Company",
        pays_destinataire: "Australie",
        ville_destinataire: "Sydney",
        date_livraison: "05/01/2023",
        tracking: "consectetur adipiscing elit",
        pod: "defxyzxyz",
    },
    {
        reference: "1357",
        enlevement_date: "03/02/2022",
        expediteur: "YZA Corporation",
        pays_expediteur: "Chine",
        ville_expediteur: "Pékin",
        destinataire: "BCD Company",
        pays_destinataire: "Japon",
        ville_destinataire: "Tokyo",
        date_livraison: "18/02/2023",
        tracking: "sed do eiusmod tempor",
        pod: "lmnxyzxyz",
    },
    {
        reference: "8642",
        enlevement_date: "15/03/2022",
        expediteur: "EFG Corporation",
        pays_expediteur: "Inde",
        ville_expediteur: "Mumbai",
        destinataire: "HIJ Company",
        pays_destinataire: "Afrique du Sud",
        ville_destinataire: "Le Cap",
        date_livraison: "30/03/2023",
        tracking: "incididunt ut labore et dolore",
        pod: "pqrxyzxyz",
    },
];

export const enteteTableData = [
    "Reference",
    "Date Enlèvement",
    "Expediteur",
    "Pays",
    "Ville",
    "Destinataire",
    "Pays",
    "Ville",
    "Date livraison",
    "Tracking",
    "Pod",
];

export const enteteContactData = [
    "Nom et Prénom(s)",
    "Email",
    "Téléphone",
    "Adresse",
    "Code postal",
    "Ville",
    "Pays",
   
];

export const contactData = [
    {
        nom: "Nom 1",
        prenom: "Prenom 1",
        email: "email1@example.com",
        telephone: "1111111111",
        adresse: "Adresse",
        ville: "Ville 1",
        pays: "Pays 1",
        code_postal: "11111",
    },
    {
        nom: "Nom 2",
        prenom: "Prenom 2",
        email: "email2@example.com",
        telephone: "2222222222",
        adresse: "Complément d'adresse",
        ville: "Ville 2",
        pays: "Pays 2",
        code_postal: "22222",
    },
    {
        nom: "Nom 3",
        prenom: "Prenom 3",
        email: "email3@example.com",
        telephone: "3333333333",
        adresse: "Adresse 3",
        ville: "Ville 3",
        pays: "Pays 3",
        code_postal: "33333",
    },
    {
        nom: "Nom 4",
        prenom: "Prenom 4",
        email: "email4@example.com",
        telephone: "4444444444",
        adresse: "Adresse 4",
        ville: "Ville 4",
        pays: "Pays 4",
        code_postal: "44444",
    },
    {
        nom: "Nom 5",
        prenom: "Prenom 5",
        email: "email5@example.com",
        telephone: "5555555555",
        adresse: "Adresse 5",
        ville: "Ville 5",
        pays: "Pays 5",
        code_postal: "55555",
    },
];
