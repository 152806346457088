import React, { useEffect } from "react";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import adminService from "../../services/admin/adminService";

function StateComponent({ data, type }) {

    // useEffect(() => {
    //     try {
    //         adminService.statisticData({type}).then(
    //             (response) => {
    //                 console.log(response)
    //             },
    //             (error) => {
    //                 console.log(error)
    //             }
    //         )
    //     } catch (error) {

    //     }
    // }, [])
    return (
        <div className="col-xl-4 col-md-6 d-flex">
            <div className={`card-holder ${data.bgColor}`}>
                <div className="main-balance-blk">
                    <div className="main-balance">
                        <h4>{data.libelle}</h4>
                        <h3>{data.nombre}</h3>
                    </div>
                    <div className="balance-bit-img">
                        <Player
                            autoplay
                            loop
                            src={data.iconUrl}
                            style={{ height: `${data.height}px`, width: `${data.width}px` }}
                        ></Player>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StateComponent;
