import { TbAddressBook, TbBarcode, TbCar, TbChartLine, TbFileInvoice, TbHistory, TbHomeDollar, TbInfoCircle, TbLayoutDashboard, TbPackage, TbSettings, TbUser, TbUserExclamation, TbUsers, TbUsersGroup } from "react-icons/tb";
import * as wi from "react-icons/wi";
import { IoIosTimer } from "react-icons/io";
import historiqueLogo from '../../../assets/img/Icones/historique/paris.svg'
export default {
    data: [
        {
            libelle: "Dashboard",
            lien: "/admin/dash",
            icon: <i><TbLayoutDashboard /></i>,
        },
        {
            libelle: "Services",
            color: "#098721"
        },
        {
            libelle: "Clients",
            lien: "/admin/dash/clients",
            icon: <i><TbUsersGroup /></i>,
        },
        {
            libelle: "Centres de facturation",
            lien: "/admin/dash/centre-facturation",
            icon: <i><TbHomeDollar /> </i>,
        },
        {
            libelle: "Utilisateurs",
            lien: "/admin/dash/utilisateurs",
            icon: <i><TbUsersGroup /></i>,
        },
        {
            libelle: "Contacts",
            lien: "/admin/dash/contacts",
            icon: <i><TbAddressBook /> </i>,
        },
        {
            libelle: "Fournisseurs",
            lien: "/admin/dash/soustraitants",
            icon: <i><TbUser /> </i>,
        },
        {
            libelle: "Le 24 Heures",
            lien: "/admin/dash/historiques",
            icon: <i><IoIosTimer /></i>,
        },
        {
            libelle: "Gestion facture",
            color: "#222"
        },
        {
            libelle: "Fuels",
            lien: "/admin/dash/facture/fuel",
            icon: <i><wi.WiRaindrop /> </i>,
        },
        {
            libelle: "Codes de facturation",
            lien: "/admin/dash/facture/code-facturation",
            icon: <i><TbBarcode /> </i>,
        },
        {
            libelle: "Statistiques",
            color: "#4361ee"
        },
        {
            libelle: "Courses",
            lien: "/admin/dash/statiques/expeditions",
            icon: <i><TbPackage /> </i>,
        },
        {
            libelle: "Freight",
            lien: "/admin/dash/statiques/freight",
            icon: <i><TbChartLine /> </i>,
        },
        {
            libelle: "Configuration / Gestion",
            color: "#ff595e"
        },
        {
            libelle: "Tracking templates",
            lien: "/admin/dash/tracking-template",
            icon: <i><TbFileInvoice /> </i>,
        },
        {
            libelle: "Type de transports",
            lien: "/admin/dash/transport-types",
            icon: <i><wi.WiRaindrop /> </i>,
        },
        {
            libelle: "Véhicules",
            lien: "/admin/dash/vehicules",
            icon: <i><TbCar /> </i>,
        },
        {
            libelle: "Salariés",
            lien: "/admin/dash/salaries",
            icon: <i><TbUser /> </i>,
        },
        {
            libelle: "Admin",
            lien: "/admin/dash/administrateurs",
            icon: <i><TbUserExclamation /> </i>,
        },
        {
            libelle: "OPS",
            lien: "/admin/dash/ops",
            icon: <i><TbUsers /> </i>,
        },
        {
            libelle: "Chauffeurs",
            lien: "/admin/dash/chauffeurs",
            icon: <i><TbUser /> </i>,
        },
        {
            libelle: "Information",
            lien: "/admin/dash/information",
            icon: <i><TbInfoCircle /> </i>,
        },
    ],
    configuration: {
        showExtra: false,
        showHelp: false,
    },
};
