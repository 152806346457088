import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { exnetSpaceColor, urlSplit } from '../../utils/Functions/otherFunctions';
import { TbArrowLeft, TbChevronLeft } from 'react-icons/tb';

function BackButton(props) {
    const navigate = useNavigate()
    const { pathname } = useLocation();
    const { exnetSpace, expeditionType, expedition } = urlSplit()

    const handleBack = () => {
        navigate(-1)
    }


    return (
        <div className='mb-10'>
            {pathname?.split('/')[pathname?.split('/').length - 1] !== "dash"
                &&
                <span className={' shadow  cursor-pointer w-[35px] d-flex items-center text-base h-[35px] rounded-full justify-center'}
                    onClick={handleBack}
                // style={{ color: '#fff', backgroundColor: `${exnetSpaceColor(exnetSpace)}` }}
                >
                    <span> <TbChevronLeft size={20} /></span>

                    {/* Retour */}
                </span>}

        </div>
    );
}

export default BackButton;