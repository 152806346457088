import React from 'react'
import { formatDefaultDate } from '../../utils';
import LoaderTableau from '../tableauComponent/LoaderTableau';

export default function Lists({ items, onSelect, onRowSelect, onDelete, isLoading }) {


    return (
        <div>
            {/* <h4 className="page-title">Liste</h4> */}
            <div className="card-box">
                <div className="card-block">
                    {/*                     <h4 className="card-title">Basic Table</h4>*/}
                    <div className="table-responsive">
                        <table className="table mb-0">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Description</th>
                                    <th>MAWB</th>
                                    <th>EX1</th>
                                    <th>VIAVOL</th>
                                    <th>DATE MANIFEST</th>
                                    <th>AJOUTE LE</th>
                                    <th>ACTIONS</th>
                                </tr>
                            </thead>
                            <tbody>
                                {isLoading ?
                                    <tr key="">
                                        <td colSpan={8}>
                                            <LoaderTableau />
                                        </td>
                                    </tr>
                                    : <>

                                        {items.map((item) => (
                                            <tr key={item.id} className="cursor-pointer py-2 text-lg">
                                                <td onClick={() => {
                                                    onSelect(item)
                                                }}>{item.id}</td>
                                                <td
                                                    onClick={() => {
                                                        onSelect?.(item)
                                                        onRowSelect?.(item)
                                                    }}
                                                >{item.description}</td>
                                                <td
                                                    onClick={() => {
                                                        onSelect?.(item)
                                                        onRowSelect?.(item)
                                                    }}
                                                >{item.mawb}</td>
                                                <td onClick={() => {
                                                    onSelect?.(item)
                                                    onRowSelect?.(item)
                                                }}>{item.ex1}</td>
                                                <td onClick={() => {
                                                    onSelect?.(item)
                                                    onRowSelect?.(item)
                                                }}>{item.viavol}</td>
                                                <td>{
                                                    formatDefaultDate(item.datemanifest)
                                                }</td>
                                                <td>{formatDefaultDate(item.created_at)}</td>
                                                <td>
                                                    <div className="flex items-center">
                                                        <div
                                                            className="text-green-600 cursor-pointer text-[14px] mr-5"
                                                            onClick={() => {
                                                                onSelect(item, true)
                                                            }} title="Editer"
                                                        >
                                                            <i className="fas fa-edit"></i>
                                                        </div>

                                                        <div
                                                            className="text-red-500 cursor-pointer text-[14px]"
                                                            onClick={() => {
                                                                onDelete(item)
                                                            }}
                                                            title="Supprimer"
                                                        >
                                                            <i className="fas fa-trash"></i>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </>}

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
