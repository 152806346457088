import React, { useState } from "react";
import Cookies from "js-cookie";
import useDisclosure from "../../hooks/useDisclosure";
import ModalComponent from "../../components/Utils/Modal/Index";
import "./../../components/accordionComponent/accordionStyle.css";
import "./styles.css";

export const AccordionCookies = ({
    title,
    children,
    isOpen,
    checkedState,
    setCheckedState,
    isActive,
}) => {
    const [isExpanded, setIsExpanded] = useState(isOpen);

    const toggleAccordion = () => {
        setIsExpanded(!isExpanded);
    };
    const handleCheckboxChange = (event) => {
        const { checked } = event.target;
        setCheckedState(checked);
    };
    return (
        <div className="accordion">
            <div
                className="accordion-header"
                style={{ fontSize: "16px" }}
                onClick={() => toggleAccordion()}
            >
                {" "}
                {title}{" "}
                <div className="form-check form-switch">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckChecked"
                        checked={checkedState}
                        onChange={handleCheckboxChange}
                        disabled={!isActive}
                    />
                </div>
            </div>

            {isExpanded && (
                <div className="accordion-content" style={{ fontSize: "16px" }}>
                    {children}
                </div>
            )}
        </div>
    );
};

const CookieBanner = () => {
    const [showBanner, setShowBanner] = useState(!Cookies.get("cookieConsent"));
    const [showPopup, setShowPopup] = useState(false);
    const { isOpen, onClose, onOpen } = useDisclosure();
    const [preferencesChecked, setPreferencesChecked] = useState(false);
    const [analyticsChecked, setAnalyticsChecked] = useState(false);

    const handleOpenPopup = () => {
        setShowPopup(true);
        onOpen(true);
    };

    const handleClosePopup = () => {
        setShowPopup(false);
        setShowBanner(false);
    };

    const acceptCookies = () => {
        Cookies.set("cookieConsent", "true", { expires: 365 }); // Set cookie consent for 1 year
        setShowBanner(false);
    };
    const declineCookies = () => {
        Cookies.set("cookieConsent", "false"); // Set a cookie indicating cookies are declined
        setShowBanner(false);
    };

    if (!showBanner && !showPopup) {
        return null;
    }

    const saveCookieSettings = () => {
        if (preferencesChecked) {
            Cookies.set("preferences", "true", { expires: 365 });
        } else {
            Cookies.remove("preferences");
        }

        if (analyticsChecked) {
            Cookies.set("analytics", "true", { expires: 365 });
        } else {
            Cookies.remove("analytics");
        }

        handleClosePopup();
    };
    return (
        <div>
            {showBanner && (
                <div
                
                    className="cookies-card"
                >
                    <p style={{ fontSize: "14px" }}>
                        {" "}
                        <strong
                            style={{ fontWeight: "bold", fontSize: "20px" }}
                        >
                            Nous utilisons des cookies
                        </strong>{" "}
                        <br /> <br />
                        Nous utilisons des cookies, y compris des cookies tiers,
                        à des fins de fonctionnement, d’analyses statistiques,
                        de personnalisation de votre expérience, afin de vous
                        proposer des contenus ciblés adaptés à vos centres
                        d’intérêts et analyser la performance de nos campagnes
                        publicitaires. <br /> Vous pouvez accepter ces cookies
                        en cliquant sur « Accepter tout » ou cliquer sur{" "}
                        <a href="#" onClick={handleOpenPopup}>
                            <strong>Personnaliser mes choix</strong>
                        </a>{" "}
                        pour gérer vos préférences. <br /> Pour en savoir plus
                        sur les catégories de cookies ainsi que sur les
                        émetteurs de cookie sur notre site, consultez ici notre
                        notice dédiée à la{" "}
                        <a href="https://www.prepod2-dev.fr/politiques">
                            gestion des cookies
                        </a>{" "}
                        <br />
                        <br />
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <button
                                        style={{
                                            backgroundColor: "#4D4B62",
                                            color: "#fff",
                                        }}
                                        onClick={acceptCookies}
                                        className="col-md-12 py-2"
                                    >
                                        Accepter tout
                                    </button>
                                </div>
                                <div className="col-md-6">
                                    <button
                                        style={{
                                            backgroundColor: "#D3D3D3",
                                            padding: 4,
                                            color: "#4D4B62",
                                        }}
                                        onClick={declineCookies}
                                        className="col-md-12 py-2"
                                    >
                                        Refuser
                                    </button>
                                </div>
                            </div>
                        </div>
                    </p>
                </div>
            )}
            {showPopup && (
                <ModalComponent
                    isOpen={isOpen}
                    onClose={onClose}
                    size="l"
                    title="Cookie settings"
                >
                    <div className="container">
                        <div className="row">
                            <p style={{ fontSize: "16px" }}>
                                {" "}
                                <strong
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "20px",
                                    }}
                                >
                                    Utilisation des cookies
                                </strong>{" "}
                                <br /> <br /> Nous utilisons des cookies pour
                                assurer les fonctionnalités de base du site Web
                                et pour améliorer votre expérience en ligne.
                                Vous pouvez choisir pour chaque catégorie de
                                vous inscrire/désactiver quand vous le
                                souhaitez. Pour plus de détails sur les cookies
                                et autres données sensibles, veuillez lire
                                l'intégralité de la{" "}
                                <a href="https://www.prepod2-dev.fr/politiques">
                                    politique de confidentialité
                                </a>
                            </p>

                            <div style={{ padding: 6 }}>
                                <AccordionCookies
                                    title="Cookies strictement nécessaires"
                                    children="Ces cookies sont indispensables au bon fonctionnement de mon site internet. Sans ces cookies, le site Web ne fonctionnerait pas correctement"
                                    isOpen={true}
                                    isActive={false}
                                ></AccordionCookies>
                                <br />
                                <AccordionCookies
                                    title="Cookies de préférences"
                                    children="Ces cookies permettent au site Web de se souvenir des choix que vous avez faits dans le passé"
                                    isOpen={false}
                                    checkedState={preferencesChecked}
                                    setCheckedState={setPreferencesChecked}
                                    isActive={true}
                                ></AccordionCookies>
                                <br />
                                <AccordionCookies
                                    title="Cookies analytiques"
                                    children="Ces cookies collectent des informations sur la façon dont vous utilisez le site Web, les pages que vous avez visitées et les liens sur lesquels vous avez cliqué. Toutes les données sont anonymisées et ne peuvent pas être utilisées pour vous identifier"
                                    isOpen={false}
                                    checkedState={analyticsChecked}
                                    setCheckedState={setAnalyticsChecked}
                                    isActive={true}
                                ></AccordionCookies>
                            </div>

                            {/* <button onClick={handleClosePopup}>Close</button> */}
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-5">
                                        <button
                                            style={{
                                                backgroundColor: "#4D4B62",
                                                color: "#fff",
                                                fontSize: "16px",
                                            }}
                                            onClick={handleClosePopup}
                                            className="col-md-6 py-2"
                                        >
                                            Accepter tout
                                        </button>
                                    </div>
                                    <div className="col-md-7 d-flex flex-row-reverse">
                                        <button
                                            style={{
                                                backgroundColor: "#D3D3D3",
                                                padding: 4,
                                                color: "#4D4B62",
                                                fontSize: "16px",
                                            }}
                                            // onClick={declineCookies}
                                            className="col-md-6 py-2"
                                            onClick={saveCookieSettings}
                                        >
                                            Enregistrer les parametres
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalComponent>
            )}
        </div>
    );
};

export default CookieBanner;
