import React, { useCallback, useEffect, useState } from "react";
import Select from "react-select";
import CountriesSelector from "../Utils/Selectors/CountriesSelector";
import useAuth from "../../hooks/useAuth";
import { useLocation } from "react-router-dom";
import {
  VerifyStep_2,
  expeditorReceiverValidator,
} from "../../utils/Functions/verifyDropdown";
import axios from "axios";
import CitiesSelector from "../Utils/Selectors/CitiesSelector";
import ZipCodesSelector from "../Utils/Selectors/ZipCodesSelector";
import { toast } from "react-toastify";
import { ContactFormInput } from "../contact/CarnetAddress";
import { getCountryNameOrCode } from "../../utils/const/countriesConstant";
import {
  exnetSpaceColor,
  urlSplit,
} from "../../utils/Functions/otherFunctions";
import useCountries from "../../states/countries";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/plain.css";
import { InputNomSearch } from "../contact/InputNomSearch";
import CivilitySelector from "../Utils/Selectors/CivilitySelector";
import PhoneNumber from "libphonenumber-js";
import InputComponent from "../InputComponent/InputComponent";
import clsx from "clsx";
import PhoneInputComponent from "../phoneInputComonent/PhoneInputComonent";

const options = [
  { value: "M", label: "Monsieur" },
  { value: "Mme", label: "Madame" },
];

function NewExpeditionExpeditorComponent({
  expeditor,
  setExpeditor,
  toggleAccordion,
  resetData,
  contactType,
  exnetSpace,
}) {
  const { role } = urlSplit();
  const [changeCountry, setChangeCountry] = useState(false)
  const getValue = (value1, value2) =>
    value1 !== undefined && value1 !== ""
      ? value1
      : value2 !== undefined && value2 !== ""
        ? value2
        : "";

  const { countries: ListCountries, setCountries: setListCountries } =
    useCountries();

  const [UserStateData, setUserStateData] = useState({
    civility: { value: "M", label: "Monsieur" },
    company: "",
    fullname: "",
    address1: "",
    address2: "",
    country: "fr",
    postalCode: "",
    city: "",
    phonecode: "",
    phone1: "+33",
    phone2: "+33",
    email: "",
    update_contact: 0,
  });

  const [errorInformation, setErrorInformation] = useState({
    field: "",
    message: "",
  });

  const clearAll = () => {
    setUserStateData({
      civility: { value: "M", label: "Monsieur" },
      company: "",
      fullname: "",
      lastName: "",
      firstName: "",
      address1: "",
      address2: "",
      country: "fr",
      postalCode: "",
      city: "",
      phonecode: "",
      phone1: "+33",
      phone2: "+33",
      email: "",
      update_contact: 0,
    });
  };

  // postalCode villep paysp
  const { isAuthenticated, user } = useAuth();
  const { pathname } = useLocation();

  const [countryInfo, setcountryInfo] = useState({});

  const updateExpeditorData = (element, valeur) => {
    if (element === "name") {
      let value =
        expeditor?.fullname && expeditor?.fullname !== ""
          ? expeditor.fullname
          : expeditor?.name;
      let firstName =
        getValue(expeditor?.fullname, expeditor?.name).split(" ")[0] ??
        expeditor?.firstName;
      let lastName =
        getValue(expeditor?.fullname, expeditor?.name).split(" ")[1] &&
          getValue(expeditor?.fullname, expeditor?.name).split(" ")[1] !==
          "undefined"
          ? getValue(expeditor?.fullname, expeditor?.name).split(" ")[1]
          : expeditor?.lastName;
      setExpeditor({
        ...expeditor,
        fullname: `${valeur} ${value?.split(" ")[1]}`,
        name: valeur,
        firstName: firstName,
        lastName: lastName,
      });
    } else if (element === "fullname") {
      let value =
        expeditor?.fullname && expeditor?.fullname !== ""
          ? expeditor.fullname
          : expeditor?.name;
      let firstName =
        getValue(expeditor?.fullname, expeditor?.name).split(" ")[0] ??
        expeditor?.firstName;
      let lastName =
        getValue(expeditor?.fullname, expeditor?.name).split(" ")[1] &&
          getValue(expeditor?.fullname, expeditor?.name).split(" ")[1] !==
          "undefined"
          ? getValue(expeditor?.fullname, expeditor?.name).split(" ")[1]
          : expeditor?.lastName;
      setExpeditor({
        ...expeditor,
        fullname: `${value?.split(" ")[0]} ${valeur}`,
        firstName: firstName,
        lastName: lastName,
      });
    } else {

      // setChangeCountry(element === 'country' ? true : false)

      let data = { ...expeditor };
      data[element] = valeur;

      setExpeditor({ ...data });
    }

  };

  const handleNext = () => {
    const { valid, error, errorData } =
      expeditorReceiverValidator(UserStateData);
    console.log(expeditorReceiverValidator(UserStateData))
    setExpeditor({ ...expeditor, ...UserStateData });

    if (error) {
      setErrorInformation({ ...errorData });
      // toast.error(error, {
      //     position: "top-center",
      //     autoClose: 500,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //     theme: "light",
      // });
    } else {
      if (
        validatePhoneNumber(
          UserStateData?.phone1,
          getCountryNameOrCode(
            getCountryNameOrCode(UserStateData?.country),
            true
          ) ?? "fr"
        )
      ) {
        if (
          UserStateData?.phone2 &&
          UserStateData?.phone2 !== "null" &&
          UserStateData?.phone2 !== null &&
          (UserStateData?.phone2).length >= 5 &&
          validatePhoneNumber(
            UserStateData?.phone2,
            getCountryNameOrCode(
              getCountryNameOrCode(UserStateData?.country),
              true
            ) ?? "fr"
          )
        ) {
          toggleAccordion(contactType === "expeditor" ? 3 : 4);
        } else if (
          UserStateData?.phone2 &&
          (UserStateData?.phone2).length > 0 &&
          (UserStateData?.phone2).length < 5
        ) {
          setErrorInformation({
            field: "phone2",
            message: "Erreur lors de la validation du numéro de téléphone",
          });
        } else {
          toggleAccordion(contactType === "expeditor" ? 3 : 4);
        }
      } else {
        setErrorInformation({
          field: "phone1",
          message: "Erreur lors de la validation du numéro de téléphone",
        });
      }
    }
  };

  const [search, setSearch] = useState("");

  const onContactSelect = useCallback((contact) => {
    updateExpeditorData(
      "phonecode",
      getCountryNameOrCode(getCountryNameOrCode(contact?.country), true)
    );
    updateExpeditorData("country", getCountryNameOrCode(contact?.country));

    setExpeditor({
      ...contact,
      name: contact.fullname,
      email: contact.email2,
      fullname: contact.fullname,
      phone1: contact.telephone1,
      phone2: contact.telephone2,
      contact_id: contact.id,
      phonecode: contact.num_code,
      postalCode: contact.postal_code,
      firstName: contact.fullname.split(" ")[0] ?? contact?.firstName,
      lastName: contact.fullname.split(" ")[1] ?? contact?.lastName,
    });

    let newValue = {
      ...contact,
      email: contact.email1,
      fullname: contact.fullname,
      phone1: contact.telephone1,
      phone2: contact.telephone2,
      contact_id: contact.id,
      phonecode: contact.num_code,
      postalCode: contact.postal_code,
      firstName: contact.fullname.split(" ")[0] ?? contact?.firstName,
      lastName:
        contact.fullname.split(" ")[1] &&
          contact.fullname.split(" ")[1] !== "undefined"
          ? contact.fullname.split(" ")[1]
          : contact?.lastName,
    };

    setUserStateData(newValue);
  }, []);

  const validatePhoneNumber = (number, code) => {
    try {
      const phone = PhoneNumber(number, code);

      return phone.isValid();
    } catch (error) {
      return false;
    }
  };

  const fetchPhonecode = async (countryCode) => {
    try {
      if (
        countryCode &&
        countryCode !== null &&
        countryCode !== undefined &&
        countryCode !== ""
      ) {
        const response = await axios.get(
          `https://restcountries.com/v3.1/alpha/${countryCode ? countryCode : "fr"
          }`
        );
        const countryData = response.data[0];

        if (countryData && countryData.idd) {
          const iddRoot = countryData.idd.root;
          const iddSuffixes = countryData.idd.suffixes[0];

          const completeIdd = `${iddRoot}${iddSuffixes}`;
          return completeIdd;
        } else {
          console.log("Country code not found");
        }
      }
    } catch (error) {
      console.error("Error fetching country IDD:", error);
    }
  };

  const handlePhoneCode = (countryCode) => {
    if (countryCode) {
      fetchPhonecode(countryCode)
        .then((completeIdd) => {
          updateExpeditorData("phonecode", completeIdd);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  const handleData = useCallback(() => {
    // Fonction d'utilitaire pour vérifier si la valeur existe et n'est pas vide

    // Utilisation de la fonction d'utilitaire pour créer le nouvel objet
    let newValue = {
      civility: expeditor?.civility,
      company: getValue(expeditor?.company, expeditor?.entreprise_physique),
      fullname: getValue(expeditor?.fullname, expeditor?.name),
      address1: expeditor?.address1,
      address2: expeditor?.address2,
      country: expeditor?.country,
      postalCode: getValue(expeditor?.postalCode, expeditor?.postal_code),
      city: expeditor?.city,
      phonecode: expeditor?.phonecode,
      phone1: getValue(expeditor?.phone1, expeditor?.telephone1),
      phone2: getValue(expeditor?.phone2, expeditor?.telephone2),
      email: expeditor?.email,
      firstName:
        expeditor?.firstName ?? getValue(expeditor?.fullname, expeditor?.name).split(" ")[0],
      lastName: expeditor?.lastName ?? (
        getValue(expeditor?.fullname, expeditor?.name).split(" ")[1] &&
          getValue(expeditor?.fullname, expeditor?.name).split(" ")[1] !==
          "undefined"
          ? getValue(expeditor?.fullname, expeditor?.name).split(" ")[1]
          : ''),
    };

    setUserStateData({ ...newValue });

    setExpeditor({
      ...expeditor,
      ...newValue,
    });
  }, []);

  const updateUserData = (val1, val2 = false) => {

    let newValue = { ...UserStateData };
    let { key1, value1 } = val1;
    let { key2, value2 } = val2;
    setChangeCountry(key1 === 'country' ? true : false)

    if (key1) {
      newValue[key1] = value1;
      setUserStateData({ ...newValue });
      setExpeditor({ ...expeditor, ...newValue });
    } else if (
      value1 &&
      key1 &&
      key1 !== "" &&
      value2 &&
      value2 !== "" &&
      key2
    ) {
      newValue[key1] = value1;
      newValue[key2] = value2;
      setUserStateData({ ...newValue });
      setExpeditor({ ...expeditor, ...newValue });
    }
  };

  useEffect(() => {
    handleData();

    // setTimeout(() => {
    //     updateUserData({ key1: "phone1", value1: UserStateData?.phone1 }, { key2: "phone2", value2: UserStateData?.phone2 })
    // }, 1000);
  }, [contactType]);

  useEffect(() => {
    if (countryInfo) {
      handlePhoneCode(countryInfo.value);
    }
  }, [countryInfo]);

  return (
    <div className="content">
      <div className="row">
        <div className="col-md-12">
          <form action="#">
            <div className="card-box">
              <div className="row">
                <div className="col-md-4 ">
                  <div className="form-group">
                    <CivilitySelector
                      showLabel={false}
                      disabled={false}
                      value={UserStateData.civility}
                      onChange={(v) =>
                        updateUserData({ key1: "civility", value1: v })
                      }
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <ContactFormInput
                      placeholder="RECHERCHER CONTACT"
                      value={search}
                      onChange={setSearch}
                      onSelect={onContactSelect}
                      attribute={"company"}
                      client={role === "client" ? true : false}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <button
                      type={"button"}
                      className="w-full btn "
                      style={{
                        color: "#fff",
                        backgroundColor: `${exnetSpaceColor(exnetSpace)}`,
                      }}
                      onClick={clearAll}
                    >
                      NOUVELLE FICHE
                    </button>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <InputNomSearch
                          placeholder="NOM*"
                          value={UserStateData?.firstName}
                          onChange={(v) => {
                            let newValue =
                              v + " " + UserStateData?.fullname?.split(" ")[1];
                            updateUserData({ key1: "firstName", value1: v });
                          }}
                          onSelect={onContactSelect}
                          attribute={"fullname"}
                          client={role === "client" ? true : false}
                          isError={
                            errorInformation.field === "firstName"
                              ? true
                              : false
                          }
                          errorMessage={errorInformation.message}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <InputNomSearch
                          placeholder={"PRéNOM*".toUpperCase()}
                          value={UserStateData?.lastName}
                          onChange={(v) => {
                            let newValue =
                              UserStateData?.fullname?.split(" ")[0] + " " + v;
                            updateUserData({ key1: "lastName", value1: v });
                          }}
                          onSelect={onContactSelect}
                          attribute={"fullname"}
                          client={role === "client" ? true : false}
                          isError={
                            errorInformation.field === "lastName" ? true : false
                          }
                          errorMessage={errorInformation.message}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <InputNomSearch
                      placeholder="ENTREPRISE (FACULTATIVE)"
                      value={UserStateData?.company}
                      onChange={(v) => {
                        updateUserData({
                          key1: "company",
                          value1: v !== "" ? v : "",
                        });
                      }}
                      onSelect={onContactSelect}
                      attribute={"company"}
                      client={role === "client" ? true : false}
                      isError={
                        errorInformation.field === "company" ? true : false
                      }
                      errorMessage={errorInformation.message}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="d-flex" style={{ gap: 5 }}>
                    <div className="form-group" style={{ flex: 1 }}>
                      <InputComponent
                        value={UserStateData.address1}
                        onChange={(valeur) =>
                          updateUserData({ key1: "address1", value1: valeur })
                        }
                        errorMessage={errorInformation.message}
                        placeholder={"Adresse*".toUpperCase()}
                        label=""
                        withLabel={false}
                        isError={
                          errorInformation.field === "address1" ? true : false
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group" style={{ flex: 4 }}>
                    <input
                      type="text"
                      className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                      placeholder={"Complément d'adresse".toUpperCase()}
                      value={UserStateData.address2}
                      onChange={(e) => {
                        updateUserData({
                          key1: "address2",
                          value1: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="row pe-0">
                  <div className="col-md-6 pe-0">
                    <div className="row">
                      <div className="col-12">
                        <div className="form-group">
                          <CountriesSelector
                            selectedValue={
                              UserStateData.country
                                ? UserStateData.country.toLowerCase()
                                : "fr"
                            }
                            onSelect={(v) => {
                              if (v.label) {
                                updateUserData({
                                  key1: "country",
                                  value1: v.label,
                                });
                                updateExpeditorData("country", v.label);
                                setcountryInfo(v);
                              }
                            }}
                            showLabel={false}
                            isError={
                              errorInformation.field === "country"
                                ? true
                                : false
                            }
                            errorMessage={errorInformation.message}
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="d-flex" style={{ gap: 5 }}>
                          <div className="form-group" style={{ flex: 4 }}>
                            <ZipCodesSelector
                              defaultValue={UserStateData?.postalCode}
                              country_code={
                                getCountryNameOrCode(
                                  getCountryNameOrCode(UserStateData?.country),
                                  true
                                ) ?? "fr"
                              }
                              onSelect={(e) => {
                                updateUserData({
                                  key1: "postalCode",
                                  value1: e,
                                });
                              }}
                              showLabel={false}
                              isError={
                                errorInformation.field === "postalCode"
                                  ? true
                                  : false
                              }
                              errorMessage={errorInformation.message}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group">
                          <CitiesSelector
                            defaultValue={UserStateData.city}
                            country_code={getCountryNameOrCode(
                              getCountryNameOrCode(UserStateData.country),
                              true
                            )}
                            postalCode={UserStateData.postalCode}
                            onSelect={(e) => {
                              updateUserData({ key1: "city", value1: e });
                            }}
                            isError={
                              errorInformation.field === "city" ? true : false
                            }
                            errorMessage={errorInformation.message}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 ps-4 pe-0">
                    <div className={"row"}>
                      <div className="col-12">
                        {/* expeditor?.phonecode */}
                        <div className="form-group" style={{ flex: 1 }}>
                          <PhoneInputComponent
                            isChangeCountry={changeCountry}
                            value={UserStateData?.phone1}
                            country={UserStateData?.country}
                            onChange={(value, country, e, formattedValue) => {
                              updateUserData(
                                { key1: "phone1", value1: formattedValue },
                                { key2: "phonecode", value2: country?.dialCode }
                              );
                            }}
                            errorInformation={errorInformation}
                          />
                          {errorInformation.field === "phone1" && (
                            <p
                              className="mt-2 text-sm text-red-600"
                              id="email-error"
                            >
                              {errorInformation.message}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <PhoneInput
                            country={
                              getCountryNameOrCode(
                                getCountryNameOrCode(UserStateData?.country),
                                true
                              ) ?? "fr"
                            }
                            value={UserStateData?.phone2}
                            onChange={(value, country, e, formattedValue) => {
                              updateUserData({
                                key1: "phone2",
                                value1: formattedValue,
                              });
                            }}
                            enableSearch={true}
                            inputClass={"form-controle"}
                            countryCodeEditable={true}
                            disableDropdown={false}
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <InputComponent
                            type={"email"}
                            value={UserStateData.email?.toLowerCase()}
                            onChange={(valeur) => {
                              let newValue = valeur?.toLowerCase()
                              updateUserData({ key1: "email", value1: newValue })
                            }

                            }
                            errorMessage={errorInformation.message}
                            placeholder={"EMAIL*"}
                            label=""
                            withLabel={false}
                            isError={
                              errorInformation.field === "email" ? true : false
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <div className="checkbox">
                      <label className={"selection"}>
                        <input
                          // save-contact
                          onChange={(e) =>
                            updateUserData({
                              key1: "save-contact",
                              value1: !expeditor["save-contact"] ? 1 : 0,
                            })
                          }
                          checked={expeditor["save-contact"]}
                          type="checkbox"
                          name="checkbox"
                        />{" "}
                        ENREGISTRER CE CONTACT DANS MON CARNET D’ADRESSES
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <div className="checkbox">
                      <label className={"selection"}>
                        <input
                          type="checkbox"
                          name="checkbox"
                          onChange={(e) =>
                            updateUserData({
                              key1: "update-contact",
                              value1: !expeditor["update-contact"] ? 1 : 0,
                            })
                          }
                          checked={expeditor["update-contact"]}
                        />{" "}
                        MODIFIER CE CONTACT DANS MON CARNET D’ADRESSES
                      </label>
                    </div>
                  </div>
                </div>

                <div className="row " style={{ justifyContent: "flex-end" }}>
                  <div className="col-md-6"></div>
                  <div className="col-md-6">
                    <div className={"row"}>
                      <div className="col-6">
                        <div className="form-group w-100">
                          <button
                            className="btn w-100"
                            onClick={() =>
                              toggleAccordion(
                                contactType === "expeditor" ? 1 : 2
                              )
                            }
                            style={{
                              color: "#fff",
                              backgroundColor: `${exnetSpaceColor(exnetSpace)}`,
                            }}
                          >
                            Précédent
                          </button>
                        </div>
                      </div>

                      <div className="col-6">
                        <div className="form-group w-100">
                          <button
                            type="button"
                            className="btn w-100"
                            onClick={handleNext}
                            style={{
                              color: "#fff",
                              backgroundColor: `${exnetSpaceColor(exnetSpace)}`,
                            }}
                          >
                            Suivant
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default NewExpeditionExpeditorComponent;
