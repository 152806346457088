import React from "react";

function UserStateComponent({ data }) {
    return (
        <div className="col-xl-3 col-md-6">
            <div className="transaction-record blue-view">
                <h2>{data.nombre}</h2>
                <p>{data.libelle}</p>
            </div>
        </div>
    );
}

export default UserStateComponent;
