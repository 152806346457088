import React from 'react';
import { LineComponent, ResumeComponentLayout } from './resumeComponentLayout';

function ShipmentDetails(props) {
    return (
        <>
            <ResumeComponentLayout medium={false} title={"détails de l'enlèvement et de la livraison"} onClick={() => console.log("sdfdsf")} >

                <div>
                    <LineComponent value={'Deux roues'} label={"Type de prestation"} />

                    <LineComponent value={'3 Heures'} label={"Délais de livraison"} />
                </div>
                <div className='grid-cols-2 gap-3 space-y-4 md:grid md:space-y-0'>

                    <div>
                        <LineComponent value={'Deux roues'} label={"Date d'enlèvement"} />

                        <LineComponent value={'3 Heures'} label={"Heure d'enlèvement"} />
                    
                        <div className=''>
                            <span className='block font-bold text-slate-400 '>
                               "Instruction d'enlèvement"
                            </span>
                            <p className=''>
                               "xssqsqsq"
                            </p>
                        </div>
                    </div>

                    <div className='md:border-l md:ps-3 md:border-l-black' >
                        <LineComponent value={'Deux roues'} label={"Date de livraison"} />

                        <LineComponent value={'3 Heures'} label={"Heure de livraison"} />

                        <div className=''>
                            <span className='block font-bold text-slate-400 '>
                               "Instruction de livraison"
                            </span>
                            <p className=''>
                                "jdnsn"
                            </p>
                        </div>
                    </div>

                </div>
            </ResumeComponentLayout>
        </>
    );
}

export default ShipmentDetails;