import clsx from 'clsx';
import React from 'react';
import { TbEdit } from 'react-icons/tb';

export const ResumeComponentLayout = ({ title, onClick, children,medium=true }) => {
    return (
        <section className='p-2 rounded-md bg-slate-50 '>
            <ResumeComponentHeader medium={medium} title={title} onClick={onClick} />

            <div>
                {children}
            </div>
        </section>
    );
}





function ResumeComponentHeader({ title, onClick,medium }) {
    return (
        <div className='flex items-center justify-end ' >
            <div className={ "w-full relative "} >
                <h2 className="pt-2 font-semibold text-center uppercase">{title}</h2>
                <span className='absolute p-0 top-2 right-1 '>
                    <TbEdit className='py-0 my-0 text-2xl cursor-pointer ' onClick={onClick} />
                </span>
            </div>
        </div>
    );
}


export const LineComponent = ({ label, value }) => {

    return (
        <div className='flex items-center justify-between'>
            <span className='block font-bold text-slate-400 '>
                {label}
            </span>
            <span className='block font-bold '>
                {value}
            </span>
        </div>
    )
}