import React, { useState } from 'react'
import useDisclosure from '../../../hooks/useDisclosure';
import EmployeeFormModal from './Form';
import useAdminLists from '../../../hooks/useAdminLists';
import { formatDefaultDate } from '../../../utils/moment';
import { TableLoader } from '../../Utils/Loader';
import PaginationComponent from '../../paginationComponent/PaginationComponent';
import useTableFilter from '../../../hooks/useTableFilter';
import { TbArrowsUpDown } from 'react-icons/tb';

export const EmployeesView = () => {
    return <MainView title="Liste des salariés" type="salaries" />
}

export const SoustraitantsView = () => {
    return <MainView title="Liste des fournisseurs" type="soustraitants" />
}

export const DriversView = () => {
    return <MainView title="Liste des chauffeurs" type="chauffeur" />
}

export default function MainView({ title, type = "salaries" }) {
    const { isOpen, onClose, onOpen } = useDisclosure();

    const {
        isOpen: isDeleteConfirmOpen,
        onClose: onDeleteConfirmClose,
        onOpen: onDeleteConfirmOpen
    } = useDisclosure();


    const { adminData, metadata, setPage, setPerPage, isLoading, onSubmit } = useAdminLists({ type });
    const [searchValue, setSearchValue] = useState("");
    const [selectedItem, setSelectedItem] = useState(null);

    const filteredLists = adminData.data.filter(item => {
        const matchingFirstName = item.prenom.includes(searchValue)
        const matchingLastName = item.nom.includes(searchValue)
        const matchingEmail = item.email.includes(searchValue)
        return matchingFirstName || matchingLastName || matchingEmail
    })

    const onItemSelected = (item, action) => {
        setSelectedItem(item)
        if (action === "edit") {
            onOpen()
        } else if (action === "delete") {
            //alert("Delete")
            onDeleteConfirmOpen()
        } else {

        }
    }

    return (
        <div>
            <div className="flex items-center justify-between">
                <div>
                    <h4 className="uppercase page-title">{title}</h4>
                    <div className="min-w-[350px]">
                        <input
                            className="w-full px-4 py-2 mb-3 bg-white border border-gray-500 rounded-lg outline-none form-control"
                            type="search"
                            placeholder="Recherche"
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                        />
                    </div>
                </div>
                <div
                    className="float-right text-white bg-black btn btn-rounded"
                    onClick={onOpen}
                >
                    <i className="fas fa-plus"></i>
                </div>
            </div>

            <div className="py-3">
                {adminData.total ?
                    <PaginationComponent
                        page={metadata.page}
                        handlePageClick={setPage}
                        totalItems={adminData.total}
                        perPageItems={metadata.perPage}
                        setnumberPerPage={setPerPage}
                    /> : null}
            </div>

            <Lists data={filteredLists} onSelect={onItemSelected} isLoading={isLoading} />
            <div className="py-3">
                {adminData.total ?
                    <PaginationComponent
                        page={metadata.page}
                        element='pagination'
                        handlePageClick={setPage}
                        totalItems={adminData.total}
                        perPageItems={metadata.perPage}
                        setnumberPerPage={setPerPage}
                    /> : null}
            </div>
            <EmployeeFormModal
                isOpen={isOpen}
                onClose={() => {
                    setSelectedItem(null)
                    onClose()
                }}
                onSubmit={onSubmit}
                selectedData={selectedItem}
                type={type}
            />

        </div>
    )
}


const Lists = ({ data, onSelect, isLoading }) => {
    const { results, onTableHeaderClick } = useTableFilter({ items: data });

    const HEADERS = [
        { label: 'ID', key: 'id' },
        { label: 'Nom', key: 'nom' },
        { label: 'Prénoms', key: 'prenom' },
        { label: 'Email', key: 'email' },
        { label: 'Téléphone', key: 'telephone' },
        { label: 'DATE DE CRÉATION', key: 'created_at' },
        /* { label: 'Action', key: null }, */
    ];

    return (
        <div className="relative overflow-x-auto">
            <div className="bg-white card-box">
                <div className="card-block">
                    {/*                     <h4 className="card-title">Basic Table</h4>*/}
                    <div className="table-responsive">
                        <table className="table mb-0">
                            <thead>
                                <tr className='uppercase'>
                                    {HEADERS.map((h, i) => (
                                        <th key={i} className="text-left cursor-pointer" onClick={() => {
                                            onTableHeaderClick(h.key)
                                        }}>
                                            <div className="flex items-center">
                                                <span className='mr-2'>
                                                    {h.label}
                                                </span>
                                                {h.key && <span>
                                                    <TbArrowsUpDown />
                                                </span>}
                                            </div>

                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {results.length > 0 && (
                                    results.map((item, i) => {
                                        return (
                                            <tr key={i} className="py-3">
                                                <td className={'cursor-pointer'}
                                                    onClick={() => {
                                                        onSelect(item, "edit")
                                                    }}
                                                >{item.id}</td>
                                                <td className={'cursor-pointer'}
                                                    onClick={() => {
                                                        onSelect(item, "edit")
                                                    }}
                                                >{item.nom}</td>
                                                <td className={'cursor-pointer'}
                                                    onClick={() => {
                                                        onSelect(item, "edit")
                                                    }}
                                                >{item.prenom}</td>
                                                <td className={'cursor-pointer'}
                                                    onClick={() => {
                                                        onSelect(item, "edit")
                                                    }}
                                                >{item.email}</td>
                                                <td className={'cursor-pointer'}
                                                    onClick={() => {
                                                        onSelect(item, "edit")
                                                    }}
                                                >{item.telephone}</td>
                                                <td className={'cursor-pointer'}
                                                    onClick={() => {
                                                        onSelect(item, "edit")
                                                    }}
                                                >{formatDefaultDate(item.created_at)}</td>
                                                {/* <td>
                                                    <SwitchComponent checked={Boolean(item.actif)} />
                                                </td> */}
                                                <td>
                                                    <div className="flex items-center">
                                                        {/* <div
                                                            className="text-green-600 cursor-pointer text-[14px] mr-5"
                                                            onClick={() => {
                                                                onSelect(item, "edit")
                                                            }} title="Editer"
                                                        >
                                                            <i className="fas fa-edit"></i>
                                                        </div> */}

                                                        {/* <div
                                                            className="text-red-500 cursor-pointer text-[14px]"
                                                            onClick={() => {
                                                                onSelect(item, "delete")
                                                            }}
                                                            title="Supprimer"
                                                        >
                                                            <i className="fas fa-trash"></i>
                                                        </div> */}
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                )}
                            </tbody>
                        </table>
                        {isLoading ? <TableLoader /> : results.length === 0 ? (
                            <h6 className="my-5 text-lg text-center">Aucune donnée</h6>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    )
}