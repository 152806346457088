import React, { useEffect, useState } from "react";
import Select from "react-select";
import { userService } from "../../../services/user/UserService";

export default function UsersSelector({
    selectedValue,
    onSelect,
    showLabel = true,
    isDisabled,
    profil = "chauffeur",
    label,
    isMulti,
    ...props
}) {
    const [drivers, setDrivers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(1)

    useEffect(() => {
        const fetchDrivers = async () => {
            setIsLoading(true);
            const { data } = await userService.adminGetUserList({
                profil,
                type: "all",
                per_page: 50,
                page
            });

            if (data.success) {
                setDrivers(d => {
                    const items = page === 1 ? data.data.data : [...d, ...data.data.data]
                    return items.map((c) => ({
                        ...c,
                        label: c.name,
                        value: c.id,
                    }))
                }
                );
                setIsLoading(false);
                if (page < parseInt(data.data.last_page)) {
                    setPage(page + 1)
                }
            }
        };
        fetchDrivers();
    }, [profil, page]);

    const value = isMulti && Array.isArray(selectedValue) ? drivers.filter((c) => selectedValue?.includes(c?.value)) : drivers.find((c) => c?.value === selectedValue);

    return (
        <div>
            {showLabel && (
                <label
                    for="drivers"
                    class="block mb-2 text-sm font-medium text-gray-900  capitalize"
                >
                    {label || profil}
                </label>
            )}
            <Select
                placeholder={`Choix du  ${profil !== "client" ? profil : "client"}*`.toUpperCase()}
                options={drivers}
                value={value}
                onChange={(v) => {
                    onSelect?.(v);
                }}
                isDisabled={isDisabled}
                className="text-base"
                isMulti={isMulti}
                {...props}
            />
        </div>
    );
}
