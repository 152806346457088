import React, { useEffect } from 'react'
import useAuth from './useAuth'
import AuthService from '../services/auth/AuthService';
import { userService } from '../services/user/UserService';

export default function useFetchUserData() {
    const { setUser } = useAuth();

    useEffect(() => {
        (async () => {
            const token = localStorage.getItem("client");

            if (token) {
                AuthService.setHeader(token);
                const { data } = await userService.getUserData();
                //console.log("DTA=====", data)
                if (data) {
                    setUser(data)
                }
            }
        })()

    }, [setUser])


}
