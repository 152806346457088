import React, { useCallback, useState } from 'react'
import useDisclosure from '../../hooks/useDisclosure';
import Form from './UsersForm';
import useAdminLists from '../../hooks/useAdminLists';
import { formatDefaultDate } from '../../utils/moment';
import { formatInterface } from '../../utils';
import { TableLoader } from '../Utils/Loader';
import { SwitchComponent } from '../Utils/Index';
import GeneralStateComponent from '../generalStateComponent/GeneralStateComponent';
import { getCountryNameOrCode } from '../../utils/const/countriesConstant';
import { toast } from 'react-toastify';
import { userService } from '../../services/user/UserService';
import PaginationComponent from '../paginationComponent/PaginationComponent';
import { useNavigate } from 'react-router-dom';
import AuthService from '../../services/auth/AuthService';
import { useDispatch, useSelector } from "react-redux";
import { SetUser } from '../../features/slices/UserSlice';
import useTableFilter from '../../hooks/useTableFilter';
import { TbArrowsUpDown } from 'react-icons/tb';
import clsx from 'clsx';
import ExnetTypeSelector from '../Utils/Selectors/ExnetTypeSelector';
import { useBasicFormValues } from '../../hooks/useFormValues';
import OptionsSelector from '../Utils/Selectors/Index';
import { stateOptions } from '../../utils/const/data';

const entete = ['ID', "NOM D'UTILISATEUR", 'TYPE', 'PAYS', 'ADRESSE', 'TELEPHONE', 'ADRESSE EMAIL', 'ENTREPRISE PHYSIQUE', "DATE DE CRÉATION", 'ACTIF']

export default function UsersView() {
    const { isOpen, onClose, onOpen } = useDisclosure();
    const dispatch = useDispatch()
    const user = useSelector((state) => state?.user);
    const [userTypeList, setUserTypeList] = useState('all');

    const {
        isOpen: isDeleteConfirmOpen,
        onClose: onDeleteConfirmClose,
        onOpen: onDeleteConfirmOpen
    } = useDisclosure();

    const navigate = useNavigate()

    const type = "utilisateurs";


    const { adminData, metadata, setMetadata, setMetadataKey, setPage, setPerPage, isLoading, onSubmit, search, setSearch } = useAdminLists({
        type,
        /*  params: {
             filter: query
         } */
    });
    const [selectedItem, setSelectedItem] = useState(null)

    const query = metadata.filter;

    const setFilterValue = (key, value) => {
        setMetadata(m => ({
            ...m,
            filter: {
                ...m.filter,
                [key]: value
            }
        }))
    }

    //console.log({ query })


    const onItemSelected = (item, action) => {
        setSelectedItem(item)
        if (action === "edit") {
            onOpen()
        } if (action === "connect") {
            onConnectUser(item)
        } else {
            onDeleteConfirmOpen()
        }
    }

    const toggleActif = useCallback(async (item, value) => {
        try {
            //onSubmit({ ...item, is_actif: value ? 1 : 0 })
            //onSubmit({ ...item, actif: value ? 1 : 0 })
            const request = value ? userService.activer : userService.desactiver
            const { data } = await request({ id: item.id });
            if (data.success) {
                onSubmit({ ...item, is_actif: value ? 1 : 0 })
            } else {
                toast.error(`Une erreur est sruvenue`, {
                    position: "bottom-center",
                    autoClose: 4000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            console.error(error)
            toast.error(`Une erreur est sruvenue`, {
                position: "bottom-center",
                autoClose: 4000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }, [onSubmit])

    const redirectUrl = (roles) => {
        if (roles.includes("ROLE_USER_SERVICE")) {
            return "/client/service/dash"
        } else if (roles.includes("ROLE_USER_MEDICAL")) {
            return "/client/medical/dash"
        } else if (roles.includes("ROLE_USER_PARIS")) {
            return "/client/paris/dash"
        } else {
            return "/client/paris/dash"
        }
    }

    const onConnectUser = useCallback(async (item) => {
        try {
            const { data } = await userService.connectAccount({ user_id: item.id });
            console.log(data)
            if (data.success) {
                // get storage
                const oldToken = localStorage.getItem("client")
                const admin = JSON.parse(localStorage.getItem("persist:root"))

                const newToken = data.data.token;
                localStorage.setItem("client", newToken);
                AuthService.setHeader(newToken);
                dispatch(SetUser({ ...data.data.user }));
                localStorage.setItem("oldToken", oldToken);
                localStorage.setItem("persist:root", JSON.stringify(data.data.user));
                localStorage.setItem("persist:rootOld", JSON.stringify(admin));
                localStorage.setItem("admincheck", "true");

                // navigate(redirectUrl(data.data.user.roles))
                window.location.href = redirectUrl(data.data.user.roles)
            } else {
                toast.error(`Une erreur est sruvenue`, {
                    position: "bottom-center",
                    autoClose: 4000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            console.error(error)
            toast.error(`Une erreur est sruvenue`, {
                position: "bottom-center",
                autoClose: 4000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }, [])



    return (
        <div>
            {/*  <GeneralStateComponent dataType={type} /> */}
            <h4 className="mb-1 uppercase page-title">Liste des utilisateurs</h4>
            <div className="flex items-center justify-between">
                <div>
                    <div className="flex items-center mt-3">
                        <div className="min-w-[350px] mr-4">
                            <label
                                for="types"
                                className="block mb-2 text-sm font-medium text-gray-900 "
                            >
                                Recherche
                            </label>
                            <input
                                className="w-full px-4 py-2 bg-white border border-gray-500 rounded-lg outline-none form-control"
                                type="search"
                                placeholder="Recherche"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </div>
                        <div className="min-w-[200px] mr-4">
                            <ExnetTypeSelector
                                value={metadata.type}
                                onChange={(v) => {
                                    //const _values = v
                                    //console.log({ _values })
                                    setMetadataKey("type", v.value);
                                }}
                                className={"w-full"}
                                multiple={false}
                            />
                        </div>

                        <div className="min-w-[200px]">
                            <OptionsSelector
                                label={"Statut"}
                                options={stateOptions}
                                value={query.state}
                                defaultValue="all"
                                onChange={v => {
                                   
                                    setFilterValue("state", v.value)
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div
                    className="float-right text-white bg-black btn btn-rounded"
                    onClick={onOpen}
                >
                    <i className="fas fa-plus"></i>
                </div>
            </div>

            {/*  <div className="justify-end mt-5 text-right" >
                <button
                    className={clsx({ "border-black": userTypeList !== 'inactif', "bg-black text-white": userTypeList === 'inactif' }, 'px-5 py-1 text-base border-2  border-solid rounded-lg mx-1')}
                    onClick={() => setUserTypeList('inactif')}>
                    Inactif
                </button>
                <button
                    className={clsx({ "border-black": userTypeList !== 'actif', "bg-black text-white": userTypeList === 'actif' }, 'px-5 py-1 text-base border-2  border-solid rounded-lg mx-1')}
                    onClick={() => setUserTypeList('actif')}
                >
                    Actif
                </button>
                <button
                    className={clsx({ "border-black": userTypeList !== 'all', "bg-black text-white": userTypeList === 'all' }, 'px-5 py-1 text-base border-2  border-solid rounded-lg mx-1')}
                    onClick={() => setUserTypeList('all')}
                >
                    Toute
                </button>
            </div> */}

            <div className="py-3">
                {adminData.total ?
                    <PaginationComponent
                    page={metadata.page}
                        handlePageClick={setPage}
                        totalItems={adminData.total}
                        perPageItems={metadata.perPage}
                        setnumberPerPage={setPerPage}
                    /> : null}
            </div>

            <Lists data={adminData.data} onSelect={onItemSelected} isLoading={isLoading} onToggleActive={toggleActif} />

            <div className="py-3">
                {adminData.total ?
                    <PaginationComponent
                    page={metadata.page}
                        element='pagination'
                        handlePageClick={setPage}
                        totalItems={adminData.total}
                        perPageItems={metadata.perPage}
                        setnumberPerPage={setPerPage}
                    /> : null}
            </div>

            <Form
                isOpen={isOpen}
                onClose={() => {
                    onClose()
                    setSelectedItem(null)
                }}
                onSubmit={onSubmit}
                selectedData={selectedItem}
            />

        </div>
    )
}


const Lists = ({ data, onSelect, isLoading, onToggleActive }) => {

    const HEADERS = [
        { label: 'ID', key: 'id' },
        { label: "NOM D'UTILISATEUR", key: 'name' },
        { label: 'TYPE', key: 'type' },
        { label: 'PAYS', key: 'country' },
        { label: 'ADRESSE', key: 'address1' },
        { label: 'TELEPHONE', key: 'telephone1' },
        { label: 'ADRESSE EMAIL', key: 'email' },
        { label: 'ENTREPRISE PHYSIQUE', key: 'physic_company' },
        { label: 'DATE DE CRÉATION', key: 'created_at' },
        { label: 'ACTIF', key: null },
    ];

    const { results, onTableHeaderClick } = useTableFilter({ items: data })

    return (
        <div className="relative overflow-x-auto">
            <div className="bg-white card-box">
                <div className="card-block">
                    {/*                     <h4 className="card-title">Basic Table</h4>*/}
                    <div className="table-responsive">
                        <table className="table mb-0">
                            <thead>
                                <tr className='uppercase'>
                                    {HEADERS.map((h, i) => (
                                        <th key={i} className="text-left cursor-pointer" onClick={() => {
                                            onTableHeaderClick(h.key)
                                        }}>
                                            <div className="flex items-center">
                                                <span className='mr-2'>
                                                    {h.label}
                                                </span>
                                                <span>
                                                    <TbArrowsUpDown />
                                                </span>
                                            </div>

                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {results.length > 0 && (
                                    results.map((item, i) => {
                                        const formattedType = formatInterface(item.type)
                                        return (
                                            <tr key={i} className="py-3">
                                                <td
                                                    className={'cursor-pointer'}
                                                    onClick={() => {
                                                        onSelect(item, "edit")
                                                    }}
                                                >{item.id}</td>
                                                <td
                                                    className={'cursor-pointer'}
                                                    onClick={() => {
                                                        onSelect(item, "edit")
                                                    }}
                                                >{item.name}</td>
                                                <td
                                                    className={'cursor-pointer'}
                                                    onClick={() => {
                                                        onSelect(item, "edit")
                                                    }}
                                                >
                                                    <div className={`${item.type} text-center rounded-sm`}>
                                                        {formattedType}
                                                    </div>
                                                </td>

                                                <td
                                                    className={'cursor-pointer'}
                                                    onClick={() => {
                                                        onSelect(item, "edit")
                                                    }}
                                                >
                                                    <div className="flex flex-wrap items-center">
                                                        <img
                                                            src={`https://flagcdn.com/16x12/${getCountryNameOrCode(getCountryNameOrCode(item.country), true)}.png`}
                                                            srcSet={`https://flagcdn.com/32x24/${getCountryNameOrCode(getCountryNameOrCode(item.country), true)}.png 2x,
                                                                https://flagcdn.com/48x36/${getCountryNameOrCode(getCountryNameOrCode(item.country), true)}.png 3x`}
                                                            width="16"
                                                            height="12"
                                                             alt={getCountryNameOrCode(
                                                                            getCountryNameOrCode(item.country),
                                                                            true
                                                                        )} />
                                                        <div className='ml-1'>
                                                            {getCountryNameOrCode(item.country)}
                                                        </div>
                                                    </div>

                                                </td>
                                                <td
                                                    className={'cursor-pointer'}
                                                    onClick={() => {
                                                        onSelect(item, "edit")
                                                    }}
                                                >{item.address1}</td>
                                                <td
                                                    className={'cursor-pointer'}
                                                    onClick={() => {
                                                        onSelect(item, "edit")
                                                    }}
                                                >{item.telephone1}</td>
                                                <td
                                                    className={'cursor-pointer'}
                                                    onClick={() => {
                                                        onSelect(item, "edit")
                                                    }}
                                                >{item.email}</td>
                                                <td
                                                    className={'cursor-pointer'}
                                                    onClick={() => {
                                                        onSelect(item, "edit")
                                                    }}
                                                >{item.entreprise_physique}</td>
                                                <td
                                                    className={'cursor-pointer'}
                                                    onClick={() => {
                                                        onSelect(item, "edit")
                                                    }}
                                                >{formatDefaultDate(item.created_at)}</td>
                                                <td>
                                                    <div className="flex flex-wrap items-center">
                                                        <div className="mr-5">
                                                            <SwitchComponent checked={Boolean(item.is_actif)} onChange={(value) => {
                                                                onToggleActive(item, value)
                                                            }} />
                                                        </div>


                                                        {/* <div
                                                            className="text-green-600 cursor-pointer text-[14px]"
                                                            onClick={() => {
                                                                onSelect(item, "edit")
                                                            }} title="Editer"
                                                        >
                                                            <i className="fas fa-edit"></i>
                                                        </div> */}


                                                        {Boolean(item.is_actif) ? <div
                                                            className="text-[#098721] cursor-pointer text-[14px] p-1"
                                                            onClick={() => {
                                                                onSelect(item, "connect")
                                                            }}
                                                            title="Se connecter"
                                                        >
                                                            <i className="fas fa-user"></i>
                                                        </div> : null}
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                )}
                            </tbody>
                        </table>
                        {isLoading ? <TableLoader /> : results.length === 0 ? (
                            <h6 className="my-5 text-lg text-center">Aucune donnée</h6>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    )
}