import AuthService from "../../services/auth/AuthService.js";
import {SetUser} from "../../features/slices/UserSlice.js";
import {toast} from "react-toastify";
import errors from "../../messages/errors.js";

export const authController = async (email, password, interfac, type) => {
    try {
        interfac = !interfac ? 'admin' : interfac;
        type = !type ? 'admin' : type;
        const data = await AuthService.Login(email, password, interfac, type);
        let response = data.data;
       
        if (response.success) {
            let userData = response.data;
            AuthService.setHeader(userData.token);
            localStorage.setItem("client", userData.token);
            userData = userData.user;
            const roles = userData.roles.replace('[', '').replace(']', '').replace(/"/g, '').split(',');
            userData = { ...userData, roles: roles };
            return {
                success: true,
                data: userData,
            }
        }
    } catch (error) {
        return {
            success: false,
            data: error,
        }
    }
}
