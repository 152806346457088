import React from "react";

export default function TexteareaInput({ label, value, onChange, ...props }) {
  return (
    <div>
      <div className="form-group">
        {label && (
          <label htmlFor="volume" className="form-label mb-1">
            {label}
          </label>
        )}
        <textarea
          //className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
          value={value}
          onChange={onChange}
          {...props}
        />
      </div>
    </div>
  );
}
