import React, { useCallback, useEffect, useState } from 'react'
import useDisclosure from '../../../hooks/useDisclosure';
//import Form from './Form';
import useAdminLists from '../../../hooks/useAdminLists';
import { formatDefaultDate } from '../../../utils/moment';
import { TableLoader } from '../../Utils/Loader';
import SalarieSalarieComponent from '../../Utils/Selectors/SalarieSalarieComponent';
import useFormValues, { useBasicFormValues } from '../../../hooks/useFormValues';
import adminService from '../../../services/admin/adminService';
import { exnetSpaceColor } from '../../../utils/Functions/otherFunctions';
import { useParams } from "react-router-dom";
import PaginationComponent from '../../paginationComponent/PaginationComponent';
import useTableFilter from '../../../hooks/useTableFilter';
import { TbArrowsUpDown } from 'react-icons/tb';
import UsersSelector from '../../Utils/Selectors/UsersSelector';

export default function HistoriesView() {
    const { isOpen, onClose, onOpen } = useDisclosure();

    const {
        isOpen: isDeleteConfirmOpen,
        onClose: onDeleteConfirmClose,
        onOpen: onDeleteConfirmOpen
    } = useDisclosure();

    const { milieu: exnetSpace } = useParams()


    const [selectedItem, setSelectedItem] = useState(null)
    const [items, setItems] = useState({
        data: []
    })
    const [isLoading, setisLoading] = useState(false)

    const [search, setSearch] = useState("")

    const initialValue = {
        user_id: "",
        page: 1,
        ref: "",
        perPage: 25,
        startDate: "",
        endDate: ""
    }

    const { data: metadata, setFormValue, setData: setMetadata } = useBasicFormValues(initialValue)

    const onItemSelected = (item, action) => {
        setSelectedItem(item)
        if (action === "edit") {
            onOpen()
        } else {
            onDeleteConfirmOpen()
        }
    }

    const filteredLists = items.data/* .filter(item => {
        //const today = new Date().getTime()
        return item.user?.name.includes(search)
            && item.shipping?.ref?.toString().includes(metadata.ref)
            && item.user?.id?.toString().includes(metadata.user_id)
        //&& (metadata.start ? new Date(item.created_at).getTime() >= new Date(metadata.start).getTime() : true)
        //&& (metadata.end ? new Date(item.created_at).getTime() <= new Date(metadata.end).getTime() : true)
    }
    ) */

    const filterHistories = useCallback(
        async () => {
            try {

                const params = {
                    type: "all",
                    per_page: metadata.perPage,
                    page: metadata.page,
                    date: {}
                }

                if (metadata.user_id) {
                    params["user_id"] = metadata.user_id;
                }

                if (metadata.ref) {
                    const ref = parseInt(metadata.ref)

                    if (!isNaN(ref)) {
                        params["ref"] = ref;
                    }
                }

                if (metadata.startDate) {
                    /* if (!params.date) {
                        params.date = {}
                    } */
                    params.date.start = metadata.startDate
                }

                if (metadata.endDate) {
                    /* if (!params.date) {
                        params.date = {}
                    } */
                    params.date.end = metadata.endDate
                }

                const { data } = await adminService.filterHistories(params);

                if (data.success) {
                    setItems(_items => ({
                        ..._items,
                        data: data.data.data,
                        total: data.data.total,
                        lastPage: data.data.last_page,
                    }))
                    /* if (metadata.page < data.data.last_page) {
                        setFormValue("page", metadata.page + 1)
                    } */
                }
            } catch (error) {
                console.error(error)
            }
        }, [metadata.user_id, metadata.page, metadata.perPage, metadata.ref, metadata.startDate, metadata.endDate])


    useEffect(() => {
        filterHistories()
    }, [])

    const cancelSearch = useCallback(() => {
        setMetadata(initialValue)
        setSearch("")
    }, [initialValue, setMetadata])

    //console.log({ metadata })

    return (
        <div>
            <h4 className="uppercase page-title">Le 24 Heures</h4>
            <div className="flex flex-wrap items-center">
                <div>
                    <div className="min-w-[280px] mr-4">
                        <label
                            className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                            htmlFor="suivi"
                        >
                            Recherche
                        </label>
                        <input
                            className="w-full px-4 py-2 mb-3 bg-white border border-gray-500 rounded-lg outline-none form-control"
                            type="search"
                            placeholder="Recherche"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </div>
                </div>

                <div className="w-[220px] mb-6 md:mb-0 mr-4">
                    <label
                        className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                        htmlFor="suivi"
                    >
                        Ref
                    </label>
                    <div className="mb-3">
                        <input
                            className="w-full px-4 py-2 mb-3 bg-white border border-gray-500 rounded-lg outline-none form-control"
                            type="search"
                            placeholder="45729"
                            value={metadata.ref}
                            onChange={(e) => setFormValue("ref", e.target.value)}
                        />
                    </div>
                </div>

                <div className="w-[220px] mb-6 md:mb-0 mr-4">
                    <label
                        className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                        htmlFor="suivi"
                    >
                        Utilisateur
                    </label>
                    <div className="mb-3">
                        {/* <SalarieSalarieComponent showLabel={false}
                            selectedValue={metadata.user_id}
                            onChange={(v) => {
                                //console.log({ v })
                                if (v.value) {
                                    //console.log({ v: v.value })
                                    setFormValue("user_id", v.value.id)
                                }
                            }} /> */}
                        <UsersSelector
                            profil="user"
                            showLabel={false}
                            selectedValue={metadata.user_id}
                            onChange={(v) => {
                                if (v?.id) {
                                    //console.log({ v: v.value })
                                    setFormValue("user_id", v.id)
                                }
                            }}
                        />

                    </div>
                </div>

                <div className="w-[220px] mb-6 md:mb-0 mr-4">
                    <label
                        className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                        htmlFor="startDate"
                    >
                        Date de début
                    </label>
                    <input
                        className="block text-sm w-full h-[40px] px-4 py-2 mb-3 leading-tight text-gray-700 bg-white border border-red-500 rounded appearance-none focus:outline-none focus:bg-white"
                        id="startDate"
                        type="date"
                        placeholder=""
                        value={metadata.startDate}
                        onChange={(e) => setFormValue("startDate", e.target.value)}
                    />
                </div>

                <div className="w-[220px] mb-6 md:mb-0 mr-6">
                    <label
                        className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                        htmlFor="endDate"
                    >
                        Date de fin
                    </label>
                    <input
                        className="block text-sm w-full h-[40px] px-4 py-2 mb-3 leading-tight text-gray-700 bg-white border border-red-500 rounded appearance-none focus:outline-none focus:bg-white"
                        id="endDate"
                        type="date"
                        placeholder=""
                        value={metadata.endDate}
                        onChange={(e) => setFormValue("endDate", e.target.value)}
                    />
                </div>

                <button
                    onClick={filterHistories}
                    type="button"
                    className="mx-2 text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm w-full sm:w-auto px-3 py-2.5 text-center mr-4"
                    // disabled={isSubmitting}
                    style={{
                        color: "#fff",
                        backgroundColor: `${exnetSpaceColor(exnetSpace)}`,
                    }}
                >
                    Rechercher
                </button>

                <button
                    onClick={cancelSearch}
                    type="button"
                    className="mx-2 text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm w-full sm:w-auto px-3 py-2.5 text-center"
                    // disabled={isSubmitting}
                    style={{
                        color: "#fff",
                        backgroundColor: `${exnetSpaceColor(exnetSpace)}`,
                    }}
                >
                    Annuler
                </button>


            </div>
            <div className="py-3">
                {items.total ?
                    <PaginationComponent
                        page={metadata.page}
                        handlePageClick={(v) => {
                            setMetadata(m => ({ ...m, page: v }))
                        }}
                        totalItems={items.total}
                        perPageItems={metadata.perPage}
                        setnumberPerPage={(v) => {
                            setMetadata(m => ({ ...m, perPage: v }))
                        }}
                    /> : null}
            </div>
            <Lists
                data={filteredLists}
                onSelect={onItemSelected}
                isLoading={isLoading}
                metadata={metadata}
            />
            <div className="py-3">
                {items.total ?
                    <PaginationComponent
                        page={metadata.page}
                        element={'pagination'}
                        handlePageClick={(v) => {
                            setMetadata(m => ({ ...m, page: v }))
                        }}
                        totalItems={items.total}
                        perPageItems={metadata.perPage}
                        setnumberPerPage={(v) => {
                            setMetadata(m => ({ ...m, perPage: v }))
                        }}
                    /> : null}
            </div>
        </div>
    )
}


const Lists = ({ data, metadata, isLoading }) => {
    const { results, onTableHeaderClick } = useTableFilter({ items: data });

    const HEADERS = [
        { label: 'Date', key: 'created_at' },
        { label: 'Expédition', key: 'shipping.ref' },
        { label: 'Utilisateur', key: 'user.name' },
        { label: 'Action', key: null },
    ];

    return (
        <div className="relative overflow-x-auto">
            <div className="bg-white card-box">
                <div className="card-block">
                    {/*                     <h4 className="card-title">Basic Table</h4>*/}
                    <div className="table-responsive">
                        <table className="table mb-0">
                            <thead>
                                <tr className='uppercase'>
                                    {HEADERS.map((h, i) => (
                                        <th key={i} className="text-left cursor-pointer" onClick={() => {
                                            onTableHeaderClick(h.key)
                                        }}>
                                            <div className="flex items-center">
                                                <span className='mr-2'>
                                                    {h.label}
                                                </span>
                                                {h.key && <span>
                                                    <TbArrowsUpDown />
                                                </span>}
                                            </div>

                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {data.length > 0 && (
                                    data.map((item, i) => {
                                        return (
                                            <tr key={i} className="py-3">
                                                <td>{formatDefaultDate(item.created_at, "DD-MM-YYYY à hh:mm")}</td>
                                                <td>
                                                    {item?.shipping?.ref || '-'}
                                                </td>
                                                <td>
                                                    {item?.user?.name}
                                                </td>
                                                <td>
                                                    {item.action}
                                                </td>
                                            </tr>
                                        )
                                    })
                                )}
                            </tbody>
                        </table>
                        {isLoading ? <TableLoader /> : data.length === 0 ? (
                            <h6 className="my-5 text-lg text-center">Aucune donnée</h6>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    )
}