import React, { useEffect, useState } from "react";
import Select from "react-select";
import BillCenterSelector from "../Utils/Selectors/BillCenterSelector";
import { VerifyStep_1 } from "../../utils/Functions/verifyDropdown";
import ClientSelector from "../Utils/Selectors/ClientSelector";
import { exnetSpaceColor, urlSplit } from "../../utils/Functions/otherFunctions";
import { ToastContainer, toast } from "react-toastify";
import useAuth from "../../hooks/useAuth";
import UsernameSelector, { UsernameSelect, UsernameSelector2 } from "../Utils/Selectors/UsernameSelector";
import InputComponent from "../InputComponent/InputComponent";


function NexExpeditionReferenceComponent({
  type,
  setInfosSupp,
  infosSupp,
  toggleAccordion,
  ExpeditionType,
}) {
  const {
    user_id,
    bill_center_id,
    username,
    nomContact,
    customer_ref,
    tracking_mail,
    code_receveur,
    code_donneur,
    transport_type,
    selected_user
  } = infosSupp;
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedCentre, setSelectedCentre] = useState(bill_center_id);
  const [selectedDelais, setSelectedDelais] = useState(null);
  const [selectedRoues, setSelectedRoues] = useState(null);
  const { isAuthenticated, user } = useAuth();

  const { role, exnetSpace } = urlSplit();

  const updateData = (element, valeur) => {
    let data = { ...infosSupp };
    data[element] = valeur;
    setInfosSupp({ ...data });
  };
  const [errorInformation, setErrorInformation] = useState({ field: '', message: '' })

  const handleNext = () => {
    const { valid, error: messageError, errorData } = VerifyStep_1(infosSupp);
    console.log({ messageError, errorData })
    if (messageError) {
      toast.error(messageError, {
        position: "top-center",
        autoClose: 500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setErrorInformation({ ...errorData })
    } else {
      toggleAccordion(2);
    }
  };

  useEffect(() => {
    let data = { ...infosSupp };
    updateData("user_id", selectedClient?.value);
    setInfosSupp({ ...data });
    if (type === "courses") {
      data["delais"] = selectedDelais;
      setInfosSupp({ ...data });
    }
  }, [selectedDelais]);





  return (
    <div className="content">
      <div className="mx-1 row">
        <div className="card-box">
          <div className="row">
            {role !== "client" && (
              <div className="col-md-6">
                <div className="form-group">
                  <ClientSelector
                    type={exnetSpace}
                    selectedValue=
                    {
                      user_id?.value
                        ? {
                          label: "Choix du client",
                          value: null,
                        }
                        : user_id
                    }

                    showLabel={false}
                    profil={"customer"}
                    onSelect={(v) => {
                      updateData("user_id", v.value);
                      setSelectedCentre(v);
                    }}
                  />
                </div>
              </div>
            )}

            <div className="col-md-6">
              {" "}
              <div className="form-group">
                {/* <input
                  type="text"
                  className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                  placeholder={"Référence du client ".toUpperCase()}
                  value={customer_ref}
                  onChange={(e) => updateData("customer_ref", e.target.value)}
                /> */}
                <InputComponent
                  value={customer_ref}
                  onChange={(valeur) => updateData("customer_ref", valeur)}
                  errorMessage={errorInformation.message}
                  placeholder={"Référence du client".toUpperCase()}
                  label='' withLabel={false}
                  isError={errorInformation.field === 'customer_ref' ? true : false}
                />
              </div>
            </div>
            <div className="col-md-6">
              {" "}
              <div className="form-group">
                <BillCenterSelector
                  type={exnetSpace}
                  defaultInputValue={
                    bill_center_id?.value
                      ? {
                        label: "Choix du centre de facturation",
                        value: null,
                      }
                      : bill_center_id
                  }
                  selectedValue={selectedCentre}
                  onSelect={(v) => {
                    updateData("bill_center_id", v);
                    setSelectedCentre(v);
                  }}
                  isDisabled={false}
                  user={infosSupp.user_id}
                />
              </div>
            </div>

            {(exnetSpace === 'medical') && <div className="col-md-6">
              {" "}
              <div className="form-group">
                {/* <input
                  type="text"
                  className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                  placeholder={"CODE DONNEUR".toUpperCase()}
                  value={code_donneur}
                  onChange={(e) => updateData("code_donneur", e.target.value)}
                /> */}
                <InputComponent
                  value={code_donneur}
                  onChange={(valeur) => updateData("code_donneur", valeur)}
                  errorMessage={errorInformation.message}
                  placeholder={"CODE DONNEUR".toUpperCase()}
                  label='' withLabel={false}
                  isError={errorInformation.field === 'code_donneur' ? true : false}
                />
              </div>
            </div>}

            <div className="col-md-6">
              {" "}
              <div className="form-group">
                {/* <input
                  type="email"
                  className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                  placeholder={"Mail de suivi".toUpperCase()}
                  value={tracking_mail}
                  onChange={(e) => updateData("tracking_mail", e.target.value)}
                /> */}
                <InputComponent
                  type="email"
                  value={tracking_mail}
                  onChange={(valeur) => updateData("tracking_mail", valeur)}
                  errorMessage={errorInformation.message}
                  placeholder={"MAIL DE SUIVI".toUpperCase()}
                  label='' withLabel={false}
                  isError={errorInformation.field === 'tracking_mail' ? true : false}
                />
              </div>
            </div>

            {(exnetSpace === 'medical') && <div className="col-md-6">
              {" "}
              <div className="form-group">
                {/* <input
                  type="text"
                  className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                  placeholder={"CODE Receveur".toUpperCase()}
                  value={code_receveur}
                  onChange={(e) => updateData("code_receveur", e.target.value)}
                /> */}
                <InputComponent
                  value={code_receveur}
                  onChange={(valeur) => updateData("code_receveur", valeur)}
                  errorMessage={errorInformation.message}
                  placeholder={"CODE Receveur".toUpperCase()}
                  label='' withLabel={false}
                  isError={errorInformation.field === 'code_receveur' ? true : false}
                />
              </div>
            </div>}



            {role === 'client' &&
              <div className="col-md-6">
                {" "}
                <div className="form-group">
                  {/* <input
                    type="text"
                    className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                    placeholder={"Nom d'utilisateur*".toUpperCase()}
                    value={username}
                    onChange={(e) => updateData("username", e.target.value)}
                  /> */}
                  <InputComponent
                    value={username}
                    onChange={(valeur) => updateData("username", valeur)}
                    errorMessage={errorInformation.message}
                    placeholder={"Nom d'utilisateur*".toUpperCase()}
                    label='' withLabel={false}
                    isError={errorInformation.field === 'username' ? true : false}
                  />
                </div>
              </div>
            }

            {role === 'ops' &&
              <div className="col-md-6">
                {" "}
                <div className="form-group">
                  {/* <UsernameSelector2 onSelect={(v) => {
                    let data = { ...infosSupp };
                    data['username'] = v.label;
                    data['selected_user'] = v.value;
                    setInfosSupp({ ...data });

                  }}
                    defaultValue={{ label: username, value: selected_user }}
                    billing_center_id={bill_center_id?.value}
                    customer_id={infosSupp.user_id}
                  /> */}
                  {exnetSpace === 'paris' ? (
                    <UsernameSelect
                      onSelect={(v) => {
                        //sconsole.log('salut la famille comment allez vous',v)
                       
                        updateData("username", v.value)
                      }}
                      defaultValue={username}
                      billing_center_id={bill_center_id?.value}
                      customer_id={infosSupp.user_id}
                    />
                  ) : (
                    <UsernameSelector
                      onSelect={(v) => {
                        //sconsole.log('salut la famille comment allez vous',v)
                       
                        updateData("username", v)
                      }}
                      defaultValue={username}
                      billing_center_id={bill_center_id?.value}
                      customer_id={infosSupp.user_id}
                    />
                  )}

                  {/* <input
                    type="text"
                    className="w-full bg-white border border-gray-500 rounded-lg outline-none form-control"
                    placeholder={"Nom d'utilisateur*".toUpperCase()}
                    value={username}
                    onChange={(e) => updateData("username", e.target.value)}
                  /> */}
                </div>
              </div>
            }



            {/* {ExpeditionType !== "import" && ExpeditionType !== "export" && (
              <div className={role !== "client" ? "col-md-6" : "col-md-12"}>
                {" "}
                <div className="form-group">
                  <Select
                    className={"text-base"}
                    // defaultInputValue={transport_type.label}
                    value={transport_type}
                    placeholder={"Type de course".toUpperCase()}
                    options={[
                      {
                        label: "DEUX ROUES",
                        value: 2,
                      },
                      {
                        label: "QUATRE ROUES",
                        value: 4,
                      },
                    ]}
                    onChange={(v) => {
                      updateData("transport_type", v);
                      setSelectedRoues(v);
                    }}
                  />
                </div>
              </div>
            )} */}

            <div className={role !== "client" ? "col-md-6" : "col-md-12 "}>
              <div className="row " style={{ justifyContent: "flex-end" }}>
                <div className="col-3">
                  <div className="form-group w-100">
                    <button
                      id="next"
                      className="btn w-100"
                      onClick={handleNext}
                      style={{ color: '#fff', backgroundColor: `${exnetSpaceColor(exnetSpace)}` }}
                    >
                      Suivant
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NexExpeditionReferenceComponent;
