import { TbHomeDollar, TbLayoutDashboard, TbPackage, TbSettings, TbUser, TbUserExclamation, TbUsers, TbUsersGroup } from "react-icons/tb";
import * as wi from "react-icons/wi";

export const exploitationParisMenu = {
    data: [
        {
            libelle: "Exploitation",
            lien: "/exploitation",
            icon: <i><TbLayoutDashboard /></i>,
        },
        {
            libelle: "Liste des chauffeurs",
            lien: "/exploitation/paris/drivers",
            icon: <i><TbUsersGroup /></i>,
        },
        {
            libelle: "Liste des courses",
            lien: "/exploitation/paris/courses",
            icon: <i><TbHomeDollar /> </i>,
        }
    ],
    configuration: {
        showExtra: false,
        showHelp: false,
    },
}

export const exploitationMedicalMenu = {
    data: [
        {
            libelle: "Exploitation",
            lien: "/exploitation",
            icon: <i><TbLayoutDashboard /></i>,
        },
        {
            libelle: "Liste des chauffeurs",
            lien: "/exploitation/medical/drivers",
            icon: <i><TbUsersGroup /></i>,
        },
        {
            libelle: "Liste des courses",
            lien: "/exploitation/medical/courses",
            icon: <i><TbHomeDollar /> </i>,
        }
    ],
    configuration: {
        showExtra: false,
        showHelp: false,
    },
}

export const exploitationServiceMenu = {
    data: [
        {
            libelle: "Exploitation",
            lien: "/exploitation",
            icon: <i><TbLayoutDashboard /></i>,
        },
        {
            libelle: "Liste des chauffeurs",
            lien: "/exploitation/service/drivers",
            icon: <i><TbUsersGroup /></i>,
        },
        {
            libelle: "Liste des courses",
            lien: "/exploitation/service/courses",
            icon: <i><TbHomeDollar /> </i>,
        }
    ],
    configuration: {
        showExtra: false,
        showHelp: false,
    },
}