import React from "react";
import notificationDetail from "./notificationDetail.layout.jsx";

function NotificationDetailLayout({notification}) {
    return (
        <>
            <li>
                <a href={notification.link}>
                    <div className="list-item">
                        <div className="list-left">
                            <span className="avatar"> {notification.username[0].toUpperCase()} </span>
                        </div>
                        <div className="list-body">
                            <span className="message-author"> {notification.username}{" "} </span>
                            <span className="message-time"> {notification.time} </span>
                            <div className="clearfix"></div>
                            <span className="message-content">{notification.message}</span>
                        </div>
                    </div>
                </a>
            </li>
        </>
    );
}

export default NotificationDetailLayout;
