export const TableEntete = (space) => {

  if (space === "medical") {
    return [
      { label: 'REF', cle: 'ref' },
      { label: 'ref inter', cle: 'customer_ref' },
      { label: 'EXP', cle: 'expeditor.fullname' },
      { label: 'Code don', cle: 'code_donneur' },
      { label: 'adresse', cle: 'expeditor.address1' },
      { label: 'DEST', cle: 'receiver.fullname' },
      { label: 'Code rec', cle: 'code_receveur' },
      { label: 'adresse', cle: 'receiver.address1' },
      { label: 'Centre de Fact.', cle: 'billing_center.billing_center_name' },
      { label: 'TRACKING', cle: 'suivis_details' },
      { label: 'Information', cle: false },
      { label: 'ATTRIBUTION', cle: false },
      { label: 'ACTION', cle: false }
    ]
  } else {
    return [
      { label: 'REF', cle: 'ref' },
      { label: 'ref inter', cle: 'customer_ref' },
      { label: 'EXP', cle: 'expeditor.fullname' },
      { label: 'adresse', cle: 'expeditor.address1' },
      { label: 'DEST', cle: 'receiver.fullname' },
      { label: 'adresse', cle: 'receiver.address1' },
      { label: 'Centre de Fact.', cle: 'billing_center.billing_center_name' },
      { label: 'TRACKING', cle: 'suivis_details' },
      { label: 'Information', cle: false },
      { label: 'ATTRIBUTION', cle: false },
      { label: 'ACTION', cle: false }
    ]
  }

}


const filterKey = (index, exnetSpace) => {
  if (exnetSpace !== 'medical') {
    if (index === 0) {
      return 'ref';
    } else if (index === 1) {
      return 'pickup_time';
    } else if (index === 2) {
      return 'expeditor.fullname';
    }
    else if (index === 3) {
      return 'expeditor.address1';
    }
    else if (index === 4) {
      return 'expeditor.country';
    }
    else if (index === 5) {
      return 'receiver.fullname';
    } else if (index === 6) {
      return 'receiver.address1';
    }
    else if (index === 7) {
      return 'receiver.country';
    } else if (index === 8) {
      return 'suivis_details';
    }
  } else {

    if (index === 0) {
      return 'ref';
    }
    else if (index === 1) {
      return 'pickup_time';
    }
    else if (index === 2) {
      return 'expeditor.fullname';
    }
    else if (index === 3) {
      return 'code_donneur';
    }
    else if (index === 4) {
      return 'expeditor.address1';
    }
    else if (index === 5) {
      return 'expeditor.country';
    }
    else if (index === 6) {
      return 'receiver.fullname';
    }
    else if (index === 7) {
      return 'code_receveur';
    }
    else if (index === 8) {
      return 'receiver.address1';
    }
    else if (index === 9) {
      return 'receiver.country';
    }
    else if (index === 10) {
      return 'suivis_details';
    }
  }

}
