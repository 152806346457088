import React, { useCallback, useEffect, useState } from "react";
import useDisclosure from "../../hooks/useDisclosure";
import { useBasicFormValues } from "../../hooks/useFormValues";
import CountriesSelector from "../Utils/Selectors/CountriesSelector";
import ExnetTypeSelector from "../Utils/Selectors/ExnetTypeSelector";
import clientService from "../../services/client/clientService";
import ModalUI from "../Utils/Modal/Modal";
import FormUploadSection from "../Utils/FormUploadSection";
import { SwitchComponent } from "../Utils/Index";
import { getFileUrl, jsonToFormData } from "../../utils";
import Loader from "../Utils/Loader";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import { getCountryNameOrCode } from "../../utils/const/countriesConstant";

const FormComponent = ({ modalFormContent, newData, editable, onSubmit, onClose }) => {
  const {
    data: clientData,
    setFormValue,
    setData,
  } = useBasicFormValues(
    modalFormContent
      ? modalFormContent
      : {
        user_type: "client",
        country: "fr"
      }
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [makeUpdate, setmakeUpdate] = useState(false);

  useEffect(() => {
    if (modalFormContent) {
      setData((d) => ({ ...d, ...modalFormContent }));
      console.log({ modalFormContent })
      if (modalFormContent.logo) {
        const _logo = modalFormContent.logo.split("/public").pop() //getFileUrl(modalFormContent.logo);
        setFormValue("logo", _logo)
      }

      if (modalFormContent.header) {
        const _header = modalFormContent.header.split("/public").pop() //getFileUrl(modalFormContent.header);
        setFormValue("header", _header)
      }

      if (modalFormContent.footer) {
        const _footer = modalFormContent.footer.split("/public").pop() //getFileUrl(modalFormContent.footer);
        setFormValue("footer", _footer)
      }
    }
  }, [modalFormContent]);

  const handleSubmit = useCallback(
    async (e) => {
      try {
        e.preventDefault();
        //if (!modalFormContent) {
        setIsSubmitting(true);
        //const updateData = pick(clientData, ["fullname", "country", "type", "address", ""])
        const copy = { ...clientData };
        if (clientData.id) {
          delete copy.mailing;
          copy.actif = 1
        }

        //console.log({ copy })

        const submitData = jsonToFormData(copy);
        const { data } = clientData.id
          ? await clientService.editClient(submitData)
          : await clientService.addClient(submitData);
        //console.log({ data });
        if (data.success) {
          onSubmit?.(clientData.id ? { ...clientData, ...data.data } : { ...data.data, actif: 1 });
        }
        //}
      } catch (error) {
        console.error(error);
        toast.error('Une erreur lors de la soumission de votre demande', {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } finally {
        setIsSubmitting(false);
      }
    },
    [clientData, onSubmit]
  );

  //console.log({ clientData });

  return (
    <div className="p-3 bg-gray-50">
      {!newData && (
        <div className={"flex justify-end"}>
          <button
            type="submit"
            className="text-white  bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
            onClick={() => setmakeUpdate(!makeUpdate)}
          >
            <span className={"fa fa-edit"}></span>
          </button>
        </div>
      )}

      <form onSubmit={handleSubmit}>
        <div className="grid gap-6 mb-3 md:grid-cols-2">
          <div className="text-left">
            <label
              htmlFor="name"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Nom du client*
            </label>
            <input
              type="text"
              id="fullname"
              className="block w-full p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg focus:border-blue-100"
              required
              value={clientData.fullname ? clientData.fullname : ""}
              onChange={(e) => setFormValue("fullname", e.target.value)}
            />
          </div>

          <div className="text-left">
            <label
              htmlFor="address"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Adresse de facturation *
            </label>
            <input
              type="text"
              id="address"
              placeholder="Adresse"
              className="block w-full p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg focus:border-blue-100"
              required
              value={clientData.address}
              onChange={(e) => setFormValue("address", e.target.value)}
            />
          </div>

          <div className="text-left">
            <label
              htmlFor="address2"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Complément d'adresse
            </label>
            <input
              type="text"
              id="address2"
              className="block w-full p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg focus:border-blue-100"
              value={clientData.address2 ?? ""}
              onChange={(e) => setFormValue("address2", e.target.value)}
            />
          </div>

          <div className="text-left">
            <label
              htmlFor="city"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Ville*
            </label>
            <input
              type="text"
              id="city"
              className="block w-full p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg focus:border-blue-100"
              required
              value={clientData.city}
              onChange={(e) => setFormValue("city", e.target.value)}
            />
          </div>

          <div className="text-left">
            <label
              htmlFor="postal_code"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Code Postal*
            </label>
            <input
              type="text"
              id="postal_code"
              className="block w-full p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg focus:border-blue-100"
              required
              value={clientData.postal_code}
              onChange={(e) => setFormValue("postal_code", e.target.value)}
            />
          </div>

          <div className="text-left">
            <CountriesSelector
              selectedValue={clientData.country}
              onSelect={(v) => {
                setFormValue("country", v.value);
              }}
            // isDisabled={!editable}
            />
          </div>

          <div className="text-left">
            <label
              htmlFor="telephone"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Téléphone*
            </label>
            {/* <input
              type="text"
              id="telephone"
              className="block w-full p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg focus:border-blue-100"
              value={clientData.telephone}
              onChange={(e) => setFormValue("telephone", e.target.value)}
            /> */}
            <PhoneInput
              country={getCountryNameOrCode(getCountryNameOrCode(clientData?.country), true) ?? 'fr'}
              defaultValue={clientData.telephone}
              value={clientData.telephone}
              onChange={(value, country, e, formattedValue) => { setFormValue("telephone", formattedValue) }}
              inputClass={'form-controle'}
              countryCodeEditable={true}
              disableDropdown={false}
            />
          </div>

          <div className="text-left">
            <label
              htmlFor="invoice_mail"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Email de facturation*
            </label>
            <input
              type="email"
              id="invoice_mail"
              required
              className="block w-full p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg focus:border-blue-100"
              value={clientData.invoice_mail}
              onChange={(e) => setFormValue("invoice_mail", e.target.value)}
            />
          </div>

          <div className="text-left">
            {/*  {clientData.type} */}
            <ExnetTypeSelector
              value={clientData.type?.split(",")}
              onChange={(v) => {
                setFormValue("type", v.join(","));
              }}
            />
          </div>

        </div>



        <div className="w-full h-[1px] bg-gray-300 my-2" />

        <div className="grid gap-6 mb-2 md:grid-cols-3">
          <div className="text-left">
            <label
              htmlFor="dirigeant"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Dirigeant (Nom/Prénoms)
            </label>
            <input
              id="dirigeant"
              className="block w-full p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg focus:border-blue-100"
              value={clientData.dirigeant}
              onChange={(e) => setFormValue("dirigeant", e.target.value)}
            />
          </div>
          <div className="grid col-span-2 gap-6 mb-3 md:grid-cols-2">
            <div className="text-left">
              <label
                htmlFor="dirigeant_tel"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Téléphone
              </label>

              <PhoneInput
                country={getCountryNameOrCode(getCountryNameOrCode(clientData?.country), true) ?? 'fr'}
                defaultValue={clientData.dirigeant_tel}
                value={clientData.dirigeant_tel}
                onChange={(value, country, e, formattedValue) => { setFormValue("dirigeant_tel", formattedValue) }}
                inputClass={'form-controle'}
                countryCodeEditable={true}
                disableDropdown={false}
              />
            </div>

            <div className="text-left">
              <label
                htmlFor="dirigeant_email"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Email
              </label>
              <input
                type="text"
                id="dirigeant_email"
                className="block w-full p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg focus:border-blue-100"
                value={clientData.dirigeant_email}
                onChange={(e) =>
                  setFormValue("dirigeant_email", e.target.value)
                }
              />
            </div>
          </div>
        </div>

        <div className="w-full h-[1px] bg-gray-300 my-2" />

        <div className="grid gap-6 mb-2 md:grid-cols-3">
          <div className="text-left">
            <label
              htmlFor="compta_responsible"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Resp. Compta. (Nom/Prénoms)
            </label>
            <input
              id="compta_responsible"
              className="block w-full p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg focus:border-blue-100"
              value={clientData.compta_responsible}
              onChange={(e) =>
                setFormValue("compta_responsible", e.target.value)
              }
            />
          </div>

          <div className="text-left">
            <label
              htmlFor="responsable_comptable_tel"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Téléphone
            </label>


            <PhoneInput
              country={getCountryNameOrCode(getCountryNameOrCode(clientData?.country), true) ?? 'fr'}
              defaultValue={clientData.responsable_comptable_tel}
              value={clientData.responsable_comptable_tel}
              onChange={(value, country, e, formattedValue) => { setFormValue("responsable_comptable_tel", formattedValue) }}
              inputClass={'form-controle'}
              countryCodeEditable={true}
              disableDropdown={false}
            />

          </div>

          <div className="text-left">
            <label
              htmlFor="responsable_comptable_email"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Email
            </label>
            <input
              type="text"
              id="responsable_comptable_email"
              className="block w-full p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg focus:border-blue-100"
              value={clientData.responsable_comptable_email}
              onChange={(e) =>
                setFormValue("responsable_comptable_email", e.target.value)
              }
            />
          </div>
        </div>

        <div className="w-full h-[1px] bg-gray-300 my-2" />

        <>
          <div className="grid gap-6 mb-2 md:grid-cols-3">
            <div className="text-left">
              <label
                htmlFor="dafresponsible_name"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Resp. DAF. (Nom/Prénoms)
              </label>
              <input
                id="dafresponsible_name"
                className="block w-full p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg focus:border-blue-100"
                value={clientData.dafresponsible_name}
                onChange={(e) =>
                  setFormValue("dafresponsible_name", e.target.value)
                }
              />
            </div>

            <div className="text-left">
              <label
                htmlFor="responsable_daf_tel"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Téléphone
              </label>


              <PhoneInput
                country={getCountryNameOrCode(getCountryNameOrCode(clientData?.country), true) ?? 'fr'}
                defaultValue={clientData.responsable_daf_tel}
                value={clientData.responsable_daf_tel}
                onChange={(value, country, e, formattedValue) => { setFormValue("responsable_daf_tel", formattedValue) }}
                inputClass={'form-controle'}
                countryCodeEditable={true}
                disableDropdown={false}
              />

            </div>

            <div className="text-left">
              <label
                htmlFor="responsable_daf_email"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Email
              </label>
              <input
                type="text"
                id="responsable_daf_email"
                className="block w-full p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg focus:border-blue-100"
                value={clientData.responsable_daf_email}
                onChange={(e) =>
                  setFormValue("responsable_daf_email", e.target.value)
                }
              />
            </div>
          </div>

          <div className="w-full h-[1px] bg-gray-300 my-2" />

          <div className="grid gap-6 mb-3 md:grid-cols-3">
            <div className="text-left">
              <label
                htmlFor="intra_vatnumber"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                N° TVA Intracommunautaire
              </label>
              <input
                type="text"
                id="intra_vatnumber"
                className="block w-full p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg focus:border-blue-100"
                value={clientData.intra_vatnumber}
                onChange={(e) =>
                  setFormValue("intra_vatnumber", e.target.value)
                }
              />
            </div>

            <div className="text-left">
              <label
                htmlFor="siren"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                N° SIREN
              </label>
              <input
                type="text"
                id="siren"
                className="block w-full p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg focus:border-blue-100"
                value={clientData.siren}
                onChange={(e) => setFormValue("siren", e.target.value)}
              />
            </div>
            <div className="text-left">
              <label
                htmlFor="numero_eori"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                N° EORI
              </label>
              <input
                type="text"
                id="numero_eori"
                className="block w-full p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg focus:border-blue-100"
                value={clientData.numero_eori}
                onChange={(e) => setFormValue("numero_eori", e.target.value)}
              />
            </div>
          </div>
        </>

        <div className="grid gap-6 mb-3 md:grid-cols-3">
          <div className="text-left">
            <label
              htmlFor="email1"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Email1
            </label>
            <input
              type="text"
              id="email1"
              className="block w-full p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg focus:border-blue-100"
              value={clientData.email1}
              onChange={(e) => setFormValue("email1", e.target.value)}
            />
          </div>
          <div className="text-left">
            <label
              htmlFor="email2"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Email 2
            </label>
            <input
              type="text"
              id="email2"
              className="block w-full p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg focus:border-blue-100"
              value={clientData.email2}
              onChange={(e) => setFormValue("email2", e.target.value)}
            />
          </div>

          <div className="text-left">
            <label
              htmlFor="email3"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Email 3
            </label>
            <input
              type="text"
              id="email3"
              className="block w-full p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg focus:border-blue-100"
              value={clientData.email3}
              onChange={(e) => setFormValue("email3", e.target.value)}
            />
          </div>
        </div>

        <div className="mb-3">
          <FormUploadSection
            logo={clientData.logo}
            header={clientData.header}
            footer={clientData.footer}
            onDataChange={setFormValue}
          />
        </div>

        <div className="flex items-center justify-between mb-3">
          <label
            htmlFor="is_centre_de_facturation"
            className="text-sm font-medium text-gray-900 cursor-pointer"
          >
            Ce client est aussi un centre de facturation
          </label>
          <SwitchComponent
            id="is_centre_de_facturation"
            label="is_centre_de_facturation"
            checked={clientData.is_centre_de_facturation === 1}
            onChange={(checked) => {
              setFormValue("is_centre_de_facturation", checked ? 1 : 0);
            }}
            checkedColor="#098721"
          />
        </div>

        {/* <div className="flex items-center justify-between mb-3">
          <label
            htmlFor="valeur_customer"
            className="text-sm font-medium text-gray-900 cursor-pointer"
          >
            Afficher valeur commerciale sur bordereau
          </label>
          <SwitchComponent
            id="valeur_customer"
            label="valeur_customer"
            checked={clientData.valeur_customer === 1}
            onChange={(checked) => {
              setFormValue("valeur_customer", checked ? 1 : 0);
            }}
          />
        </div> */}

        <div className="flex items-center justify-between mb-3">
          <label
            htmlFor="customer_name"
            className="text-sm font-medium text-gray-900 cursor-pointer"
          >
            Afficher nom client sur bordereau
          </label>
          <SwitchComponent
            id="customer_name"
            label="customer_name"
            checked={clientData.customer_name === 1}
            onChange={(checked) => {
              setFormValue("customer_name", checked ? 1 : 0);
            }}
            checkedColor="#098721"
          />
        </div>

        {/* <div className="flex items-center justify-between mb-3">
          <label
            htmlFor="instruction_mail"
            className="text-sm font-medium text-gray-900 cursor-pointer"
          >
            Afficher mail d'instruction
          </label>
          <SwitchComponent
            id="instruction_mail"
            label="instruction_mail"
            checked={clientData.instruction_mail === 1}
            onChange={(checked) => {
              setFormValue("instruction_mail", checked ? 1 : 0);
            }}
          />
        </div> */}

        <div className="flex items-center justify-between mb-3">
          <label
            htmlFor="mailing"
            className="text-sm font-medium text-gray-900 cursor-pointer"
          >
            Lettre d'info / mailing
          </label>
          <SwitchComponent
            id="mailing"
            label="mailing"
            checked={clientData.mailing === 1}
            onChange={(checked) => {
              setFormValue("mailing", checked ? 1 : 0);
            }}
            checkedColor="#098721"
          />
        </div>

        <div className="flex items-center justify-between mb-3">
          <label
            htmlFor="tracking_mail"
            className="text-sm font-medium text-gray-900 cursor-pointer"
          >
            Recevoir mail de  Tracking
          </label>
          <SwitchComponent
            id="tracking_mail"
            label="tracking_mail"
            checked={clientData.tracking_mail === 1}
            onChange={(checked) => {
              setFormValue("tracking_mail", checked ? 1 : 0);
            }}
            checkedColor="#098721"
          />
        </div>

        <div className="flex-wrap items-center hidden mb-8">
          <div className="flex items-start mr-12">
            <div className="flex items-center h-5">
              <input
                id="submitted_to_french_vat"
                type="checkbox"
                checked={clientData.submitted_to_french_vat === 1}
                onChange={(e) => {
                  {
                    if (editable || newData) {
                      setFormValue(
                        "submitted_to_french_vat",
                        clientData.submitted_to_french_vat === 1 ? 0 : 1
                      );
                    }
                  }
                }}
                className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300"
              />
            </div>
            <label
              htmlFor="submitted_to_french_vat"
              className="ml-2 text-sm font-medium text-gray-900"
            >
              Soumise à la TVA Française
            </label>
          </div>

          <div className="flex items-start">
            <div className="flex items-center h-5">
              <input
                id="is_centre_de_facturation"
                type="checkbox"
                checked={clientData.is_centre_de_facturation === 1}
                onChange={(e) => {
                  if (editable || newData) {
                    setFormValue(
                      "is_centre_de_facturation",
                      clientData.is_centre_de_facturation === 1 ? 0 : 1
                    );
                  }
                }}
                className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300"
              />
            </div>
            <label
              htmlFor="is_centre_de_facturation"
              className="ml-2 text-sm font-medium text-gray-900"
            >
              Ce client est un centre de facturation
            </label>
          </div>
        </div>
        <div className="flex justify-end mt-10">
          <div
            onClick={() =>
              onClose?.()
            }
            className="bg-gray-200 hover:bg-gray-300 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center mr-3"
          >
            Annuler
          </div>
          {(editable || makeUpdate) && (
            <button
              type="submit"
              className={`${isSubmitting ? "cursor-not-allowed" : "cursor-pointer"} text-white bg-[#098721] hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center`}
              disabled={isSubmitting}
            >
              <span className="mr-1">Modifier</span>
              {isSubmitting && <Loader />}
            </button>
          )}

          {newData && (
            <button
              type="submit"
              className={`${isSubmitting ? "cursor-not-allowed" : "cursor-pointer"} text-white bg-[#098721] hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center`}
              disabled={isSubmitting}
            >
              <span className="mr-1">Enregistrer</span>
              {isSubmitting && <Loader />}
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default function CreateClientForm({
  withoutModal,
  modalFormContent,
  editable,
  dataType,
  onSubmit,
}) {
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      {withoutModal ? (
        <FormComponent
          modalFormContent={modalFormContent}
          editable={editable}
          dataType={dataType}
          onClose={onClose}
        />
      ) : (
        <>
          <div
            className="float-right btn btn-primary btn-rounded"
            onClick={onOpen}
          >
            <i className="fas fa-plus"></i>
          </div>
          <ModalUI
            isOpen={isOpen}
            onClose={onClose}
          /*   title={"Créer un client"} */
          >
            <FormComponent
              modalFormContent={null}
              newData={true}
              dataType={dataType}
              onClose={onClose}
              onSubmit={onSubmit}
            />
          </ModalUI>
        </>
      )}
    </>
  );
}

export const ClientModal = ({ isOpen, selectedData, onClose, onSubmit }) => {
  //console.log({ selectedData })

  return (
    <ModalUI
      isOpen={isOpen}
      onClose={onClose}
      title={selectedData ? "Modifier le client" : "Créer un client"}
    >
      <FormComponent
        modalFormContent={selectedData}
        newData={true}
        onSubmit={onSubmit}
        onClose={onClose}
      />
    </ModalUI>
  );
};
