import React, { useCallback } from "react";
import { Paragraphe } from "../../Utils/UI/PragrapheComponent";
import { exnetSpaceColor, urlSplit } from "../../../utils/Functions/otherFunctions";
import JSZip from "jszip";
import { toast } from 'react-toastify';

function ColisClientDetailComponent({ packages, courses, dataExpedition, colisType = 'expedition' }) {

    const { exnetSpace } = urlSplit()


    const handleDownloadClick = useCallback(async () => {
        try {

            let fileLinks = [
                `${process.env.REACT_APP_BACKEND_HOST}/${dataExpedition.doc_borderaux}`
            ];

            if (dataExpedition?.doc_accompagnement && dataExpedition?.doc_accompagnement?.length > 0) {
                dataExpedition?.doc_accompagnement?.forEach(element => {
                    fileLinks.push(`${process.env.REACT_APP_BACKEND_HOST}/${element}`)
                });
            }

            if (dataExpedition?.documents && dataExpedition?.documents?.length > 0) {
                dataExpedition?.documents?.forEach(element => {
                    fileLinks.push(`${process.env.REACT_APP_BACKEND_HOST}/${element.document}`)
                });
            }



            const zip = new JSZip();

            // Utilisation de Promise.all pour attendre que toutes les requêtes fetch soient terminées
            // Utilisation de Promise.all pour attendre que toutes les requêtes fetch soient terminées
            await Promise.all(
                fileLinks.map(async (fileLink) => {
                    try {
                        const response = await fetch(fileLink);
                        if (!response.ok) {
                            throw new Error(`Failed to fetch: ${fileLink}`);
                        }

                        const fileBlob = await response.blob();
                        console.log(`File blob for ${fileLink}:`, fileBlob); // Ajoutez cette ligne pour déboguer

                        const fileName = fileLink.split('/').pop();
                        if (fileName.endsWith('.pdf')) {
                            // Traitement spécifique pour les fichiers PDF
                            const pdfBuffer = await fileBlob.arrayBuffer();
                            const pdfBlob = new Blob([pdfBuffer], { type: 'application/pdf' });
                            zip.file(fileName, pdfBlob);
                        } else if (fileName.match(/\.(jpg|jpeg|png|gif)$/i)) {
                            // Traitement spécifique pour les images (ajoutez d'autres extensions si nécessaire)
                            const imageBlob = new Blob([fileBlob], { type: 'image/*' });
                            zip.file(fileName, imageBlob);
                        } else {
                            // Traitement générique pour les autres types de fichiers
                            zip.file(fileName, fileBlob);
                        }
                    } catch (error) {
                        console.error(`Error fetching ${fileLink}:`, error);
                    }
                })
            );


            zip.generateAsync({ type: 'blob' }).then((blob) => {
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = 'files.zip';
                link.click();
            });

        } catch (error) {
            toast.error('Une erreur est survenue lors de la génération du fichier zip.', {
                position: "top-center",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

        }


    }, []);

    const ZipGenerator = () => {
        handleDownloadClick()
    }


    return (
        <div className="bg-white rounded-md h-100">
            <div className="px-3">
                <div className="text-center  flex items-center justify-center text-base font-semibold h-[40px] py-6">
                    <span>DETAILS COLIS</span>

                </div>
                <hr className="mt-0" />
                {(packages && packages.length > 0) &&
                    <div className="mt-6">
                        <div class="grid grid-cols-1 md:grid-cols-2 gap-6 text-base font-normal">
                            <div class="">

                                <Paragraphe label={'LONGUEUR'} value={`${packages[0]?.length ? packages[0]?.length : ""} cm`} />
                                <Paragraphe label={'LARGEUR'} value={`${packages[0]?.width ? packages[0]?.width : ""}cm`} />
                                <Paragraphe label={'HAUTEUR'} value={`${packages[0]?.height ? packages[0]?.height : ""}cm`} />

                            </div>
                            <div class="">
                                <Paragraphe label={'POIDS VOLUMéTRIQUE'.toUpperCase()} value={`${packages[0]?.weight_volume ? packages[0]?.weight_volume : ""} kg`} />
                                <Paragraphe label={'VALEUR DéCLAREE'} value={`${packages[0]?.value_in_douane ? packages[0]?.value_in_douane : ""}€`} />
                                <Paragraphe label={'POIDS TOTAL'} value={`${packages[0]?.weight ? packages[0]?.weight : ""} KG`} />

                            </div>
                        </div>


                        <div className="my-2 ">
                            <span className="text-sm font-semibold"> DESCRIPTION :  </span>
                            <span className="text-sm ml-1 font-normal block">   {packages[0]?.description ? packages[0]?.description : ""}</span>
                        </div>


                    </div>
                }

                {(courses.length > 0 && courses) &&
                    <div className="mt-6">
                        <div class="grid grid-cols-1 md:grid-cols-2 gap-6 text-base font-normal">
                            <div class="">

                                <Paragraphe label={'LONGUEUR'} value={`${packages[0]?.length ? packages[0]?.length : ""} cm`} />
                                <Paragraphe label={'LARGEUR'} value={`${packages[0]?.width ? packages[0]?.width : ""}cm`} />
                                <Paragraphe label={'HAUTEUR'} value={`${packages[0]?.height ? packages[0]?.height : ""}cm`} />

                            </div>
                            <div class="">
                                <Paragraphe label={'POIDS VOLUMéTRIQUE'.toUpperCase()} value={`${packages[0]?.weight_volume ? packages[0]?.weight_volume : ""} kg`} />
                                <Paragraphe label={'VALEUR DéCLAREE'.toUpperCase()} value={`${courses[0]?.valeur_douane ? courses[0]?.valeur_douane : ""}€`} />
                                <Paragraphe label={'POIDS TOTAL'} value={`${packages[0]?.weight ? packages[0]?.weight : ""} KG`} />

                            </div>
                        </div>


                        <div className="my-2 ">
                            <span className="text-sm font-semibold"> DESCRIPTION :  </span>
                            <span className="text-sm ml-1 font-normal block">  {packages[0]?.description ? packages[0]?.description : ""}</span>
                        </div>

                    </div>
                }

                <div className="my-2 ">
                    <span
                        onClick={ZipGenerator}
                        download={true}
                        className="mx-1 my-auto btn"
                        style={{
                            color: "#fff",
                            backgroundColor: `${exnetSpaceColor(exnetSpace)}`,
                        }}
                    >
                        {/* <TbFileDownload size={25} /> */}
                        Télécharger les fichiers
                    </span>
                </div>
            </div>
        </div>
    );
}

export default ColisClientDetailComponent;
