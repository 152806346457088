import React, { useState } from "react";
import useDisclosure from "../../hooks/useDisclosure";
import CountriesSelector from "../Utils/Selectors/CountriesSelector";
import ModalComponent from "../Utils/Modal/Index";
import useFormValues from "../../hooks/useFormValues";
import adminService from "../../services/admin/adminService";
import Select from "react-select";
import UsersSelector from "../Utils/Selectors/UsersSelector";
import { ToastContainer, toast } from "react-toastify";
import ModalUI from "../Utils/Modal/Modal";
import PhoneInput from "react-phone-input-2";
import CivilitySelector from "../Utils/Selectors/CivilitySelector";
import { verifyContact } from "../../utils/Functions/verifyDropdown";
import InputComponent from "../InputComponent/InputComponent";
import clsx from "clsx";
import PhoneNumber from "libphonenumber-js";
import { getCountryNameOrCode } from "../../utils/const/countriesConstant";

const FormComponent = ({
  modalFormContent,
  newData,
  editable,
  onClose,
  onSubmit,
}) => {
  const [contactData, SetContactData] = useState(
    modalFormContent
      ? modalFormContent
      : {
        country: "fr",
      }
  );

  const [errorInformation, setErrorInformation] = useState({
    field: "",
    message: "",
  });

  const setFormValue = (key, value) => {
    SetContactData((d) => ({
      ...d,
      [key]: value,
    }));
  };

  const validatePhoneNumber = (number, code) => {
    console.log("Salut comment", number, code);
    try {
      const phone = PhoneNumber(number, code);

      return phone.isValid();
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const [contactType, setContactType] = useState([
    { value: "expeditor", label: "Expéditeur" },
    { value: "receiver", label: "Receveur" },
  ]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [makeUpdate, setmakeUpdate] = useState(false);

  const handleSubmit = async (e) => {
    const { valid, error, errorData } = verifyContact(contactData);

    e.preventDefault();

    if (!error) {
      if (
        validatePhoneNumber(
          contactData?.telephone1,
          getCountryNameOrCode(
            getCountryNameOrCode(contactData?.country),
            true
          ) ?? "fr"
        )
      ) {
        if (
          contactData?.telephone2 &&
          contactData?.telephone2 !== "null" &&
          contactData?.telephone2 !== null &&
          (contactData?.telephone2).length >= 5 &&
          validatePhoneNumber(
            contactData?.telephone2,
            getCountryNameOrCode(
              getCountryNameOrCode(contactData?.country),
              true
            ) ?? "fr"
          )
        ) {
          const toasId = toast.loading("Soumission du formulaire", {
            position: "top-right",
            theme: "light",
          });

          try {
            setIsSubmitting(true);
            let newData = { ...contactData };
            newData.fullname =
              newData.firstName.toUpperCase() + " " + newData.lastName;

            delete contactData.updated_at;
            delete contactData.created_at;

            const { data } = makeUpdate
              ? await adminService.updateContact(newData)
              : await adminService.createContact(newData);

            if (data.success) {
              onClose();
              onSubmit?.(makeUpdate ? newData : data.data);
              toast.update(toasId, {
                render: data.message,
                type: "success",
                isLoading: false,
                autoClose: 500,
              });
              setTimeout(() => {
                //window.location.reload(false);
              }, 500);
            } else if (data.is_done) {
              onClose();
              toast.update(toasId, {
                render: "Le Formulaire a été soumise avec succès!",
                type: "success",
                isLoading: false,
                autoClose: 500,
              });
              setTimeout(() => {
                //window.location.reload(false);
              }, 500);
            }
          } catch (error) {
            console.error(error);
            toast.update(toasId, {
              render: "Une erreur lors de la soumission de votre demande",
              type: "eeror",
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          } finally {
            setIsSubmitting(false);
          }
        } else {
          setErrorInformation({
            field: "telephone2",
            message: "Erreur lors de la validation du numéro de téléphone",
          });
        }
      } else {
        setErrorInformation({
          field: "telephone1",
          message: "Erreur lors de la validation du numéro de téléphone",
        });
      }
    } else {
      setErrorInformation({ ...errorData });
    }
  };

  const DesabledInput = () => {
    return newData || makeUpdate ? false : true;
  };

  const value = contactType.find((c) => c?.value === contactData.type_contact);

  return (
    <div className="p-3">
      {!newData && (
        <div className={"flex my-4"}>
          <button
            type="submit"
            className="text-white  bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
            onClick={() => setmakeUpdate(DesabledInput())}
          >
            <span className={"fa fa-edit"}></span>
          </button>
        </div>
      )}

      <form onSubmit={handleSubmit}>
        <div className="grid gap-6 mb-6 md:grid-cols-2">
          <div className="text-left">
            <label
              for="name"
              className="block mb-2 text-sm font-medium text-gray-900 "
            >
              Civilité
            </label>
            <CivilitySelector
              showLabel={false}
              disabled={DesabledInput()}
              value={contactData.civility}
              onChange={(v) => setFormValue("civility", v)}
            />
          </div>

          <div className="text-left">
            <label
              for="lastName"
              className="block mb-2 text-sm font-medium text-gray-900 "
            >
              Nom*
            </label>
            <InputComponent
              value={
                contactData.firstName || contactData?.fullname?.split(" ")[0]
              }
              onChange={(valeur) => setFormValue("firstName", valeur)}
              errorMessage={errorInformation.message}
              placeholder={""}
              label=""
              withLabel={false}
              isError={errorInformation.field === "firstName" ? true : false}
            />
          </div>

          <div className="text-left">
            <label
              for="firstName"
              className="block mb-2 text-sm font-medium text-gray-900 "
            >
              Prénoms*
            </label>

            <InputComponent
              value={
                contactData.lastName || contactData?.fullname?.split(" ")[0]
              }
              onChange={(valeur) => setFormValue("lastName", valeur)}
              errorMessage={errorInformation.message}
              placeholder={""}
              label=""
              withLabel={false}
              isError={errorInformation.field === "lastName" ? true : false}
            />
          </div>

          <div className="text-left">
            <label
              for="address1"
              className="block mb-2 text-sm font-medium text-gray-900 "
            >
              Adresse*
            </label>

            <InputComponent
              value={contactData?.address1}
              onChange={(valeur) => setFormValue("address1", valeur)}
              errorMessage={errorInformation.message}
              placeholder={""}
              label=""
              withLabel={false}
              isError={errorInformation.field === "address1" ? true : false}
            />
          </div>

          <div className="text-left">
            <label
              for="address2"
              className="block mb-2 text-sm font-medium text-gray-900 "
            >
              Complément d'adresse
            </label>

            <InputComponent
              value={contactData?.address2}
              onChange={(valeur) => setFormValue("address2", valeur)}
              errorMessage={errorInformation.message}
              placeholder={""}
              label=""
              withLabel={false}
              isError={errorInformation.field === "address2" ? true : false}
            />
          </div>

          <div className="text-left">
            <label
              for="address"
              className="block mb-2 text-sm font-medium text-gray-900 "
            >
              Ville*
            </label>

            <InputComponent
              value={contactData?.city}
              onChange={(valeur) => setFormValue("city", valeur)}
              errorMessage={errorInformation.message}
              placeholder={""}
              label=""
              withLabel={false}
              isError={errorInformation.field === "city" ? true : false}
            />
          </div>

          <div className="text-left">
            <label
              for="compta_responsible"
              className="block mb-2 text-sm font-medium text-gray-900 "
            >
              Code postal*
            </label>

            <InputComponent
              value={contactData?.postal_code}
              onChange={(valeur) => setFormValue("postal_code", valeur)}
              errorMessage={errorInformation.message}
              placeholder={""}
              label=""
              withLabel={false}
              isError={errorInformation.field === "postal_code" ? true : false}
            />
          </div>

          <div className="text-left">
            <CountriesSelector
              selectedValue={contactData.country}
              onSelect={(v) => {
                setFormValue("country", v.value);
              }}
              isDisabled={DesabledInput()}
            />
          </div>

          <div className="text-left">
            <label
              for="telephone1"
              className="block mb-2 text-sm font-medium text-gray-900 "
            >
              Téléphone 1*
            </label>

            <PhoneInput
              country={contactData?.country?.toLowerCase()}
              defaultValue={contactData.telephone1}
              value={contactData.telephone1}
              onChange={(value, country, e, formattedValue) => {
                setFormValue("telephone1", formattedValue);
              }}
              inputClass={clsx({
                "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500 form-controle-error":
                  errorInformation.field === "telephone1",
                "ring-gray-300 focus:ring-gray-500 bg-white border border-gray-500 form-controle":
                  errorInformation.field !== "telephone1",
              })}
              countryCodeEditable={true}
              disableDropdown={false}
            />
            {errorInformation.field === "telephone1" && (
              <p className="mt-2 text-sm text-red-600" id="email-error">
                {errorInformation.message}
              </p>
            )}
          </div>

          <div className="text-left">
            <label
              for="telephone2"
              className="block mb-2 text-sm font-medium text-gray-900 "
            >
              Téléphone2
            </label>

            <PhoneInput
              country={contactData.country}
              defaultValue={contactData.telephone2}
              value={contactData.telephone2}
              onChange={(value, country, e, formattedValue) => {
                setFormValue("telephone2", formattedValue);
              }}
              inputClass={clsx({
                "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500 form-controle-error":
                  errorInformation.field === "telephone2",
                "ring-gray-300 focus:ring-gray-500 bg-white border border-gray-500 form-controle":
                  errorInformation.field !== "telephone2",
              })}
              countryCodeEditable={true}
              disableDropdown={false}
            />
            {errorInformation.field === "telephone2" && (
              <p className="mt-2 text-sm text-red-600" id="email-error">
                {errorInformation.message}
              </p>
            )}
          </div>

          <div className="text-left">
            <label
              for="email1"
              className="block mb-2 text-sm font-medium text-gray-900 "
            >
              Email1*
            </label>

            <InputComponent
              value={contactData?.email1}
              onChange={(valeur) => setFormValue("email1", valeur)}
              errorMessage={errorInformation.message}
              placeholder={""}
              label=""
              withLabel={false}
              isError={errorInformation.field === "email1" ? true : false}
            />
          </div>

          <div className="text-left">
            <label
              for="company"
              className="block mb-2 text-sm font-medium text-gray-900 "
            >
              Société (facultatif)
            </label>

            <InputComponent
              value={contactData?.company}
              onChange={(valeur) => setFormValue("company", valeur)}
              errorMessage={errorInformation.message}
              placeholder={""}
              label=""
              withLabel={false}
              isError={errorInformation.field === "company" ? true : false}
            />
          </div>

          <div className="text-left">
            <UsersSelector
              profil={"user"}
              selectedValue={contactData.user_id}
              onSelect={(val) => {
                setFormValue("user_id", val.user_id);
              }}
            />
          </div>

          {/*  <div className="text-left">
                        <label
                            for="email2"
                            className="block mb-2 text-sm font-medium text-gray-900 "
                        >
                            Email2
                        </label>

                        <InputComponent
                            value={contactData?.email2}
                            onChange={(valeur) => setFormValue("email2", valeur)}
                            errorMessage={errorInformation.message}
                            placeholder={""}
                            label='' withLabel={false}
                            isError={errorInformation.field === 'email2' ? true : false}
                        />
                    </div> */}

          <div className="text-left">
            <label
              for="address1"
              className="block mb-2 text-sm font-medium text-gray-900 "
            >
              Type de contact
            </label>
            <Select
              options={contactType}
              value={value}
              onChange={(v) => {
                setFormValue("type_contact", v.value);
                setFormValue("type", v.value);
              }}
              isDisabled={DesabledInput()}
            />
          </div>
        </div>

        <div className="flex justify-end">
          <div
            type="button"
            onClick={() => onClose?.()}
            className="bg-gray-200 hover:bg-gray-300 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center mr-5"
          >
            Annuler
          </div>
          {(editable || makeUpdate) && (
            <button
              type="submit"
              className="text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
              disabled={isSubmitting}
            >
              Modifier
            </button>
          )}

          {newData && (
            <button
              type="submit"
              className="text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
              disabled={isSubmitting}
            >
              Enregistrer
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default function ContactAdminFomComponent({
  withoutModal,
  modalFormContent,
  editable,
  dataType,
  Fermer,
}) {
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      {withoutModal ? (
        <FormComponent
          modalFormContent={modalFormContent}
          editable={editable}
          dataType={dataType}
          onClose={Fermer}
        />
      ) : (
        <>
          <div
            className="float-right btn btn-primary btn-rounded"
            onClick={onOpen}
            editable={false}
          >
            <i className="fas fa-plus"></i>
          </div>
          <ModalComponent
            isOpen={isOpen}
            onClose={onClose}
            title="Créer un contact"
          >
            <FormComponent
              modalFormContent={null}
              newData={true}
              dataType={dataType}
              onClose={onClose}
            />
          </ModalComponent>
        </>
      )}
    </>
  );
}

export const FormModal = ({ isOpen, selectedData, onClose, onSubmit }) => {
  return (
    <ModalUI
      isOpen={isOpen}
      onClose={onClose}
      title={selectedData ? "Modifier le contact" : "Créer un contact"}
    >
      <FormComponent
        modalFormContent={selectedData}
        newData={!Boolean(selectedData)}
        onClose={onClose}
        onSubmit={onSubmit}
      />
    </ModalUI>
  );
};
