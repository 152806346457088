import axios from "axios";

class TokenService {
    setHeader() {
        const authorizationToken = localStorage.getItem('client');
        if (authorizationToken) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${authorizationToken}`;
        }
    }

    refreshToken(token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }

    removeHeader() {
        axios.defaults.headers.common['Authorization'] = '';
    }
}

export default new TokenService();
