import React, { useEffect } from "react";
import { formatDefaultDate } from "../../../utils";
import { chekText } from "../../../utils/Functions/otherFunctions";
import { Paragraphe } from "../../Utils/UI/PragrapheComponent";
import { getCountryNameOrCode } from "../../../utils/const/countriesConstant";



function DetailAgentComponent({ agent, details, instruction, date,heure }) {

useEffect(() => {
    console.log('details client',details)
}, [])

    return (
        <>

            {details && (
                <div className="bg-white rounded-md text-uppercase h-100">
                    <div className="text-center  flex items-center justify-center text-base font-semibold h-[40px] py-6">
                        {agent === "expediteur"
                            ? "EXPEDITEUR"
                            : "DESTINATAIRE"}
                    </div>
                    <hr />
                    <div className="grid grid-cols-1 gap-6 my-3 md:grid-cols-2">
                        <div className="">
                            <div className=" h-100">
                                <div className="px-3 h-100">

                                    <div class="">
                                        <Paragraphe label={'Entreprise'} value={details.company} />
                                        <Paragraphe label={'Nom et prenom'} value={details.fullname} />
                                        <Paragraphe label={'Adresse'} value={details.address1} />
                                        <Paragraphe label={'Code postal'} value={details.postalCode} />
                                        <Paragraphe label={'Ville'} value={details.city} />

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" ">
                            <div className="h-100">
                                <div className="px-3 h-100">

                                    <div class=" ">
                                        <Paragraphe label={'Pays'} value={getCountryNameOrCode(details.country) } />
                                        <Paragraphe label={'Téléphone'} value={details.phone1} />
                                        <Paragraphe label={'Email'} value={details.email} mail={true}/>
                                        <div className="my-2 ">
                                            <span className="text-sm font-semibold"> {agent === "expediteur"
                                                ? "ENLEVEMENT"
                                                : "LIVRAISON"}{" "} :  </span>
                                            <span className="text-sm ml-1 font-normal"> LE : {formatDefaultDate(date)} à {heure}</span>
                                        </div>

                                        <div className="my-2 ">
                                            <span className="text-sm font-semibold"> INSTRUCTIONS{" "}
                                            {agent === "expediteur"
                                                ? "D'ENLEVEMENT"
                                                : "DE LIVRAISON"} :  </span>
                                            <span className="text-sm ml-1 font-normal block">  {instruction}</span>
                                        </div>

                                      
                                       

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}</>
    );
}

export default DetailAgentComponent;
