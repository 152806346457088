import React, { useEffect } from "react";
import searchSvg from '../../../assets/img/icon/search-normal.svg';
import avatar_3 from '../../../assets/img/profile/avatar-05.jpg';

import useTchats from "../../../states/tchat";
import { useState } from "react";
import useAuth from "../../../hooks/useAuth";
import ChatService from "../../../services/chat/ChatService";
import { TbUser } from "react-icons/tb";

function DiscussionList({ allChat, setActiveChat }) {
    const { tchats } = useTchats();
    const [searchValue, setSearchValue] = useState("");
    const [listDiscussion, setListDiscussion] = useState([])
    const { isAuthenticated, user } = useAuth();


    //la liste a mapper
    const filteredLists = tchats.items.filter(item => {
        const matchingRef = item.ref.includes(searchValue)
        const matchingContact = item.contact.includes(searchValue)
        //const matchingEmail = item.email.includes(searchValue)
        return matchingRef || matchingContact //|| matchingEmail 
    })


    const fetchMessageByShipping = (shipping_id) => {
        ChatService.fetchMessages(shipping_id, 1).then(
            (res) => {
                let newActive = res.data.data.data
                newActive = newActive[0]
                setActiveChat({ ...newActive })
            },
            (error) => {
                console.log(error)
            }
        )

    }

    const orderByLastMessage = () => {
        const newOrder = allChat.sort((a, b) => {
            let firstChat = a?.chat_messages.length > 0 ? a?.chat_messages[a?.chat_messages.length - 1].created_at : ''
            let secondChat = b?.chat_messages.length > 0 ? b?.chat_messages[b?.chat_messages.length - 1].created_at : ''

            // Inversez l'ordre de soustraction pour trier dans l'ordre décroissant
            return new Date(secondChat) - new Date(firstChat);
        });
        setListDiscussion([...newOrder])
    }


    useEffect(() => {
        orderByLastMessage()
    }, [setActiveChat, allChat])


    return (
        <div className="card chat-box mb-0">
            <div className="chat-widgets">
                <div className="chat-user-group d-flex align-items-center">
                    <div className="img-users call-user">
                        <a href="profile.html">
                        <TbUser size={25} className="text-black" />
                        </a>
                        <span className="active-users" />
                    </div>
                    <div className="chat-users user-main">
                        <div className="user-titles">
                            <h5>{user?.name}</h5>
                            <div className="chat-user-time">
                                <p>10:22 AM</p>
                            </div>
                        </div>
                        <div className="drop-item chat-menu user-dot-list">
                            <a
                                href="#"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                className
                            >
                                <i className="feather-more-vertical" />
                            </a>
                            <div className="dropdown-menu" style={{}}>
                                <a
                                    className="dropdown-item"
                                    href="javascript:;"
                                >
                                    <i className="feather-user me-2 text-primary" />
                                    Profile
                                </a>
                                <a
                                    className="dropdown-item"
                                    href="javascript:;"
                                >
                                    <i className="feather-plus-circle me-2 text-success" />
                                    Archive
                                </a>
                                <a
                                    className="dropdown-item"
                                    href="javascript:;"
                                >
                                    <i className="feather-trash-2 me-2 text-danger" />
                                    Delete
                                </a>
                                <a
                                    className="dropdown-item"
                                    href="javascript:;"
                                >
                                    <i className="feather-slash me-2 text-secondary" />
                                    Block
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="top-liv-search top-chat-search">
                    <form>
                        <div className="chat-search">
                            <div className="form-group me-2 mb-0">
                                <input
                                    type="text"
                                    className="form-control border border-black"
                                    placeholder="Search here"
                                    value={searchValue}
                                    onChange={e => {
                                        setSearchValue(e.target.value)
                                    }}
                                />
                                <a className="btn">
                                    <img
                                        src={searchSvg}
                                        alt
                                    />
                                </a>
                            </div>
                            <div className="add-search">
                                <a href="javascript:;">
                                    <i className="feather-plus" />
                                </a>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="md:max-h-[70vh] overflow-y-scroll">
                    {listDiscussion.length > 0 ? <>
                        {listDiscussion.map((item, index) => (
                            <div className="chat-user-group d-flex align-items-center pointer" onClick={() => fetchMessageByShipping(item.shipping_id)}>
                                <div className="img-users call-user">
                                    <a href="#">
                                    <TbUser size={25} className="text-black" />
                                    </a>
                                    <span className="active-users bg-info" />
                                </div>
                                <div className="chat-users">
                                    <h6 className="text-[14px] font-mon font-semibold">
                                        REF: #{item?.shipping.ref}
                                    </h6>
                                    <div className="user-titles d-flex">
                                        <h5>{item?.shipping?.username}</h5>
                                        <div className="chat-user-time">
                                            <p>{item?.chat_messages.length > 0 ? item?.chat_messages[item?.chat_messages.length - 1].created_at.split('T')[1].split(':').slice(0,2).join(':') : ''}</p>
                                        </div>
                                    </div>
                                    <div className="user-text d-flex">
                                        <p>{item?.chat_messages.length > 0 ? item?.chat_messages[item?.chat_messages.length - 1].message : ''}...</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </> :
                        <h4 className="text-center">"Vous n'avez pas encore de discussion"</h4>
                    }


                </div>
            </div>
        </div>

    );
}

export default DiscussionList;
