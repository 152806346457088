import React, { useCallback, useState } from 'react'
import ModalComponent from '../../../Utils/Modal/Index'
import { getFactureRef } from '../../../../utils'
import { useApi } from '../../../../hooks/useRequestLoader';
import factureService from '../../../../services/facture/factureService';
import ExpeditionLists from '../../../Tableaux/Expeditions/Lists';
import { useParams } from 'react-router-dom';
import useDisclosure from '../../../../hooks/useDisclosure';
import { exnetSpaceColor } from '../../../../utils/Functions/otherFunctions';
import FacturationModal from '../../../Facturation/Modal';
import useAvoirLoader from '../../../../hooks/useAvoirLoader';

export default function AvoirShippings({ isOpen, onClose, facture }) {
    const formattedRef = facture ? getFactureRef(facture) : "";
    //console.log({ facture })

    const { milieu } = useParams()
    const { isOpen: isPreviewSubmitOpen, onOpen: onPreviewSubmitOpen, onClose: onPreviewSubmitClose } = useDisclosure();
    const { data } = useAvoirLoader(facture.ref)

    const shippingsInAvoir = data.map(el => el.shipping_id)

    const ref = facture?.ref

    const request = useCallback(() => {
        return factureService.getAvoirShippings({ ref })
    }, [ref])

    const { items, isLoading } = useApi({ request })

    // console.log({ items, isLoading, lists: items?.shipping_ids })

    const shippingsLists = items?.shipping_ids?.map(sh => ({
        ...sh,
        type: milieu
    })) || [];

    const ids = shippingsLists.map(s => s.id)

    const onSubmit = useCallback(
        () => {
            onPreviewSubmitOpen()
            onClose()
        },
        [onPreviewSubmitOpen, onClose],
    )

    return (
        <>
            <ModalComponent containerClassName={"sm:max-w-[1200px]"} isOpen={isOpen} onClose={onClose} className={"max-w-5xl"}>
                <h4 className="mb-4 text-2xl font-bold">Liste des expéditions de la facture {formattedRef}</h4>
                <div className="border border-gray-400 bg-gray-50">
                    <ExpeditionLists
                        isLoading={isLoading}
                        items={shippingsLists}
                        getShippingUrl={shipping => `/ops/${milieu}/dash/facturee/${shipping.ref}?isAvoir=${ref}&formattedRef=${formattedRef}`}
                        shippingsInAvoir={shippingsInAvoir}
                        linkTarget = {"_blank"}
                    />
                </div>
                <div className="flex justify-end mt-10">
                    <button
                        onClick={onSubmit}
                        type="button"
                        className="mb-2 text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
                        // disabled={isSubmitting}
                        style={{ color: "#fff", backgroundColor: `${exnetSpaceColor(milieu)}` }}
                    >
                        Valider
                    </button>
                </div>

            </ModalComponent>
            <FacturationModal
                shippingIds={ids}
                isOpen={isPreviewSubmitOpen}
                onClose={onPreviewSubmitClose}
                selectedFacture={facture}
            />
        </>
    )
}
