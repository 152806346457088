import React, { useEffect, useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import CountriesSelector from "../../Utils/Selectors/CountriesSelector";
import UsersSelector from "../../Utils/Selectors/UsersSelector";
import contactService from "../../../services/contact/contactService";
import { useDispatch, useSelector } from "react-redux";
import {
  addContact,
  addOneContact,
  updateContact,
} from "../../../features/slices/contactSlice";
import PhoneInput from "react-phone-input-2";
import CivilitySelector from "../../Utils/Selectors/CivilitySelector";
import { verifyContact } from "../../../utils/Functions/verifyDropdown";
import InputComponent from "../../InputComponent/InputComponent";
import clsx from "clsx";
import PhoneNumber from "libphonenumber-js";
import { getCountryNameOrCode } from "../../../utils/const/countriesConstant";

const FormComponent = ({
  modalFormContent,
  onClose,
  isNew,
  setmodalFormContent,
  setIsNew
}) => {
  const dispatch = useDispatch();
  const contacts = useSelector((state) => state.contact.contacts);
  const [errorInformation, setErrorInformation] = useState({
    field: "",
    message: "",
  });

  const [contactData, SetContactData] = useState(
    modalFormContent
      ? {
        ...modalFormContent,
        lastName: modalFormContent?.fullname?.split(" ")[1] ?? "",
        firstName: modalFormContent?.fullname?.split(" ")[0] ?? "",
        country: 'fr'
      }
      : {
        country: "fr",
      }
  );

  const setFormValue = (key, value) => {
    SetContactData((d) => ({
      ...d,
      [key]: value,
    }));
  };

  const [contactType, setContactType] = useState([
    { value: "expeditor", label: "Expéditeur" },
    { value: "receiver", label: "Receveur" },
  ]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [makeUpdate, setmakeUpdate] = useState(false);

  const validatePhoneNumber = (number, code) => {
    console.log("Salut comment", number, code);
    try {
      const phone = PhoneNumber(number, code);

      return phone.isValid();
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    // form validation
    console.log(verifyContact(contactData), contactData)
    const { valid, error, errorData } = verifyContact(contactData);
    if (!error) {
      if (
        validatePhoneNumber(
          contactData?.telephone1,
          getCountryNameOrCode(
            getCountryNameOrCode(contactData?.country),
            true
          ) ?? "fr"
        )
      ) {

        const toasId = toast.loading("Soumission du formulaire", {
          position: "top-right",
          theme: "light",
        });

        try {
          setIsSubmitting(true);

          let { updated_at, created_at, ...value } = contactData;
          let newData = { ...value };
          newData.fullname =
            newData.firstName.toUpperCase() + " " + newData.lastName;

          console.log({ isNew, newData })

          const {
            data: { success, data, is_done, message },
          } = !isNew
              ? await contactService.clientUpdateContact(newData)
              : await contactService.clientCreateContact(newData);

          if (data.is_done) {
            toast.update(toasId, {
              render: "Modification éffectuée avec succès!",
              type: "success",
              isLoading: false,
              autoClose: 500,
            });
            dispatch(
              updateContact({ id: contactData.id, updatedData: contactData })
            );
            setTimeout(() => {
              setIsNew(true)
              onClose();
            }, 1000);
          } else if (success) {
            toast.update(toasId, {
              render: data.message,
              type: "success",
              isLoading: false,
              autoClose: 500,
            });

            dispatch(addOneContact(data));

            setTimeout(() => {
              setIsNew(true)
              onClose();
            }, 1000);
          } else {
            toast.update(toasId, {
              render: "Une erreur de soumission du formulair2",
              type: "error",
              isLoading: false,
              autoClose: 500,
            });
            // onClose();
          }
        } catch (error) {
          toast.update(toasId, {
            render: "Une erreur lors de la soumission du formulaire",
            type: "error",
            isLoading: false,
            autoClose: 500,
          });
        } finally {
          setIsSubmitting(false);
        }

      } else {
        setErrorInformation({
          field: "telephone1",
          message: "Erreur lors de la validation du numéro de téléphone",
        });
      }
    } else {
      setErrorInformation({ ...errorData });
    }
  };

  const DesabledInput = () => {
    return isNew || makeUpdate ? false : true;
  };

  const value = contactType.find((c) => c?.value === contactData.type_contact);

  /*  useEffect(() => {
     if (isNew) {
       console.log(isNew)
       setmodalFormContent({});
     }
   }, [isNew]); */

  console.log({ contactData })

  return (
    <div className="p-3">
      <form onSubmit={onSubmit}>
        <div className="grid gap-6 mb-6 md:grid-cols-2">
          <div className="text-left">
            <label
              for="name"
              className="block mb-2 text-sm font-medium text-gray-900 uppercase"
            >
              Civilité
            </label>
            <CivilitySelector
              showLabel={false}
              value={contactData?.civility}
              onChange={(v) => setFormValue("civility", v)}
            />
          </div>
          <div className="text-left">
            <label
              for="company"
              className="block mb-2 text-sm font-medium text-gray-900 uppercase"
            >
              Entreprise (FACULTATIF)
            </label>

            <InputComponent
              value={contactData?.company}
              onChange={(valeur) => setFormValue("company", valeur)}
              errorMessage={errorInformation.message}
              placeholder={""}
              label=""
              withLabel={false}
              isError={errorInformation.field === "company" ? true : false}
            />
          </div>

          <div className="text-left">
            <label
              for="lastName"
              className="block mb-2 text-sm font-medium text-gray-900 uppercase"
            >
              Nom*
            </label>

            <InputComponent
              value={contactData?.firstName}
              onChange={(valeur) => setFormValue("firstName", valeur)}
              errorMessage={errorInformation.message}
              placeholder={""}
              label=""
              withLabel={false}
              isError={errorInformation.field === "firstName" ? true : false}
            />
          </div>
          <div className="text-left">
            <label
              for="firstName"
              className="block mb-2 text-sm font-medium text-gray-900 uppercase"
            >
              Prénom*
            </label>

            <InputComponent
              value={contactData?.lastName}
              onChange={(valeur) => setFormValue("lastName", valeur)}
              errorMessage={errorInformation.message}
              placeholder={""}
              label=""
              withLabel={false}
              isError={errorInformation.field === "lastName" ? true : false}
            />
          </div>

          <div className="col-span-2 text-left ">
            <CountriesSelector
              selectedValue={contactData?.country || "fr"}
              onSelect={(v) => {
                setFormValue("country", v.value);
              }}
              is
            />
          </div>
          <div className="text-left">
            <label
              for="address1"
              className="block mb-2 text-sm font-medium text-gray-900 uppercase"
            >
              Adresse*
            </label>

            <InputComponent
              value={contactData?.address1}
              onChange={(valeur) => setFormValue("address1", valeur)}
              errorMessage={errorInformation.message}
              placeholder={""}
              label=""
              withLabel={false}
              isError={errorInformation.field === "address1" ? true : false}
            />
          </div>{" "}
          <div className="text-left">
            <label
              for="address2"
              className="block mb-2 text-sm font-medium text-gray-900 uppercase"
            >
              Complément d'adresse
            </label>

            <InputComponent
              value={contactData?.address2}
              onChange={(valeur) => setFormValue("address2", valeur)}
              errorMessage={errorInformation.message}
              placeholder={""}
              label=""
              withLabel={false}
              isError={errorInformation.field === "address2" ? true : false}
            />
          </div>
          <div className="text-left">
            <label
              for="compta_responsible"
              className="block mb-2 text-sm font-medium text-gray-900 uppercase"
            >
              Code postal*
            </label>

            <InputComponent
              value={contactData?.postal_code}
              onChange={(valeur) => setFormValue("postal_code", valeur)}
              errorMessage={errorInformation.message}
              placeholder={""}
              label=""
              withLabel={false}
              isError={errorInformation.field === "postal_code" ? true : false}
            />
          </div>
          <div className="text-left">
            <label
              for="address"
              className="block mb-2 text-sm font-medium text-gray-900 uppercase"
            >
              Ville*
            </label>

            <InputComponent
              value={contactData?.city}
              onChange={(valeur) => setFormValue("city", valeur)}
              errorMessage={errorInformation.message}
              placeholder={""}
              label=""
              withLabel={false}
              isError={errorInformation.field === "city" ? true : false}
            />
          </div>
          <div className="text-left">
            <label
              for="telephone1"
              className="block mb-2 text-sm font-medium text-gray-900 uppercase"
            >
              Téléphone 1*
            </label>
            <PhoneInput
              country={contactData?.country?.toLowerCase() || "fr"}
              defaultValue={contactData?.telephone1}
              value={contactData?.telephone1}
              onChange={(value, country, e, formattedValue) => {
                setFormValue("telephone1", formattedValue);
              }}
              inputClass={clsx({
                "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500 form-controle-error":
                  errorInformation.field === "telephone1",
                "ring-gray-300 focus:ring-gray-500 bg-white border border-gray-500 form-controle":
                  errorInformation.field !== "telephone1",
              })}
              countryCodeEditable={true}
              disableDropdown={false}
            />
            {errorInformation.field === "telephone1" && (
              <p className="mt-2 text-sm text-red-600" id="email-error">
                {errorInformation.message}
              </p>
            )}
          </div>
          <div className="text-left">
            <label
              for="telephone2"
              className="block mb-2 text-sm font-medium text-gray-900 uppercase"
            >
              Téléphone 2
            </label>
            <PhoneInput
              country={contactData?.country?.toLowerCase() || "fr"}
              defaultValue={contactData?.telephone2}
              value={contactData?.telephone2}
              onChange={(value, country, e, formattedValue) => {
                setFormValue("telephone2", formattedValue);
              }}
              // inputClass={'form-controle'}
              inputClass={clsx({
                "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500 form-controle-error":
                  errorInformation.field === "telephone2",
                "ring-gray-300 focus:ring-gray-500 bg-white border border-gray-500 form-controle":
                  errorInformation.field !== "telephone2",
              })}
              countryCodeEditable={true}
              disableDropdown={false}
            />
            {errorInformation.field === "telephone2" && (
              <p className="mt-2 text-sm text-red-600" id="email-error">
                {errorInformation.message}
              </p>
            )}
          </div>
          <div className="text-left">
            <label
              for="email1"
              className="block mb-2 text-sm font-medium text-gray-900 uppercase"
            >
              Email1*
            </label>

            <InputComponent
              value={contactData?.email1}
              onChange={(valeur) => setFormValue("email1", valeur)}
              errorMessage={errorInformation.message}
              placeholder={""}
              label=""
              withLabel={false}
              isError={errorInformation.field === "email1" ? true : false}
            />
          </div>
          <div className="text-left">
            <label
              for="email2"
              className="block mb-2 text-sm font-medium text-gray-900 uppercase"
            >
              Email 2
            </label>

            <InputComponent
              value={contactData?.email2}
              onChange={(valeur) => setFormValue("email2", valeur)}
              errorMessage={errorInformation.message}
              placeholder={""}
              label=""
              withLabel={false}
              isError={errorInformation.field === "email2" ? true : false}
            />
          </div>
          {/* <div className="text-left">
            <label
              for="address1"
              className="block mb-2 text-sm font-medium text-gray-900 "
            >
              Type de contact
            </label>
            <Select
              options={contactType}
              value={value}
              onChange={(v) => {
                setFormValue("type_contact", v.value);
                setFormValue("type", v.value);
              }}
              is
              className={"text-base"}
            />
          </div> */}
        </div>

        <div className="flex justify-center">
          {DesabledInput() && (
            <button
              type="submit"
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
              disabled={isSubmitting}
            >
              Enregistrer
            </button>
          )}

          {isNew && !DesabledInput() && (
            <button
              type="submit"
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
              disabled={isSubmitting}
            >
              Enregistrer
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default function ContactForm({
  modalFormContent,
  editable,
  setIsNew,
  isNew,
  onClose,
  setmodalFormContent,
}) {

  return (
    <>
      <FormComponent
        modalFormContent={modalFormContent}
        editable={editable}
        onClose={() => {
          setmodalFormContent(null)
          onClose()
        }}
        setIsNew={setIsNew}
        isNew={isNew}
        setmodalFormContent={setmodalFormContent}
      />
    </>
  );
}
